import React from 'react';

import { Design, Wording } from '@components';
import Link from 'next/link';

type Props = {
  slug: string;
  discountPercentage: number;
  title: string;
};

const MainOfferTitle = ({ slug, discountPercentage, title }: Props) => {
  /**
   * @description
   * This component contains the main product title
   * and offer percentage (the green box on right side of the title)
   * ex: "Starter Kyrie Irving Hoodie Sweatshirt" with a "41% OFF"
   */

  return (
    <Design
      display="flex"
      justifyContent="space-between"
      flexDirection={{ all: 'row', mobile: 'column' }}
      gap={{ all: '160px', tablet: '20px', mobile: '10px' }}
    >
      <Design flex="1">
        <Link href={`/product/${slug}`}>
          <a>
            <Wording
              fontSize={{ all: '40px', tablet: '32px', mobile: '16px' }}
              lineHeight={{
                all: '46.92px',
                tablet: '37.54px',
                mobile: '22.4px',
              }}
              letterSpacing="-3%"
              fontWeight={{ all: '600', mobile: '400' }}
            >
              {title}
            </Wording>
          </a>
        </Link>
      </Design>

      <Design
        width={{ all: '84px', tablet: '64px' }}
        height={{ all: '84px', tablet: '64px', mobile: '26px' }}
        backgroundColor="error"
        borderRadius={{ all: '8px', mobile: '4px' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={{ all: '0', mobile: '5px' }}
        flexDirection={{ all: 'column', mobile: 'row' }}
      >
        <Wording
          fontSize={{ all: '32px', tablet: '24px', mobile: '16px' }}
          fontWeight="600"
          color="background"
          lineHeight={{
            all: '37.54px',
            tablet: '28.15px',
            mobile: '22.4px',
          }}
        >
          {discountPercentage}%
        </Wording>
        <Wording
          fontSize={{ all: '20px', tablet: '16px', mobile: '12px' }}
          fontWeight={{ all: '400', tablet: '600', mobile: '400' }}
          color="background"
          lineHeight={{
            all: '32px',
            tablet: '18.77px',
            mobile: '14.08px',
          }}
        >
          OFF
        </Wording>
      </Design>
    </Design>
  );
};

export default MainOfferTitle;
