import React, { FC } from 'react';

import { Design, Wording } from '@components';
import { CategoryFacetOptionsModel, VisualSwatchTypeEnum } from '@models';
import { Checkbox } from 'antd';
import Image from 'next/image';

import styles from './filters-checkbox-item.module.scss';

type Props = {
  option: CategoryFacetOptionsModel;
  disabled: boolean;
  onChange: (value: string) => void;
};
const FiltersCheckboxItemModule: FC<Props> = ({
  option,
  disabled,
  onChange,
}) => {
  const renderVisualSwatch = () => {
    if (!option.visual_swatch_type) return <></>;
    if (
      option.visual_swatch_type === VisualSwatchTypeEnum.COLOR &&
      option.visual_swatch_color_code
    ) {
      return (
        <Design
          width="10px"
          height="10px"
          borderRadius="50%"
          border="1px solid"
          borderColor={option.slug === 'white' ? 'border' : 'transparent'}
          CustomBackgroundColor={option.visual_swatch_color_code}
        />
      );
    }

    return (
      <Design width="10px" height="10px" borderRadius="50%" overflow="hidden">
        <Image
          width="10px"
          height="10px"
          src={option?.visual_swatch_image?.urls['original'] ?? ''}
          alt={option.slug}
        />
      </Design>
    );
  };

  return (
    <Checkbox
      value={option.slug}
      className={styles.FilterItemCheckbox}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    >
      <Design display="flex" justifyContent="space-between" alignItems="center">
        <Wording fontSize="12px" color="surface" lineHeight="18px">
          {option.label}
        </Wording>
        {renderVisualSwatch()}
      </Design>
    </Checkbox>
  );
};

export default FiltersCheckboxItemModule;
