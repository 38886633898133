import React, { FC } from 'react';

import { Design, Wording } from '@components';
import Rate from 'antd/lib/rate';

interface Props {
  rating: number;
  reviewsCount: number;
}

const ProductRating: FC<Props> = ({ rating, reviewsCount }: Props) => {
  return (
    <Design display="flex" gap="14px" alignItems="center">
      <Rate allowHalf disabled defaultValue={rating} value={rating} />
      <Wording fontSize="16px" fontWeight="400">
        ({reviewsCount} Review)
      </Wording>
    </Design>
  );
};

export default ProductRating;
