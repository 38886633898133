import Redis from 'ioredis';
import logger from './logger.helper';

export default class RedisController {
  private static _client: Redis | null = null;

  public init() {
    if (this.client) return;
    return this.initiateClientConnection();
  }

  get client(): Redis {
    return RedisController._client!;
  }

  private initiateClientConnection() {
    try {
      RedisController._client = new Redis(
        process.env.NEXT_PUBLIC_REDIS_CONNECTION ?? ''
      );
    } catch (error) {
      console.log(error);
    }
  }

  public async isActiveConnection(callback) {
    this.client.set('test', 'success', (err, result) => {
      if (err) {
        callback(true);
      } else {
        logger.crit('Redis: Connection Error');
        callback(false);
      }
    });
  }

  async setKey(key: any, value: any) {
    try {
      return await this.client.set(
        key,
        JSON.stringify(value),
        'EX',
        +(process?.env?.NEXT_PUBLIC_CACHE_REVALIDATION_IN_SECONDS ?? 60) * 60
      );
    } catch (error) {
      logger.crit('Redis: ' + error);
    }
  }

  async getKey(key: string) {
    try {
      return await this.client.get(key);
    } catch (error) {
      logger.crit('Redis: ' + error);
    }
  }

  async flushCache() {
    // logger.info('Redis: Cache Removed.');
    return await this.client.flushall();
  }
}
