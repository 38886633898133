import React, { FC } from 'react';

import { Design, Wording } from '@components';
import {
  ProductVariationConvertModel,
  StockStatusEnum,
  VariationConvertModelAttributeModel,
} from '@models';
import { Select } from 'antd';

const { Option } = Select;
interface Props {
  onOptionSelect: (index: number, optionSlug: string) => void;
  variation: ProductVariationConvertModel;
  allowedVariations: string[];
  variationTitlePrefix: string;
  index: number;
  isInCart?: boolean;
  defaultSelected: number | null;
}

const SelectSwatch: FC<Props> = ({
  onOptionSelect,
  variation,
  allowedVariations,
  variationTitlePrefix,
  index,
  defaultSelected,
  isInCart = false,
}: Props) => {
  const renderOption = (option: VariationConvertModelAttributeModel) => {
    const isSelected = option.variationId === defaultSelected;
    const isOutOfStock = option.stock_status === StockStatusEnum.OUT_OF_STOCK;
    const isNotAllowed =
      allowedVariations.length > 0 &&
      allowedVariations.indexOf(option.slug) === -1;

    return (
      <Option
        key={option.slug}
        value={option.slug}
        defaultSelected={isSelected}
        disabled={isOutOfStock || isNotAllowed}
      >
        {option.label}
      </Option>
    );
  };

  const renderOptions = () =>
    variation.attributes.map((vr, index) => renderOption(vr));

  return (
    <Design
      key={variation.label}
      display="flex"
      gap="16px"
      marginTop="16px"
      marginBottom="16px"
      alignItems={isInCart ? 'flex-start' : 'center'}
      flexDirection={isInCart ? 'column' : 'row'}
    >
      <Wording
        fontWeight={isInCart ? '500' : 'normal'}
        fontSize={isInCart ? '16px' : '14px'}
      >
        {variationTitlePrefix + ' '}
        {variation.label}
        {!isInCart && ':'}
      </Wording>
      <Select
        size="large"
        placeholder={`Select ${variation.label}`}
        style={{ width: 200 }}
        onSelect={(value, option) => {
          onOptionSelect(index, option.itemSlug);
        }}
      >
        <Option disabled>Select {variation.label}</Option>
        {renderOptions()}
      </Select>
    </Design>
  );
};

export default SelectSwatch;
