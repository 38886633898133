import { productsActionTypes } from '@actionTypes';
import { takeEvery } from 'redux-saga/effects';

import axiosMiddleware from '../../axios.middleware';

export default function* productSaga() {
  yield takeEvery(productsActionTypes.LOAD_ALL_PRODUCTS, axiosMiddleware);
  yield takeEvery(productsActionTypes.LOAD_BEST_SELLERS, axiosMiddleware);
  yield takeEvery(productsActionTypes.LOAD_DEAL_CATEGORIES, axiosMiddleware);
  yield takeEvery(productsActionTypes.LOAD_PRODUCTS, axiosMiddleware);
  yield takeEvery(productsActionTypes.PRODUCT_VIEW, axiosMiddleware);
  yield takeEvery(productsActionTypes.GET_SIMILAR_ITEMS, axiosMiddleware);
  yield takeEvery(productsActionTypes.GET_RECOMMENDED_FOR_YOU, axiosMiddleware);
  yield takeEvery(productsActionTypes.GET_FREQUENTLY_BOUGHT, axiosMiddleware);
}
