import React, { FC } from 'react';

import { ProductFullDescriptionTabProps } from '@models';
import parse from 'html-react-parser';
import unescape from 'lodash/unescape';

import ProductTabTitle from '../tab-title/tab-title';
import styles from './full-descriptions-tab.module.scss';

const ProductFullDescriptionTab: FC<ProductFullDescriptionTabProps> = ({
  fullDescription,
}) => {
  return (
    <>
      <ProductTabTitle title="Descriptions" />
      <div className={styles.Container}>{parse(unescape(fullDescription))}</div>
    </>
  );
};

export default ProductFullDescriptionTab;
