import React, { useEffect, FC } from 'react';

import { Button, Design, Media, Wording } from '@components';
import { generateNestedRouteBySlug } from '@helpers';
import {
  CategoryModel,
  CheckoutPriceItemsType,
  SeoEventListLocation,
  SeoEventProductModel,
} from '@models';
import { categoriesSelectors, orderSelectors } from '@selectors';
import { triggerPurchaseCompleteEvent } from '@utils/seo/events';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

const CheckoutDoneComponent: FC = () => {
  const router = useRouter();
  const recentOrder = useSelector(orderSelectors.recentOrder);
  const { data: categoriesData } = useSelector(categoriesSelectors.selectAll);

  // Factory function that return user to cart page when click on back on this step
  useEffect(() => {
    const onRouteChangeStart = (url) => {
      if (router.asPath === '/checkout/done' && url.indexOf('/checkout') > -1)
        return router.push('/cart');
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [router.events]);

  useEffect(() => {
    // Seo Event : Purchase Complete
    const compatibleSeoProducts: SeoEventProductModel[] = [];
    recentOrder?.items?.forEach((p, i) => {
      const nestedCategories = generateNestedRouteBySlug(
        categoriesData as CategoryModel[],
        p.product.categories[0].slug
      );

      compatibleSeoProducts.push({
        brand: p?.product?.brand_name,
        category: nestedCategories,
        id: p.product.id.toString(),
        list_location: SeoEventListLocation.CHECKOUT_DONE,
        discount: p.list_price - p.price,
        position: i,
        name: p?.product?.title,
        price: p.price,
        qty: p.qty,
        variant: p.product?.variation?.attributes?.[0]?.option?.label ?? '',
      } as SeoEventProductModel);
    });

    triggerPurchaseCompleteEvent(
      recentOrder?.id,
      recentOrder?.total,
      recentOrder?.promotion?.coupon_code,
      +(
        recentOrder?.price_items?.find(
          (p) => p.type === CheckoutPriceItemsType.VAT
        )?.amount ?? 0
      ),
      +(
        recentOrder?.price_items?.find(
          (p) => p.type === CheckoutPriceItemsType.SHIPPING
        )?.amount ?? 0
      ),
      recentOrder.payment_method.slug,
      compatibleSeoProducts
    );
  }, []);

  return (
    <Design
      padding={{
        all: '48px',
        mobile: '24px 16px',
      }}
      backgroundColor="background"
      marginTop={{
        all: '42px',
        tablet: '32px',
        mobile: '1px',
      }}
      borderRadius={{
        all: '8px',
        mobile: '0',
      }}
    >
      <Design
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        margin="0 auto"
        maxWidth={{
          all: '100%',
          tablet: '460px',
        }}
      >
        <Design
          marginBottom={{
            all: '24px',
            mobile: '16px',
          }}
        >
          <Media lessThan="tablet">
            <Image
              alt="success-order"
              src="/checkout-done.svg"
              width="120px"
              height="120px"
            />
          </Media>
          <Media greaterThan="mobile">
            <Image
              alt="success-order"
              src="/checkout-done.svg"
              width="140px"
              height="140px"
            />
          </Media>
        </Design>
        <Wording
          fontSize={{
            all: '24px',
            mobile: '16px',
          }}
          lineHeight={{
            all: '34px',
            mobile: '22px',
          }}
          fontWeight="500"
          marginBottom={{
            all: '24px',
            mobile: '6px',
          }}
          color="surface"
          textAlign="center"
        >
          Thank you! Your order has been placed
        </Wording>
        <Wording
          fontSize={{
            all: '18px',
            mobile: '14px',
          }}
          lineHeight={{
            all: '26px',
            mobile: '20px',
          }}
          fontWeight="500"
          marginBottom={{
            all: '42px',
            mobile: '30px',
          }}
          color="caption"
          textAlign="center"
        >
          For order confirmation and detailed delivery information please check
          your email.
        </Wording>
        <Design
          width="100%"
          maxWidth={{ all: '100%', desktop: '498px' }}
          backgroundColor="background_alt"
          borderRadius="8px"
          padding={{ all: '24px', mobile: '16px' }}
        >
          <Design
            display="flex"
            justifyContent="space-between"
            width="100%"
            marginBottom="24px"
          >
            <Wording
              fontSize={{
                all: '16px',
                mobile: '14px',
              }}
              lineHeight={{
                all: '22px',
                mobile: '20px',
              }}
              fontWeight="500"
              color="caption"
            >
              Order Number
            </Wording>
            <Wording
              fontSize={{
                all: '16px',
                mobile: '14px',
              }}
              lineHeight={{
                all: '22px',
                mobile: '20px',
              }}
              fontWeight="500"
              color="surface"
            >
              {recentOrder?.id}
            </Wording>
          </Design>
          <Design display="flex" justifyContent="space-between" width="100%">
            <Wording
              fontSize={{
                all: '16px',
                mobile: '14px',
              }}
              lineHeight={{
                all: '22px',
                mobile: '20px',
              }}
              fontWeight="500"
              color="caption"
            >
              Delivery Date
            </Wording>
            <Wording
              fontSize={{
                all: '16px',
                mobile: '14px',
              }}
              lineHeight={{
                all: '22px',
                mobile: '20px',
              }}
              fontWeight="500"
              color="surface"
            >
              {recentOrder?.estimated_shipping}
            </Wording>
          </Design>
        </Design>
        <Design
          width="100%"
          maxWidth={{ all: '100%', desktop: '498px' }}
          display="flex"
          justifyContent="space-between"
          gap="16px"
          marginTop={{
            all: '36px',
            mobile: '98px',
          }}
        >
          <Button
            size="large"
            shape="round"
            onClick={() => router.push('/')}
            fullWidth
          >
            GO TO HOME
          </Button>
          <Button
            size="large"
            shape="round"
            type="primary"
            onClick={() =>
              recentOrder.id && router.push(`/profile/orders/${recentOrder.id}`)
            }
            fullWidth
          >
            TRACK ORDER
          </Button>
        </Design>
      </Design>
    </Design>
  );
};

export default CheckoutDoneComponent;
