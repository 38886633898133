import React, { ReactNode, FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Design, Wording } from '@components';
import { Colors } from '@models';

import ds from '@ds';

type Props = {
  loading?: boolean;
  width?: string;
  height?: string;
  size?: string;
  color?: Colors;
  children?: ReactNode;
};
const SpinnerModule: FC<Props> = ({
  loading = true,
  width = '24px',
  height = '24px',
  size = '16px',
  color,
  children,
}) => {
  return (
    <Design
      width={children ? 'auto' : width}
      height={children ? 'auto' : height}
    >
      {children ? (
        <Spin
          spinning={loading}
          indicator={
            <Wording fontSize={size}>
              <LoadingOutlined spin color={color && ds[color]} />
            </Wording>
          }
        >
          {children}
        </Spin>
      ) : (
        <Spin
          spinning={loading}
          indicator={
            <Wording fontSize={size}>
              <LoadingOutlined color={color && ds[color]} spin />
            </Wording>
          }
        />
      )}
    </Design>
  );
};

export default SpinnerModule;
