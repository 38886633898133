import { CartCompactItemModel } from './cart.model';
import {
  BannerEntity,
  DealEntity,
  HeroBannerStructure,
  SliderStructure,
} from './home.model';
import {
  CarouselProductsListModel,
  ProductDealModel,
} from './products-list.model';
import {
  ProductImage,
  ProductSpecificationModel,
  ProductVariation,
} from './products.model';
import { SeoEventListLocation, SeoEventProductModel } from './seo-events.model';
import { StockStatusEnum } from './shared.model';

export enum Mode {
  INLINE,
  GRID,
}

export interface ProductsListPaginationProps {
  pageSize: number;
  currentPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

export interface ProductsListProps {
  title?: string;
  dealsList?: Map<string, DealEntity>;
  listLocation: SeoEventListLocation;
  path?: string;
  showMore?: boolean;
  lazy?: boolean;
  products: CarouselProductsListModel[];
  shadowColor?: string;
  showSeeMoreCard?: boolean;
  mode?: Mode;
  productInNewTab?: boolean;
  pagination?: ProductsListPaginationProps;
  columns?: {
    desktop?: number;
    tablet?: number;
    laptop?: number;
    mobile?: number;
  };
  slidePerView?: {
    desktop?: number;
    tablet?: number;
    laptop?: number;
    mobile?: number;
  };
  margin?: string;
  firstCardData?: {
    imageUrl: string;
    imageAlt: string;
    url: string;
  };
  withDisplayEffect?: boolean;
  showShimmerEffect?: boolean;
  showTimer?: boolean;
  categorySlug?: string;
}

export interface BannersProps {
  banners: BannerEntity[];
}

export interface BrandValuesProps {
  page: 'home' | 'product_details' | 'static_pages';
}

export interface MegaMenuProps {
  isOnStickyNavbar: boolean;
}

export interface HeroBannersProps {
  data: HeroBannerStructure[];
}

export interface ImageSliderProps {
  data: SliderStructure[];
}

export interface ProductImageGalleryProps {
  images: ProductImage[] | undefined;
  alt: string;
}

export enum TabKeys {
  DESCRIPTIONS = 'specifications',
  SPECIFICATIONS = 'details',
  REVIEWS = 'reviews',
}

export interface ProductTabsListProps {
  showSpecificationsTab: boolean;
  showDescriptionsTab: boolean;
  showReviewsTab: boolean;
  activeTab: TabKeys | undefined;
  tabPositions: any;
}

export interface ProductOrderBoxProps {
  title: string;
  image: string;
  price: number;
  discountedPrice: number;
  discountAmount: number;
  isAllowedToAddToCart: boolean;
  product_id: number;
  variation_id: number | null;
  min_qty: number;
  max_qty: number;
  loading: boolean;
  relatedCartDetails?: CartCompactItemModel;
  seoItems: Omit<SeoEventProductModel, 'list_location'>;
  onAddToCart: () => void;
  onSetLoading: (state: boolean) => void;
  stockStatus: StockStatusEnum;
  productDeal?: ProductDealModel;
  activeDeal?: boolean;
  id: number;
  inventoryQty: number;
}

export interface ProductFullDescriptionTabProps {
  fullDescription: string;
}

export interface ProductSpecificationsTabProps {
  specifications: ProductSpecificationModel[];
}

export interface PopupCartProps {
  show: boolean;
}

export interface ProductStickyOrderProps {
  addToCartButton: AddToCartButtonProps;
  variations: ProductVariationProps;
  variationIsNotSelected: boolean;
  price: number;
  discountedPrice: number;
  discountAmount: number;
  minQty: number;
  maxQty: number;
  inventoryQty: number;
  relatedCartDetails?: CartCompactItemModel;
  productDeal: ProductDealModel;
  activeDeal: boolean;
  stockStatus: StockStatusEnum;
}

export interface AddToCartButtonProps {
  onClick: () => void;
  isAllowedToAddToCart: boolean;
  location: 'top' | 'box' | 'sticky';
  product_id: number;
  variation_id: number | null;
  min_qty: number;
  max_qty: number;
  relatedCartDetails?: CartCompactItemModel;
  loading: boolean;
  seoItems: Omit<SeoEventProductModel, 'list_location'>;
  onSetLoading: (state: boolean) => void;
  onHideVariationsSheet?: () => void;
  stockStatus: StockStatusEnum;
}

export interface ProductVariationProps {
  variations: ProductVariation[];
  isInCart?: boolean;
  isInLanding?: boolean;
  variationTitlePrefix?: string;
  validateVariations: boolean;
  onVariationSelection: (variationId: number | null) => void;
  showErrorMessage: boolean;
  selectedVariation: number | null;
}
