import React, { FC, useEffect, useMemo, useState } from 'react';
import { ConfirmationResult, UserCredential } from 'firebase/auth';

import {
  AuthPhoneVerify,
  RewardPhone,
  RewardLoginWrapper,
  RewardWelcome,
} from '@modules';
import { AuthStateEnum } from '@models';

type Props = {
  onClose: () => void;
};
const RewardLoginOverview: FC<Props> = ({ onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [OTPConfirmation, setOTPConfirmation] = useState<ConfirmationResult>();
  const [authState, setAuthState] = useState<AuthStateEnum>();

  useEffect(() => {
    setAuthState(AuthStateEnum.LOGIN_OVERVIEW);
  }, []);

  const handleVerifyStep = (phone: string, OTP: ConfirmationResult) => {
    setPhoneNumber(phone);
    setOTPConfirmation(OTP);
    setAuthState(AuthStateEnum.VERIFY_PHONE);
  };

  const handleVerifiedNumber = () => {
    setAuthState(AuthStateEnum.WELCOME);
  };

  const renderAuthFlow = (): JSX.Element => {
    switch (authState) {
      case AuthStateEnum.VERIFY_PHONE:
        return (
          <RewardLoginWrapper>
            <AuthPhoneVerify
              phoneNumber={phoneNumber}
              OTP={OTPConfirmation}
              onSetOTP={setOTPConfirmation}
              onVerified={() => handleVerifiedNumber()}
              onChangePhone={() => setAuthState(AuthStateEnum.LOGIN_OVERVIEW)}
            />
          </RewardLoginWrapper>
        );
      case AuthStateEnum.WELCOME:
        return <RewardWelcome onClose={onClose} />;

      case AuthStateEnum.LOGIN_OVERVIEW:
        return (
          <RewardLoginWrapper>
            <RewardPhone onGetCode={handleVerifyStep} />
          </RewardLoginWrapper>
        );
      default:
        return <></>;
    }
  };

  return <>{renderAuthFlow()}</>;
};

export default RewardLoginOverview;
