import {
  AddressRegionSelected,
  AddressRegionCityModel,
  Region,
  AddressModel,
  AddressAreaModel,
  AddressAreasModel,
} from '@models';
import { getStorage } from '@helpers';
import { getRegion } from './i18n.helper';

const region = process.env.NEXT_PUBLIC_LOCALE as Region;

// Get Token for region
export const getRegionToken = () => ['oneSellRegion', region].join('_');

// Get current region data from localStorage
export const getCurrentRegion = (): AddressRegionSelected | undefined => {
  return getStorage<AddressRegionSelected>(getRegionToken());
};

// get selected area data
export const getCurrentRegionArea = (
  region: AddressRegionSelected | undefined
): AddressAreaModel | null => {
  return region?.area ?? null;
};

// Get selected city data
export const getCurrentRegionCity = (
  region: AddressRegionSelected | undefined
): AddressRegionCityModel | null => {
  return region?.city ?? null;
};

// Get current region to string
export const getCurrentRegionLabel = (
  region: AddressRegionSelected | undefined
): string => {
  const areaLabel = getCurrentRegionArea(region)?.name ?? '';
  const cityLabel = getCurrentRegionCity(region)?.label ?? '';
  if (!areaLabel) return '';
  if (!cityLabel) return areaLabel;
  return [cityLabel, areaLabel].join('/');
};

// Get area label base on area id
export const getCurrentRegionLabelBaseAreaId = (
  area_id: number | undefined,
  areas: AddressAreaModel[]
): string => {
  if (!area_id) return '';
  const area = getAreaDetailsBaseAreaId(area_id, areas);
  return area?.name || '';
};

export const getAreaDetailsBaseAreaId = (
  area_id: number,
  areas: AddressAreaModel[]
): AddressAreaModel | undefined => {
  return areas.find((area) => area.id === area_id);
};

// Get default region base on user address
export const getUserAddressRegion = (
  addresses: AddressModel[]
): AddressRegionSelected | undefined => {
  const defaultAddress = addresses.find((i) => i.is_default_shipping);
  if (!defaultAddress?.area) return undefined;

  return {
    country: getRegion(),
    area: defaultAddress.area,
    city: null,
  };
};

export const getDefaultArea = (
  areas: AddressAreaModel[],
  id: number | null
): AddressAreaModel => {
  const [firstArea] = areas;
  if (!id) return firstArea;
  return areas.find((i) => i.id === id) ?? firstArea;
};

export const getDefaultRegion = (
  areas: AddressAreasModel
): AddressRegionSelected => {
  return {
    country: getRegion(),
    area: getDefaultArea(areas.areas, areas.default_area_id),
    city: null,
  };
};
