import React, { FC, useRef, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useInView } from 'react-intersection-observer';

import { Design, Media } from '@components';
import { CarouselProductsListModel, Mode } from '@models';

import ProductCardShimmerModule from '../product-card-shimmer/product-card-shimmer.module';
import styles from './products-list-shimmer.module.scss';

interface Props {
  shimmerCount: number;
  title: string;
  mode?: Mode;
  columns?: {
    desktop?: number;
    tablet?: number;
    laptop?: number;
    mobile?: number;
  };
  slidePerView?: {
    desktop?: number;
    tablet?: number;
    laptop?: number;
    mobile?: number;
  };
}

const ProductsListShimmerModule: FC<Props> = ({
  title,
  shimmerCount,
  columns,
  slidePerView,
  mode,
}) => {
  const [keenLoaded, setKeenLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider({
    created() {
      setKeenLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    renderMode: 'performance',
    breakpoints: {
      '(min-width: 0px)': {
        mode: 'free',
        slides: {
          spacing: 8,
          perView: slidePerView?.mobile ?? 'auto',
        },
      },
      '(min-width: 360px)': {
        mode: 'free',
        slides: {
          spacing: 8,
          perView: slidePerView?.mobile ?? 'auto',
        },
      },
      '(min-width: 768px)': {
        mode: 'free',
        slides: {
          spacing: 8,
          perView: slidePerView?.tablet ?? 'auto',
        },
      },
      '(min-width: 992px)': {
        mode: 'free',
        slides: {
          spacing: 8,
          perView: slidePerView?.laptop ?? 'auto',
        },
      },
      '(min-width: 1320px)': {
        slides: {
          perView: slidePerView?.desktop ?? 'auto',
          spacing: 8,
        },
      },
    },
  });

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [loaded, setLoaded] = useState(true);
  const [lazyProducts, setLazyProducts] = useState<CarouselProductsListModel[]>(
    []
  );

  const { ref: observe, inView } = useInView({
    triggerOnce: true,
    rootMargin: '25%',
  });

  const renderInlineProducts = () => {
    return [...Array(shimmerCount).keys()].map((item, index: number) => {
      return (
        <div className="keen-slider__slide" key={`${index}`}>
          <ProductCardShimmerModule />
        </div>
      );
    });
  };

  const renderGridProducts = () =>
    [...Array(shimmerCount).keys()].map((item, index: number) => {
      return <ProductCardShimmerModule key={`${index}`} />;
    });

  const renderSliderArrows = () => {
    const prevButtonIsDisabled =
      (instanceRef.current && instanceRef.current.track.details.maxIdx === 0) ||
      currentSlide === 0;

    const nextButtonIsDisabled =
      (instanceRef.current && instanceRef.current.track.details.maxIdx === 0) ||
      (instanceRef.current &&
        currentSlide === instanceRef.current.track.details.maxIdx);

    return (
      <>
        <div
          className={`${styles.PrevButton} ${
            prevButtonIsDisabled ? styles.Disabled : ''
          }`}
        >
          <ArrowLeft
            color={'#fff'}
            onClick={(e) => {
              instanceRef.current?.prev();
            }}
          />
        </div>

        <div
          className={`${styles.NextButton} ${
            nextButtonIsDisabled ? styles.Disabled : ''
          }`}
        >
          <ArrowRight
            color={'#fff'}
            onClick={(e) => {
              instanceRef.current?.next();
            }}
          />
        </div>
      </>
    );
  };

  const renderInlineType = () => (
    <div
      className={`${styles.Container}${
        inView ? styles.InView : styles.OutOfView
      }`}
      ref={observe}
    >
      <div className={styles.Header}>
        <h6>{title}</h6>
      </div>
      <div className={styles.ProductsList}>
        <div ref={sliderRef} className="keen-slider">
          {renderInlineProducts()}
        </div>
        {keenLoaded && instanceRef.current && renderSliderArrows()}
      </div>
    </div>
  );

  const renderGridType = () => (
    <>
      <Media lessThan="tablet">
        <Design
          className={styles.Grid}
          gridTemplateColumns={`repeat(${columns?.mobile}, 1fr)`}
        >
          {renderGridProducts()}
        </Design>
      </Media>
      <Media between={['tablet', 'laptop']}>
        <Design
          className={styles.Grid}
          gridTemplateColumns={`repeat(${columns?.tablet}, 1fr)`}
        >
          {renderGridProducts()}
        </Design>
      </Media>
      <Media between={['laptop', 'desktop']}>
        <Design
          className={styles.Grid}
          gridTemplateColumns={`repeat(${columns?.laptop}, 1fr)`}
        >
          {renderGridProducts()}
        </Design>
      </Media>
      <Media greaterThan="laptop">
        <Design
          className={styles.Grid}
          gridTemplateColumns={`repeat(${columns?.desktop}, 1fr)`}
        >
          {renderGridProducts()}
        </Design>
      </Media>
    </>
  );

  return (
    <>
      {mode === Mode.INLINE && renderInlineType()}
      {mode === Mode.GRID && renderGridType()}
    </>
  );
};

export default ProductsListShimmerModule;
