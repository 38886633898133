import React, { FC } from 'react';

import { Design, Wording } from '@components';
import { PaymentMethodEnum, PaymentMethodModel } from '@models';

type Props = {
  option: PaymentMethodModel;
  hasBorder: boolean;
  isSelected: boolean;
  onSelectMethod: (slug: PaymentMethodEnum) => void;
};
const CheckoutPaymentOptionItemModule: FC<Props> = ({
  option,
  hasBorder,
  isSelected,
  onSelectMethod,
}) => {
  return (
    <Design
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      padding={{
        all: '24px 0',
        tablet: '20px 0',
        mobile: '16px 0',
      }}
      borderTop={hasBorder ? '1px solid' : ''}
      borderColor="natural"
    >
      <Design
        display="flex"
        justifyContent="flex-start"
        className="has-pointer"
        onClick={() => onSelectMethod(option.slug)}
      >
        <Design
          marginTop={{
            all: '1px',
            desktop: '4px',
          }}
          width="20px"
          height="20px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="1px solid"
          borderColor={isSelected ? 'primary' : 'placeholder'}
          borderRadius="50%"
          marginRight="10px"
        >
          <Design
            width="10px"
            height="10px"
            borderRadius="50%"
            backgroundColor={isSelected ? 'primary' : undefined}
          />
        </Design>
        <Design>
          <Wording
            color={isSelected ? 'surface' : 'action'}
            fontSize={{
              all: '16px',
              desktop: '18px',
            }}
            lineHeight={{
              all: '22px',
              desktop: '26px',
            }}
            fontWeight="500"
          >
            {option.title}
          </Wording>
        </Design>
      </Design>
    </Design>
  );
};

export default CheckoutPaymentOptionItemModule;
