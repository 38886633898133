import React, { FC } from 'react';
import Link from 'next/link';
import cn from 'clsx';

import {
  CampaignType,
  EventProductModel,
  StockStatusEnum,
  AppereanceColorsModel,
} from '@models';
import { Image } from '@components';
import ds from '@ds';

import styles from './landing-products.module.scss';

interface Props {
  cartItems: number[];
  colors: AppereanceColorsModel;
  moreLink: string;
  campaignType: CampaignType;
  products: EventProductModel[];
  toggleVariationModal: (product: EventProductModel) => void;
}

const LandingProducts: FC<Props> = ({
  cartItems,
  colors,
  moreLink,
  products,
  campaignType,
  toggleVariationModal,
}: Props) => {
  const renderDiscountBadge = (discount: number) => (
    <div className={styles.DiscountBadge}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.9973 23.9941L47.3087 28.2127C47.9026 28.7747 48.1206 29.6189 47.9346 30.401C47.7166 31.1831 47.0907 31.8072 46.3108 31.9952L40.4357 33.4954L42.0914 39.3082C42.3094 40.0903 42.0914 40.9324 41.4975 41.4965C40.9356 42.0906 40.0917 42.3086 39.3098 42.0906L33.4987 40.4344L31.9989 46.3112C31.8109 47.0933 31.187 47.7174 30.4051 47.9354C30.2171 47.9674 29.9992 47.9974 29.8112 47.9974C29.2173 47.9974 28.6233 47.7474 28.2174 47.3093L24 42.9967L19.7826 47.3093C19.2207 47.9034 18.3768 48.1214 17.5949 47.9354C16.783 47.7174 16.1891 47.0913 16.0011 46.3112L14.5013 40.4344L8.69016 42.0906C7.90827 42.3086 7.06639 42.0906 6.50247 41.4965C5.90856 40.9344 5.69059 40.0903 5.90856 39.3082L7.56432 33.4954L1.68915 31.9952C0.907264 31.8072 0.283352 31.1831 0.0653832 30.401C-0.12259 29.6189 0.0973787 28.7767 0.691295 28.2127L5.00269 23.9941L0.691295 19.7755C0.0973787 19.2134 -0.12059 18.3693 0.0653832 17.5872C0.283352 16.8051 0.909264 16.181 1.68915 15.993L7.56432 14.4927L5.90856 8.67993C5.69059 7.89782 5.90856 7.0557 6.50247 6.49162C7.06439 5.89754 7.90827 5.67951 8.69016 5.89754L14.5013 7.55377L16.0011 1.67695C16.1891 0.894838 16.813 0.27075 17.5949 0.0827242C18.3768 -0.135306 19.2187 0.082724 19.7826 0.676807L24 5.02142L28.2174 0.676807C28.7793 0.082724 29.5932 -0.135306 30.4051 0.0827242C31.187 0.27075 31.8109 0.894838 31.9989 1.67695L33.4987 7.55377L39.3098 5.89754C40.0917 5.67951 40.9336 5.89754 41.4975 6.49162C42.0914 7.0537 42.3094 7.89782 42.0914 8.67993L40.4357 14.4927L46.3108 15.993C47.0927 16.181 47.7166 16.8051 47.9346 17.5872C48.1226 18.3693 47.9026 19.2114 47.3087 19.7755L42.9973 23.9941Z"
          fill={ds.color_primary}
        />
      </svg>
      <span style={{ color: colors?.text_color }}>{discount.toString()}%</span>
    </div>
  );

  const renderSeeMoreButton = () => (
    <div className={styles.SeeMoreButton}>
      <Link href={moreLink ?? ''}>
        <a
          title="see more"
          target="_blank"
          style={{
            background: colors?.link_color,
            borderColor: colors?.border_color,
          }}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 9C0 8.44771 0.447715 8 1 8H17C17.5523 8 18 8.44771 18 9C18 9.55229 17.5523 10 17 10H1C0.447715 10 0 9.55229 0 9Z"
              fill={colors?.border_color}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893L17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071C7.90237 17.3166 7.90237 16.6834 8.29289 16.2929L15.5858 9L8.29289 1.70711C7.90237 1.31658 7.90237 0.683417 8.29289 0.292893Z"
              fill={colors?.border_color}
            />
          </svg>
        </a>
      </Link>

      <p>See More...</p>
    </div>
  );

  const generateProductUrl = (slug: string) => {
    if (campaignType === CampaignType.LATE) return `/products/${slug}`;

    return '#';
  };

  const handleBuyButton = (
    e: React.MouseEvent<HTMLElement>,
    product: EventProductModel
  ) => {
    e.preventDefault();
    e.stopPropagation();
    toggleVariationModal(product);
  };

  const renderBuyButton = (product) => {
    if (cartItems?.indexOf(product.id) > -1) {
      return (
        <div
          className={styles.BuyButton}
          style={{
            borderColor: colors?.text_color,
            color: colors?.text_color,
          }}
        >
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.4711 0.52827C13.7314 0.788619 13.7314 1.21073 13.4711 1.47108L5.22108 9.72108C4.96073 9.98143 4.53862 9.98143 4.27827 9.72108L0.52827 5.97108C0.26792 5.71073 0.26792 5.28862 0.52827 5.02827C0.788619 4.76792 1.21073 4.76792 1.47108 5.02827L4.74967 8.30687L12.5283 0.52827C12.7886 0.26792 13.2107 0.26792 13.4711 0.52827Z"
              fill="white"
            />
          </svg>
          Added
        </div>
      );
    } else {
      return (
        <div
          className={styles.BuyButton}
          style={{
            borderColor: colors?.border_color,
            color: colors?.border_color,
          }}
          onClick={(e) => handleBuyButton(e, product)}
        >
          Add to cart
        </div>
      );
    }
  };

  const renderOutOfStockBadge = () => {
    return (
      <div className={styles.OutOfStock}>
        <span>Sold Out</span>
      </div>
    );
  };

  const preventProductClick = (e) => {
    if (campaignType === CampaignType.EARLY) e.preventDefault();
  };

  const renderProduct = (product: EventProductModel) => (
    <div
      className={styles.Product}
      key={product.id}
      style={{ background: colors?.link_color }}
      onClick={preventProductClick}
    >
      <Link href={generateProductUrl(product?.slug)}>
        <a target="_blank">
          {product.stock_status === StockStatusEnum.OUT_OF_STOCK &&
            renderOutOfStockBadge()}

          {renderDiscountBadge(product?.discount)}

          <div
            className={cn(styles.ProductInner, {
              [styles.Blur]: campaignType === CampaignType.EARLY,
            })}
          >
            {product?.images?.length > 0 && (
              <Image
                src={product.images[0].urls[512]}
                alt=""
                isDark
                className={styles.ProductImage}
                width={300}
                height={276}
                objectFit="cover"
              />
            )}

            <div className={styles.Informations}>
              <div
                className={styles.ProductTitle}
                style={{ color: colors?.text_color }}
              >
                {product.title}
              </div>

              <div className={styles.ProductBottomContainer}>
                <div
                  className={styles.ProductPrice}
                  style={{ color: colors?.text_color }}
                >
                  <p>{product.price} QR</p>
                  <p>{product.list_price} QR</p>
                </div>
                {campaignType === CampaignType.LATE &&
                  product.stock_status === StockStatusEnum.IN_STOCK &&
                  renderBuyButton(product)}
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );

  const renderProducts = () => (
    <>
      {products?.map((product) => renderProduct(product))}
      {campaignType !== CampaignType.EARLY && renderSeeMoreButton()}
    </>
  );

  return <div className={styles.ProductsList}>{renderProducts()}</div>;
};

export default LandingProducts;
