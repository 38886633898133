import React, { FC } from 'react';

import { Button, Design, Media, Wording } from '@components';
import {
  CategoryFacetModel,
  CategoryFilterModel,
  FacetTypeEnum,
  ProductFacetRangeModel,
} from '@models';
import {
  FiltersBoolean,
  FiltersCard,
  FiltersCategory,
  FiltersCheckboxList,
  FiltersRange,
} from '@modules';
import { Modal, Spin } from 'antd';
import { useRouter } from 'next/router';
import { ChevronRight, X } from 'react-feather';

import styles from './filters-management.module.scss';

type Props = {
  facets: CategoryFacetModel[];
  initialValues: CategoryFilterModel[];
  showBottomSheet: boolean;
  resultCount: number;
  loading: boolean;
  onHideFilters: () => void;
  onClearFilters: () => void;
  onQueryChange: (
    filters: Record<string, string | string[] | undefined>
  ) => void;
};
const FiltersManagementComponent: FC<Props> = ({
  facets,
  initialValues,
  showBottomSheet,
  resultCount,
  loading,
  onHideFilters,
  onClearFilters,
  onQueryChange,
}) => {
  const router = useRouter();
  const selectedFilters = new Map(
    initialValues.map((item) => [item.slug, item.values])
  );

  const searchTerm = router?.query?.q;

  const handleOnOptionChange = (
    name: string,
    value: string,
    type?: 'boolean' | 'range' | 'default'
  ) => {
    // Color is just an example in the comments so you can understand the behavior
    // if you don't understand it, then, please don't change this code
    const { slug, ...routerQuery } = Object.assign({}, router.query);

    const orderBy = routerQuery?.order_by ?? 'popularity';
    const orderDir = routerQuery?.order_dir ?? 'DESC';

    let splittedFilterValue = routerQuery[name]?.toString().split(',') ?? [];

    if (routerQuery[name]) {
      // Fill filters base on type
      switch (type) {
        // Boolean Type Filters
        case 'boolean':
          splittedFilterValue = value === 'true' ? [value] : [];
          break;

        // Range Type Filters
        case 'range':
          splittedFilterValue = [value];
          break;

        // Default Type Filters
        default:
          if (splittedFilterValue?.includes(value)) {
            // check if color is already in query
            splittedFilterValue = splittedFilterValue.filter(
              (item) => item !== value
            );
          } else {
            // add new color to query
            splittedFilterValue?.push(value);
          }
          break;
      }
    } else {
      // add color to query
      splittedFilterValue = [value];
    }

    // if no color in query, remove color from query
    if (splittedFilterValue.length === 0) {
      delete routerQuery[name];
    }

    const filters = {
      ...routerQuery,
      order_by: orderBy,
      order_dir: orderDir,
      page: '1',
    };

    // if color exists, add color to query
    if (splittedFilterValue.length > 0)
      filters[name] = splittedFilterValue?.join(',');

    router.push(
      {
        pathname: searchTerm
          ? '/search'
          : `/category/${router.query.slug?.[0]}/products/`,
        query: filters,
      },
      undefined,
      { shallow: true }
    );

    onQueryChange({ ...filters, category_slug: router.query.slug?.[0] });
  };

  const handleRangeChange = (name: string, range: ProductFacetRangeModel) => {
    handleOnOptionChange(name, `${range.min}-${range.max}`, 'range');
  };

  const handleClearFilters = () => {
    if (!selectedFilters || selectedFilters.size < 1) return;
    onClearFilters();
  };

  /**
   * Render facet footer for category
   * @returns {JSX.Element}
   */
  const renderCategoryFacetFooter = (): JSX.Element => (
    <Design
      display="flex"
      alignItems="center"
      justifyContent="center"
      className="has-pointer"
    >
      <Wording>View All Categories</Wording>
      <Design
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="12px"
        height="12px"
        padding="0"
      >
        <ChevronRight />
      </Design>
    </Design>
  );

  /**
   * Render facet category card
   * @param {CategoryFacetModel} facet
   * @returns {JSX.Element}
   */
  const renderCategoryFilterCard = (facet: CategoryFacetModel): JSX.Element => {
    const optionsCount = Object.values(facet.options)?.length ?? 0;
    if (optionsCount === 0) return <></>;
    return (
      <FiltersCard key={facet.slug} title={facet.label} isExpanded={true}>
        <FiltersCategory onHideFilters={onHideFilters} facet={facet} />
      </FiltersCard>
    );
  };

  const renderFiltersBody = (facet: CategoryFacetModel) => {
    switch (facet.type) {
      case FacetTypeEnum.SELECT:
      case FacetTypeEnum.TEXT_SWATCH:
      case FacetTypeEnum.VISUAL_SWATCH:
        return (
          <FiltersCard
            key={facet.slug}
            title={facet.label}
            isExpanded={!!selectedFilters.get(facet.slug)}
          >
            <FiltersCheckboxList
              facet={facet}
              checked={selectedFilters.get(facet.slug) ?? ['']}
              onChange={handleOnOptionChange}
            />
          </FiltersCard>
        );

      case FacetTypeEnum.CATEGORY:
        return renderCategoryFilterCard(facet);

      case FacetTypeEnum.BOOLEAN:
        return (
          <Design
            padding="9px 12px"
            backgroundColor="background"
            borderRadius={{
              all: '0',
              desktop: '8px',
            }}
            width="100%"
            marginBottom="12px"
          >
            <FiltersBoolean
              selectValue={selectedFilters.get(facet.slug) ?? ['']}
              facet={facet}
              onChange={(name, value) =>
                handleOnOptionChange(name, value, 'boolean')
              }
            />
          </Design>
        );

      case FacetTypeEnum.RANGE:
        return (
          <FiltersCard key={facet.slug} title={facet.label}>
            <FiltersRange
              selectedRangeInfo={selectedFilters.get(facet.slug) ?? ['']}
              facet={facet}
              onRangeChange={handleRangeChange}
            />
          </FiltersCard>
        );

      default:
        return <></>;
    }
  };

  const renderFilterModal = () => {
    return (
      <Design
        width="100%"
        position="fixed"
        backgroundColor="background_alt__second"
        top="0"
        left="0"
        zIndex="99999"
        height="100vh"
        overflow="hidden"
      >
        <Design position="relative">
          <Design
            display="flex"
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="52px"
            backgroundColor="background"
            zIndex="999"
            padding="16px"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="natural"
          >
            <Design onClick={handleClearFilters}>
              <Wording
                color={selectedFilters.size > 0 ? 'error' : 'placeholder'}
                fontWeight="500"
                fontSize="12px"
                lineHeight="18px"
              >
                Reset
              </Wording>
            </Design>
            <Design flex="1" textAlign="center">
              <Wording
                color="surface"
                fontWeight="500"
                fontSize="16px"
                lineHeight="22px"
              >
                Filtering
              </Wording>
            </Design>
            <Design
              onClick={onHideFilters}
              width="24px"
              height="24px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Wording color="action">
                <X width="100%" height="100%" />
              </Wording>
            </Design>
          </Design>
          <Design
            backgroundColor="background_alt__second"
            padding="64px 0 170px"
            overflow="auto"
            height="100vh"
          >
            {facets.map((facet) => (
              <Design key={facet.slug}>{renderFiltersBody(facet)}</Design>
            ))}
          </Design>
          <Design
            width="100%"
            zIndex="10"
            position="fixed"
            bottom="0"
            left="0"
            backgroundColor="background"
            padding="8px 16px"
            borderTop="1px solid"
            borderColor="natural"
            height="64px"
          >
            <Button
              onClick={onHideFilters}
              type="primary"
              shape="round"
              fullWidth
            >
              {loading ? <Spin /> : <>Show {resultCount} Results</>}
            </Button>
          </Design>
        </Design>
      </Design>
    );
  };

  return (
    <>
      <Media greaterThan="laptop">
        <Design variant="aside" width="238px" marginRight="24px">
          {facets.map((facet) => (
            <Design key={facet.slug}>{renderFiltersBody(facet)}</Design>
          ))}
        </Design>
      </Media>
      <Media lessThan="desktop">{showBottomSheet && renderFilterModal()}</Media>
    </>
  );
};

export default FiltersManagementComponent;
