import numeral from 'numeral';

/**
 * Factory function that check decimal numbers
 * @param {number} value
 * @returns {boolean}
 */
const hasDecimalValue = (value: number) => {
  return value % 1 !== 0;
};

/**
 * Factory function that show numbers in format model
 * @param {number | string} value - numbers
 * @returns {string}
 */
export const formattedNumber = (value: number | string): string => {
  if (!value) return '0';
  return hasDecimalValue(+value)
    ? numeral(value).format('0,0.00')
    : numeral(value).format('0,0');
};

/**
 * Factory function that return price in formatted value
 * @param {number | string} value - price value
 * @returns {string}
 */
export const formatPriceWithCurrency = (value: string | number) =>
  [formattedNumber(value), process.env.NEXT_PUBLIC_CURRENCY].join(' ');
