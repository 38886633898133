import { addressesActionTypes } from '@actionTypes';
import { getRegion } from '@helpers';
import {
  AddressAreaModel,
  CreateAddressModel,
  ListOrder,
  Region,
} from '@models';

export function loadAll(params?: ListOrder) {
  return {
    type: addressesActionTypes.LOAD_ALL_ADDRESSES,
    method: 'get',
    url: '/addresses?order_dir=DESC&order_by=is_default_shipping',
    params,
  };
}

export function load(id: number) {
  return {
    type: addressesActionTypes.LOAD_ADDRESSES,
    method: 'get',
    url: `/addresses/${id}`,
  };
}

export function create(data: CreateAddressModel) {
  return {
    type: addressesActionTypes.CREATE_ADDRESS,
    method: 'post',
    url: '/addresses',
    data,
  };
}

export function update(id: number, data: CreateAddressModel) {
  return {
    type: addressesActionTypes.UPDATE_ADDRESS,
    method: 'put',
    url: `/addresses/${id}`,
    data,
  };
}

export function remove(id: number) {
  return {
    type: addressesActionTypes.DELETE_ADDRESS,
    url: `/addresses/${id}`,
    method: 'delete',
  };
}

export function loadAllCities() {
  return {
    type: addressesActionTypes.LOAD_ALL_CITIES,
    method: 'get',
    url: '/addresses/cities/all',
  };
}

export function loadAllRegion() {
  const region = getRegion() === Region.AR_AE ? 'AE' : 'QA';
  return {
    type: addressesActionTypes.LOAD_ALL_REGION,
    method: 'get',
    url: `/addresses/region/all?country=${region}`,
  };
}

export function loadAreas() {
  return {
    type: addressesActionTypes.LOAD_ALL_AREAS,
    method: 'get',
    url: '/addresses/areas/all',
  };
}

export function clearAddressDetails() {
  return {
    type: addressesActionTypes.CLEAR_ADDRESS_DETAILS,
  };
}

export function setAddressArea(data: AddressAreaModel | null) {
  return {
    type: addressesActionTypes.SET_ADDRESS_AREA,
    data,
  };
}
