import React, { FC, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'clsx';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { Check } from 'react-feather';

import { Design, mediaQuery, Wording } from '@components';
import ds from '@ds';
import { useMediaQuery } from '@hooks';
import {
  CategoryOrderModel,
  CategorySortOptions,
  SortItemModel,
  SortTypeKey,
} from '@models';
import SortIcon from 'public/icons/sort-icon.svg';

import styles from './category-sort.module.scss';

type Props = {
  order: CategoryOrderModel;
  onSort: (filters: Record<string, string | string[] | undefined>) => void;
};

const CategorySort: FC<Props> = ({ order, onSort }) => {
  const router = useRouter();
  const isMobile = useMediaQuery(mediaQuery.mobile);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const visibleBottomSheetBtn = isTablet || isMobile;

  const searchTerm = router?.query?.q;

  const [currentSort, setCurrentSort] = useState<SortTypeKey>();
  const [currentSortTitle, setCurrentSortTitle] = useState<string>();
  const [visibleBottomSheet, setVisibleBottomSheet] = useState(false);

  const selectedSortOption = useMemo(
    () =>
      CategorySortOptions.find(
        (i) => i.key === order.order_by && i.sort_dir === order.order_dir
      ),
    [order]
  );

  // Selected Sort Option
  useEffect(() => {
    if (selectedSortOption) {
      setCurrentSort(selectedSortOption.key);
      setCurrentSortTitle(selectedSortOption.title);
    }
  }, [selectedSortOption]);

  const handleSort = (
    order_by: SortTypeKey,
    order_dir: string,
    orderTitle: string
  ) => {
    const { slug: routeSlugs, ...routerQuery } = Object.assign(
      {},
      router.query
    );

    setCurrentSort(order_by);
    setCurrentSortTitle(orderTitle);

    if (visibleBottomSheetBtn) {
      setVisibleBottomSheet(false);
    }

    const filters = {
      ...routerQuery,
      order_by,
      order_dir,
      page: '1',
    };

    router.push(
      {
        pathname: searchTerm
          ? '/search'
          : `/category/${router.query.slug?.[0]}/products/`,
        query: filters,
      },
      undefined,
      { shallow: true }
    );

    onSort({ ...filters, category_slug: router.query.slug?.[0] });
  };

  // Factory function that check if the order item is selected
  const isItemSelected = (item: SortItemModel): boolean =>
    currentSort === item.key && currentSortTitle === item.title;

  const renderFiltersList = () => {
    return (
      <>
        {CategorySortOptions.map((item) => (
          <Design
            padding="6px 8px"
            borderRadius="8px"
            textAlign="center"
            height={visibleBottomSheetBtn ? '42px' : 'auto'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            key={[item.key, item.sort_dir].join('_')}
            className={cn(styles.SortItem, {
              [styles.ActiveItem]: isItemSelected(item),
            })}
            onClick={() => handleSort(item.key, item.sort_dir, item.title)}
          >
            {currentSort === item.key &&
              currentSortTitle === item.title &&
              visibleBottomSheetBtn && (
                <Design
                  position="absolute"
                  left="12px"
                  top="9px"
                  width="24px"
                  height="24px"
                >
                  <Check color={ds.color_primary} />
                </Design>
              )}

            <Wording
              fontWeight="500"
              color={isItemSelected(item) ? 'primary' : 'surface'}
            >
              {item.title}
            </Wording>
          </Design>
        ))}
      </>
    );
  };

  const renderBottomSheetContents = () => {
    return (
      <Design>
        <Design
          width="100%"
          padding="4px 0 12px 0"
          borderBottom="1px solid"
          borderColor="natural"
        >
          <Wording fontSize="16px" fontWeight="500" textAlign="center">
            Sort by
          </Wording>
        </Design>

        <Design padding="16px" display="flex" gap="4px" flexDirection="column">
          {renderFiltersList()}
        </Design>
      </Design>
    );
  };

  if (visibleBottomSheetBtn) {
    return (
      <>
        <Design
          display="flex"
          gap="5px"
          alignItems="center"
          backgroundColor="background"
          borderRadius="100px"
          padding="9px 10px"
          width="max-content"
          className={styles.MobileSortButton}
          onClick={() => setVisibleBottomSheet(true)}
        >
          <SortIcon />
          <Wording fontWeight="500" color="surface">
            {currentSortTitle}
          </Wording>
        </Design>

        <BottomSheet
          open={visibleBottomSheet}
          onDismiss={() => setVisibleBottomSheet(false)}
        >
          {renderBottomSheetContents()}
        </BottomSheet>
      </>
    );
  }

  return (
    <Design
      display={{ all: 'flex', mobile: 'none' }}
      gap="16px"
      margin="0 0 32px 0"
      alignItems="center"
    >
      <Design display="flex" gap="5px" alignItems="center">
        <SortIcon />
        <Wording fontWeight="500" color="caption">
          Sort by:
        </Wording>
      </Design>

      {renderFiltersList()}
    </Design>
  );
};

export default CategorySort;
