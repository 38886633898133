import React, { FC } from 'react';

import ds from '@ds';
import { DesignProps, ScreenType } from '@models';

const variantsMapping = {
  div: 'div',
  section: 'section',
  header: 'header',
  footer: 'footer',
  aside: 'aside',
  article: 'article',
  figure: 'figure',
  ul: 'ul',
  ol: 'ol',
  li: 'li',
};

const Design: FC<Partial<DesignProps>> = ({
  innerRef,
  onClick,
  onScroll,
  onMouseEnter,
  onMouseLeave,
  children,
  variant: as = 'div',
  display,
  opacity,
  backgroundColor,
  CustomBackgroundColor,
  textAlign,
  lineHeight,
  className,
  zIndex,
  color,
  margin,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  border,
  borderBottom,
  borderLeft,
  borderRight,
  borderTop,
  borderRadius,
  borderColor,
  overflow,
  overflowX,
  overflowY,
  verticalAlign,
  position,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  gap,
  rowGap,
  columnGap,
  flexWrap,
  justifyContent,
  alignItems,
  flexDirection,
  flexOrder,
  flex,
  gridGap,
  gridTemplateRows,
  gridTemplateColumns,
  gridTemplateAreas,
  gridAutoRows,
  gridAutoFlow,
  gridAutoColumns,
  top,
  right,
  bottom,
  left,
}) => {
  const Component = variantsMapping[as] as React.ElementType;

  const getStyle = <T,>(propType: T, screenSize: ScreenType) => {
    if (typeof propType === 'object') {
      if (typeof propType[screenSize] === 'undefined') {
        return propType['all'];
      }

      return propType[screenSize];
    }

    return propType;
  };

  const getStylesByScreen = (screen: ScreenType) => {
    return `
      ${
        backgroundColor
          ? `background-color: ${
              ds['color_' + getStyle(backgroundColor, screen)]
            };`
          : ''
      }
      ${
        CustomBackgroundColor
          ? `background-color: ${getStyle(CustomBackgroundColor, screen)};`
          : ''
      }
      ${display ? `display: ${getStyle(display, screen)};` : ''}
      ${textAlign ? `text-align: ${getStyle(textAlign, screen)};` : ''}
      ${lineHeight ? `line-height: ${getStyle(lineHeight, screen)};` : ''}
      ${position ? `position: ${getStyle(position, screen)};` : ''}
      
      ${gridGap ? `gap: ${getStyle(gridGap, screen)};` : ''}
      ${gap ? `gap: ${getStyle(gap, screen)};` : ''}
      ${rowGap ? `row-gap: ${getStyle(rowGap, screen)};` : ''}
      ${columnGap ? `column-gap: ${getStyle(columnGap, screen)};` : ''}
      
      ${zIndex ? `z-index: ${getStyle(zIndex, screen)};` : ''}
      ${color ? `color: ${ds['color_' + getStyle(color, screen)]};` : ''}
      ${opacity ? `opacity: ${getStyle(opacity, screen)};` : ''}
      ${
        verticalAlign
          ? `vertical-align: ${getStyle(verticalAlign, screen)};`
          : ''
      }

      ${flexWrap ? `flex-wrap: ${getStyle(flexWrap, screen)};` : ''}
      ${
        justifyContent
          ? `justify-content: ${getStyle(justifyContent, screen)};`
          : ''
      }
      ${alignItems ? `align-items: ${getStyle(alignItems, screen)};` : ''}
      ${
        flexDirection
          ? `flex-direction: ${getStyle(flexDirection, screen)};`
          : ''
      }
      ${flexOrder ? `order: ${getStyle(flexOrder, screen)};` : ''}
      ${flex ? `flex: ${getStyle(flex, screen)};` : ''}

      ${margin ? `margin: ${getStyle(margin, screen)};` : ''}
      ${marginTop ? `margin-top: ${getStyle(marginTop, screen)};` : ''}
      ${marginRight ? `margin-right: ${getStyle(marginRight, screen)};` : ''}
      ${marginBottom ? `margin-bottom: ${getStyle(marginBottom, screen)};` : ''}
      ${marginLeft ? `margin-left: ${getStyle(marginLeft, screen)};` : ''}
      
      ${padding ? `padding: ${getStyle(padding, screen)};` : ''}
      ${paddingTop ? `padding-top: ${getStyle(paddingTop, screen)};` : ''}
      ${paddingRight ? `padding-right: ${getStyle(paddingRight, screen)};` : ''}
      ${
        paddingBottom
          ? `padding-bottom: ${getStyle(paddingBottom, screen)};`
          : ''
      }
      ${paddingLeft ? `padding-left: ${getStyle(paddingLeft, screen)};` : ''}
      
      ${border ? `border: ${getStyle(border, screen)};` : ''}
      ${borderTop ? `border-top: ${getStyle(borderTop, screen)};` : ''}
      ${borderRight ? `border-right: ${getStyle(borderRight, screen)};` : ''}
      ${borderBottom ? `border-bottom: ${getStyle(borderBottom, screen)};` : ''}
      ${borderLeft ? `border-left: ${getStyle(borderLeft, screen)};` : ''}
      ${borderRadius ? `border-radius: ${getStyle(borderRadius, screen)};` : ''}
      ${
        borderColor
          ? `border-color: ${ds['color_' + getStyle(borderColor, screen)]};`
          : ''
      }

      ${overflow ? `overflow: ${getStyle(overflow, screen)};` : ''}
      ${overflowX ? `overflow-x: ${getStyle(overflowX, screen)};` : ''}
      ${overflowY ? `overflow-y: ${getStyle(overflowY, screen)};` : ''}

      ${width ? `width: ${getStyle(width, screen)};` : ''}
      ${minWidth ? `min-width: ${getStyle(minWidth, screen)};` : ''}
      ${maxWidth ? `max-width: ${getStyle(maxWidth, screen)};` : ''}

      ${height ? `height: ${getStyle(height, screen)};` : ''}
      ${minHeight ? `min-height: ${getStyle(minHeight, screen)};` : ''}
      ${maxHeight ? `max-height: ${getStyle(maxHeight, screen)};` : ''}
      
      ${
        gridTemplateRows
          ? `grid-template-rows: ${getStyle(gridTemplateRows, screen)};`
          : ''
      }
      ${
        gridTemplateColumns
          ? `grid-template-columns: ${getStyle(gridTemplateColumns, screen)};`
          : ''
      }
      ${
        gridTemplateAreas
          ? `grid-template-areas: ${getStyle(gridTemplateAreas, screen)};`
          : ''
      }
      ${
        gridAutoRows ? `grid-auto-rows: ${getStyle(gridAutoRows, screen)};` : ''
      }
      ${
        gridAutoFlow ? `grid-auto-flow: ${getStyle(gridAutoFlow, screen)};` : ''
      }
      ${
        gridAutoColumns
          ? `grid-auto-columns: ${getStyle(gridAutoColumns, screen)};`
          : ''
      }

      ${top ? `top: ${getStyle(top, screen)};` : ''}
      ${right ? `right: ${getStyle(right, screen)};` : ''}
      ${bottom ? `bottom: ${getStyle(bottom, screen)};` : ''}
      ${left ? `left: ${getStyle(left, screen)};` : ''}

    `;
  };

  return (
    <Component
      onClick={onClick}
      onScroll={onScroll}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      ref={innerRef}
    >
      {children && children}
      <style jsx>
        {`
          @media (min-width: 1320px) {
            ${variantsMapping[as]} {
              ${getStylesByScreen('desktop')}
            }
          }

          @media (min-width: 992px) and (max-width: 1319px) {
            ${variantsMapping[as]} {
              ${getStylesByScreen('laptop')}
            }
          }

          @media (min-width: 768px) and (max-width: 1319px) {
            ${variantsMapping[as]} {
              ${getStylesByScreen('tablet')}
            }
          }

          @media (max-width: 767px) {
            ${variantsMapping[as]} {
              ${getStylesByScreen('mobile')}
            }
          }
        `}
      </style>
    </Component>
  );
};

export default Design;
