import { cmsActionTypes } from '@actionTypes';
import {
  CmsStateModel,
  ValuePropositionDetailsModel,
  SimpleOrderDataType,
} from '@models';

import { ItemInitialState } from './reducer.constant';

const initialState: CmsStateModel = {
  valuePropositions: ItemInitialState(
    {} as SimpleOrderDataType<ValuePropositionDetailsModel[]>
  ),
};

function cmseducer(state = initialState, action): CmsStateModel {
  switch (action.type) {
    /**
     * LOAD_CART_DETAILS
     */
    case cmsActionTypes.LOAD_VALUE_PROPOSITION:
      return {
        ...state,
        valuePropositions: {
          ...state.valuePropositions,
          fetching: true,
        },
      };
    case cmsActionTypes.LOAD_VALUE_PROPOSITION_SUCCESS:
      return {
        ...state,
        valuePropositions: {
          data: action.data,
          fetching: false,
        },
      };
    case cmsActionTypes.LOAD_VALUE_PROPOSITION_FAILURE:
      return {
        ...state,
        valuePropositions: {
          ...state.valuePropositions,
          fetching: false,
        },
      };

    default:
      return state;
  }
}

export default cmseducer;
