import {
  CategoryFacetOptionChildrenModel, CategoryFacetOptionsModel, DealCategory,
  ProductCategory, ProductImage, ProductsListModel, ProductVariation,
  ProductVariationAttributeTypeEnum, ProductVariationConvertModel, SortModel,
  StockStatusEnum, VisualSwatchType,
} from '@models';
import { INVALID_PARAMS } from '@utils/shared.utils';

/**
 * Factory function that return current active categories base on each deal
 * @param {ProductsListModel} productDetails | details of deal products
 * @returns {DealCategory[]}
 */
const dealCategories = (productDetails: ProductsListModel): DealCategory[] => {
  if (!productDetails?.facets) return [];
  const categories = productDetails.facets.find(
    (facet) => facet.type === 'category'
  );
  if (!categories) return [];
  return categories.options.map((category) => ({
    slug: category.slug,
    label: category.label,
  }));
};

/**
 * Factory function that return order details base on selected order option
 * @param {string} key
 * @param {SortModel[]} options
 * @returns {{ order_by: string; order_dir: string }}
 */
const orderDetails = (
  key: string,
  options: SortModel[]
): { order_by: string; order_dir: string } => {
  const selectedSortType = options.find((item) => item.key === key);

  return {
    order_by: key === 'low' ? 'price' : selectedSortType?.key ?? 'low',
    order_dir: selectedSortType?.sort_dir ?? 'ASC',
  };
};

/*
  {
    128: ['XL', 'Blue', 'Brown', 'XLL'],
    XL: [128, 'XLL'],
    Blue: [128]
  }
*/
const generateAvailableVariations = (variations: ProductVariation[]) => {
  const finalList: {} = {};

  variations.forEach((v) => {
    v.attributes.map((a) => {
      const option = a.options[0];
      finalList[option.slug] = _findRelatedOptions(variations, option.slug);
    });
  });

  return finalList;
};

const _findRelatedOptions = (
  variations: ProductVariation[],
  optionSlug: string
) => {
  const relatedSlugs: string[] = [optionSlug];

  variations.forEach((v) => {
    v.attributes.forEach((a) => {
      if (a.options[0].slug === optionSlug) {
        v.attributes.forEach((vv) => {
          if (
            relatedSlugs.indexOf(vv.options[0].slug) === -1 &&
            vv.options[0].slug !== optionSlug
          )
            relatedSlugs.push(vv.options[0].slug);
        });
      }
    });
  });

  return relatedSlugs;
};

/**
 * This method only generate a list for more simpler code when we want to render variations and nothing more
 * @param variations a list of variations that comes from the api
 * @returns a normalized list that will be used in rendering
 */
const normalizeProductVariationsForRender = (
  variations: ProductVariation[]
): ProductVariationConvertModel[] => {
  const finalList: ProductVariationConvertModel[] = [];

  const allAvailableAttributes: {
    label: string;
    slug: string;
    type: ProductVariationAttributeTypeEnum;
  }[] = variations[0].attributes.map((x) => {
    return {
      label: x.label,
      type: x.type,
      slug: x.slug,
    };
  });

  allAvailableAttributes.forEach((a) => {
    const attributes: {
      variationId: number;
      stock_status: StockStatusEnum;
      inventory_qty: number;
      label: string;
      slug: string;
      visual_swatch_type: VisualSwatchType;
      visual_swatch_color_code: string;
      text_swatch_label: string;
      visual_swatch_image: ProductImage;
      order: number;
    }[] = [];

    variations.forEach((v) => {
      v.attributes.forEach((t) => {
        if (t.slug === a.slug) {
          const option = t.options[0];

          if (attributes.find((x) => x.label === option.label)) return;

          attributes.push({
            variationId: v.id,
            stock_status: v.stock_status,
            inventory_qty: v.inventory_qty,
            label: option.label,
            slug: option.slug,
            visual_swatch_type: option.visual_swatch_type,
            visual_swatch_color_code: option.visual_swatch_color_code,
            text_swatch_label: option.text_swatch_label,
            visual_swatch_image: option.visual_swatch_image,
            order: option.order,
          });
        }
      });
    });

    finalList.push({
      label: a.label,
      type: a.type,
      attributes: attributes.sort((a, b) => a.order - b.order),
    });
  });

  return finalList;
};

/*
{
  1479203832: ['128', 'Brown', 'XLL'],
  1788294793: ['128', 'Brown', 'XL'],
}
*/
const generateVariationsTemplate = (
  variations: ProductVariation[]
): Record<number, string[]> => {
  const list = {};

  variations.forEach((v) => {
    const items: string[] = [];
    v.attributes.forEach((a) => {
      items.push(a.options[0].slug);
    });
    list[v.id] = items;
  });

  return list;
};

type Route = Record<'key' | 'path' | 'title', string>;
let routesArray: Route[];

const getRouteFromCategory = (category: ProductCategory) => {
  routesArray.push({
    key: category?.slug,
    path: `category/${category?.slug}/products`,
    title: category?.title ?? category?.slug,
  });

  if (category?.child) {
    getRouteFromCategory(category.child);
  }
};

const generateProductRouteForBreadcrumb = (category: ProductCategory) => {
  routesArray = [];
  getRouteFromCategory(category);

  return routesArray;
};

/**
 * Factory function that convert first level facet option to tree node
 * @param {Record<string, CategoryFacetOptionsModel>} facetOptions - category options
 * @returns
 */
const treeNodeParentCategory = (
  facetOptions: Record<string, CategoryFacetOptionsModel>
) => {
  if (!facetOptions) return [];

  return Object.values(facetOptions).map((item) => ({
    key: item.slug,
    title: item.label,
    children: treeNodeCategory(item?.children ?? []),
  }));
};

/**
 * Factory function that convert facet option to tree node
 * @param {CategoryFacetOptionChildrenModel[]} options
 * @returns
 */
const treeNodeCategory = (options: CategoryFacetOptionChildrenModel[]) => {
  if (options.length === 0) return [];
  return options.map((item) => ({
    key: item.slug,
    title: item.label,
    children: treeNodeCategory(item?.children ?? []),
  }));
};

/**
 * Factory function that check the param is valid
 * @param {string} param | the url param key
 * @returns {boolean}
 */
const isValidParam = (param: string): boolean => {
  if (!param) return false;
  return !INVALID_PARAMS.includes(param);
};

export {
  dealCategories, generateAvailableVariations,
  generateProductRouteForBreadcrumb, generateVariationsTemplate, isValidParam,
  normalizeProductVariationsForRender, orderDetails, treeNodeCategory,
  treeNodeParentCategory,
};
