// @flow

import { OrderStateModel, PlaceOrderResponseModel } from '@models';
import { orderActionTypes } from '@actionTypes';
import { reducerItemInitialState } from './reducer.constant';

const initialState: OrderStateModel = {
  all: reducerItemInitialState as any,
  detail: reducerItemInitialState as any,
  payment_methods: reducerItemInitialState as any,
  checkout: reducerItemInitialState as any,
  recommendations: reducerItemInitialState as any,
  recentOrder: {} as PlaceOrderResponseModel,
};

function orderReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * LOAD_ALL_ORDER
     */
    case orderActionTypes.LOAD_ALL_ORDER:
      return {
        ...state,
        all: {
          fetching: true,
        },
      };
    case orderActionTypes.LOAD_ALL_ORDER_SUCCESS:
      return {
        ...state,
        all: {
          data: action.data,
          fetching: false,
        },
      };
    case orderActionTypes.LOAD_ALL_ORDER_FAILURE:
      return {
        ...state,
        all: {
          fetching: false,
        },
      };

    /**
     * LOAD_ORDER
     */
    case orderActionTypes.LOAD_ORDER:
      return {
        ...state,
        detail: {
          fetching: true,
        },
      };
    case orderActionTypes.LOAD_ORDER_SUCCESS:
      return {
        ...state,
        detail: {
          fetching: false,
          data: action.data,
        },
      };
    case orderActionTypes.LOAD_ORDER_FAILURE:
      return {
        ...state,
        detail: {
          fetching: false,
          error: action.error,
        },
      };

    /**
     * GET_CHECKOUT_RECOMMENDATIONS
     */
    case orderActionTypes.GET_CHECKOUT_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: {
          fetching: true,
        },
      };
    case orderActionTypes.GET_CHECKOUT_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        recommendations: {
          data: action.data.data,
          fetching: false,
        },
      };
    case orderActionTypes.GET_CHECKOUT_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        recommendations: {
          fetching: false,
        },
      };

    case orderActionTypes.SET_RECENT_ORDER:
      return {
        ...state,
        recentOrder: action.data,
      };

    default:
      return state;
  }
}

export default orderReducer;
