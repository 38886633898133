import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MinusCircle, PlusCircle, Trash2 } from 'react-feather';

import { cartActions } from '@actions';
import { usePromise, useStorage, useMediaQuery } from '@hooks';
import { AddressRegionSelected, ProductDealModel } from '@models';
import { SpinnerModule } from '@modules';
import { getRegionToken } from '@helpers';
import { appSelectors } from '@selectors';
import { Design, mediaQuery, notification, Wording } from '@components';

import {
  triggerCartQtyChangeEvent,
  triggerDeleteItemEvent,
} from '@utils/seo/events';

interface Props {
  cartId: string;
  productId: number;
  minQty: number;
  maxQty: number;
  inventoryQty: number;
  qty: number;
  isInCartModal?: boolean;
  productDeal?: ProductDealModel;
  activeDeal?: boolean;
  page: 'cart' | 'popup-cart' | 'sticky-order' | 'plp';
  onSetQty?: (qty: number) => void;
  onChangeQty?: (state: boolean) => void;
}

const ProductQuantityControl: FC<Props> = ({
  cartId,
  maxQty,
  minQty,
  inventoryQty,
  qty,
  isInCartModal = false,
  productDeal,
  activeDeal,
  onSetQty = () => {},
  onChangeQty = () => {},
}) => {
  const promise = usePromise();
  const isDesktop = useMediaQuery(mediaQuery.desktop);
  const iconWrapperSize = isDesktop ? '32px' : '28px';
  const iconSize = isDesktop ? 28 : 24;

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [newQuantity, setNewQuantity] = useState<number>(0);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visibleAreaModal
  );

  const itemMaxQty = () => {
    if (isInCartModal) {
      onSetQty(Math.min(maxQty, qty));
      return Math.max(maxQty, qty);
    }
    if (activeDeal && productDeal) return productDeal.deal_qty;
    return Math.min(maxQty, inventoryQty);
  };

  const reloadCart = async (full: boolean) => {
    promise(cartActions.loadCartCompact(currentRegion?.area?.id));
    if (full)
      await promise(cartActions.loadCartDetails(currentRegion?.area.id));

    setLoading(false);
  };

  const handleRemoveProduct = () => {
    if (loading) return;

    setLoading(true);
    promise(cartActions.cartDelete(cartId))
      .then(() => {
        triggerDeleteItemEvent();
        reloadCart(true);
      })
      .catch((error) => {
        notification({
          type: 'error',
          message: 'Error',
          description: error?.message,
        });
      })
      .finally(() => {
        if (mounted) setLoading(false);
      });
  };

  const handleDecreaseQuantity = () => {
    if (!cartId) return;

    const newQty = (newQuantity > 0 ? newQuantity : qty) - 1;

    triggerCartQtyChangeEvent('minus');

    if (isInCartModal) {
      onSetQty(newQty);
      onChangeQty(true);
    }

    if (!isInCartModal) {
      setLoading(true);

      return promise(
        cartActions.cartUpdateQty(cartId, {
          qty: newQty,
        })
      )
        .then(() => {
          setNewQuantity(newQty);
          reloadCart(true);
        })
        .catch((error) => {
          notification({
            type: 'error',
            message: 'Error',
            description: error?.message,
          });
        })
        .finally(() => setLoading(false));
    }

    return;
  };

  const handleIncreaseQuantity = () => {
    if (!cartId) return;

    const newQty = (newQuantity > 0 ? newQuantity : qty) + 1;

    triggerCartQtyChangeEvent('plus');

    if (isInCartModal) {
      setLoading(false);
      onSetQty(newQty);
      onChangeQty(true);
    }

    if (!isInCartModal) {
      setLoading(true);

      return promise(
        cartActions.cartUpdateQty(cartId, {
          qty: newQty,
        })
      )
        .then(() => {
          setNewQuantity(newQty);
          reloadCart(true);
        })
        .catch((error) => {
          notification({
            type: 'error',
            message: 'Error',
            description: error?.message,
          });
        })
        .finally(() => setLoading(false));
    }

    return;
  };

  const handleMinusButton = () => {
    if (loading) return;

    if (isInCartModal) {
      if (qty > 1) {
        handleDecreaseQuantity();
      }
      return;
    }
    if (qty > minQty) {
      // just decrease the qty
      handleDecreaseQuantity();
    } else {
      // remove from basket
      handleRemoveProduct();
    }
  };

  const handlePlusButton = () => {
    if (loading) return;

    if (qty >= itemMaxQty()) return;

    handleIncreaseQuantity();
  };

  const renderMinusHandler = () => {
    if (isInCartModal) {
      return (
        <Design
          variant="li"
          width={iconWrapperSize}
          height={iconWrapperSize}
          minWidth={iconWrapperSize}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={
            qty === minQty || loading ? 'no-pointer-events' : 'has-pointer'
          }
          onClick={handleMinusButton}
        >
          <MinusCircle
            width={iconSize}
            height={iconSize}
            color={qty === minQty ? '#9E9E9E' : '#212121'}
          />
        </Design>
      );
    }

    return (
      <Design
        variant="li"
        width={iconWrapperSize}
        height={iconWrapperSize}
        minWidth={iconWrapperSize}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={loading ? 'no-pointer-events' : 'has-pointer'}
        onClick={handleMinusButton}
      >
        {(newQuantity === 0 ? qty : newQuantity) <= minQty ? (
          <Trash2 width={iconSize} height={iconSize} color={'#212121'} />
        ) : (
          <MinusCircle
            width={iconSize}
            height={iconSize}
            color={
              (newQuantity === 0 ? qty : newQuantity) === minQty
                ? '#9E9E9E'
                : '#212121'
            }
          />
        )}
      </Design>
    );
  };

  return (
    <Design variant="ul" display="flex" gap="9px" alignItems="center">
      {renderMinusHandler()}

      <Design
        variant="li"
        width="16px"
        height="28px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        {loading ? (
          <SpinnerModule />
        ) : (
          <Wording
            fontSize="16px"
            lineHeight="28px"
            fontWeight="500"
            textAlign="center"
          >
            {newQuantity === 0 ? qty : newQuantity}
          </Wording>
        )}
      </Design>

      <Design
        variant="li"
        width={iconWrapperSize}
        height={iconWrapperSize}
        minWidth={iconWrapperSize}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={
          (newQuantity === 0 ? qty : newQuantity) >= itemMaxQty() || loading
            ? 'no-pointer-events'
            : 'has-pointer'
        }
        onClick={handlePlusButton}
      >
        <PlusCircle
          width={iconSize}
          height={iconSize}
          color={
            (newQuantity === 0 ? qty : newQuantity) >= itemMaxQty()
              ? '#9E9E9E'
              : '#212121'
          }
        />
      </Design>
    </Design>
  );
};

export default ProductQuantityControl;
