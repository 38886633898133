import React, { FC, useCallback, useEffect, useRef } from 'react';

import { ArrowUp } from 'react-feather';

import styles from './go-to-top.module.scss';

interface Props {}

const GoToTop: FC<Props> = () => {
  const buttonRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (buttonRef.current) {
      if (window.scrollY > document.body.scrollHeight * 0.3) {
        buttonRef.current.classList.add('active');
      } else {
        buttonRef.current.classList.remove('active');
      }
    }
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <a className={styles.Container} ref={buttonRef} onClick={handleClick}>
        <ArrowUp width={18} height={18} color='#fff' />
    </a>
  );
};

export default GoToTop;