import { AddressModel } from '@models';
import { Design, Wording } from '@components';

import styles from './profile-address-card.module.scss';
import { FC } from 'react';

type Props = {
  data: AddressModel;
  isInModal?: boolean;
  onEdit: (data: AddressModel) => void;
  onDelete: (data: AddressModel) => void;
};

const ProfileAddressCard: FC<Props> = ({
  data,
  isInModal = false,
  onEdit,
  onDelete,
}) => {
  return (
    <Design className={styles.AddressCardItem} flexDirection="column">
      <Design display="flex" justifyContent="space-between" marginBottom="5px">
        <Wording fontSize="16px" fontWeight="600">
          {data?.full_name}
        </Wording>

        {!isInModal && (
          <Design display="flex" gap="20px">
            <div onClick={() => onEdit(data)}>
              <Wording fontWeight="600" className="has-pointer">
                Edit
              </Wording>
            </div>
            <div onClick={() => onDelete(data)}>
              <Wording fontWeight="600" className="has-pointer" color="error">
                Delete
              </Wording>
            </div>
          </Design>
        )}
      </Design>

      <Design display="flex" flexDirection="column">
        <Wording color="caption">{data?.address_line_1}</Wording>
        {data?.address_line_2 && (
          <Wording color="caption">{data?.address_line_2}</Wording>
        )}
        {data?.area && (
          <Wording color="caption">{data.area?.name ?? ''}</Wording>
        )}
        <Wording color="caption">{data?.phone_number}</Wording>
      </Design>

      {data?.is_default_shipping && !isInModal && (
        <Wording color="link" marginTop="5px">
          Preferred Shipping Address
        </Wording>
      )}
    </Design>
  );
};

export default ProfileAddressCard;
