import React, { FC } from 'react';

import { Design, Wording } from '@components';

interface Props {
  searchResultTitle?: string;
  title?: string;
  metaDescription?: string;
  productsCount: number;
}

const CategoryTitle: FC<Props> = ({
  searchResultTitle = '',
  title = '',
  metaDescription,
  productsCount,
}) => {
  if (searchResultTitle)
    return (
      <Design
        display="flex"
        flexDirection="column"
        margin={{ all: '0 0 20px 0', mobile: '0 0 16px 0' }}
      >
        <Design
          display="flex"
          flexDirection={{ all: 'row', mobile: 'column' }}
          justifyContent="space-between"
        >
          <Wording
            fontSize={{ all: '24px', tablet: '18px', mobile: '16px' }}
            lineHeight={{ all: '34px', tablet: '26px', mobile: '22px' }}
            fontWeight="500"
          >
            Search results for “{searchResultTitle}”
          </Wording>

          <Wording
            fontSize={{ all: '14px', tablet: '14px', mobile: '12px' }}
            lineHeight={{ all: '20px', tablet: '20px', mobile: '18px' }}
            color="caption"
            fontWeight="500"
            marginTop={{
              all: '0',
              mobile: '10px',
            }}
          >
            {productsCount} Items
          </Wording>
        </Design>
      </Design>
    );

  return (
    <Design
      display="flex"
      flexDirection="column"
      margin={{ all: '0 0 20px 0', mobile: '0 0 16px 0' }}
    >
      <Design display="flex" gap="12px" flexDirection="column">
        <Design
          display="flex"
          flexDirection={{ all: 'row', mobile: 'column' }}
          justifyContent="space-between"
        >
          {title && (
            <Wording
              fontSize={{ all: '24px', tablet: '18px', mobile: '16px' }}
              lineHeight={{ all: '34px', tablet: '26px', mobile: '22px' }}
              fontWeight="500"
            >
              {title}
            </Wording>
          )}

          <Wording
            fontSize={{ all: '14px', tablet: '14px', mobile: '12px' }}
            lineHeight={{ all: '20px', tablet: '20px', mobile: '18px' }}
            color="caption"
            fontWeight="500"
            marginTop={{
              all: '0',
              mobile: '10px',
            }}
          >
            {productsCount} Items
          </Wording>
        </Design>

        {metaDescription && (
          <Wording
            fontSize={{ all: '16px', tablet: '14px', mobile: '14px' }}
            lineHeight={{ all: '22px', tablet: '20px', mobile: '20px' }}
            color="caption"
          >
            {metaDescription}
          </Wording>
        )}
      </Design>
    </Design>
  );
};

export default CategoryTitle;
