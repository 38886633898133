import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import { Modal } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { X } from 'react-feather';

import { Design, Media } from '@components';
import { secondToShowReward, showRewardModal } from '@helpers';
import { triggerRewardFlowEvent } from '@utils/seo/events';
import { appSelectors } from '@selectors';
import RewardLoginOverview from '../reward-login-overview';

import classes from './reward-manager.module.scss';

const RewardManager: FC = () => {
  const auth = getAuth();
  const visibleAuthModal = useSelector(appSelectors.visibleAuthModal);
  const [showReward, setShowReward] = useState(false);
  const [showed, setShowed] = useState(false);
  const [second, setSecond] = useState(secondToShowReward());

  const { pathname } = useRouter();

  onAuthStateChanged(auth, (user) => {
    if (user && !user?.isAnonymous)
      setTimeout(() => setShowReward(false), 20000);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setSecond((second) => {
        if (second - 1 > 0) return second - 1;
        clearInterval(interval);
        return 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const { currentUser } = auth;

    if (
      second === 0 &&
      pathname !== '/login' &&
      pathname.indexOf('profile') < 0 &&
      pathname.indexOf('checkout') < 0 &&
      pathname.indexOf('landing/black_friday2022') < 0
    ) {
      const status = showRewardModal(
        currentUser ? currentUser?.isAnonymous : true
      );
      if (visibleAuthModal) {
        setShowReward(false);
      } else {
        if (!showed && status) {
          setTimeout(() => {
            setShowReward(status);
            setShowed(true);
          }, 3000);
        }
      }
    }
  }, [second, visibleAuthModal]);

  useEffect(() => {
    if (showed) triggerRewardFlowEvent();
  }, [showed]);

  return (
    <>
      <Media greaterThan="laptop">
        <Modal
          destroyOnClose
          visible={showReward}
          footer={false}
          onCancel={() => setShowReward(false)}
          centered
          className={classes.RewardModalWrapper}
          closeIcon={
            <Image
              height={36}
              width={36}
              src="/icons/reward/close-reward-icon.svg"
              alt="close-reward-icon"
            />
          }
          width={768}
        >
          <RewardLoginOverview onClose={() => setShowReward(false)} />
        </Modal>
      </Media>
      <Media lessThan="desktop">
        <BottomSheet
          open={showReward}
          onDismiss={() => setShowReward(false)}
          className={classes.RewardBottomSheetWrapper}
        >
          <Design
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="1"
            top="10px"
            right="10px"
            width="36px"
            height="36px"
            borderRadius="50%"
            backgroundColor="surface"
            onClick={() => setShowReward(false)}
          >
            {/* <Image
              height={36}
              width={36}
              src="/icons/reward/close-reward-icon.svg"
              alt="close-reward-icon"
            /> */}
            <X size={24} color="white" />
          </Design>
          <RewardLoginOverview onClose={() => setShowReward(false)} />
        </BottomSheet>
      </Media>
    </>
  );
};

export default RewardManager;
