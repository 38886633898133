export * from './details';
export * from './variations';
export * from './tabs';
export { default as ProductAddToCart } from './product-add-to-cart';
export { default as AddToWishlist } from './add-to-wishlist';
export { default as SocialShareComponent } from './social-share';
export { default as ProductOrderBox } from './product-order-box';
export { default as ProductTabsList } from './product-tabs-list';
export { default as ProductQuantityControl } from './quantity-control';
export { default as ProductStickyOrder } from './sticky-order';
