export const LOAD_ALL_WISHLIST = 'LOAD_ALL_WISHLIST';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const COMPACT_WISHLIST = 'COMPACT_WISHLIST';
export const DETAIL_WISHLIST = 'DETAIL_WISHLIST';
export const REPLACE_WISHLIST = 'REPLACE_WISHLIST';
export const DELETE_WISHLIST = 'DELETE_WISHLIST';

export const LOAD_ALL_WISHLIST_SUCCESS = 'LOAD_ALL_WISHLIST_SUCCESS';
export const LOAD_ALL_WISHLIST_FAILURE = 'LOAD_ALL_WISHLIST_FAILURE';
export const ADD_TO_WISHLIST_SUCCESS = 'ADD_TO_WISHLIST_SUCCESS';
export const ADD_TO_WISHLIST_FAILURE = 'ADD_TO_WISHLIST_FAILURE';
export const DELETE_WISHLIST_SUCCESS = 'DELETE_WISHLIST_SUCCESS';
export const DELETE_WISHLIST_FAILURE = 'DELETE_WISHLIST_FAILURE';
export const COMPACT_WISHLIST_SUCCESS = 'COMPACT_WISHLIST_SUCCESS';
export const COMPACT_WISHLIST_FAILURE = 'COMPACT_WISHLIST_FAILURE';
export const DETAIL_WISHLIST_SUCCESS = 'DETAIL_WISHLIST_SUCCESS';
export const DETAIL_WISHLIST_FAILURE = 'DETAIL_WISHLIST_FAILURE';
