import React, { FC, useEffect, useState } from 'react';

import { Design, Media, mediaQuery, Wording } from '@components';
import { CheckoutStepEnum } from '@models';
import { CheckCircle, CreditCard, MapPin } from 'react-feather';

import styles from './checkout-steps.module.scss';
import { useRouter } from 'next/router';
import { checkoutHelper } from '@helpers';
import { useMediaQuery } from '@hooks';

const CheckoutStepsModule: FC = () => {
  const isMobile = useMediaQuery(mediaQuery.mobile);
  const router = useRouter();
  const { step: checkoutStep } = router.query;
  const [currentStep, setCurrentStep] = useState(CheckoutStepEnum.SHIPPING);

  useEffect(() => {
    setCurrentStep(checkoutHelper.stepChange(checkoutStep as string));
  }, [checkoutStep]);

  const handleChangeStep = () => {
    if (checkoutStep === 'payment') {
      router.push('/checkout/shipping', undefined, { shallow: true });
      return;
    }
    return;
  };

  const renderStateTitle = (title: string, active: boolean) => (
    <Wording
      color={active ? 'surface' : 'placeholder'}
      fontWeight="500"
      fontSize={{
        all: '16px',
        tablet: '14px',
        mobile: '12px',
      }}
      lineHeight={{
        all: '22px',
        tablet: '20px',
        mobile: '18px',
      }}
    >
      {title}
    </Wording>
  );
  return (
    <Design
      padding={{ all: '', mobile: '4px 0 17px' }}
      marginTop={{ all: '', mobile: '-28px' }}
      backgroundColor={{
        all: 'transparent',
        mobile: 'background',
      }}
    >
      <Media greaterThan="mobile">
        <Design margin="0 auto 36px">
          <Wording
            textAlign="center"
            fontWeight="500"
            fontSize={{
              all: '34px',
              tablet: '24px',
            }}
            lineHeight={{
              all: '44px',
              tablet: '34px',
            }}
            color="surface"
          >
            CHECKOUT
          </Wording>
        </Design>
      </Media>
      <Design
        maxWidth="694px"
        margin="0 auto"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        className={isMobile ? 'container' : ''}
      >
        <Design
          className={[
            styles.step,
            checkoutStep === 'payment' && 'has-pointer',
            checkoutHelper.stepStatus(CheckoutStepEnum.SHIPPING, currentStep) &&
              styles.active,
          ].join(' ')}
          onClick={handleChangeStep}
        >
          <Design className={styles.icon_wrapper}>
            <MapPin />
          </Design>
          {renderStateTitle(
            'Shipping',
            checkoutHelper.stepStatus(CheckoutStepEnum.SHIPPING, currentStep)
          )}
        </Design>
        <Design
          className={[
            styles.line_separator,
            checkoutHelper.stepStatus(CheckoutStepEnum.PAYMENT, currentStep) &&
              styles.active_line,
          ].join(' ')}
        />
        <Design
          className={[
            styles.step,
            checkoutHelper.stepStatus(CheckoutStepEnum.PAYMENT, currentStep) &&
              styles.active,
          ].join(' ')}
        >
          <Design className={styles.icon_wrapper}>
            <CreditCard />
          </Design>
          {renderStateTitle(
            'Payment',
            checkoutHelper.stepStatus(CheckoutStepEnum.PAYMENT, currentStep)
          )}
        </Design>
        <Design
          className={[
            styles.line_separator,
            checkoutHelper.stepStatus(CheckoutStepEnum.DONE, currentStep) &&
              styles.active_line,
          ].join(' ')}
        />
        <Design
          className={[
            styles.step,
            checkoutHelper.stepStatus(CheckoutStepEnum.DONE, currentStep) &&
              styles.done,
          ].join(' ')}
        >
          <Design className={styles.icon_wrapper}>
            <CheckCircle />
          </Design>
          {renderStateTitle(
            'Done',
            checkoutHelper.stepStatus(CheckoutStepEnum.DONE, currentStep)
          )}
        </Design>
      </Design>
    </Design>
  );
};

export default CheckoutStepsModule;
