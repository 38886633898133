export const LOAD_ALL_CART_LIST = 'LOAD_ALL_CART_LIST';
export const LOAD_ALL_CART_LIST_SUCCESS = 'LOAD_ALL_CART_LIST_SUCCESS';
export const LOAD_ALL_CART_LIST_FAILURE = 'LOAD_ALL_CART_LIST_FAILURE';

export const LOAD_CART_DETAILS = 'LOAD_CART_DETAILS';
export const LOAD_CART_DETAILS_SUCCESS = 'LOAD_CART_DETAILS_SUCCESS';
export const LOAD_CART_DETAILS_FAILURE = 'LOAD_CART_DETAILS_FAILURE';

export const CART_COMPACT = 'CART_COMPACT';
export const CART_COMPACT_SUCCESS = 'CART_COMPACT_SUCCESS';
export const CART_COMPACT_FAILURE = 'CART_COMPACT_FAILURE';

export const CART_ADD = 'CART_ADD';
export const CART_ADD_SUCCESS = 'CART_ADD_SUCCESS';
export const CART_ADD_FAILURE = 'CART_ADD_FAILURE';

export const CART_DELETE = 'CART_DELETE';
export const CART_DELETE_SUCCESS = 'CART_DELETE_SUCCESS';
export const CART_DELETE_FAILURE = 'CART_DELETE_FAILURE';

export const CART_DETAIL = 'CART_DETAIL';
export const CART_DETAIL_SUCCESS = 'CART_DETAIL_SUCCESS';
export const CART_DETAIL_FAILURE = 'CART_DETAIL_FAILURE';

export const GET_CART_RECOMMENDATIONS = 'GET_CART_RECOMMENDATIONS';
export const GET_CART_RECOMMENDATIONS_SUCCESS =
  'GET_CART_RECOMMENDATIONS_SUCCESS';
export const GET_CART_RECOMMENDATIONS_FAILURE =
  'GET_CART_RECOMMENDATIONS_FAILURE';

export const CART_UPDATE_QTY = 'CART_UPDATE_QTY';
export const CART_UPDATE_VARIANT = 'CART_UPDATE_VARIANT';
export const CART_EMPTY = 'CART_EMPTY';
export const CART_VIEW = 'CART_VIEW';
