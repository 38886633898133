import { getRegionCurrency } from '@helpers';

const HomeBrandValues = [
  {
    key: `Free_Delivery_Over_100_${process.env.NEXT_PUBLIC_CURRENCY}`,
    image: '/icons/services/checkout-light.svg',
    title: `Free Delivery Over 100 ${process.env.NEXT_PUBLIC_CURRENCY}`,
  },
  {
    key: 'Fast_Shipping',
    image: '/icons/services/delivery-light.svg',
    title: 'Fast Shipping',
  },
  {
    key: 'Easy_Return_Refund',
    image: '/icons/services/refund-light.svg',
    title: 'Easy Return and Refund',
  },
  {
    key: 'Cash_On_Delivery',
    image: '/icons/services/cash-on-delivery.svg',
    title: 'Cash on Delivery',
  },
];

const HomeBrandValuesMobile = [
  {
    key: `Free_Delivery_Over_100_${process.env.NEXT_PUBLIC_CURRENCY}`,
    image: '/icons/services/checkout-light.svg',
    title: `Free Delivery Over 100 ${process.env.NEXT_PUBLIC_CURRENCY}`,
  },
  {
    key: 'Fast_Shipping',
    image: '/icons/services/delivery-light.svg',
    title: 'Fast Shipping',
  },
  {
    key: 'Best_Price_Guaranteed',
    image: '/icons/services/cash-on-delivery.svg',
    title: 'Cash on Delivery',
  },
];

const ProductDetailsBrandValues = [
  {
    key: '',
    image: '/icons/services/checkout-light.svg',
    title: `Free Shipping Over 100 ${process.env.NEXT_PUBLIC_CURRENCY}`,
  },
  {
    key: 'Fast_Cheap_Delivery',
    image: '/icons/services/delivery-light.svg',
    title: 'Fast & Cheap Delivery',
  },
  {
    key: 'Easy_Return_Refund',
    image: '/icons/services/refund-light.svg',
    title: 'Easy Return and Refund',
  },
  {
    key: 'Best_Price_Guaranteed',
    image: '/icons/services/price-light.svg',
    title: 'Best Price Guarantee',
  },
];

const ProductDetailsBrandValuesMobile = [
  {
    key: `Free_Delivery_Over_100_${process.env.NEXT_PUBLIC_CURRENCY}`,
    image: '/icons/services/checkout-light.svg',
    title: `Free Delivery Over 100 ${process.env.NEXT_PUBLIC_CURRENCY}`,
  },
  {
    key: 'Fast_Shipping',
    image: '/icons/services/delivery-light.svg',
    title: 'Fast Shipping',
  },
  {
    key: 'Best_Price_Guaranteed',
    image: '/icons/services/cash-on-delivery.svg',
    title: 'Cash on Delivery',
  },
];

const StaticPagesBrandValues = [
  {
    key: `Free_Delivery_Over_100_${process.env.NEXT_PUBLIC_CURRENCY}`,
    image: '/icons/services/checkout-light.svg',
    title: `Free Delivery Over 100 ${process.env.NEXT_PUBLIC_CURRENCY}`,
  },
  {
    key: 'Fast_Shipping',
    image: '/icons/services/delivery-light.svg',
    title: 'Fast Shipping',
  },
  {
    key: 'Easy_Return_Refund',
    image: '/icons/services/refund-light.svg',
    title: 'Easy Return and Refund',
  },
  {
    key: 'Cash_On_Delivery',
    image: '/icons/services/cash-on-delivery.svg',
    title: 'Cash on Delivery',
  },
  {
    key: 'Best_Price_Guaranteed',
    image: '/icons/services/price-light.svg',
    title: 'Best Price Guaranteed',
  },
];

const StaticPagesValuesTablet = [
  {
    key: `Free_Delivery_Over_100_${process.env.NEXT_PUBLIC_CURRENCY}`,
    image: '/icons/services/checkout-light.svg',
    title: `Free Delivery Over 100 ${process.env.NEXT_PUBLIC_CURRENCY}`,
  },
  {
    key: 'Fast_Shipping',
    image: '/icons/services/delivery-light.svg',
    title: 'Fast Shipping',
  },
  {
    key: 'Easy_Return_Refund',
    image: '/icons/services/refund-light.svg',
    title: 'Easy Return and Refund',
  },
  {
    key: 'Cash_On_Delivery',
    image: '/icons/services/cash-on-delivery.svg',
    title: 'Cash on Delivery',
  },
];

const StaticPagesValuesMobile = [
  {
    key: `Free_Delivery_Over_100_${process.env.NEXT_PUBLIC_CURRENCY}`,
    image: '/icons/services/checkout-light.svg',
    title: `Free Delivery Over 100 ${process.env.NEXT_PUBLIC_CURRENCY}`,
  },
  {
    key: 'Fast_Shipping',
    image: '/icons/services/delivery-light.svg',
    title: 'Fast Shipping',
  },
  {
    key: 'Cash_On_Delivery',
    image: '/icons/services/cash-on-delivery.svg',
    title: 'Cash on Delivery',
  },
];

export {
  StaticPagesBrandValues,
  StaticPagesValuesMobile,
  StaticPagesValuesTablet,
  HomeBrandValues,
  HomeBrandValuesMobile,
  ProductDetailsBrandValues,
  ProductDetailsBrandValuesMobile,
};
