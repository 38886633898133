import React, { FC } from 'react';

import { Design, Wording } from '@components';
import { formatPriceWithCurrency } from '@utils/number.utils';

interface Props {
  price: number;
  discountedPrice: number;
  discountAmount: number;
  fontSize: {
    priceSize: string;
    discountedPriceSize: string;
  };
}

const ProductPricing: FC<Props> = ({
  price,
  discountedPrice,
  discountAmount,
  fontSize: { priceSize = '32px', discountedPriceSize = '20px' },
}: Props) => {
  return (
    <>
      <Wording fontWeight="600" fontSize={priceSize}>
        {formatPriceWithCurrency(price)}
      </Wording>
      {discountAmount > 0 && (
        <Design display="flex" alignItems="center" gap="8px">
          <Wording
            fontWeight="normal"
            fontSize={discountedPriceSize}
            color="action"
            decoration="line-through"
          >
            {formatPriceWithCurrency(discountedPrice)}
          </Wording>
        </Design>
      )}
    </>
  );
};

export default ProductPricing;
