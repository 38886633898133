import { firebaseErrorHandler } from '@helpers';
import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import React, { FC, useEffect, useState } from 'react';

import classes from './auth-phone-resend-code.module.scss';

type Props = {
  phoneNumber: string;
  period: number;
  onResendCode: (data: ConfirmationResult) => void;
};
const AuthPhoneResendCode: FC<Props> = ({
  phoneNumber,
  period,
  onResendCode,
}) => {
  const [seconds, setSeconds] = useState(period);
  const [loading, setLoading] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();

  const handleResendCode = async () => {
    if (loading) return;
    setLoading(true);
    let appVerifier = recaptchaVerifier;
    if (!appVerifier) {
      const verifier = new RecaptchaVerifier(
        'submitBtn',
        {
          size: 'invisible',
        },
        getAuth()
      );
      setRecaptchaVerifier(verifier);
      appVerifier = verifier;
    }

    try {
      const confirmationResult = await signInWithPhoneNumber(
        getAuth(),
        phoneNumber,
        appVerifier
      );
      onResendCode(confirmationResult);
      setSeconds(period);
    } catch (error) {
      firebaseErrorHandler(error);
    } finally {
      setTimeout(() => setLoading(false), 1000);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => {
        if (seconds - 1 > 0) return seconds - 1;
        return 0;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const renderMinutes = () => Math.floor(seconds / 60);
  const renderSeconds = () => {
    const value = Math.floor(seconds % 60);
    if (value < 10) return [0, value].join('');
    return value;
  };

  if (seconds === 0)
    return (
      <div className={classes.ResendCodeButton} onClick={handleResendCode}>
        Resend Code
      </div>
    );
  return (
    <div className={classes.ResendTimerWrapper}>
      <p>Resend code in</p>
      <p className={classes.Timer}>
        {renderMinutes()}:{renderSeconds()}
      </p>
    </div>
  );
};

export default AuthPhoneResendCode;
