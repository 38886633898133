import { productsActionTypes } from '@actionTypes';
import { ProductsStateModel } from '@models';

import { reducerItemInitialState } from './reducer.constant';

const initialState: ProductsStateModel = {
  similarItems: reducerItemInitialState as any,
  recommendedForYou: reducerItemInitialState as any,
  frequentlyBought: reducerItemInitialState as any,
};

function ProductsReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * GET_SIMILAR_ITEMS
     */
    case productsActionTypes.GET_SIMILAR_ITEMS:
      return {
        ...state,
        similarItems: {
          ...state.similarItems,
          fetching: true,
        },
      };
    case productsActionTypes.GET_SIMILAR_ITEMS_SUCCESS:
      return {
        ...state,
        similarItems: {
          data: action.data.data,
          fetching: false,
        },
      };
    case productsActionTypes.GET_SIMILAR_ITEMS_FAILURE:
      return {
        ...state,
        similarItems: {
          fetching: false,
        },
      };

    /**
     * GET_RECOMMENDED_FOR_YOU
     */
    case productsActionTypes.GET_RECOMMENDED_FOR_YOU:
      return {
        ...state,
        recommendedForYou: {
          ...state.recommendedForYou,
          fetching: true,
        },
      };
    case productsActionTypes.GET_RECOMMENDED_FOR_YOU_SUCCESS:
      return {
        ...state,
        recommendedForYou: {
          data: action.data.data,
          fetching: false,
        },
      };
    case productsActionTypes.GET_RECOMMENDED_FOR_YOU_FAILURE:
      return {
        ...state,
        recommendedForYou: {
          fetching: false,
        },
      };

    /**
     * GET_FREQUENTLY_BOUGHT
     */
    case productsActionTypes.GET_FREQUENTLY_BOUGHT:
      return {
        ...state,
        frequentlyBought: {
          ...state.frequentlyBought,
          fetching: true,
        },
      };
    case productsActionTypes.GET_FREQUENTLY_BOUGHT_SUCCESS:
      return {
        ...state,
        frequentlyBought: {
          data: action.data.data,
          fetching: false,
        },
      };
    case productsActionTypes.GET_FREQUENTLY_BOUGHT_FAILURE:
      return {
        ...state,
        frequentlyBought: {
          fetching: false,
        },
      };

    default:
      return state;
  }
}

export default ProductsReducer;
