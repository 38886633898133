import { Design, Wording } from '@components';
import Image from 'next/image';
import React, { FC } from 'react';
import { X } from 'react-feather';

type Props = {
  handleClose: (got: boolean) => void;
};
const AppBannerModule: FC<Props> = ({ handleClose }) => {
  return (
    <Design
      display={{
        all: 'none',
        mobile: 'flex',
      }}
      padding="8px 16px"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="primary"
      height="52px"
    >
      <Design display="flex" alignItems="center">
        <Design
          width="24px"
          height="24px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight="12px"
          onClick={() => handleClose(false)}
        >
          <X />
        </Design>
        <Design marginRight="8px" height="36px" width="36px">
          <Image
            src="/icons/app-banner-logo.svg"
            width="36px"
            height="36px"
            alt="Remove Item"
          />
        </Design>
        <Design display="flex" flexDirection="column" alignItems="flex-start">
          <Wording
            font-weight="500"
            fontSize="14px"
            lineHeight="20px"
            marginBottom="4px"
          >
            Get 1Sell Application
          </Wording>
          <Wording font-weight="500" fontSize="10px" lineHeight="14px">
            Experience better shopping
          </Wording>
        </Design>
      </Design>

      <Design
        backgroundColor="background"
        borderRadius="6px"
        padding="5px 10px"
        onClick={() => handleClose(true)}
      >
        <a href="http://onelink.to/d892jr">
          <Wording
            font-weight="500"
            fontSize="16px"
            lineHeight="22px"
            color="surface"
          >
            GET
          </Wording>
        </a>
      </Design>
    </Design>
  );
};

export default AppBannerModule;
