import { addressesActionTypes } from '@actionTypes';
import {
  AddressAreasModel,
  AddressesStateModel,
  AddressModel,
  SimpleOrderDataType,
} from '@models';

import { ItemInitialState } from './reducer.constant';

const initialState: AddressesStateModel = {
  all: ItemInitialState({} as SimpleOrderDataType<AddressModel[]>),
  detail: ItemInitialState({} as AddressModel),
  cities: ItemInitialState([]),
  areas: ItemInitialState({} as AddressAreasModel),
  addressArea: null,
};

function addresses(state = initialState, action): AddressesStateModel {
  switch (action.type) {
    /**
     * LOAD_ALL_ADDRESSES
     */
    case addressesActionTypes.LOAD_ALL_ADDRESSES:
      return {
        ...state,
        all: {
          ...state.all,
          fetching: true,
        },
      };
    case addressesActionTypes.LOAD_ALL_ADDRESSES_SUCCESS:
      return {
        ...state,
        all: {
          data: action.data,
          fetching: false,
        },
      };
    case addressesActionTypes.LOAD_ALL_ADDRESSES_FAILURE:
      return {
        ...state,
        all: {
          ...state.all,

          fetching: false,
        },
      };

    /**
     * LOAD_ADDRESSES
     */
    case addressesActionTypes.LOAD_ADDRESSES:
      return {
        ...state,
        detail: {
          ...state.detail,
          fetching: true,
        },
      };
    case addressesActionTypes.LOAD_ADDRESSES_SUCCESS:
      return {
        ...state,
        detail: {
          data: action.data,
          fetching: false,
        },
      };
    case addressesActionTypes.LOAD_ADDRESSES_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          fetching: false,
        },
      };

    /**
     * LOAD_ALL_CITIES
     */
    case addressesActionTypes.LOAD_ALL_CITIES:
      return {
        ...state,
        cities: {
          ...state.cities,
          fetching: true,
        },
      };
    case addressesActionTypes.LOAD_ALL_CITIES_SUCCESS:
      return {
        ...state,
        cities: {
          data: action.data.data,
          fetching: false,
        },
      };
    case addressesActionTypes.LOAD_ALL_CITIES_FAILURE:
      return {
        ...state,
        cities: {
          ...state.cities,
          fetching: false,
        },
      };

    /**
     * LOAD_ALL_AREAS
     */
    case addressesActionTypes.LOAD_ALL_AREAS:
      return {
        ...state,
        areas: {
          ...state.areas,
          fetching: true,
        },
      };
    case addressesActionTypes.LOAD_ALL_AREAS_SUCCESS:
      return {
        ...state,
        areas: {
          data: action.data,
          fetching: false,
        },
      };
    case addressesActionTypes.LOAD_ALL_AREAS_FAILURE:
      return {
        ...state,
        areas: {
          ...state.areas,
          fetching: false,
        },
      };

    /**
     * CLEAR_ADDRESS_DETAILS
     */
    case addressesActionTypes.CLEAR_ADDRESS_DETAILS:
      return {
        ...state,
        detail: ItemInitialState({} as AddressModel),
      };
    /**
     * SET_ADDRESS_AREA
     */
    case addressesActionTypes.SET_ADDRESS_AREA:
      return {
        ...state,
        addressArea: action.data,
      };
    default:
      return state;
  }
}

export default addresses;
