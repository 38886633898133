import { campaignActionTypes } from '@actionTypes';

export function loadBlackFriday() {
  return {
    type: campaignActionTypes.LOAD_BLACK_FRIDAY,
    method: 'get',
    url: '/v2/campaign/black_friday',
  };
}

export function setCampaign(data: { campaign: string }) {
  return {
    type: campaignActionTypes.SET_REWARD_CAMPAIGN,
    method: 'post',
    url: '/v2/campaign/activate-reward-campaign',
    data,
  };
}
