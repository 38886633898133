import React, { FC, memo, useCallback, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { useRouter } from 'next/router';
import { Heart, PlusCircle } from 'react-feather';
import { useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import Link from 'next/link';

import { cartActions, wishlistActions } from '@actions';
import {
  Design,
  Image,
  mediaQuery,
  notification,
  ProductQuantityControl,
} from '@components';
import { useLiveTimer, useMediaQuery, usePromise, useStorage } from '@hooks';
import {
  AddressRegionSelected,
  CarouselProductsListModel,
  DealStatus,
  SeoEventListLocation,
  SeoEventProductModel,
  StockStatusEnum,
} from '@models';
import { appSelectors, cartSelectors } from '@selectors';
import { SpinnerModule } from '@modules';
import { formatPriceWithCurrency } from '@utils/number.utils';
import {
  triggerAddToCartEvent,
  triggerAddToWishlistEvent,
} from '@utils/seo/events';
import { getRegionToken } from '@helpers';

import styles from './product-card.module.scss';

interface Props {
  product: CarouselProductsListModel;
  cardLocation: SeoEventListLocation;
  productInNewTab?: boolean;
  cardIndex: number;
  wishlistData: any;
  dealTitle?: string;
  dealTitleBackground?: string;
  showShimmerEffect?: boolean;
  showTimer?: boolean;
  categorySlug?: string;
}

const ProductCardModule: FC<Props> = ({
  product,
  dealTitle,
  cardLocation,
  cardIndex,
  dealTitleBackground,
  showShimmerEffect = true,
  showTimer = true,
  productInNewTab = false,
  categorySlug,
}: Props) => {
  // Media Queries
  const isMobile = useMediaQuery(mediaQuery.mobile);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const isDesktop = useMediaQuery(mediaQuery.desktop);

  const iconWrapperSize = isDesktop ? '32px' : '28px';
  const iconSize = isDesktop ? 28 : 24;

  const promise = usePromise();
  const store = useStore();
  const router = useRouter();
  const [addToCartLoading, setAddToCartLoading] = useState(false);

  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visibleAreaModal
  );

  const { days, hours, minutes, seconds, hideTimer } = useLiveTimer(
    product &&
      product.product_deal &&
      product.product_deal?.deal_is_active === true,
    product?.product_deal?.deal_end_time
  );

  const [wishlistLoading, setWishlistLoading] = useState<boolean>(false);

  const selectCompactWishlist = createSelector(
    (state) => state.wishlist.compact.data,
    (products) =>
      products?.data &&
      products.data.findIndex((item) => item.product_id === product.id) > -1
  );

  const { data: compactData } = useSelector(cartSelectors.compact);

  const usedCompactWishlist = useSelector(selectCompactWishlist);

  const selectWishlist = createSelector(
    (state) => state.wishlist?.items?.data,
    (products) =>
      products &&
      products.data.findIndex((item) => item.product_id === product.id) > -1
  );

  const usedWishlist = useSelector(selectWishlist);

  const isWishlistPage = router?.pathname?.includes('/wishlist');

  const isInWishlist = usedCompactWishlist || usedWishlist;

  const productInCart = useMemo(() => {
    return compactData?.items.find((item) => item.product_id === product.id);
  }, [compactData?.items]);

  const maxQtyForAddingToCart: number = useMemo(() => {
    if (product.inventory_qty && product.inventory_qty_left)
      return Math.min(product.inventory_qty, product.inventory_qty_left);
    return product.inventory_qty ?? 0;
  }, [product]);

  const handleAddToWishlist = useCallback((productId: number) => {
    return promise(
      wishlistActions.addToWishlist({
        product_id: productId,
      })
    )
      .catch((err) => {
        notification({
          type: 'error',
          message: 'Error',
          description: err.message,
        });
      })
      .finally(() => {
        setWishlistLoading(false);
      });
  }, []);

  const handleRemoveFromWishlist = useCallback((product_id) => {
    const wishlistStore = store.getState().wishlist;
    const wishlistData: any[] = get(wishlistStore, 'compact.data.data', []);

    const id = wishlistData.find((item) => item.product_id === product_id).id;

    return promise(wishlistActions.deleteWishlist(id))
      .then(() => {
        if (isWishlistPage) {
          promise(wishlistActions.compactWishlist());
          promise(wishlistActions.loadAll());
        }
      })
      .catch((err) => {
        notification({ type: 'error', message: err.message });
      })
      .finally(() => {
        setWishlistLoading(false);
      });
  }, []);

  const handleWishlistClick = (e) => {
    setWishlistLoading(true);

    e.stopPropagation();
    e.preventDefault();
    isInWishlist
      ? handleRemoveFromWishlist(product.id)
      : handleAddToWishlist(product.id);

    const addedProducts: SeoEventProductModel[] = [];

    addedProducts.push({
      brand: '',
      category: [],
      discount: product?.discount_amount,
      id: product.id.toString(),
      list_location: SeoEventListLocation.ITEM_DETAILS,
      name: product.title,
      position: 0,
      price: product?.price,
      qty: product?.inventory_qty_left,
      variant: null,
    } as SeoEventProductModel);

    triggerAddToWishlistEvent(addedProducts);
  };

  const handleAddToCart = async () => {
    if (addToCartLoading) return;

    setAddToCartLoading(true);

    const addData = {
      product_id: product.id,
      qty: 1,
      variation_id: undefined,
    };

    promise(cartActions.cartAdd(addData))
      .then(async () => {
        await promise(cartActions.loadCartCompact(currentRegion?.area?.id));
        seoEvent();
        if (isTablet || isMobile) {
          notification({
            type: 'success',
            message: 'Product has been added to your cart.',
            duration: 5,
          });
        }

        if (router.pathname === '/cart') {
          promise(cartActions.loadCartDetails(currentRegion?.area?.id));
        }
      })
      .catch(() => {})
      .finally(() => setAddToCartLoading(false));
  };

  const seoEvent = () => {
    const addedProducts: SeoEventProductModel[] = [];
    addedProducts.push({
      discount: product.discount_amount,
      list_location: SeoEventListLocation.CATEGORY_LIST,
      name: product.title,
      position: 0,
      price: product.price,
      qty: 1,
    } as SeoEventProductModel);

    triggerAddToCartEvent(addedProducts);
  };

  const renderWishlistButton = () => (
    <div className={styles.WishlistContainer} onClick={handleWishlistClick}>
      {wishlistLoading ? (
        <div className={styles.SpinWrapper}>
          <Spin size="small" />
        </div>
      ) : (
        <>
          {isInWishlist ? (
            <Heart width="100%" color="#E78402" fill="#E78402" />
          ) : (
            <Heart width="100%" color="#949494" />
          )}
        </>
      )}
    </div>
  );

  const renderDealSlug = () => {
    if (
      dealTitle &&
      dealTitle?.length > 0 &&
      product.deal_status === DealStatus.ACTIVE &&
      product?.deal_slugs?.length > 0
    ) {
      return (
        <Design
          className={`${styles.DealTitle} ${
            dealTitle?.length > 17 ? styles.Marquee : ''
          }`}
          CustomBackgroundColor={dealTitleBackground}
        >
          <span>{dealTitle}</span>
        </Design>
      );
    }

    return null;
  };

  const renderImage = () => {
    if (product?.images?.length > 0)
      return (
        <div className={styles.ImageContainer}>
          <Image
            src={product.images[0].urls.original}
            showShimmerAnimation={showShimmerEffect}
            width={248}
            objectFit="cover"
            height={240}
            alt={product.title}
          />
        </div>
      );

    return null;
  };

  const renderTitle = () => {
    return (
      <div
        className={`${styles.Title} ${
          product.available_in?.length > 0 ? styles.WithVariants : ''
        }`}
      >
        <p>
          {truncate(product.title, {
            length: 50,
            separator: ' ',
            omission: ' ...',
          })}
        </p>
        {product.available_in?.length > 0 && (
          <div className={styles.VariantList}>
            <p>Available in</p>
            <p>{product.available_in}</p>
          </div>
        )}
      </div>
    );
  };

  const renderPriceValue = () => {
    let price = product.price;

    // check for deal price
    if (product.product_deal && product.deal_status === DealStatus.ACTIVE)
      price = product.product_deal.deal_price ?? 0;

    return (
      <Design flex="1" className={styles.Price}>
        {formatPriceWithCurrency(price)}
      </Design>
    );
  };

  const renderDiscount = () =>
    product.discount_amount > 0 &&
    product.stock_status === StockStatusEnum.IN_STOCK && (
      <Design marginBottom="3px" display="flex" justifyContent="flex-start">
        <div className={styles.DiscountPercentage}>
          {product.discount_amount}%
        </div>
        <div className={styles.DiscountAmount}>
          {formatPriceWithCurrency(product.list_price)}
        </div>
      </Design>
    );

  const renderDealTime = () => {
    if (product.deal_status === DealStatus.ACTIVE) {
      const dealHours = ('0' + hours).slice(-2);
      const dealMinutes = ('0' + minutes).slice(-2);
      const dealSeconds = ('0' + seconds).slice(-2);

      return (
        <div className={styles.DealData}>
          <span>
            {Number(hours) >= 48
              ? `${days} Days Left`
              : `${dealHours}:${dealMinutes}:${dealSeconds}`}
          </span>
        </div>
      );
    }

    if (product.deal_status === DealStatus.OUT_OF_STOCK) {
      return (
        <div className={`${styles.DealData} finished`}>Discount Finished!</div>
      );
    }
  };

  const renderQuantityControl = () => {
    if (
      product.available_in !== null ||
      product.stock_status === StockStatusEnum.OUT_OF_STOCK ||
      maxQtyForAddingToCart < 1
    )
      return;

    return (
      <Design
        className={styles.QuantityController}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {!productInCart &&
          (addToCartLoading ? (
            <Design
              variant="li"
              width={iconWrapperSize}
              height={iconWrapperSize}
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              backgroundColor="background"
              borderRadius="50%"
            >
              <SpinnerModule
                width={String(iconSize)}
                height={String(iconSize)}
                size={`${iconSize}px`}
              />
            </Design>
          ) : (
            <Design
              display="flex"
              variant="li"
              justifyContent="center"
              alignItems="center"
              width={iconWrapperSize}
              height={iconWrapperSize}
              backgroundColor="background"
              borderRadius="50%"
              className="has-pointer"
              onClick={() => handleAddToCart()}
            >
              <PlusCircle
                width={iconSize}
                height={iconSize}
                color={'#212121'}
              />
            </Design>
          ))}

        {productInCart && (
          <Design
            backgroundColor="background"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={iconWrapperSize}
            borderRadius="40px"
            width={isDesktop ? '99px' : '91px'}
          >
            <ProductQuantityControl
              cartId={productInCart.id}
              productId={productInCart.product_id}
              page="plp"
              minQty={product?.min_qty_in_shopping_cart}
              maxQty={product?.max_qty_in_shopping_cart}
              isInCartModal={false}
              inventoryQty={maxQtyForAddingToCart}
              qty={productInCart.qty}
              productDeal={product.product_deal}
              activeDeal={product.deal_status === DealStatus.ACTIVE}
            />
          </Design>
        )}
      </Design>
    );
  };

  return (
    <div className={styles.Container}>
      <Link
        href={
          categorySlug
            ? `/product/${product.slug}?category=${categorySlug}`
            : `/product/${product.slug}`
        }
        prefetch={false}
      >
        <a
          target={productInNewTab ? '_blank' : '_self'}
          onClick={() => {
            // if (cardLocation === SeoEventListLocation.RECOMMENDS_LIST) {
            window.scrollTo({ top: 0 });
            // }
          }}
        >
          <figure>
            {renderImage()}
            {renderQuantityControl()}
            <figcaption>
              {renderWishlistButton()}
              {renderDealSlug()}
              <Design
                padding={{
                  all: '2px 8px 8px',
                  mobile: '0 6px 6px',
                }}
              >
                {renderTitle()}
                <Design
                  display="flex"
                  justifyContent="flex-end"
                  flexDirection="column"
                >
                  <Design height={{ all: '18px', desktop: '20px' }}>
                    {renderDiscount()}
                  </Design>
                  <Design
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    height="26px"
                  >
                    {renderPriceValue()}
                    {!!showTimer && !hideTimer && renderDealTime()}
                  </Design>
                </Design>
              </Design>
            </figcaption>
          </figure>
        </a>
      </Link>
    </div>
  );
};

const compare = (prevProps: Props, nextProps: Props) => {
  return prevProps.product.id === nextProps.product.id;
};

export default memo(ProductCardModule, compare);
