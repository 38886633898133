import { all } from 'redux-saga/effects';

import campaignSaga from './campaign/campaign.saga';
import cartSaga from './cart/cart.saga';
import cmsSaga from './cms/cms.saga';
import categoriesSaga from './categories/categories.saga';
import contactsSaga from './contacts/contacts.saga';
import headerLinksSaga from './headerLinks/header-links.saga';
import homeSaga from './home/home.saga';
import productSaga from './products/product.saga';
import orderSaga from './order/order.saga';
import profileSaga from './profile/profile.saga';
import searchSaga from './search/search.saga';
import wishlistSaga from './wishlist/wishlist.saga';

import checkoutSaga from './checkout/checkout.saga';
import addressesSaga from './addresses/addresses.saga';

export default function* saga() {
  yield all([
    addressesSaga(),
    campaignSaga(),
    checkoutSaga(),
    cartSaga(),
    cmsSaga(),
    categoriesSaga(),
    contactsSaga(),
    headerLinksSaga(),
    homeSaga(),
    orderSaga(),
    profileSaga(),
    searchSaga(),
    wishlistSaga(),
    productSaga(),
  ]);
}
