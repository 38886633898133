import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import { Button } from 'antd';
import { useRouter } from 'next/router';

import { RewardPromotionCode, SpinnerModule } from '@modules';
import { RewardCampaigns } from '@models';

import classes from './reward-welcome.module.scss';
import { usePromise } from '@hooks';
import { campaignActions } from '@actions';

type Props = {
  onClose: () => void;
};
const RewardWelcome: FC<Props> = ({ onClose }) => {
  const router = useRouter();
  const promise = usePromise();

  const isInBlackFriday = router.pathname === '/landing/black_friday2022';

  const [newUser, setNewUser] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    promise(
      campaignActions.setCampaign({
        campaign: isInBlackFriday
          ? RewardCampaigns.BLACK_FRIDAY_2022
          : RewardCampaigns.FREE_DELIVERY_1,
      })
    )
      .then((response: any) => {
        if (response.result === 'promotion_code_already_sent') {
          setNewUser(false);
        } else {
          setNewUser(true);
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }, []);

  if (loading)
    return (
      <div className={classes.SpinnerContainer}>
        <SpinnerModule />
      </div>
    );

  if (newUser)
    return (
      <div className={classes.Container}>
        <div className={classes.ImageContainer}>
          <Image
            priority
            src="/icons/reward/welcome-new-user.svg"
            width="140px"
            height="140px"
            alt="welcome-new-user"
          />
        </div>
        <p className={classes.Title}>Welcome to 1Sell</p>
        <p className={classes.Subtitle}>
          {isInBlackFriday
            ? 'We will inform you by SMS when our deals begin.'
            : 'We will send promotion code to your phone number, happy shopping with us!'}
        </p>
        <div className={classes.ActionButtonWrapper}>
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            onClick={onClose}
          >
            Continue Exploring
          </Button>
        </div>
      </div>
    );

  return (
    <div className={classes.Container}>
      <div className={classes.ImageContainer}>
        <Image
          priority
          src="/icons/reward/message-mobile.svg"
          width="140px"
          height="140px"
          alt="message-mobile"
        />
      </div>
      <p className={classes.Title}>
        {isInBlackFriday ? 'Congrats!' : 'Welcome Back'}
      </p>
      <p className={classes.Subtitle}>
        {isInBlackFriday
          ? 'We already have your number in our list, we will inform you by SMS when deals begin.'
          : 'You already got your code for first order, please check your SMS inbox'}
      </p>

      {!isInBlackFriday && <RewardPromotionCode />}

      <div className={classes.ActionButtonWrapper}>
        <Button
          type="primary"
          htmlType="submit"
          shape="round"
          onClick={onClose}
        >
          Continue Exploring
        </Button>
      </div>
    </div>
  );
};

export default RewardWelcome;
