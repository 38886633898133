import React, { FC } from 'react';

import { Design, Wording } from '@components';

interface Props {
  estimation: string;
}

const ProductDeliveryEstimation: FC<Props> = ({ estimation }: Props) => {
  return (
    <Design display="flex" gap="6px" marginBottom="32px">
      <Wording fontWeight="500" variant="span" fontSize="16px" color="action">
        Delivery Estimation:
      </Wording>
      <Wording fontWeight="500" variant="span" fontSize="16px" color="surface">
        {estimation}
      </Wording>
    </Design>
  );
};

export default ProductDeliveryEstimation;
