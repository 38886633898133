import React, { memo } from 'react';

import { DealSortOptions } from '@models';
import { Design, Wording } from '@components';

import SortIcon from 'public/icons/sort-icon.svg';

import styles from './deal.module.scss';

type Props = {
  activeSortItem: string;
  onSort: (key: string, sortDir: string) => void;
};

const DealSort = ({ activeSortItem, onSort }: Props) => {
  return (
    <Design
      display={{ all: 'flex', mobile: 'none' }}
      gap="16px"
      margin="0 0 40px 0"
      alignItems="center"
    >
      <Design display="flex" gap="5px" alignItems="center">
        <SortIcon />
        <Wording fontWeight="500" color="caption">
          Sort by:
        </Wording>
      </Design>

      {DealSortOptions?.map((item) => (
        <Design
          padding="6px 8px"
          borderRadius="8px"
          key={item?.key}
          className={styles.SortItem}
          backgroundColor={
            activeSortItem === item?.key ? 'primary' : 'transparent'
          }
          onClick={() => onSort(item?.key, item?.sort_dir)}
        >
          <Wording
            fontWeight="500"
            color={activeSortItem === item?.key ? 'background' : 'surface'}
          >
            {item.title}
          </Wording>
        </Design>
      ))}
    </Design>
  );
};

export default memo(DealSort);
