import { addressesActionTypes } from '@actionTypes';
import { takeLatest } from 'redux-saga/effects';

import axiosMiddleware from '../../axios.middleware';

export default function* addressesSaga() {
  yield takeLatest(addressesActionTypes.LOAD_ALL_ADDRESSES, axiosMiddleware);
  yield takeLatest(addressesActionTypes.LOAD_ADDRESSES, axiosMiddleware);
  yield takeLatest(addressesActionTypes.CREATE_ADDRESS, axiosMiddleware);
  yield takeLatest(addressesActionTypes.UPDATE_ADDRESS, axiosMiddleware);
  yield takeLatest(addressesActionTypes.DELETE_ADDRESS, axiosMiddleware);
  yield takeLatest(addressesActionTypes.LOAD_ALL_CITIES, axiosMiddleware);
  yield takeLatest(addressesActionTypes.LOAD_ALL_REGION, axiosMiddleware);
  yield takeLatest(addressesActionTypes.LOAD_ALL_AREAS, axiosMiddleware);
  yield takeLatest(addressesActionTypes.CLEAR_ADDRESS_DETAILS, axiosMiddleware);
}
