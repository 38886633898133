import { searchActionTypes } from '@actionTypes';

export function catalogSearch(params?: any) {
  return {
    type: searchActionTypes.CATALOG_SEARCH,
    method: 'get',
    url: '/catalog/search/suggestions',
    params,
  };
}
