import React, { FC, useMemo } from 'react';
import { Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { ChevronDown } from 'react-feather';

import { Design } from '@components';
import { CategoryFacetModel } from '@models';

const { DirectoryTree } = Tree;

import styles from './filters-category.module.scss';
import { treeNodeParentCategory } from '@helpers';
import { useRouter } from 'next/router';

type Props = {
  facet: CategoryFacetModel;
  onHideFilters: () => void;
};
const FiltersCategoryModule: FC<Props> = ({ facet, onHideFilters }) => {
  const router = useRouter();

  const nodes: DataNode[] = useMemo(() => {
    return treeNodeParentCategory(facet.options);
  }, [facet, facet.option_slugs, facet.options]);

  const handleSelectCategory = (keys) => {
    onHideFilters();
    router.push(['', 'category', keys, 'products'].join('/'));
  };

  const onExpand = (keys, event) => {
    // console.log('Trigger Expand');
  };

  return (
    <Design
      padding={{
        all: '12px 16px',
        desktop: '12px 10px',
      }}
      className={styles.FiltersCategoryWrapper}
    >
      <DirectoryTree
        multiple
        defaultExpandedKeys={[]}
        defaultExpandParent
        onSelect={handleSelectCategory}
        onExpand={onExpand}
        treeData={nodes}
        switcherIcon={
          <Design>
            <ChevronDown width="12px" />
          </Design>
        }
      />
    </Design>
  );
};

export default FiltersCategoryModule;
