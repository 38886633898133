import { Design, Wording } from '@components';
import { SpinnerModule } from '@modules';
import { formatPriceWithCurrency, formattedNumber } from '@utils/number.utils';
import { Switch } from 'antd';
import React, { FC } from 'react';

import styles from './checkout-use-wallet.module.scss';

type Props = {
  balance: number;
  useWallet: boolean;
  isChecked: boolean;
  actionLoading: boolean;
  onUseWallet: (status: boolean) => void;
};
const CheckoutUseWalletModule: FC<Props> = ({
  balance,
  useWallet,
  isChecked,
  actionLoading,
  onUseWallet,
}) => {
  return (
    <Design
      backgroundColor="background_alt__second"
      borderRadius="8px"
      padding="10px 16px"
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="48px"
    >
      <Wording fontWeight="500" fontSize="16px" lineHeight="22px">
        Use Wallet (Balance:{' '}
        <Wording variant="span" fontWeight="700">
          {formatPriceWithCurrency(balance)}
        </Wording>{' '}
        )
      </Wording>
      {actionLoading ? (
        <SpinnerModule color="surface" />
      ) : (
        <Switch
          className={styles.UseWalletSwitch}
          defaultChecked={useWallet}
          onChange={onUseWallet}
          checked={isChecked}
        />
      )}
    </Design>
  );
};

export default CheckoutUseWalletModule;
