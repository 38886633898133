import dynamic from 'next/dynamic';
import { Spin } from 'antd';

import { AddressModel } from '@models';
import { Design, Button, Wording } from '@components';
import { FC } from 'react';

const ProfileAddressCard = dynamic<any>(() =>
  import('@components').then((mod) => mod.ProfileAddressCard)
);

type Props = {
  data: AddressModel;
  loading: boolean;
  onDelete: () => void;
  onCancel: () => void;
};

const ProfileAddressDeleteCard: FC<Props> = ({
  data,
  loading,
  onDelete,
  onCancel,
}) => {
  return (
    <Design padding={{ all: '40px', mobile: '20px' }}>
      <Wording fontSize="20px" fontWeight="500" margin="0 0 16px 0">
        Are you Sure?
      </Wording>
      <Wording fontSize="16px">
        You want to remove this address from your address book.
      </Wording>

      <Design
        margin="16px 0 0 0"
        borderRadius="8px"
        border="1px solid"
        borderColor="border"
        padding="8px 16px"
        display="flex"
        flexDirection="column"
      >
        <ProfileAddressCard data={data} isInModal={true} />
      </Design>

      <Design
        margin="32px 0 0 0"
        display="flex"
        flexDirection="column"
        gap="16px"
      >
        <Button
          type="primary"
          shape="round"
          color="error"
          fullWidth
          loading={loading}
          onClick={onDelete}
        >
          Yes, Remove this
        </Button>
        <Button size="large" shape="round" fullWidth onClick={onCancel}>
          No, I want to keep this
        </Button>
      </Design>
    </Design>
  );
};

export default ProfileAddressDeleteCard;
