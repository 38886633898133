import React, { FC } from 'react';
import { AuthSocials, Agreement, AuthPhone } from '@modules';

import classes from './auth-login-overview.module.scss';
import { ConfirmationResult } from 'firebase/auth';

type AuthLoginOverviewProps = {
  onGetCode: (phone: string, data: ConfirmationResult) => void;
  onEmailLogin: () => void;
  onLoading: (state: boolean) => void;
};
const AuthLoginOverview: FC<AuthLoginOverviewProps> = ({
  onGetCode,
  onEmailLogin,
  onLoading,
}) => {
  return (
    <>
      <p className={classes.Message}>
        Please enter your phone number, we will send you a verification code.
      </p>
      <div className={classes.Container}>
        <AuthPhone onGetCode={onGetCode} />
        <div className={classes.Divider}>
          <div />
          <p>OR</p>
          <div />
        </div>
        <AuthSocials onLoading={onLoading} />
        <p className={classes.LoginWithEmailButton} onClick={onEmailLogin}>
          Login with Email/Password
        </p>
      </div>
      <div className={classes.AgreementWrapper}>
        <Agreement place="auth" />
      </div>
    </>
  );
};

export default AuthLoginOverview;
