import { createSelector } from 'reselect';
import { ProductsStateModel, StoreModel } from '@models';

const selectProducts = (state: StoreModel) => state.products;

export const similarItems = createSelector(
  [selectProducts],
  (products: ProductsStateModel) => products?.similarItems
);

export const recommendedForYou = createSelector(
  [selectProducts],
  (products: ProductsStateModel) => products?.recommendedForYou
);

export const frequentlyBought = createSelector(
  [selectProducts],
  (products: ProductsStateModel) => products?.frequentlyBought
);
