import { notification } from '@components';
import { firebaseAuthErrors } from '@models';
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInAnonymously,
  signOut,
  UserCredential,
} from 'firebase/auth';
import { getCookie, setCookie } from '@helpers';
import { getRegionPhonePrefix } from './i18n.helper';

const rewardShowedCountKey = '1sellRewardShowTimes';
const customerLandingDateKey = '1sellCustomerLandingDate';
const validLandingRecordPeriod = 60;
const validLandingShowCount = 3;

enum ActiveTab {
  REGISTER = 'register',
  LOGIN = 'login',
}

enum OAuthKey {
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
}

interface InternalSignInModel {
  email: string;
  password: string;
}

interface InternalSignUpModel {
  email: string;
  password: string;
  full_name: string;
}

const getOAuthProviderByKey = (key: OAuthKey) => {
  switch (key) {
    case OAuthKey.GOOGLE:
      return new GoogleAuthProvider();
    case OAuthKey.FACEBOOK:
      return new FacebookAuthProvider();
    case OAuthKey.APPLE:
      return new OAuthProvider('apple.com');
    default:
      return new GoogleAuthProvider();
  }
};

const handleSignInAnonymously = async () => {
  const auth = getAuth();

  try {
    if (!getAuth().currentUser) {
      setCookie('oneSellAnonymousUser', 'true');

      return await signInAnonymously(auth);
    }
  } catch (error) {
    firebaseErrorHandler(error);
  }
};

const handleSignOut = async () => {
  const auth = getAuth();

  try {
    setCookie('oneSellAnonymousUser', 'true');

    return await signOut(auth);
  } catch (error) {
    firebaseErrorHandler(error);
  }
};

const handleParseResultData = (data: any) => {
  const json = JSON.stringify(data);
  const resultData = JSON.parse(json);
  return resultData;
};

/**
 * This function receive the errors that raised by firebase,
 * then shows the error notification
 */
const firebaseErrorHandler = (err: any) => {
  if (err.code && firebaseAuthErrors[err.code]) {
    const findErrorMessage = firebaseAuthErrors[err.code];
    notification({
      type: 'error',
      message: 'Error',
      description: findErrorMessage,
    });
    return;
  }

  return notification({
    type: 'error',
    message: 'Error',
    description:
      err?.message === 'auth_filed'
        ? 'A network error has occurred. Please check your connectivity and try again'
        : err?.message,
  });
};

const customerLastLandDate = (): Date => {
  const lastLandingDate = getCookie(customerLandingDateKey);
  const nowTime = new Date();
  let lastLanding: Date;

  if (!lastLandingDate) {
    setCookie(customerLandingDateKey, nowTime);
    lastLanding = nowTime;
  } else {
    lastLanding = new Date(getCookie(customerLandingDateKey));
  }

  return lastLanding;
};

const secondToShowReward = () => {
  const nowTime = new Date();
  const lastLanding = customerLastLandDate();

  const differenceInTimes = nowTime.getTime() - lastLanding.getTime();
  const differenceInSeconds = Math.round(differenceInTimes / 1000);

  if (differenceInSeconds > validLandingRecordPeriod) {
    setCookie(customerLandingDateKey, nowTime);
    return validLandingRecordPeriod;
  }
  return validLandingRecordPeriod - differenceInSeconds;
};

const showRewardModal = (isAnonymous: boolean): boolean => {
  const showedCount = getCookie(rewardShowedCountKey)
    ? +getCookie(rewardShowedCountKey)
    : 0;

  if (showedCount < validLandingShowCount && isAnonymous) {
    setCookie(rewardShowedCountKey, showedCount + 1);
    return true;
  }

  return false;
};

// Factory function that check if user is new or not
const isNewUser = (user: UserCredential | undefined) => {
  if (!user) return false;
  if (user.user.metadata.creationTime === user.user.metadata.lastSignInTime)
    return true;
  return false;
};

const purePhoneNumber = (phone: string): string => {
  const [_, phoneNumber] = phone.split(getRegionPhonePrefix());

  return phoneNumber ?? '';
};

export {
  ActiveTab,
  OAuthKey,
  firebaseErrorHandler,
  getOAuthProviderByKey,
  handleParseResultData,
  handleSignInAnonymously,
  handleSignOut,
  isNewUser,
  secondToShowReward,
  showRewardModal,
  purePhoneNumber,
};

export type { InternalSignInModel, InternalSignUpModel };
