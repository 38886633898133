import React from 'react';

import { useMediaQuery } from '@hooks';
import { Design, mediaQuery } from '@components';

import styles from '../profile-simmer.module.scss';

const OrderShimmer = () => {
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const items = [1, 2, 3, 4];

  return (
    <Design>
      <Design
        display="flex"
        gap="53px"
        padding={{ all: '20px 24px' }}
        margin={{ all: '0 0 24px 0' }}
        borderBottom="1px solid"
        borderColor="natural"
      >
        <Design
          width="74px"
          height="16px"
          className={styles.ShimmerItem}
        ></Design>
        <Design
          width="74px"
          height="16px"
          className={styles.ShimmerItem}
        ></Design>
      </Design>

      <Design
        display="flex"
        gap="16px"
        padding={{ all: '0 8px' }}
        flexDirection="column"
      >
        {items.map((item) => (
          <Design
            key={item}
            border="1px solid"
            borderColor="natural"
            borderRadius="8px"
            width="100%"
          >
            <Design
              width="100%"
              display="flex"
              gap="8px"
              flexDirection={{ all: 'row', mobile: 'column' }}
              justifyContent="space-between"
              backgroundColor="background_alt"
              padding={{ all: '16px' }}
            >
              <Design
                display="flex"
                gap="8px"
                flexDirection={{ all: 'column', mobile: 'row' }}
                justifyContent={{ all: 'flex-start', mobile: 'space-between' }}
              >
                <Design
                  width="53px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
                <Design
                  width="74px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
              </Design>

              <Design
                display="flex"
                gap="8px"
                flexDirection={{ all: 'column', mobile: 'row' }}
                justifyContent={{ all: 'flex-start', mobile: 'space-between' }}
              >
                <Design
                  width="53px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
                <Design
                  width="74px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
              </Design>

              <Design
                display="flex"
                gap="8px"
                flexDirection={{ all: 'column', mobile: 'row' }}
                justifyContent={{ all: 'flex-start', mobile: 'space-between' }}
              >
                <Design
                  width="53px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
                <Design
                  width="74px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
              </Design>

              {!isMobile && (
                <>
                  <Design display="flex" gap="8px" flexDirection="column">
                    <Design
                      width="53px"
                      height="12px"
                      className={styles.ShimmerItem}
                    ></Design>
                    <Design
                      width="74px"
                      height="12px"
                      className={styles.ShimmerItem}
                    ></Design>
                  </Design>

                  <Design
                    width="119px"
                    height="32px"
                    borderRadius="100px"
                    className={styles.ShimmerItem}
                  ></Design>
                </>
              )}
            </Design>

            <Design padding="16px" display="flex" gap="16px">
              <Design
                width="80px"
                height="87px"
                className={styles.ShimmerItem}
              ></Design>
              <Design
                width="80px"
                height="87px"
                className={styles.ShimmerItem}
              ></Design>
            </Design>

            {isMobile && (
              <Design padding="0 16px 16px">
                <Design
                  width="119px"
                  height="32px"
                  borderRadius="100px"
                  className={styles.ShimmerItem}
                ></Design>
              </Design>
            )}
          </Design>
        ))}
      </Design>
    </Design>
  );
};

export default OrderShimmer;
