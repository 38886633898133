import Cookies from 'universal-cookie';

export const cookies = new Cookies();
const cookiesDefaultOption = { path: '/' };

export const getClientCookiesOptions = () => {
  return { path: '/' };
};

export const getCookiesOptions = () => {
  if (typeof window !== undefined) return getClientCookiesOptions();
  return cookiesDefaultOption;
};

export const getCookie = (key: string) => cookies.get(key);

/**
 *
 * @param {string} key | key name
 * @param {any} value | value of key
 * @param {Date} expirationTime | expiration Time of cookie
 */
export const setCookie = (
  key: string,
  value: any,
  expirationTime?: Date
): void => {
  const options: any = { path: '/' };
  if (expirationTime) options.expires = expirationTime;
  cookies.set(key, value, options);
};

export const removeCookie = (key: string): void => {
  cookies.remove(key, { path: '/' });
};

export const clearCookies = (
  cookiesKeys: string[] = ['token', 'refresh_token']
): void => {
  cookiesKeys.forEach((key) => {
    removeCookie(key);
  });
};
