import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  addressesSelectors,
  appSelectors,
  checkoutSelectors,
} from '@selectors';
import { Modal, Design, Button } from '@components';
import { usePromise, useStorage } from '@hooks';
import { appActions } from '@actions';
import { RegionAreaList, RegionCitiesList } from '@modules';
import {
  AddressAreaModel,
  AddressRegionCityModel,
  AddressRegionSelected,
} from '@models';
import {
  getAreaDetailsBaseAreaId,
  getCurrentRegionArea,
  getCurrentRegionCity,
  getRegion,
  getRegionToken,
} from '@helpers';

type Props = {
  customerSelectedRegion?: AddressRegionSelected;
  onUpdateRegion: (info: AddressRegionSelected) => void;
};
const AreaSelectorComponent: FC<Props> = ({
  customerSelectedRegion,
  onUpdateRegion,
}) => {
  const region = getRegion();

  const promise = usePromise();
  const visible = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);
  const checkoutParams = useSelector(checkoutSelectors.selectParams);
  const { data: areasList, fetching: fetchingAreas } = useSelector(
    addressesSelectors.areas
  );
  const allCities = useSelector(addressesSelectors.allCities);
  const initialArea = useSelector(addressesSelectors.addressArea);

  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visible
  );

  const [selectedArea, setSelectedArea] =
    useState<AddressAreaModel | null>(null);

  const [selectedCity, setSelectedCity] =
    useState<AddressRegionCityModel | null>(
      getCurrentRegionCity(currentRegion)
    );

  const allAreas: AddressAreaModel[] = useMemo(() => {
    if (!areasList?.areas) return [];
    return areasList.areas;
  }, [areasList, fetchingAreas]);

  const handleSaveData = () => {
    if (!selectedArea) return;

    const info: AddressRegionSelected = {
      country: region,
      city: selectedCity,
      area: selectedArea,
    };
    onUpdateRegion(info);
    promise(appActions.changeAreaModalStatus(false));
    return;
  };
  const handleDefaultData = () => {
    if (customerSelectedRegion?.area.id) {
      setSelectedArea(customerSelectedRegion.area);
      setSelectedCity(customerSelectedRegion.city);
      return;
    }
    if (checkoutParams.area_id) {
      const areaDetailsBaseId = getAreaDetailsBaseAreaId(
        checkoutParams.area_id,
        allAreas
      );
      if (areaDetailsBaseId) setSelectedArea(areaDetailsBaseId);
    }

    setSelectedArea(
      initialArea ? initialArea : getCurrentRegionArea(currentRegion)
    );
    setSelectedCity(getCurrentRegionCity(currentRegion));

    return;
  };

  useEffect(() => {
    // Selected area base on checkout params
    handleDefaultData();
  }, [currentRegion, initialArea, checkoutParams.area_id]);

  const renderAreaList = () => (
    <>
      <RegionAreaList
        allAreas={allAreas}
        selectedArea={selectedArea}
        onSelectArea={setSelectedArea}
      />

      <Design
        position="sticky"
        bottom="0"
        backgroundColor="background"
        borderTop="1px solid"
        borderColor="natural"
        padding={{ all: '12px 0', mobile: '12px 14px' }}
      >
        <Design maxWidth={{ all: '402px' }} margin="0 auto">
          <Button
            fullWidth
            onClick={handleSaveData}
            size="large"
            shape="round"
            type="primary"
          >
            SAVE CHANGES
          </Button>
        </Design>
      </Design>
    </>
  );

  const renderList = () => {
    if (!showCityList) return renderAreaList();
    if (showCityList && selectedCity) return renderAreaList();
    return <RegionCitiesList onSelectCity={setSelectedCity} />;
  };
  const showCityList: boolean = useMemo(() => {
    if (allCities.length < 0) return false;
    return allCities.length > 0;
  }, [allCities]);

  const modalHeader = useMemo(() => {
    if (!showCityList) return 'Select area of delivery';
    if (showCityList && selectedCity) return selectedCity.label;
    return 'Select city & area of delivery';
  }, [allCities, selectedCity]);

  return (
    <Modal
      visible={visible}
      fullTablet
      maskClosable
      // headerActions={selectedCity ? 'back' : 'close'}
      headerActions="close"
      title={modalHeader}
      onBack={() => setSelectedCity(null)}
      onCancel={() => promise(appActions.changeAreaModalStatus(false))}
      width={574}
    >
      {renderList()}
    </Modal>
  );
};

export default AreaSelectorComponent;
