import React, { FC } from 'react';

import { generateCategoryRouteForBreadcrumb, getRegion } from '@helpers';
import { CategoryModel, Region } from '@models';
import { BreadcrumbJsonLd, NextSeo } from 'next-seo';

interface Props {
  title?: string;
  category: CategoryModel;
}

const CategorySeo: FC<Props> = ({ title = '', category }) => {
  if (title.length > 0) return <NextSeo title={`${title} Search Results`} />;

  const breadcrumb = generateCategoryRouteForBreadcrumb(
    category as CategoryModel
  );

  const renderBreadcrumb = () => {
    return breadcrumb.map((item, index) => {
      return {
        position: 1,
        name: item.title,
        item: `${process.env.NEXT_PUBLIC_SITE_URL}${item.path}`,
      };
    });
  };

  return (
    <>
      <NextSeo
        title={`Buy ${category?.title} Product`}
        description={category?.meta_description}
        canonical={`${process.env.NEXT_PUBLIC_SITE_URL}/category/${category?.slug}/products`}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: category?.meta_keywords,
          },
        ]}
        openGraph={{
          title: `Buy ${category?.title} Products Online in Qatar - 1Sell`,
          description: category?.meta_description,
          images: [
            {
              url:
                getRegion() === Region.AR_AE
                  ? process.env.NEXT_PUBLIC_SITE_URL +
                    '/images/og-image-global-ae.jpeg'
                  : process.env.NEXT_PUBLIC_SITE_URL +
                    '/images/og-image-global-qa.jpeg',
              width: 1024,
              height: 500,
              alt: '1Sell',
              type: 'image/jpeg',
            },
          ],
          site_name: process.env.NEXT_PUBLIC_SITE_URL,
          locale: 'en-US',
          type: 'category',
          url: `${process.env.NEXT_PUBLIC_SITE_URL}/category/${category?.slug}/products`,
        }}
      />

      <BreadcrumbJsonLd
        type="BreadcrumbList"
        itemListElements={renderBreadcrumb()}
      />
    </>
  );
};

export default CategorySeo;
