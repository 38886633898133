import React, { FC } from 'react';

import { Media } from '@components';
import { LeaveConfirm } from '@modules';
import { Modal } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';

type Props = {
  show: boolean;
  onLeave: () => void;
  onHide: () => void;
};
const CheckoutLeaveConfirm: FC<Props> = ({ show, onHide, onLeave }) => {
  return (
    <>
      <Media greaterThan="mobile">
        <Modal
          destroyOnClose
          centered
          visible={show}
          onCancel={onHide}
          footer={false}
        >
          <LeaveConfirm onClose={onHide} onLeave={onLeave} title="checkout" />
        </Modal>
      </Media>
      <Media lessThan="tablet">
        <BottomSheet open={show} onDismiss={onHide}>
          {<LeaveConfirm onClose={onHide} onLeave={onLeave} title="checkout" />}
        </BottomSheet>
      </Media>
    </>
  );
};

export default CheckoutLeaveConfirm;
