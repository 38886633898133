import { wishlistActionTypes } from '@actionTypes';

export function loadAll(params?: any) {
  return {
    type: wishlistActionTypes.LOAD_ALL_WISHLIST,
    method: 'get',
    url: `/wishlist`,
    params,
  };
}

export function addToWishlist(data: any) {
  return {
    type: wishlistActionTypes.ADD_TO_WISHLIST,
    method: 'post',
    url: `/wishlist/items`,
    data,
  };
}

export function compactWishlist() {
  return {
    type: wishlistActionTypes.COMPACT_WISHLIST,
    method: 'get',
    url: `/wishlist/compact`,
  };
}

export function detailWishlist(id: string) {
  return {
    type: wishlistActionTypes.DETAIL_WISHLIST,
    method: 'delete',
    url: `/wishlist/items/${id}`,
  };
}

export function replaceWishlist(data: any) {
  return {
    type: wishlistActionTypes.REPLACE_WISHLIST,
    method: 'post',
    url: `/wishlist/replace`,
    data,
  };
}

export function deleteWishlist(id: String) {
  return {
    type: wishlistActionTypes.DELETE_WISHLIST,
    method: 'delete',
    url: `/wishlist/items/${id}`,
  };
}
