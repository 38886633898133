import React, { useEffect, useState, FC } from 'react';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { useKeenSlider } from 'keen-slider/react';
import { useRouter } from 'next/router';
import { ChevronLeft, ChevronRight } from 'react-feather';

import { CheckoutLeaveConfirm, Design, mediaQuery, Wording } from '@components';
import { CheckoutCartItem } from '@modules';
import { cartSelectors } from '@selectors';

import styles from './checkout-cart-summary.module.scss';
import { useMediaQuery } from '@hooks';

const CheckoutCartSummary: FC = () => {
  const router = useRouter();
  const isDesktop = useMediaQuery(mediaQuery.desktop);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const { data: cartDetails, fetching: fetchingDetails } = useSelector(
    cartSelectors.details
  );

  const [keenLoaded, setKeenLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider({
    created() {
      setKeenLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    renderMode: 'performance',
    mode: 'free-snap',
    initial: 0,
    slides: { perView: 'auto' },
  });

  const [showLeaveModal, setShowLeaveModal] = useState(false);

  useEffect(() => {
    if (fetchingDetails === false || cartDetails?.items)
      instanceRef.current?.update();
  }, [fetchingDetails, cartDetails]);

  const renderSliderArrows = (): JSX.Element => {
    if (isDesktop && cartDetails && cartDetails.items.length < 6) return <></>;
    if (isTablet && cartDetails && cartDetails.items.length < 5) return <></>;
    if (isMobile && cartDetails && cartDetails.items.length < 4) return <></>;

    const prevButtonIsDisabled =
      (instanceRef.current && instanceRef.current.track.details.maxIdx === 0) ||
      currentSlide === 0;

    const nextButtonIsDisabled =
      (instanceRef.current && instanceRef.current.track.details.maxIdx === 0) ||
      (instanceRef.current &&
        currentSlide === instanceRef.current.track.details.maxIdx);

    return (
      <>
        <div
          className={`${styles.PrevButton} ${
            prevButtonIsDisabled ? styles.Disabled : ''
          }`}
        >
          <ChevronLeft
            color={'#fff'}
            onClick={(e) => {
              instanceRef.current?.prev();
            }}
          />
        </div>

        <div
          className={`${styles.NextButton} ${
            nextButtonIsDisabled ? styles.Disabled : ''
          }`}
        >
          <ChevronRight
            color={'#fff'}
            onClick={(e) => {
              instanceRef.current?.next();
            }}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    if (fetchingDetails === false || cartDetails?.items)
      instanceRef.current?.update();
  }, [fetchingDetails, cartDetails]);

  if (fetchingDetails || !cartDetails?.items) {
    return (
      <Design
        padding={{
          all: '24px',
          tablet: '20px',
          mobile: '16px',
        }}
        borderRadius={{
          all: '8px',
          mobile: '0',
        }}
        backgroundColor="background"
        marginBottom={{ all: '16px', mobile: '12px' }}
      >
        <Design
          margin="0 auto"
          width="100%"
          maxWidth={{
            all: '100%',
            tablet: '460px',
          }}
        >
          <Skeleton.Input
            className={styles.SkeletonDesign}
            active
            size="small"
          />
          <br />
          <br />
          <Skeleton.Input
            className={styles.SkeletonDesign}
            active
            block
            size="small"
          />
          <br />
          <br />
          <Skeleton.Input
            className={styles.SkeletonDesign}
            active
            block
            size="small"
          />
        </Design>
      </Design>
    );
  }

  return (
    <>
      <Design
        padding={{
          all: '24px',
          tablet: '20px',
          mobile: '16px',
        }}
        borderRadius={{
          all: '8px',
          mobile: '0',
        }}
        backgroundColor="background"
        marginBottom={{ all: '16px', mobile: '12px' }}
      >
        <Design
          width="100%"
          margin="0 auto"
          maxWidth={{
            all: '100%',
            tablet: '460px',
          }}
        >
          <Design
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            marginBottom="24px"
          >
            <Wording
              fontWeight="500"
              fontSize={{
                all: '14px',
                desktop: '20px',
              }}
              lineHeight={{
                all: '20px',
                desktop: '28px',
              }}
              color="surface"
            >
              Cart Summary
            </Wording>
            <Design
              className="has-pointer"
              onClick={() => setShowLeaveModal(true)}
            >
              <Wording
                color="link"
                fontWeight="500"
                lineHeight={{
                  all: '18px',
                  desktop: '22px',
                }}
                fontSize={{
                  desktop: '16px',
                  all: '12px',
                }}
              >
                Edit Items
              </Wording>
            </Design>
          </Design>
          <Design className={styles.CartItemsContainer}>
            <div ref={sliderRef} className="keen-slider">
              {cartDetails?.items.map((item, index) => (
                <div className="keen-slider__slide" key={item.id}>
                  <CheckoutCartItem item={item} />
                </div>
              ))}
            </div>
            {renderSliderArrows()}
          </Design>
        </Design>
      </Design>

      <CheckoutLeaveConfirm
        show={showLeaveModal}
        onHide={() => setShowLeaveModal(false)}
        onLeave={() => {
          router.push('/cart');
          setShowLeaveModal(false);
        }}
      />
    </>
  );
};

export default CheckoutCartSummary;
