import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Checkbox, Input } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { Design, Wording } from '@components';
import { CategoryFacetModel, CategoryFacetOptionsModel } from '@models';
import { FiltersCheckboxItem } from '@modules';
import { useIsomorphicLayoutEffect } from '@hooks';

import styles from './filters-checkbox-list.module.scss';

type Props = {
  facet: CategoryFacetModel;
  checked: string[];
  onChange: (name: string, value: string) => void;
};
const FiltersCheckboxListModule: FC<Props> = ({ facet, checked, onChange }) => {
  const [query, setQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>(checked ?? ['']);

  const allOptions: CategoryFacetOptionsModel[] = useMemo(
    () =>
      Object.values(facet.options).map((item) => ({
        ...item,
        label: item?.label ?? item?.slug,
      })),
    [facet.option_slugs]
  );

  // Factory function that update selected items
  const onFilterChange = (value: string) => {
    // setSelectedItems((prevItems) => handleUpdateSelectedItem(prevItems, value));
    onChange(facet.slug, value);
  };

  const OptionsQTY = useMemo(() => allOptions.length, [facet]);

  const filteredOptions = useMemo(() => {
    if (!query) return allOptions;
    return allOptions.filter((i) =>
      i.label ? i.label.toLowerCase().search(query) > -1 : i
    );
  }, [query, allOptions]);

  // Factory function that only accept alphabets and numbers
  const handleSearchItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const regX = /^[a-zA-Z0-9]*$/g;
    if (regX.test(inputValue) || inputValue === '') setQuery(inputValue);
  };

  // Empty search when facets update
  useEffect(() => {
    setQuery('');
  }, [facet]);

  // Update selected filters after new data came
  useIsomorphicLayoutEffect(() => {
    setSelectedItems(checked);
  }, [checked]);

  const isDisabled = (option: CategoryFacetOptionsModel) =>
    option.products_count < 1 && !checked?.includes(option.slug);

  const renderEmptySearch = () => (
    <Design padding="10px 12px" textAlign="center">
      <Wording>No results found for “{query}”</Wording>
    </Design>
  );

  return (
    <Design overflow="hidden" maxHeight={OptionsQTY > 5 ? '330px' : ''}>
      {OptionsQTY > 5 && (
        <Design
          marginBottom="6px"
          padding={{
            all: '10px 16px',
            desktop: '10px 12px',
          }}
        >
          <Input
            className={styles.FilterSearch}
            onChange={handleSearchItems}
            value={query}
            placeholder={`Find ${facet.label}...`}
            allowClear
          />
        </Design>
      )}

      <Design
        maxHeight={OptionsQTY > 5 ? '230px' : 'auto'}
        overflowY="auto"
        width="100%"
        paddingBottom={OptionsQTY > 5 ? '20px' : 'auto'}
      >
        <Checkbox.Group
          value={selectedItems}
          onChange={(checkedValue: Array<CheckboxValueType>) =>
            setSelectedItems(checkedValue as string[])
          }
        >
          <Design display="flex" flexDirection="column" width="100%">
            {filteredOptions.length > 0
              ? filteredOptions.map((option) => (
                  <FiltersCheckboxItem
                    option={option}
                    key={option.slug}
                    onChange={onFilterChange}
                    disabled={isDisabled(option)}
                  />
                ))
              : renderEmptySearch()}
          </Design>
        </Checkbox.Group>
      </Design>
    </Design>
  );
};

export default FiltersCheckboxListModule;
