import React, { FC, SetStateAction, useState } from 'react';
import { Form } from 'antd';
import Image from 'next/image';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import { Button, TextInput } from '@components';
import { firebaseErrorHandler } from '@helpers';

import classes from './auth-email-forget-password.module.scss';

const AuthEmailForgetPassword: FC = () => {
  const [actionLoading, setActionLoading] = useState(false);
  const [sentState, setSentState] = useState(false);

  const handleForgotPassword = async (values: any) => {
    setActionLoading(true);
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, values?.email);

      setSentState(true);
    } catch (error: any) {
      firebaseErrorHandler(error);
    } finally {
      setActionLoading(false);
    }
  };

  if (sentState) {
    return (
      <div className={classes.SentContainer}>
        <div className={classes.SentImageWrapper}>
          <Image
            src="/icons/forget-password-success.svg"
            width="120px"
            height="120px"
            alt="forget-password"
          />
        </div>
        <p className={classes.SentMessage}>
          Reset password email has been sent to your email, please click on the
          link in the email to reset your password
        </p>
      </div>
    );
  }

  return (
    <>
      <p className={classes.Message}>
        Enter your email address and {`we'll`} send you a link to reset your
        password.
      </p>
      <div className={classes.Container}>
        <Form
          name="forgotPasswordForm"
          autoComplete="off"
          onFinish={handleForgotPassword}
        >
          <TextInput
            name="email"
            label="Email Address*"
            type="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          />

          <Form.Item>
            <Button
              size="large"
              shape="round"
              type="primary"
              fullWidth
              htmlType="submit"
              id="submitBtn"
              className={classes.ActionButton}
              loading={actionLoading}
            >
              RESET PASSWORD
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default AuthEmailForgetPassword;
