import React, { FC, useEffect, useState } from 'react';

import { Image, mediaQuery } from '@components';
import { isExternalUrl } from '@helpers';
import { useMediaQuery } from '@hooks';
import { ImageSliderProps, SliderStructure } from '@models';
import { useKeenSlider } from 'keen-slider/react';
import Link from 'next/link';
import { ChevronLeft, ChevronRight } from 'react-feather';

import styles from './image-slider.module.scss';

const ImageSliderModule: FC<ImageSliderProps> = ({
  data,
}: ImageSliderProps) => {
  const isDesktop = useMediaQuery(mediaQuery.desktop);
  const [keenLoaded, setKeenLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderOptions: any = {
    loop: true,
    mode: 'snap',
    slides: {
      perView: 1,
    },
    created() {
      setKeenLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider?.track?.details?.rel);
    },
  };
  const sliderEvents: any = [
    (slider) => {
      let timeout;
      let mouseOver = false;
      function clearNextTimeout() {
        clearTimeout(timeout);
      }
      function nextTimeout() {
        clearTimeout(timeout);
        if (mouseOver) return;
        timeout = setTimeout(() => {
          slider.next();
        }, 7000);
      }
      slider.on('created', () => {
        slider.container.addEventListener('mouseover', () => {
          mouseOver = true;
          clearNextTimeout();
        });
        slider.container.addEventListener('mouseout', () => {
          mouseOver = false;
          nextTimeout();
        });
        nextTimeout();
      });
      slider.on('dragStarted', clearNextTimeout);
      slider.on('animationEnded', nextTimeout);
      slider.on('updated', nextTimeout);
    },
  ];

  const [sliderRef, instanceRef] = useKeenSlider(sliderOptions, sliderEvents);

  useEffect(() => {
    instanceRef.current?.update({
      ...sliderOptions,
      ...sliderEvents,
    });
  }, [instanceRef]);

  const renderSliderArrows = () => {
    return (
      <>
        <div className={styles.PrevButton}>
          <ChevronLeft
            color={'#fff'}
            onClick={(e) => {
              instanceRef.current?.prev();
            }}
          />
        </div>

        <div className={styles.NextButton}>
          <ChevronRight
            color={'#fff'}
            onClick={(e) => {
              instanceRef.current?.next();
            }}
          />
        </div>
      </>
    );
  };

  const renderDots = () => (
    <div className={styles.BulletNavigation}>
      {[
        ...Array(
          instanceRef.current &&
            instanceRef?.current?.track?.details?.slides?.length
        ).keys(),
      ].map((idx) => {
        return (
          <button
            title="dots"
            key={idx}
            onClick={() => {
              instanceRef.current?.moveToIdx(idx);
            }}
            className={currentSlide === idx ? styles.ActiveBullet : ''}
          ></button>
        );
      })}
    </div>
  );

  const renderImages = () => {
    return data?.map((item: SliderStructure, index: number) => {
      const imgUrl = isDesktop
        ? item.desktop_image.urls.original
        : item.mobile_image.urls.original;

      const isExternalLink = isExternalUrl(item.url);

      return (
        <div className="keen-slider__slide" key={`${index}_${item.title}`}>
          {isExternalLink && (
            <a href={item.url} title={item.title}>
              <Image
                src={imgUrl}
                alt={item.title}
                layout={isDesktop ? 'fixed' : 'fill'}
                priority
                width={isDesktop ? 642 : '100%'}
                height={isDesktop ? 366 : '100%'}
                showShimmerAnimation={false}
                // blurDataURL={item.desktop_image.urls.blurImageUrl}
              />
            </a>
          )}

          {!isExternalLink && (
            <Link href={item.url} prefetch={false}>
              <a>
                <Image
                  src={imgUrl}
                  alt={item.title}
                  layout={isDesktop ? 'fixed' : 'fill'}
                  priority
                  width={isDesktop ? 642 : '100%'}
                  height={isDesktop ? 366 : '100%'}
                  showShimmerAnimation={false}
                  // blurDataURL={item.desktop_image.urls.blurImageUrl}
                />
              </a>
            </Link>
          )}
        </div>
      );
    });
  };

  return (
    <div className={styles.Container}>
      <div ref={sliderRef} className="keen-slider">
        {renderImages()}
      </div>
      {keenLoaded && instanceRef.current && renderSliderArrows()}
      {keenLoaded && instanceRef.current && renderDots()}
    </div>
  );
};

export default ImageSliderModule;
