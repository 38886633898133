import React from 'react';
import numeral from 'numeral';

import { Wording, Design } from '@components';
import { formatPriceWithCurrency } from '@utils/number.utils';

type Props = {
  price: number;
  listPrice: number;
};

const MainOfferPrices = ({ price, listPrice }: Props) => {
  return (
    <Design
      display="flex"
      gap={{ all: '16px', mobile: '0' }}
      alignItems="baseline"
      flexDirection={{ all: 'row', mobile: 'column' }}
      margin={{ all: '40px 0 0 0', mobile: '8px 0 0 0' }}
    >
      <Design display="flex" gap="4px" alignItems="baseline">
        <Wording
          fontSize={{ all: '32px', tablet: '24px', mobile: '24px' }}
          lineHeight={{
            all: '37.54px',
            tablet: '28.15px',
            mobile: '28.15px',
          }}
          fontWeight="600"
        >
          {formatPriceWithCurrency(listPrice > price ? price : listPrice)}
        </Wording>
      </Design>

      <Wording lineHeight="19.6px" color="error" decoration="line-through">
        {formatPriceWithCurrency(listPrice)}
      </Wording>
    </Design>
  );
};

export default MainOfferPrices;
