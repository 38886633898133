import { categoriesActionTypes } from '@actionTypes';
import { dealCategories } from '@helpers';
import { CategoriesStateModel } from '@models';

import { reducerItemInitialState } from './reducer.constant';

const initialState: CategoriesStateModel = {
  all: reducerItemInitialState as any,
  item: reducerItemInitialState as any,
  dealCategories: reducerItemInitialState as any,
};

function categories(state = initialState, action) {
  switch (action.type) {
    /**
     * LOAD_ALL_CATEGORIES
     */
    case categoriesActionTypes.LOAD_ALL_CATEGORIES:
      return {
        ...state,
        all: {
          fetching: true,
        },
      };
    case categoriesActionTypes.LOAD_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        all: {
          ...action.data,
          fetching: false,
        },
      };
    case categoriesActionTypes.LOAD_ALL_CATEGORIES_FAILURE:
      return {
        ...state,
        all: {
          fetching: false,
        },
      };

    /**
     * LOAD_CATEGORIES
     */
    case categoriesActionTypes.LOAD_CATEGORIES:
      return {
        ...state,
        item: {
          ...state.item,
          fetching: true,
        },
      };
    case categoriesActionTypes.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        item: {
          data: action.data,
          fetching: false,
        },
      };
    case categoriesActionTypes.LOAD_CATEGORIES_FAILURE:
      return {
        ...state,
        item: {
          fetching: false,
        },
      };

    /**
     * LOAD_DEAL_CATEGORIES
     */
    case categoriesActionTypes.LOAD_DEAL_CATEGORIES:
      return {
        ...state,
        dealCategories: {
          fetching: true,
        },
      };
    case categoriesActionTypes.LOAD_DEAL_CATEGORIES_SUCCESS:
      return {
        ...state,
        dealCategories: {
          data: dealCategories(action.data),
          fetching: false,
        },
      };
    case categoriesActionTypes.LOAD_DEAL_CATEGORIES_FAILURE:
      return {
        ...state,
        dealCategories: {
          fetching: false,
        },
      };

    /**
     * CLEAR_CATEGORIES
     */
    case categoriesActionTypes.CLEAR_CATEGORIES:
      return {
        ...state,
        item: {
          data: [],
          fetching: false,
        },
      };
    default:
      return state;
  }
}

export default categories;
