import { FetchingDataType } from './redux.model';
import { ProductTypeEnum, StockStatusEnum } from '@models';

export enum CampaignType {
  EARLY = 'early',
  LATE = 'late',
  FINISHED = 'finished',
}

export enum ObjectType {
  APPEARANCE = 'appearance',
  HEADER = 'header',
  CATEGORY = 'category',
  FOOTER = 'footer',
}

export enum RewardCampaigns {
  FREE_DELIVERY_1 = 'free_delivery_1',
  BLACK_FRIDAY_2022 = 'black_friday2022',
}

export interface LandingObjectModel {
  type: ObjectType;
  appearance_payload?: AppereancePayloadModel;
  header_payload?: HeaderPayloadModel;
  category_payload: CategoryPayloadModel;
  footer_payload?: FooterPayloadModel;
}

// Appereance Models
export interface AppereanceColorsModel {
  background_page: string;
  text_color: string;
  selected_color: string;
  color_on_background: string;
  link_color: string;
  border_color: string;
}

export interface AppereancePayloadModel {
  colors: AppereanceColorsModel;
  campaign_type: CampaignType;
  og: {
    title: string;
    description: string;
    image: string;
  };
  meta: {
    description: string;
    keywords: string;
  };
}

// Header Models
export interface HeaderPayloadModel {
  show_shopping_badge: boolean;
  logo: string;
  hero_image: string;
  pre_title: string;
  title: string;
  title_image?: string;
  sub_title: string;
  timer_title: string;
  timer_start_time: number;
  blackfriday_start_time: number;
  timer_end_time: number;
}

// Category Models
export interface CategoryPayloadModel {
  type: string;
  data: LandingCategoryModel[];
}

export interface LandingCategoryModel {
  summary: string;
  discount: string;
  slug: string;
  title: string;
  is_enable: boolean;
  image: {
    urls: EventCategoryImageModel;
  };
  meta?: {
    title: string;
    action: string;
  };
  products: EventProductModel[];
}

export interface EventProductModel {
  images: EventProductImagesModel[];
  id: number;
  title: string;
  sub_title: string;
  sku: string;
  price: number;
  is_new: boolean;
  product_type: ProductTypeEnum;
  list_price: number;
  stock_status: StockStatusEnum;
  inventory_qty_left: string;
  available_in: string;
  slug: string;
  meta?: unknown;
  discount: number;
}

export interface EventProductImagesModel {
  urls: EventProductImageModel;
}

export interface EventProductImageModel {
  80: string;
  128: string;
  360: string;
  512: string;
  1024: string;
  original: string;
  '80@2x': string;
  '128@2x': string;
  '360@2x': string;
  '512@2x': string;
  '1024@2x': string;
}
export interface EventCategoryImageModel {
  128: string;
  256: string;
  512: string;
  original: string;
  '128@2x': string;
  '256@2x': string;
  '512@2x': string;
}

// Footer Models
export interface FooterPayloadModel {
  title: string;
  icon: string;
  sub_title: string;
}
