import { FetchingDataType } from '@models';

export type reducerItemType<T> = FetchingDataType<T>;
export const reducerItemInitialState: FetchingDataType<undefined> = {
  fetching: false,
  data: null,
};

export function ItemInitialState<T>(data: T | null): FetchingDataType<T> {
  return {
    fetching: false,
    data,
  };
}

export const reducerItemDefaultState: FetchingDataType<boolean | []> = {
  fetching: false,
  data: [],
};
