import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import parsePhoneNumber from 'libphonenumber-js';
import { useSelector } from 'react-redux';

import { Button, Design, PhoneInput, TextInput } from '@components';
import {
  getCurrentCountryCode,
  getRegionPhonePrefix,
  getRegionToken,
  isRegionPhoneValid,
} from '@helpers';
import {
  AddressModel,
  AddressRegionSelected,
  CreateAddressModel,
} from '@models';
import { AddressAreaInput } from '@modules';
import { useStorage } from '@hooks';
import { appSelectors } from '@selectors';

type Props = {
  hideButton?: boolean;
  loading: boolean;
  buttonId?: string;
  initial?: Partial<AddressModel>;
  buttonTitle?: string;
  enablePrefixChange: boolean;
  onSubmit: (values: CreateAddressModel) => void;
  onSelectRegion?: (values: AddressRegionSelected) => void;
};

const AddressForm = ({
  hideButton = false,
  loading,
  buttonId,
  initial,
  buttonTitle,
  enablePrefixChange,
  onSubmit,
  onSelectRegion,
}: Props) => {
  const [form] = Form.useForm<CreateAddressModel>();

  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);
  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visibleAreaModal
  );

  const [selectedAreaId, setAreaId] = useState<number | undefined>(
    currentRegion?.area.id
  );
  const [selectedCityId, setCityId] = useState<number | undefined>(
    currentRegion?.city?.id
  );

  const prefix = useMemo(
    () => getCurrentCountryCode(),
    [initial?.phone_number]
  );

  const handleSelectRegion = (info: AddressRegionSelected) => {
    setAreaId(info.area.id);
    setCityId(info.city?.id);
    onSelectRegion?.(info);
  };

  const handleSubmit = (values: CreateAddressModel) => {
    const phoneNumber = `+${getRegionPhonePrefix()}${values.phone_number}`;
    if (isRegionPhoneValid(phoneNumber))
      onSubmit({ ...values, area_id: selectedAreaId, city_id: selectedCityId });
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      full_name: initial?.full_name ?? '',
      address_line_1: initial?.address_line_1 ?? '',
      address_line_2: initial?.address_line_2 ?? '',
      phone_number: initial?.phone_number
        ? parsePhoneNumber(String(initial.phone_number))?.nationalNumber
        : '',
    });
    if (initial?.area?.id) setAreaId(initial?.area?.id);
    if (initial?.city?.id) setAreaId(initial?.city.id);
  }, [initial]);

  return (
    <>
      <Form
        form={form}
        name="addressForm"
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <TextInput
          name="full_name"
          label="Recipient Full Name*"
          type="text"
          rules={[
            {
              required: true,
              message: 'Please input your full name!',
            },
          ]}
        />

        <AddressAreaInput
          onUpdateArea={handleSelectRegion}
          addressRegionArea={initial?.area ?? null}
        />

        <TextInput
          name="address_line_1"
          label="Address Line 1*"
          type="text"
          rules={[
            {
              required: true,
              message: 'Please input your address line 1!',
            },
          ]}
        />

        <TextInput name="address_line_2" label="Address Line 2" type="text" />

        <PhoneInput
          required
          name="phone_number"
          enablePrefixChange={enablePrefixChange}
          displayPrefix={prefix}
          label="Phone Number*"
        />

        <Design margin="32px 0 0 0" display={hideButton ? 'none' : 'block'}>
          <Form.Item>
            <Button
              size="large"
              shape="round"
              type="primary"
              fullWidth
              htmlType="submit"
              loading={loading}
              disabled={loading}
              id={buttonId ?? ''}
            >
              {buttonTitle ?? 'SAVE ADDRESS'}
            </Button>
          </Form.Item>
        </Design>
      </Form>
    </>
  );
};

export default AddressForm;
