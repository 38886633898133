import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from 'react';
import { onIdTokenChanged, getAuth } from 'firebase/auth';
import { useRouter } from 'next/router';

import {
  cartActions,
  firebaseActions,
  profileActions,
  wishlistActions,
} from '@actions';
import {
  setCookie,
  getCookie,
  handleSignInAnonymously,
  handleParseResultData,
} from '@helpers';
import { usePromise } from '@hooks';

const AuthContext = createContext<any>({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const promise = usePromise();
  const route = useRouter();
  const auth = getAuth();

  const [user, setUser] = useState<any>(null);

  const loadData = () => {
    Promise.all([
      promise(profileActions.loadProfile()),
      promise(profileActions.loadProfileWalletBalance()),
      promise(cartActions.loadCartCompact()),
      promise(wishlistActions.compactWishlist()),
    ]);
  };

  const authCallback = useCallback((user) => {
    if (user) {
      setUser(user);
      const lastToken = getCookie('oneSellToken');
      const resultData = handleParseResultData(user);

      const { accessToken, expirationTime } = resultData.stsTokenManager;

      setCookie('oneSellToken', accessToken, new Date(expirationTime));
      promise(firebaseActions.setProfile(user as any));

      const upgradeToken =
        lastToken && accessToken !== lastToken && !resultData.isAnonymous;

      if (upgradeToken) {
        return promise(profileActions.upgradeToken(lastToken)).then(() => {
          loadData();
        });
      }

      loadData();
    } else {
      handleSignInAnonymously();
      setUser(null);
    }
  }, []);

  useEffect(() => {
    const unsubscribeIdToken = onIdTokenChanged(auth, authCallback);
    return () => {
      unsubscribeIdToken();
    };
  }, [route.pathname]);

  // useEffect(() => {
  //   const unsubscribeAuth = onAuthStateChanged(auth, authCallback);
  //   return () => {
  //     unsubscribeAuth();
  //   };
  // }, [route.pathname]);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
