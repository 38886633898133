import React, { useEffect, useMemo, FC } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { addressesActions } from '@actions';
import { Button, CheckoutAddresses, Design, Media } from '@components';
import { generateNestedRouteBySlug } from '@helpers';
import { usePromise } from '@hooks';
import {
  CategoryModel,
  SeoEventListLocation,
  SeoEventProductModel,
} from '@models';
import { CheckoutDeliveryTime, CheckoutPriceDetails } from '@modules';
import {
  addressesSelectors,
  cartSelectors,
  categoriesSelectors,
} from '@selectors';
import { triggerAddShippingInfoEvent } from '@utils/seo/events';

const CheckoutShipping: FC = () => {
  const promise = usePromise();
  const router = useRouter();

  const { data: addressList } = useSelector(addressesSelectors.selectAll);
  const { data: cartDetails } = useSelector(cartSelectors.details);
  const { data: categoriesData } = useSelector(categoriesSelectors.selectAll);

  const hasAddress = useMemo(() => {
    if (addressList?.data) return addressList?.data.length > 0;
    return false;
  }, [addressList]);

  const handleNextStep = () => {
    const button = document.getElementById('checkout-add-address');
    if (button) return button.click();

    const compatibleSeoProducts: SeoEventProductModel[] =
      cartDetails?.items?.map((p, i) => {
        const nestedCategories = generateNestedRouteBySlug(
          categoriesData as CategoryModel[],
          p.product.categories[0].slug
        );
        return {
          brand: p.product.brand_name,
          category: nestedCategories,
          id: p?.product?.id.toString(),
          list_location: SeoEventListLocation.CHECKOUT_LIST,
          discount: p.product.list_price - p.product.price,
          position: i,
          name: p.product.title,
          price: p.price,
          qty: p.qty,
          variant: p.product?.variation?.attributes?.[0]?.option.label ?? null,
        };
      }) ?? [];

    triggerAddShippingInfoEvent(cartDetails?.total ?? 0, compatibleSeoProducts);

    router.push('/checkout/payment', undefined, { shallow: true });
  };

  useEffect(() => {
    promise(addressesActions.loadAreas());
  }, []);

  const renderLessDesktop = () => (
    <Design marginTop={{ all: '32px', mobile: '12px' }} paddingBottom="100px">
      <CheckoutDeliveryTime />
      <Design
        padding={{ all: '16px 0 4px', mobile: '0' }}
        marginBottom={{ all: '12px', desktop: '50px' }}
        width="100%"
        borderRadius={{ all: '8px', mobile: '0' }}
        backgroundColor="background"
      >
        <CheckoutAddresses />
      </Design>
      <Design
        padding={{ all: '24px 0', mobile: '16px 20px' }}
        marginBottom={{ all: '12px', desktop: '50px' }}
        width="100%"
        borderRadius={{ all: '8px', mobile: '0' }}
        backgroundColor="background"
      >
        <Design
          width="100%"
          maxWidth={{ all: '460px', mobile: '100%' }}
          margin="0 auto"
        >
          <CheckoutPriceDetails />
        </Design>
      </Design>

      {hasAddress && (
        <Design
          position="fixed"
          bottom="0"
          left="0"
          width="100%"
          padding="8px 16px"
          backgroundColor="background"
          borderTop="1px solid"
          borderColor="natural"
          zIndex="5"
        >
          <Design maxWidth="460px" margin="0 auto">
            <Button
              shape="round"
              type="primary"
              size="large"
              fullWidth
              onClick={handleNextStep}
            >
              CONTINUE
            </Button>
          </Design>
        </Design>
      )}
    </Design>
  );

  const renderDesktop = () => (
    <Design
      display="flex"
      justifyContent="space-between"
      marginTop="42px"
      alignItems="flex-start"
    >
      <Design
        flex="1"
        padding={{ all: '16px 0 4px', desktop: '24px', mobile: '0' }}
        marginBottom={{ all: '12px', desktop: '50px' }}
        width="100%"
        borderRadius="8px"
        backgroundColor="background"
      >
        <CheckoutAddresses />
      </Design>
      <Design flex="1" width="37.68%" maxWidth="486px" marginLeft="16px">
        <Design
          marginBottom="16px"
          backgroundColor="background"
          borderRadius="8px"
          padding="36px 42px"
        >
          <CheckoutPriceDetails />
          <Design
            paddingTop={{
              all: '20px',
              desktop: '32px',
              tablet: '24px',
            }}
          >
            <Button
              shape="round"
              type="primary"
              color="primary"
              size="large"
              fullWidth
              onClick={handleNextStep}
            >
              {hasAddress ? 'CONTINUE' : 'SAVE & CONTINUE'}
            </Button>
          </Design>
        </Design>
        <CheckoutDeliveryTime />
      </Design>
    </Design>
  );

  return (
    <>
      <Media lessThan="desktop">{renderLessDesktop()}</Media>
      <Media greaterThan="laptop">{renderDesktop()}</Media>
    </>
  );
};

export default CheckoutShipping;
