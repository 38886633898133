import React from 'react';
import { Form } from 'antd';

import { ContactFormModel } from '@models';
import { Button, Design, TextInput } from '@components';

type Props = {
  loading: boolean;
  onSubmit: ({ subject, body, name, email }: ContactFormModel) => void;
};

const ContactForm = ({ loading, onSubmit }: Props) => {
  return (
    <Form name="contactForm" autoComplete="off" onFinish={onSubmit}>
      <TextInput
        name="name"
        label="Name *"
        type="text"
        rules={[{ required: true, message: 'Please input your name!' }]}
      />

      <TextInput
        name="email"
        label="Email Address *"
        type="email"
        rules={[
          { required: true, message: 'Please input your email!' },
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
        ]}
      />

      <TextInput
        name="subject"
        label="Subject *"
        type="text"
        rules={[{ required: true, message: 'Please input subject!' }]}
      />

      <TextInput
        name="body"
        label="Message *"
        type="textarea"
        rules={[{ required: true, message: 'Please input message!' }]}
      />

      <Design margin="20px 0 0 0">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            fullWidth
            loading={loading}
          >
            SUBMIT
          </Button>
        </Form.Item>
      </Design>
    </Form>
  );
};

export default ContactForm;
