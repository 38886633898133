import { onAuthStateChanged, getAuth } from 'firebase/auth';

import {
  cartActions,
  firebaseActions,
  profileActions,
  wishlistActions,
} from '@actions';
import { setCookie, getCookie, handleSignInAnonymously } from '@helpers';
import { PromiseModel } from '@models';

const initClient = (promise: PromiseModel, areaId?: number) => {
  /**
   * @description
   * This helper/initializer will be detect the authentication
   * state changes and set/replace the new token to the Cookie.
   * Then collects the user's necessary data.
   */

  const auth = getAuth();

  const loadData = () => {
    Promise.all([
      promise(profileActions.loadProfile()),
      promise(profileActions.loadProfileWalletBalance()),
      promise(wishlistActions.compactWishlist()),
      promise(cartActions.loadCartCompact(areaId)),
    ]);
  };

  onAuthStateChanged(auth, (user) => {
    if (!user) {
      handleSignInAnonymously();
    } else {
      const lastToken = getCookie('oneSellToken');
      const lastUserStatus = getCookie('oneSellAnonymousUser');

      user?.getIdToken().then((accessToken) => {
        setCookie('oneSellToken', accessToken);
        setCookie('oneSellAnonymousUser', user.isAnonymous ? 'true' : 'false');
        promise(firebaseActions.setProfile(user as any));
        if (lastUserStatus !== 'false' && !user.isAnonymous) {
          return promise(profileActions.upgradeToken(lastToken)).then(() => {
            loadData();
          });
        }
        return loadData();
      });
    }
  });
};

export default initClient;
