import { createSelector } from 'reselect';
import { StoreModel } from '@models';

const selectCart = (state: StoreModel) => state.cart;

export const details = createSelector([selectCart], (card) => card?.details);

export const compact = createSelector([selectCart], (card) => card?.compact);

export const items = createSelector([selectCart], (card) => card?.items);

export const recommendations = createSelector(
  [selectCart],
  (card) => card?.recommendations
);
