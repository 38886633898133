import { Design, Wording } from '@components';
import { AddressAreaModel } from '@models';
import React, { FC } from 'react';
import { Check } from 'react-feather';

type Props = {
  area: AddressAreaModel;
  isChecked: boolean;
  onSelect: () => void;
};
const RegionAreaItemModule: FC<Props> = ({ area, isChecked, onSelect }) => {
  return (
    <Design
      padding="13px 12px"
      backgroundColor={isChecked ? 'background_alt' : 'transparent'}
      borderRadius="8px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className="has-pointer"
      height="48px"
      onClick={onSelect}
    >
      <Wording fontWeight="500" fontSize="16px" lineHeight="22px">
        {area.name}
      </Wording>
      {isChecked && (
        <Wording fontSize="22px" lineHeight="22px">
          <Check />
        </Wording>
      )}
    </Design>
  );
};

export default RegionAreaItemModule;
