import React, { FC, useMemo, useState } from 'react';

import { Design, Media, Wording } from '@components';
import ds from '@ds';
import { getRegion } from '@helpers';
import { DeliverToItem } from '@modules';
import { Popover } from 'antd';
import Image from 'next/image';
import { ChevronDown } from 'react-feather';
import { BottomSheet } from 'react-spring-bottom-sheet';

import styles from './deliver-to.module.scss';

const DeliverToModule: FC = () => {
  const [isVisible, setVisible] = useState(false);
  const [activeLocal] = useState<'ar-QA' | 'ar-AE'>(getRegion());
  const localesList = [
    {
      id: 'ar-QA',
      name: 'Qatar',
      image: '/icons/flags/qatar.svg',
      link: 'https://1sell.com',
    },
    {
      id: 'ar-AE',
      name: 'UAE',
      image: '/icons/flags/uae.svg',
      link: 'https://1sell.ae',
    },
  ];

  const currentLocal = useMemo(
    () => localesList.find((i) => i.id === activeLocal) ?? localesList[0],
    [activeLocal]
  );

  const renderLocalList = () => (
    <Design minWidth="220px">
      {localesList.map((i, index) => (
        <DeliverToItem
          isLast={index + 1 === localesList.length}
          isActive={activeLocal === i.id}
          key={i.id}
          details={i}
        />
      ))}
    </Design>
  );

  const renderMobileView = () => (
    <Popover
      placement="topLeft"
      overlayClassName={styles.ChangeDeliverTo}
      content={renderLocalList}
      trigger="click"
      onVisibleChange={setVisible}
    >
      <Design
        display="flex"
        minWidth="109px"
        alignItems="center"
        height="36px"
        className="has-pointer"
        marginRight="24px"
      >
        <Design width="24px" height="16px">
          <Image
            src={currentLocal.image}
            alt="qatar"
            width="24px"
            height="16px"
          />
        </Design>
        <Design marginLeft="8px">
          <Design
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Wording
              fontWeight="400"
              fontSize="12px"
              lineHeight="18px"
              color="border"
            >
              Deliver to
            </Wording>
            <Design
              width="16px"
              height="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              marginLeft="4px"
            >
              <ChevronDown
                className={isVisible ? styles.RotatedChevron : styles.Chevron}
                color={ds.color_border}
                width="100%"
              />
            </Design>
          </Design>

          <Wording
            fontWeight="500"
            fontSize="14px"
            lineHeight="20px"
            color="border"
          >
            {currentLocal.name}
          </Wording>
        </Design>
      </Design>
    </Popover>
  );
  const renderDesktopView = () => (
    <>
      <Design height="16px" onClick={() => setVisible(true)}>
        <Image
          src={currentLocal.image}
          alt="qatar"
          width="24px"
          height="16px"
        />
      </Design>
      <BottomSheet open={isVisible} onDismiss={() => setVisible(false)}>
        <Design
          width="100%"
          borderBottom="1px solid"
          borderColor="natural"
          padding="11px"
        >
          <Wording
            textAlign="center"
            lineHeight="22px"
            fontWeight="500"
            fontSize="16px"
          >
            Deliver to
          </Wording>
        </Design>
        <Design padding="12px 16px">
          {localesList.map((i, index) => (
            <DeliverToItem
              isLast={index + 1 === localesList.length}
              isBottomSheet={true}
              isActive={activeLocal === i.id}
              key={i.id}
              details={i}
            />
          ))}
        </Design>
      </BottomSheet>
    </>
  );
  return (
    <>
      <Media greaterThan="tablet">{renderMobileView()}</Media>
      <Media lessThan="laptop" className={styles.FlagHeight}>
        {renderDesktopView()}
      </Media>
    </>
  );
};

export default DeliverToModule;
