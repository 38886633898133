import cn from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { ChevronRight } from 'react-feather';
import numeral from 'numeral';

import { getRegionToken, handleSignOut as authSignOut } from '@helpers';
import { profileSelectors } from '@selectors';
import { Design, Wording } from '@components';

import styles from './profile-navbar.module.scss';
import { formatPriceWithCurrency } from '@utils/number.utils';
import { FC } from 'react';
import { useStorage } from '@hooks';
import { AddressRegionSelected } from '@models';

type Props = {
  inProfilePage?: boolean;
};

const ProfileNavbar: FC<Props> = ({ inProfilePage = false }: Props) => {
  const router = useRouter();

  const { removeLocalStorage } = useStorage<AddressRegionSelected>(
    getRegionToken()
  );

  const { balance } = useSelector(profileSelectors.wallet);

  const navbarData = [
    {
      id: 'orders',
      path: '/profile/orders',
      title: 'Orders',
    },
    {
      id: 'wishlist',
      path: '/profile/wishlist',
      title: 'Wishlist',
    },
    {
      id: 'personal-info',
      path: '/profile/personal-info',
      title: 'Personal Info',
    },
    {
      id: 'address-book',
      path: '/profile/address-book',
      title: 'Address Book',
    },
    {
      id: 'wallet',
      path: '/profile/wallet',
      title: 'My Wallet',
    },
  ];

  const handleSignOut = () => {
    authSignOut().then(() => {
      removeLocalStorage();
      router.push('/');
    });
  };

  return (
    <Design
      display="flex"
      gap="16px"
      flexDirection="column"
      className={cn(styles.ProfileNavbarWrapper, {
        [styles.InProfilenavbar]: inProfilePage,
      })}
    >
      {inProfilePage && (
        <Design
          marginBottom={{
            all: '24px',
            mobile: '8px',
          }}
        >
          <Wording fontSize="32px" fontWeight="600">
            My Account
          </Wording>
        </Design>
      )}

      {navbarData?.map((item) => (
        <Design
          key={item?.id}
          display="flex"
          borderRadius="8px"
          position="relative"
          className={cn(styles.ProfileNavItem, {
            [styles.ActiveItem]: router.pathname === item?.path,
          })}
        >
          <Link href={item?.path}>
            <a>{item?.title}</a>
          </Link>
          <ChevronRight />

          {item?.id === 'wallet' && (
            <Design position="absolute" right="60px" top="15px">
              <Wording fontWeight="bold" color="placeholder">
                {formatPriceWithCurrency(balance)}
              </Wording>
            </Design>
          )}
        </Design>
      ))}

      <Design
        display="flex"
        borderRadius="8px"
        position="relative"
        className={styles.ProfileNavItem}
      >
        <span onClick={handleSignOut}>Sign out</span>
      </Design>
    </Design>
  );
};

export default ProfileNavbar;
