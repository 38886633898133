import React from 'react';
import Image from 'next/image';

import * as Sentry from '@sentry/browser';
import { Button, Design, Wording, Media } from '@components';
import { Plus } from 'react-feather';

type State = {
  hasError: boolean;
  error: string;
  showMore: boolean;
};
class ErrorBoundary extends React.Component<unknown, State> {
  // Define a state variable to track whether is an error or not
  state = {
    hasError: false,
    error: '',
    showMore: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true, error: error && JSON.stringify(error) };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service

    this.setState({
      error: JSON.stringify(errorInfo),
    });

    if (Sentry)
      Sentry.captureException(error, {
        level: 'error',
        extra: { info: errorInfo },
      });
    console.log({ error, errorInfo });
  }

  // Toggle Details of error
  handleToggleDetails = () =>
    this.setState((prevState) => ({ showMore: !prevState.showMore }));

  render() {
    const { error, showMore, hasError } = this.state;
    // Check if the error is thrown
    if (hasError) {
      return (
        <Design
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="100vh"
          maxWidth={{
            all: '810px',
            tablet: '698px',
            mobile: '328px',
          }}
          margin="0 auto"
          padding="10px"
        >
          <Design
            width={{
              all: '140px',
              tablet: '120px',
              mobile: '100px',
            }}
            height={{
              all: '140px',
              tablet: '120px',
              mobile: '100px',
            }}
          >
            <Image
              priority
              src="/icons/server-error.svg"
              height="100%"
              width="100%"
              layout="responsive"
              alt="server error"
            />
          </Design>
          <Wording
            marginTop={{
              all: '32px',
              mobile: '20px',
            }}
            variant="h1"
            fontSize={{ all: '34px', mobile: '18px' }}
            lineHeight={{ all: '44px', mobile: '26px' }}
            textAlign="center"
          >
            Well, this is unexpected...
          </Wording>
          <Wording
            marginTop={{
              all: '24px',
              mobile: '12px',
            }}
            fontSize={{ all: '20px', mobile: '14px' }}
            lineHeight={{ all: '28px', mobile: '20px' }}
            textAlign="center"
          >
            An error has occurred and we’re working to fix the problem! We’ll be
            up and running shortly. Please try again later...
          </Wording>

          {error && (
            <Design
              border="1px solid"
              borderColor="border"
              borderRadius="8px"
              width="100%"
              margin={{
                all: '42px 0 0',
                mobile: '24px 0 0',
              }}
            >
              <Design
                padding="10px"
                onClick={this.handleToggleDetails}
                display="flex"
                justifyContent="space-between"
                className="has-pointer"
              >
                <Wording fontWeight="600">ERROR DETAILS</Wording>
                <Plus />
              </Design>
              {showMore && (
                <Design
                  padding="20px 10px"
                  borderTop="1px solid"
                  borderColor="border"
                >
                  <code>{error}</code>
                </Design>
              )}
            </Design>
          )}
          <Design
            maxWidth={{
              all: '360px',
              mobile: '296px',
            }}
            width="100%"
            marginTop={{
              all: '42px',
              mobile: '24px',
            }}
          >
            <Button
              fullWidth
              shape="round"
              type="primary"
              onClick={() => this.setState({ hasError: false })}
            >
              TRY AGAIN
            </Button>
          </Design>
        </Design>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
