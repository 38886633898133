export const LOAD_PROFILE = 'LOAD_PROFILE';
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_FAILURE = 'LOAD_PROFILE_FAILURE';

export const GIFT_CARD = 'GIFT_CARD';
export const GIFT_CARD_SUCCESS = 'GIFT_CARD_SUCCESS';
export const GIFT_CARD_FAILURE = 'GIFT_CARD_FAILURE';

export const NEWSLETTER = 'NEWSLETTER';
export const NEWSLETTER_SUCCESS = 'NEWSLETTER_SUCCESS';
export const NEWSLETTER_FAILURE = 'NEWSLETTER_FAILURE';

export const LOAD_WALLET_HISTORY = 'LOAD_WALLET_HISTORY';
export const LOAD_WALLET_HISTORY_SUCCESS = 'LOAD_WALLET_HISTORY_SUCCESS';
export const LOAD_WALLET_HISTORY_FAILURE = 'LOAD_WALLET_HISTORY_FAILURE';

export const LOAD_PROFILE_WALLET_BALANCE = 'LOAD_PROFILE_WALLET_BALANCE';
export const LOAD_PROFILE_WALLET_BALANCE_SUCCESS =
  'LOAD_PROFILE_WALLET_BALANCE_SUCCESS';
export const LOAD_PROFILE_WALLET_BALANCE_FAILURE =
  'LOAD_PROFILE_WALLET_BALANCE_FAILURE';

export const UPGRADE_TOKEN = 'UPGRADE_TOKEN';

export const SET_FCM_TOKEN = 'SET_FCM_TOKEN';
export const SET_FCM_TOKEN_SUCCESS = 'SET_FCM_TOKEN_SUCCESS';
export const SET_FCM_TOKEN_FAILURE = 'SET_FCM_TOKEN_FAILURE';

export const SET_SIGNUP_METHOD = 'SET_SIGNUP_METHOD';
export const SET_SIGNUP_METHOD_SUCCESS = 'SET_SIGNUP_METHOD_SUCCESS';
export const SET_SIGNUP_METHOD_FAILURE = 'SET_SIGNUP_METHOD_FAILURE';
