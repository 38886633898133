import React, { FC } from 'react';
import Image from 'next/image';

import classes from './reward-promotion-code.module.scss';
import { notification } from '@components';

type Props = {
  code?: string;
};
const RewardPromotionCode: FC<Props> = ({ code = 'bcxtk232' }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(code).then(() =>
      notification({
        type: 'info',
        message: 'The code copied to clipboard',
      })
    );
  };
  return (
    <div className={classes.Container}>
      <Image
        className="has-pointer"
        onClick={copyToClipboard}
        priority
        src="/icons/reward/copy-to-clipboard.svg"
        width="24px"
        height="24px"
        alt="copy-to-clipboard"
      />
      <p className={classes.CodeText} onClick={copyToClipboard}>
        Code: <span className="has-pointer">{code}</span>
      </p>
    </div>
  );
};

export default RewardPromotionCode;
