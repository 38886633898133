import {
  getApps,
  initializeApp as firebaseApp,
  getApp,
  FirebaseApp,
} from 'firebase/app';

import firebaseConfig from './firebaseConfig';

const initializeApp = (): FirebaseApp => {
  const appExist = getApps().length;
  if (!appExist) return firebaseApp(firebaseConfig);
  return getApp();
};

export default initializeApp;
