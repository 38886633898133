import React, { FC } from 'react';

import { Design, Wording } from '@components';

interface Props {
  id: number;
}

const ProductIdentifier: FC<Props> = ({ id }: Props) => {
  return (
    <Design display="flex" gap="4px">
      <Wording color="action" fontSize="16px">
        Product ID:
      </Wording>
      <Wording fontSize="16px" fontWeight='500'>{id}</Wording>
    </Design>
  );
};

export default ProductIdentifier;
