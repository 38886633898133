import { useEffect, useState } from 'react';

const useLocalStorage = (storage_key: string, maximum_store_count?: number) => {
  const [localItems, setLocalItems] = useState<string[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    setLocalItems(getLocalStorageItems());
  };

  const getLocalStorageItems = () => {
    const recentItems = localStorage.getItem(storage_key);
    if (recentItems) {
      return JSON.parse(recentItems);
    }

    return [];
  };

  const setItem = (value: string) => {
    const recentItems: string[] = Object.assign([], getLocalStorageItems());

    // Remove last item if we exceed the maximum store count
    if (maximum_store_count) {
      if (recentItems.length + 1 > maximum_store_count) {
        recentItems.splice(recentItems.length - 1, 1);
      }
    }

    const findDuplicate = recentItems.findIndex((term) => term === value);

    if (findDuplicate > -1) {
      recentItems.splice(findDuplicate, 1);
    }

    recentItems.unshift(value);

    setLocalItems(recentItems);
    updateStorage(recentItems);
  };

  const removeItemByIndex = (index: number) => {
    const recentItems: string[] = Object.assign([], localItems);

    recentItems.splice(index, 1);

    setLocalItems(recentItems);
    updateStorage(recentItems);
  };

  const removeAllItems = () => {
    setLocalItems([]);
    updateStorage([]);
  };

  const updateStorage = (list: string[]) => {
    localStorage.setItem(storage_key, JSON.stringify(list));
    setLocalItems(list);
  };

  return {
    localItems,
    setItem,
    removeItemByIndex,
    removeAllItems,
  };
};

export default useLocalStorage;
