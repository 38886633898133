import { productsActionTypes } from '@actionTypes';
import { ProductViewModel } from '@models';

export function loadAll(params: any) {
  return {
    type: productsActionTypes.LOAD_ALL_PRODUCTS,
    method: 'get',
    url: '/catalog/products',
    params,
  };
}

export function loadBestSellers(category_slug: string) {
  return {
    type: productsActionTypes.LOAD_BEST_SELLERS,
    method: 'get',
    url: '/catalog/products',
    params: {
      page: 1,
      page_size: 10,
      order_by: 'sold_count',
      category_slug,
    },
  };
}

export function loadDealCategories(deal_slug: string, params?) {
  return {
    type: productsActionTypes.LOAD_DEAL_CATEGORIES,
    method: 'get',
    url: '/catalog/products',
    params: {
      page: 1,
      page_size: 0,
      deal_slug,
      ...params,
    },
  };
}

export function load(product_id: number) {
  return {
    type: productsActionTypes.LOAD_PRODUCTS,
    method: 'get',
    url: `/catalog/products/product_id/${product_id}`,
  };
}

export function productView(data: ProductViewModel) {
  return {
    type: productsActionTypes.PRODUCT_VIEW,
    method: 'post',
    url: '/catalog/products/recently-viewed',
    data,
  };
}

export function getSimilarItems(params: { product_id: number }) {
  return {
    type: productsActionTypes.GET_SIMILAR_ITEMS,
    method: 'get',
    url: '/catalog/products/similar-items',
    params,
  };
}

export function getRecommendedForYou(params: { product_id: number }) {
  return {
    type: productsActionTypes.GET_RECOMMENDED_FOR_YOU,
    method: 'get',
    url: '/catalog/products/recommended-for-you',
    params,
  };
}

export function getFrequentlyBought(params: { product_id: number }) {
  return {
    type: productsActionTypes.GET_FREQUENTLY_BOUGHT,
    method: 'get',
    url: '/catalog/products/frequently-bought',
    params,
  };
}
