import { Facebook, Twitter, Mail, Share2 } from 'react-feather';

import { FooterPayloadModel } from '@models';
import { useMediaQuery } from '@hooks';
import ds from '@ds';
import { mediaQuery } from '@components';

import OnesellFooterLogo from 'public/icons/onesell-landing-footer-logo-desktop.svg';
import OnesellFooterLogoMobile from 'public/icons/onesell-landing-footer-logo-mobile.svg';

import styles from './landing-footer.module.scss';

interface Props {
  footerPayload: FooterPayloadModel;
}

const LandingFooter = ({ footerPayload }: Props) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);
  const isTablet = useMediaQuery(mediaQuery.tablet);

  const handleClickShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Black Friday',
        url: 'https://1sell.com/landing/black_friday2022',
      });
    }
  };

  if (isMobile || isTablet) {
    return (
      <div className="container">
        <div className={styles.FooterContainer}>
          <div
            className={styles.MobileShareContainer}
            onClick={handleClickShare}
          >
            <Share2
              width={20}
              height={20}
              fill={'transparent'}
              color={ds.color_background}
            />

            <span className={styles.MobileShareTitle}>
              {footerPayload?.title}
            </span>
          </div>

          <div className={styles.FooterLogo}>
            <OnesellFooterLogoMobile />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className={styles.FooterContainer}>
        <div className={styles.FooterTitle}>{footerPayload?.title}</div>
        <div className={styles.SocialIcons}>
          <li>
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A//1sell.com/landing/blackfriday2021"
              target="blank"
            >
              <Facebook color={ds.color_background} />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/intent/tweet?text=https%3A//1sell.com/landing/blackfriday2021"
              target="blank"
            >
              <Twitter color={ds.color_background} />
            </a>
          </li>
          <li>
            <a href="mailto:info@1sell.com">
              <Mail color={ds.color_background} />
            </a>
          </li>
        </div>

        <div className={styles.FooterLogo}>
          <OnesellFooterLogo />
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
