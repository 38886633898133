import { LegacyRef, ReactNode } from 'react';

export type CssDefaultValues = 'inherit' | 'initial' | 'revert' | 'unset';
export const enum x {
  PRIMARY,
  SECONDARY,
}
export type Colors =
  | 'transparent'
  | 'primary'
  | 'primary__dimmer'
  | 'primary__dark'
  | 'primary__night'
  | 'background'
  | 'background__dark'
  | 'background__night'
  | 'background_alt'
  | 'background_alt__dark'
  | 'background_alt__night'
  | 'background_alt__second'
  | 'background_alt__second_dark'
  | 'background_alt__second_night'
  | 'surface'
  | 'surface__dark'
  | 'surface__night'
  | 'action'
  | 'action__dark'
  | 'action__night'
  | 'caption'
  | 'caption__dark'
  | 'caption__night'
  | 'placeholder'
  | 'placeholder__dark'
  | 'placeholder__night'
  | 'border'
  | 'border__dark'
  | 'border__night'
  | 'natural'
  | 'natural__dark'
  | 'natural__night'
  | 'error'
  | 'error__dark'
  | 'error__night'
  | 'warning'
  | 'warning__dark'
  | 'warning__night'
  | 'success'
  | 'success__dark'
  | 'success__night'
  | 'hover'
  | 'hover__dark'
  | 'hover__night'
  | 'link'
  | 'link__dark'
  | 'link__night'
  | 'gray__600';

export type Spacing =
  | '0'
  | '2'
  | '4'
  | '6'
  | '8'
  | '10'
  | '12'
  | '14'
  | '16'
  | '18'
  | '20'
  | '22'
  | '24'
  | '26'
  | '28'
  | '30'
  | '32'
  | '64'
  | '100%';

export type FontSize =
  | '6'
  | '8'
  | '10'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '18'
  | '20'
  | '22'
  | '24'
  | '26'
  | '28'
  | '30'
  | '32'
  | '34'
  | '36'
  | '38'
  | '40';

export type FontWeight =
  | CssDefaultValues
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'normal'
  | 'bold';

export type TextType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

export type ScreenType = 'all' | 'desktop' | 'laptop' | 'tablet' | 'mobile';

export type DesignType =
  | 'div'
  | 'section'
  | 'header'
  | 'footer'
  | 'aside'
  | 'article'
  | 'figure'
  | 'ul'
  | 'ol'
  | 'li';

export interface IScreenSize<T> {
  all: T;
  desktop?: T;
  laptop?: T;
  mobile?: T;
  tablet?: T;
}

type SharedAlign =
  | CssDefaultValues
  | 'left'
  | 'center'
  | 'right'
  | 'start'
  | 'end'
  | 'justify';

type SharedDisplay =
  | CssDefaultValues
  | 'inline'
  | 'block'
  | 'inline-block'
  | 'flex'
  | 'inline-flex'
  | 'grid'
  | 'inline-grid'
  | 'none';

type SharedPosition =
  | CssDefaultValues
  | 'unset'
  | 'inherit'
  | 'relative'
  | 'absolute'
  | 'fixed'
  | 'static'
  | 'sticky';

type BoxVerticalAlign =
  | CssDefaultValues
  | 'baseline'
  | 'sub'
  | 'super'
  | 'text-top'
  | 'text-bottom'
  | 'middle'
  | 'top'
  | 'bottom';

type FlexWrap = CssDefaultValues | 'nowrap' | 'wrap' | 'wrap-reverse';

type FlexJustifyContent =
  | CssDefaultValues
  | 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'left'
  | 'right'
  | 'normal'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'
  | 'safe'
  | 'unsafe';

type FlexAlignItems =
  | CssDefaultValues
  | 'normal'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'start'
  | 'end'
  | 'self-start'
  | 'self-end'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'
  | 'stretch'
  | 'safe'
  | 'unsafe';
type FlexDirection =
  | CssDefaultValues
  | 'row'
  | 'row-reverse'
  | 'column'
  | 'column-reverse';

type BoxOverflow =
  | CssDefaultValues
  | 'visible'
  | 'hidden'
  | 'clip'
  | 'scroll'
  | 'auto';

export interface DesignProps<T = unknown> {
  innerRef: LegacyRef<T>;
  onClick: Function;
  onScroll: Function;
  onMouseEnter: Function;
  onMouseLeave: Function;
  children: ReactNode;
  className: string;
  variant: DesignType;
  textAlign: IScreenSize<SharedAlign> | SharedAlign;
  lineHeight: IScreenSize<string> | string;
  backgroundColor: IScreenSize<Colors> | Colors;
  CustomBackgroundColor: IScreenSize<string> | string;
  display: IScreenSize<SharedDisplay> | SharedDisplay;
  position: IScreenSize<SharedPosition> | SharedPosition;
  verticalAlign: IScreenSize<BoxVerticalAlign> | BoxVerticalAlign;
  gap: IScreenSize<string> | string;
  rowGap: IScreenSize<string> | string;
  columnGap: IScreenSize<string> | string;
  zIndex: IScreenSize<string> | string;
  opacity: IScreenSize<string> | string;
  color?: IScreenSize<Colors> | Colors;

  // Flex Props
  flexWrap: IScreenSize<FlexWrap> | FlexWrap;
  justifyContent: IScreenSize<FlexJustifyContent> | FlexJustifyContent;
  alignItems: IScreenSize<FlexAlignItems> | FlexAlignItems;
  flexDirection: IScreenSize<FlexDirection> | FlexDirection;
  flexOrder: IScreenSize<string> | string;
  flex: IScreenSize<string> | string;
  // Grid Props
  gridGap: IScreenSize<string> | string;
  gridTemplateRows: IScreenSize<string> | string;
  gridTemplateColumns: IScreenSize<string> | string;
  gridTemplateAreas: IScreenSize<string> | string;
  gridAutoRows: IScreenSize<string> | string;
  gridAutoFlow: IScreenSize<string> | string;
  gridAutoColumns: IScreenSize<string> | string;
  // margins
  margin: IScreenSize<string> | string;
  marginTop: IScreenSize<string> | string;
  marginRight: IScreenSize<string> | string;
  marginBottom: IScreenSize<string> | string;
  marginLeft: IScreenSize<string> | string;
  // paddings
  padding: IScreenSize<string> | string;
  paddingTop: IScreenSize<string> | string;
  paddingRight: IScreenSize<string> | string;
  paddingBottom: IScreenSize<string> | string;
  paddingLeft: IScreenSize<string> | string;
  // borders
  border: IScreenSize<string> | string;
  borderTop: IScreenSize<string> | string;
  borderRight: IScreenSize<string> | string;
  borderBottom: IScreenSize<string> | string;
  borderLeft: IScreenSize<string> | string;
  borderRadius: IScreenSize<string> | string;
  borderColor: IScreenSize<Colors> | Colors;
  // overflow
  overflow: IScreenSize<BoxOverflow> | BoxOverflow;
  overflowX: IScreenSize<BoxOverflow> | BoxOverflow;
  overflowY: IScreenSize<BoxOverflow> | BoxOverflow;
  // width
  width: IScreenSize<string> | string;
  minWidth: IScreenSize<string> | string;
  maxWidth: IScreenSize<string> | string;
  // height
  height: IScreenSize<string> | string;
  minHeight: IScreenSize<string> | string;
  maxHeight: IScreenSize<string> | string;
  // Position
  top: IScreenSize<string> | string;
  right: IScreenSize<string> | string;
  bottom: IScreenSize<string> | string;
  left: IScreenSize<string> | string;
}
