import React from 'react';

import { Design, Image } from '@components';
import Link from 'next/link';

type Props = {
  slug: string;
  image: string;
  title: string;
};

const MainOfferImage = ({ slug, image, title }: Props) => {
  return (
    <Design
      width={{ all: '323px', tablet: '200px', mobile: '120px' }}
      height={{ all: '352px', tablet: '213px', mobile: '128px' }}
      borderRadius="8px"
      overflow="hidden"
      position="relative"
    >
      <Link href={`/product/${slug}`}>
        <a>
          <Image src={image} width={323} height={352} alt={title} />
        </a>
      </Link>
    </Design>
  );
};

export default MainOfferImage;
