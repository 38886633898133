import React, { FC, useEffect, useMemo, useState } from 'react';

import { Wording } from '@components';
import {
  generateAvailableVariations,
  generateVariationsTemplate,
  normalizeProductVariationsForRender,
} from '@helpers';
import {
  ProductVariationAttributeTypeEnum,
  ProductVariationConvertModel,
  ProductVariationProps,
} from '@models';

import SelectSwatch from './types/select.variation';
import TextSwatch from './types/text-swatch.variation';
import VisualSwatch from './types/visual-swatch.variation';

const ProductVariations: FC<ProductVariationProps> = ({
  variations,
  isInCart = false,
  isInLanding = false,
  variationTitlePrefix = '',
  validateVariations,
  onVariationSelection,
  showErrorMessage,
  selectedVariation,
}: ProductVariationProps) => {
  /*
    { p
      1: '128',
      2: 'XL',
      3: 'Brown'
    }
  */
  const [selectedOptions, setSelectedOptions] = useState<{}>({});

  const normalizedVariations: ProductVariationConvertModel[] = useMemo(() => {
    return normalizeProductVariationsForRender(variations);
  }, [variations]);

  const variationsTemplate: Record<number, string[]> = useMemo(() => {
    return generateVariationsTemplate(variations);
  }, [variations]);

  /*
    {
      128: ['XL', 'Blue', 'Brown', 'XLL'],
      XL: [128, 'XLL'],
      Blue: [128]
    }
  */
  const availableVariations = useMemo(() => {
    return generateAvailableVariations(variations);
  }, [variations]);

  const [allowedVariations, setAllowedVariations] = useState<string[]>([]);

  useEffect(() => {
    const allItemsHasSelected: boolean = Object.values(selectedOptions).every(
      (v) => v !== null
    );

    if (!allItemsHasSelected) {
      onVariationSelection(null);
      // ReadableStreamDefaultController;
    }

    //find suitable variation
    const selectedItems: string[] = Object.values(selectedOptions).map(
      (v) => v as string
    );

    Object.keys(variationsTemplate).forEach((key) => {
      const isFound = variationsTemplate[key].some(
        (v: string) => selectedItems.indexOf(v) > -1
      );
      if (isFound) {
        onVariationSelection(+key);
        return;
      }
    });
  }, [selectedOptions]);

  const onUserSelect = (index: number, optionSlug: string | null) => {
    const options = Object.assign({}, selectedOptions);
    options[index] = optionSlug;

    setSelectedOptions(options);

    if (!optionSlug) {
      setAllowedVariations([]);
    }

    if (optionSlug && availableVariations[optionSlug]) {
      setAllowedVariations(availableVariations[optionSlug]);
    }
  };

  useEffect(() => {
    const findVariation = variations.find((v) => v.id === selectedVariation);

    if (findVariation) {
      onUserSelect(0, findVariation.attributes[0].options[0].slug);
    }
  }, [selectedVariation]);

  useEffect(() => {
    // create initial value for selected options
    const list = {};
    normalizedVariations.forEach((v, i) => {
      list[i] = null;
    });

    setSelectedOptions(list);
  }, [normalizedVariations]);

  const renderVariations = () => {
    return normalizedVariations.map((v, index) => {
      switch (v.type) {
        case ProductVariationAttributeTypeEnum.VISUAL_SWATCH:
          return (
            <div key={v.label}>
              <VisualSwatch
                index={index}
                variation={v}
                isInCart={isInCart}
                isInLanding={isInLanding}
                allowedVariations={allowedVariations}
                onOptionSelect={onUserSelect}
                variationTitlePrefix={variationTitlePrefix}
                defaultSelected={selectedVariation}
              />
              {showErrorMessage &&
                validateVariations &&
                selectedOptions[index] === null && (
                  <Wording
                    color="error"
                    fontSize="16px"
                    fontWeight="500"
                    lineHeight="22px"
                  >
                    Please Select {v.label}
                  </Wording>
                )}
            </div>
          );

        case ProductVariationAttributeTypeEnum.TEXT_SWATCH:
          return (
            <div key={v.label}>
              <TextSwatch
                index={index}
                variation={v}
                isInCart={isInCart}
                isInLanding={isInLanding}
                allowedVariations={allowedVariations}
                onOptionSelect={onUserSelect}
                variationTitlePrefix={variationTitlePrefix}
                defaultSelected={selectedVariation}
              />
              {showErrorMessage &&
                validateVariations &&
                selectedOptions[index] === null && (
                  <Wording
                    color="error"
                    fontSize="16px"
                    fontWeight="500"
                    lineHeight="22px"
                  >
                    Please Select {v.label}
                  </Wording>
                )}
            </div>
          );

        case ProductVariationAttributeTypeEnum.SELECT:
          return (
            <div key={v.label}>
              <SelectSwatch
                index={index}
                variation={v}
                isInCart={isInCart}
                allowedVariations={allowedVariations}
                onOptionSelect={onUserSelect}
                variationTitlePrefix={variationTitlePrefix}
                defaultSelected={selectedVariation}
              />
              {showErrorMessage &&
                validateVariations &&
                selectedOptions[index] === null && (
                  <Wording
                    color="error"
                    fontSize="16px"
                    fontWeight="500"
                    lineHeight="22px"
                  >
                    Please Select {v.label}
                  </Wording>
                )}
            </div>
          );

        default:
          return <></>;
      }
    });
  };

  if (!variations || variations.length === 0) return <></>;

  return <>{renderVariations()}</>;
};

export default ProductVariations;
