import { cartActionTypes } from '@actionTypes';
import { takeLatest } from 'redux-saga/effects';

import axiosMiddleware from '../../axios.middleware';

export default function* cartSaga() {
  yield takeLatest(cartActionTypes.LOAD_ALL_CART_LIST, axiosMiddleware);
  yield takeLatest(cartActionTypes.LOAD_CART_DETAILS, axiosMiddleware);
  yield takeLatest(cartActionTypes.CART_ADD, axiosMiddleware);
  yield takeLatest(cartActionTypes.CART_COMPACT, axiosMiddleware);
  yield takeLatest(cartActionTypes.CART_DELETE, axiosMiddleware);
  yield takeLatest(cartActionTypes.CART_DETAIL, axiosMiddleware);
  yield takeLatest(cartActionTypes.CART_EMPTY, axiosMiddleware);
  yield takeLatest(cartActionTypes.CART_UPDATE_QTY, axiosMiddleware);
  yield takeLatest(cartActionTypes.CART_UPDATE_VARIANT, axiosMiddleware);
  yield takeLatest(cartActionTypes.CART_VIEW, axiosMiddleware);
  yield takeLatest(cartActionTypes.GET_CART_RECOMMENDATIONS, axiosMiddleware);
}
