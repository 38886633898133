import React, { FC } from 'react';

import { Design, Wording } from '@components';
import {
  CartItemModel,
  CartProductVariationAttribute,
  ProductVariationAttributeTypeEnum,
} from '@models';
import { formatPriceWithCurrency } from '@utils/number.utils';
import Image from 'next/image';

type Props = {
  item: CartItemModel;
};
const CheckoutCartItemModule: FC<Props> = ({ item }) => {
  /**
   * Factory function that generate attribute element
   * @param {CartProductVariationAttribute} attribute - product attribute item
   * @returns {JSX.Element}
   */
  const renderItemAttribute = (
    attribute: CartProductVariationAttribute
  ): JSX.Element => {
    if (attribute.type === ProductVariationAttributeTypeEnum.VISUAL_SWATCH) {
      return (
        <Design
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
          marginBottom="6px"
          key={[attribute.slug, attribute.option.label].join('_')}
        >
          <Wording
            fontSize={{
              all: '12px',
              desktop: '14px',
            }}
            lineHeight={{
              all: '18px',
              desktop: '20px',
            }}
            key={attribute.slug}
            color="caption"
            marginRight="6px"
          >
            {attribute.label}:{' '}
          </Wording>
          <Design
            width="12px"
            height="12px"
            CustomBackgroundColor={attribute.option.visual_swatch_color_code}
            borderRadius="2px"
          />
        </Design>
      );
    }
    return (
      <Design
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
        marginBottom="6px"
        key={[attribute.slug, attribute.option.label].join('_')}
      >
        <Wording
          fontSize={{
            all: '12px',
            desktop: '14px',
          }}
          lineHeight={{
            all: '18px',
            desktop: '20px',
          }}
          fontWeight="500"
          key={attribute.slug}
          color="caption"
          marginRight="6px"
        >
          {attribute.label}:
        </Wording>
        <Wording
          fontSize={{
            all: '12px',
            desktop: '14px',
          }}
          lineHeight={{
            all: '18px',
            desktop: '20px',
          }}
          fontWeight="500"
          variant="span"
          color="surface"
        >
          {attribute.option?.label}
        </Wording>
      </Design>
    );
  };
  return (
    <Design
      marginLeft={{ all: '24px', tablet: '0', mobile: '16px' }}
      marginRight={{ all: '12px', tablet: '32px', mobile: '4px' }}
      maxWidth={{ all: '100px', mobile: '64px' }}
    >
      <Design
        position="relative"
        width={{ all: '100px', tablet: '72px', mobile: '64px' }}
        height={{ all: '100px', tablet: '72px', mobile: '64px' }}
        border="1px solid"
        borderColor="natural"
        borderRadius="6px"
        overflow="hidden"
      >
        <Image
          src={item.product.image.urls.original}
          alt={item.product.title}
          width="100px"
          height="100px"
        />
        <Design
          position="absolute"
          left="6px"
          top="6px"
          width={{
            all: '24px',
            tablet: '16px',
            mobile: '16px',
          }}
          height={{
            all: '24px',
            tablet: '16px',
            mobile: '16px',
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="3px"
          backgroundColor="natural"
        >
          <Wording
            fontSize={{
              all: '12px',
              desktop: '14px',
            }}
            lineHeight={{
              all: '18px',
              desktop: '20px',
            }}
            fontWeight="500"
            color="surface"
          >
            {item.qty}
          </Wording>
        </Design>
      </Design>
      <Wording
        fontSize={{
          all: '12px',
          desktop: '14px',
        }}
        lineHeight={{
          all: '18px',
          desktop: '20px',
        }}
        fontWeight="500"
        color="surface"
        margin="6px 0"
      >
        {formatPriceWithCurrency(item.price)}
      </Wording>
      {item.product?.variation?.attributes?.map((attribute) =>
        renderItemAttribute(attribute)
      )}
    </Design>
  );
};

export default CheckoutCartItemModule;
