import { Design, Image, Wording, AboutPageLayout } from '@components';

import styles from './about-hero.module.scss';

const AboutHero = () => {
  return (
    <Design className={styles.Header}>
      <Design
        className={styles.HeaderImage}
        position="relative"
        right="-10px"
        width="100%"
        height="100%"
      >
        <img src="/images/about-header-image.png" alt="Shop wit CONFIDENCE" />
      </Design>

      <div className={styles.HeaderTexts}>
        <div className={styles.HeaderNormalText}>Shop with</div>
        <div className={styles.HeaderBoldText}>
          CONFIDENCE<span>.</span>
        </div>
      </div>
    </Design>
  );
};

export default AboutHero;
