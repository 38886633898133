export enum SeoEvent {
  VIEW_ITEM = 'view_item',
  SELECT_ITEM = 'select_item',
  ADD_TO_CART = 'add_to_cart',
  ADD_TO_WISHLIST = 'add_to_wishlist',
  REMOVE_FROM_CART = 'remove_from_cart',
  BEGIN_CHECKOUT = 'begin_checkout', // DONE
  ADD_SHIPPING_INFO = 'add_shipping_info', // DONE
  PURCHASE_COMPLETE = 'purchase', // DONE

  LOGIN = 'login', // DONE
  SIGNUP = 'signup', // DONE (with drawback - we don't know if its a auth provider login or register - both is the same)
  APP_BANNER_CLICK = 'app_banner_click', // DONE
  SELECT_ADDRESS_TYPE = 'select_address_type', // DONE
  PAYMENT_OPTION_SELECT = 'payment_option_select', // DONE
  ADD_PROMO_CODE = 'add_promo_code', // DONE
  CART_QTY_CHANGE = 'cart_qty_change', // DONE
  CART_DELETEITEM_CLICK = 'cart_deleteitem_click', // DONE
  CART_UPDATEVARIATION_CLICK = 'cart_updatevariation_click',
  CART_UPDATEVARIATION_FINALIZE = 'cart_updatevariation_finalize',
  CART_OPEN = 'cart_open', // DONE

  AREA_LIST_OPEN = 'area_list_open',
  AREA_CHANGED = 'area_changed',

  REGISTRATION_REWARD_OPEN = 'Registration_reward_open',
  LOGIN_REGISTER_STARTED = 'login_register_started',
  LOGIN_NUMBER_ENTERED = 'login_number_entered',
}

export const SEO_EVENT_AFFILIATION_NAME = '1Sell Online Store';
export const SEO_EVENT_CURRENCY = process.env.NEXT_PUBLIC_SEO_CURRENCY;

export enum SeoEventListLocation {
  UNKNOWN_LOCATION = 'unknown_location',
  HOME_PRODUCTS_LIST = 'home_products_list',
  CATEGORY_LIST = 'category_list',
  WISH_LIST = 'wish_list',
  BEST_SELLERS = 'best_sellers',
  FREQUENTLY_BOUGHT = 'frequently_bought',
  DEAL_PRODUCTS_LIST = 'deal_products_list',
  CHECKOUT_LIST = 'checkout_list',
  ITEM_DETAILS = 'item_details',
  RECOMMENDS_LIST = 'recommended_for_you',
  CHECKOUT_DONE = 'checkout_done',
}

export interface NormalizedGtagProductModel {
  item_id: string;
  item_name: string;
  affiliation: string;
  currency: string;
  discount: number;
  index: number;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_category3: string;
  item_category4: string;
  item_category5: string;
  item_list_id: string;
  item_list_name: SeoEventListLocation;
  item_variant: string;
  location_id: number;
  price: number;
  quantity: number;
}

export interface SeoEventProductModel {
  id: string;
  name: string;
  variant: null | string;
  price: number;
  discount: number;
  list_location: SeoEventListLocation;
  brand: string;
  category: string[];
  position: number;
  qty: number;
}

export interface SeoEventImpressionProductModel
  extends Omit<SeoEventProductModel, 'position' | 'variant' | 'brand'> {
  list: string;
}

export interface SeoEventBasketModel
  extends Omit<SeoEventProductModel, 'position'> {
  quantity: number;
}

export interface SeoEventTransactionModel {
  id: string;
  affiliation: '1Sell';
  revenue: number; // transaction value (receipt total)
  tax: number;
  shipping: {};
}
