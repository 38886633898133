import React, { FC } from 'react';
import { Form } from 'antd';
import { Check, X } from 'react-feather';

import { Button, TextInput, Design, Wording } from '@components';

type messageModel = {
  type: '';
  text: '';
};

type Props = {
  message: messageModel;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (redeem_code: string) => void;
};

const RedeemCodeForm: FC<Props> = ({
  message,
  loading,
  onCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();

  return (
    <div>
      <Form
        form={form}
        name="redeemCodeForm"
        autoComplete="off"
        onFinish={onSubmit}
      >
        <TextInput
          name="redeem_code"
          label="Enter your redeem code *"
          rules={[
            { required: true, message: 'Please input your redeem code!' },
          ]}
        />

        {message?.type !== '' && (
          <Design
            marginTop="16px"
            borderRadius="8px"
            display="flex"
            gap="8px"
            padding="16px"
            alignItems="center"
            backgroundColor={message?.type === 'success' ? 'success' : 'error'}
          >
            <Design
              minWidth="30px"
              minHeight="30px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="8px"
              backgroundColor={
                message?.type === 'success' ? 'success__dark' : 'error__dark'
              }
            >
              {message?.type === 'success' ? (
                <Check color="#fff" />
              ) : (
                <X color="#fff" />
              )}
            </Design>
            <Wording
              color="background"
              fontSize={{ all: '16px', mobile: '15px' }}
              fontWeight="600"
            >
              {message?.text}
            </Wording>
          </Design>
        )}

        {(message?.type === '' || message?.type !== 'success') && (
          <Design marginTop="32px" display="flex" flexDirection="column">
            <Form.Item>
              <Button
                size="large"
                shape="round"
                type="primary"
                fullWidth
                htmlType="submit"
                loading={loading}
              >
                Apply
              </Button>
            </Form.Item>

            <Design margin="-10px 0 0 0">
              <Button size="large" shape="round" fullWidth onClick={onCancel}>
                Cancel
              </Button>
            </Design>
          </Design>
        )}
      </Form>
    </div>
  );
};

export default RedeemCodeForm;
