import queryString from 'query-string';

export const createUrl = (router: any, query?: any, hash?: any) => ({
  ...router,
  ...(query && {
    search: queryString.stringify({
      ...query,
    }),
  }),
  ...(hash && {
    hash: queryString.stringify({
      ...hash,
    }),
  }),
});

export function parse(location: any) {
  return queryString.parse(location.search);
}

export const convertToSlug = (term: string) => {
  return term
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const isExternalUrl = (url: string) => url.indexOf('http') > -1;

export default {
  createUrl,
  parse,
  convertToSlug,
  isExternalUrl,
};
