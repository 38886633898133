import { formatPriceWithCurrency } from '@utils/number.utils';
import { Design, Wording } from '@components';

type Props = {
  price: number;
  listPrice: number;
  isInModal?: boolean;
};

const CartItemPrice = ({ price, listPrice, isInModal }: Props) => {
  return (
    <Design
      display="flex"
      gap={{ all: isInModal ? '2px' : '8px', tablet: '2px', mobile: '2px' }}
      alignItems={{
        all: isInModal ? 'flex-start' : 'center',
        tablet: 'flex-start',
        mobile: 'flex-start',
      }}
      justifyContent="flex-start"
      flexDirection={{
        all: isInModal ? 'column' : 'row',
        tablet: 'column',
        mobile: 'column',
      }}
    >
      <Design
        flexOrder={{ all: isInModal ? '2' : '1', tablet: '2', mobile: '2' }}
      >
        <Wording
          fontWeight="500"
          fontSize={{ all: '18px', tablet: '16px', mobile: '16px' }}
          lineHeight={{ all: '26px', tablet: '22px', mobile: '22px' }}
        >
          {formatPriceWithCurrency(price) ?? 0}
        </Wording>
      </Design>

      {price < listPrice && (
        <Design
          flexOrder={{ all: isInModal ? '1' : '2', tablet: '1', mobile: '1' }}
        >
          <Wording
            decoration="line-through"
            color="caption"
            fontSize={{ all: '14px', tablet: '14px', mobile: '12px' }}
            lineHeight="20px"
          >
            {formatPriceWithCurrency(listPrice) ?? 0}
          </Wording>
        </Design>
      )}
    </Design>
  );
};

export default CartItemPrice;
