import React, { FC } from 'react';
import { Input, Form, InputProps } from 'antd';
import { Rule } from 'antd/lib/form';

import InputsWrapper from './inputs-wrapper';

import styles from './form-fields.module.scss';
import clsx from 'clsx';

interface Props extends InputProps {
  name: string;
  label: string;
  value?: string;
  placeholder?: string;
  error?: string[];
}

const ActionInput: FC<Props> = ({
  name,
  type = 'text',
  label,
  value = '',
  placeholder = '',
  error,
  disabled,
  addonAfter,
  onChange,
}) => {
  return (
    <InputsWrapper name={name} label={label} value={value} error={error}>
      <Form.Item name={name}>
        <Input
          disabled={disabled}
          placeholder={placeholder}
          className={clsx(styles.InputStyles, styles.ActionInput)}
          type={type}
          addonAfter={addonAfter}
          onChange={onChange}
        />
      </Form.Item>
    </InputsWrapper>
  );
};

export default ActionInput;
