import { CategoriesStateModel, CategoryModel, StoreModel } from '@models';
import { createSelector } from 'reselect';

const selectCategories = (state: StoreModel) => state.categories;

export const selectAll = createSelector(
  [selectCategories],
  (categories: CategoriesStateModel) => categories?.all
);

export const selectAllMap = createSelector(
  [selectCategories],
  (categories: CategoriesStateModel) => {
    const categoriesMap = new Map<string, CategoryModel>();
    if (categories.all?.data)
      categories.all.data.forEach((item) => categoriesMap.set(item.slug, item));
    return categoriesMap;
  }
);

export const selectItem = createSelector(
  [selectCategories],
  (categories: CategoriesStateModel) => categories?.item
);

export const dealCategories = createSelector(
  [selectCategories],
  (categories: CategoriesStateModel) => categories?.dealCategories
);
