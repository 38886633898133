import React, { FC, useRef } from 'react';

import { Design, Wording } from '@components';
import { CategoryModel } from '@models';
import Link from 'next/link';

import styles from './category-siblings.module.scss';

interface Props {
  data: CategoryModel[];
}

const CategorySiblings: FC<Props> = ({ data }) => {
  const listRef = useRef<HTMLDivElement>(null);

  if (data.length === 0) return <></>;

  const handleShowAtFirst = () => {
    listRef.current?.scrollTo({ left: 0, behavior: 'smooth' });
  };

  return (
    <Design
      position="relative"
      height={{ all: 'auto', mobile: '94px' }}
      marginBottom={{ all: '24px', desktop: '32px' }}
    >
      <Design
        display={{ all: 'grid', mobile: 'flex' }}
        gap={{ all: '16px', mobile: '8px' }}
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        padding={{ all: '0', mobile: '0px 16px 0px' }}
        position={{ all: 'initial', mobile: 'absolute' }}
        overflowX={{ all: 'visible' }}
        overflowY={{ all: 'visible', mobile: 'hidden' }}
        maxWidth={{ all: 'initial', mobile: '100vw' }}
        width={{ all: 'auto', mobile: 'fit-content' }}
        left={{ all: '0', mobile: '-16px' }}
        className={styles.Wrapper}
        onClick={handleShowAtFirst}
        innerRef={listRef}
      >
        {data?.map((item) => (
          <Link key={item?.slug} href={`/category/${item.slug}/products`}>
            <a>
              <Design
                // key={item?.slug}
                backgroundColor="background"
                borderRadius="8px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={{ all: '20px', mobile: '16px' }}
                width={{ all: 'auto', mobile: '160px' }}
                gap={{ all: '8px', mobile: '6px', tablet: '6px' }}
                className={styles.CardItem}
              >
                <Wording
                  fontSize={{ all: '18px', tablet: '16px', mobile: '14px' }}
                  lineHeight={{ all: '26px', tablet: '22px', mobile: '20px' }}
                  fontWeight="500"
                  textAlign="center"
                  className={styles.CardItemTitle}
                >
                  {item?.title}
                </Wording>
                <Wording
                  fontSize={{ all: '14px', tablet: '16px', mobile: '14px' }}
                  lineHeight={{
                    all: '19.6px',
                    tablet: '22px',
                    mobile: '20px',
                  }}
                  fontWeight="500"
                  color="caption"
                >
                  {item?.products_count} Products
                </Wording>
              </Design>
            </a>
          </Link>
        ))}
      </Design>
    </Design>
  );
};

export default CategorySiblings;
