import React, { FC, useEffect, useMemo, useState } from 'react';

import { Design, Wording } from '@components';
import {
  ProductVariationConvertModel,
  StockStatusEnum,
  VariationConvertModelAttributeModel,
} from '@models';
import cn from 'clsx';
import Image from 'next/image';

import styles from '../variations.module.scss';

interface Props {
  onOptionSelect: (index: number, optionSlug: string | null) => void;
  variation: ProductVariationConvertModel;
  allowedVariations: string[];
  variationTitlePrefix: string;
  index: number;
  isInCart?: boolean;
  isInLanding?: boolean;
  defaultSelected: number | null;
}

const VisualSwatch: FC<Props> = ({
  onOptionSelect,
  variation,
  allowedVariations,
  variationTitlePrefix,
  index,
  defaultSelected,
  isInCart = false,
  isInLanding = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [defaultVariationId, setDefaultVariationId] = useState<number | null>(
    defaultSelected || null
  );

  const localAttributes = useMemo(() => {
    return variation.attributes.map((v) => {
      return v.slug;
    });
  }, [variation]);

  useEffect(() => {
    if (selectedOption && allowedVariations.indexOf(selectedOption) === -1)
      setSelectedOption(null);
  }, [allowedVariations]);

  useEffect(() => {
    onOptionSelect(index, selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    setDefaultVariationId(defaultSelected);
  }, [defaultSelected]);

  useEffect(() => {
    const findVariation = variation.attributes.find(
      (i) => i.variationId === defaultSelected
    );

    if (isInCart || isInLanding) {
      setSelectedOption(String(findVariation?.slug));
    }
  }, [defaultSelected]);

  const handleOptionSelect = (slug: string) => {
    if (isInCart || isInLanding) {
      setDefaultVariationId(null);
    }

    if (slug === selectedOption) setSelectedOption(null);
    else setSelectedOption(slug);
  };

  const renderColor = (option: VariationConvertModelAttributeModel) => {
    return (
      <>
        <div>
          <style jsx>
            {`
              div {
                background-color: ${option.visual_swatch_color_code};
                width: 20px;
                height: 20px;
                border-radius: 8px;
                border: 1px solid #eee;
              }
            `}
          </style>
        </div>
        <Wording>
          {option.text_swatch_label && option.text_swatch_label?.length > 0
            ? option.text_swatch_label
            : option.label}
        </Wording>
      </>
    );
  };

  const renderImage = (option: VariationConvertModelAttributeModel) => {
    return (
      <>
        <Image
          src={option.visual_swatch_image?.urls.original ?? ''}
          width={20}
          height={20}
          alt={option.label}
        />
        <Wording>
          {option.text_swatch_label && option.text_swatch_label?.length > 0
            ? option.text_swatch_label
            : option.label}
        </Wording>
      </>
    );
  };

  const renderOption = (option: VariationConvertModelAttributeModel) => {
    const isSelected =
      option.slug === selectedOption ||
      option.variationId === defaultVariationId;

    const isOutOfStock = option.stock_status === StockStatusEnum.OUT_OF_STOCK;
    const isNotAllowed =
      localAttributes.indexOf(option.slug) === -1 ||
      (allowedVariations.length > 0 &&
        allowedVariations.indexOf(option.slug) === -1);

    const rootClassName = cn({
      [styles.DisableVariation]: isNotAllowed,
      [styles.SelectedVariation]: isSelected,
      [styles.FullDisable]: isOutOfStock,
    });

    return (
      <li
        key={option.slug}
        className={rootClassName}
        onClick={() => {
          if (!isOutOfStock) handleOptionSelect(option.slug);
        }}
      >
        {option.visual_swatch_type === 'color' && renderColor(option)}
        {option.visual_swatch_type === 'image' && renderImage(option)}
      </li>
    );
  };

  const renderOptions = () =>
    variation.attributes.map((vr) => renderOption(vr));

  if (isInCart || isInLanding)
    return (
      <Design
        key={variation.label}
        display="flex"
        gap="16px"
        marginTop="16px"
        marginBottom="16px"
        alignItems={'flex-start'}
        flexDirection={'column'}
      >
        <Wording
          fontWeight={'500'}
          fontSize={'16px'}
          color={isInLanding ? 'background' : 'surface'}
        >
          {variationTitlePrefix + ' '}
          {variation.label}:
        </Wording>
        <ul
          className={cn(styles.VisualSwatchWrapper, {
            [styles.IsInLanding]: isInLanding,
          })}
        >
          {renderOptions()}
        </ul>
      </Design>
    );

  return (
    <Design
      key={variation.label}
      display="flex"
      gap="16px"
      marginTop="16px"
      marginBottom="16px"
      alignItems={{
        all: 'center',
        mobile: 'flex-start',
        tablet: 'flex-start',
      }}
      flexDirection={{
        all: 'row',
        mobile: 'column',
        tablet: 'column',
      }}
    >
      <Wording fontWeight={'normal'} fontSize={'14px'}>
        {variationTitlePrefix + ' '}
        {variation.label}
      </Wording>
      <ul className={styles.VisualSwatchWrapper}>{renderOptions()}</ul>
    </Design>
  );
};

export default VisualSwatch;
