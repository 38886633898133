import { contactsActionTypes } from '@actionTypes';
import { ContactDetailModel, ContactStateModel } from '@models';

import { ItemInitialState } from './reducer.constant';

const initialState: ContactStateModel = {
  detail: ItemInitialState({} as ContactDetailModel),
};

function addresses(state = initialState, action) {
  switch (action.type) {
    /**
     * LOAD_ALL_ADDRESSES
     */
    case contactsActionTypes.GET_CONTACTS:
      return {
        ...state,
        detail: {
          fetching: true,
        },
      };
    case contactsActionTypes.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        detail: {
          data: action.data,
          fetching: false,
        },
      };
    case contactsActionTypes.GET_CONTACTS_FAILURE:
      return {
        ...state,
        detail: {
          fetching: false,
        },
      };

    default:
      return state;
  }
}

export default addresses;
