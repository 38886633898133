import React, { FC } from 'react';
import cn from 'clsx';
import { ChevronsRight, ShoppingCart } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { appActions, cartActions } from '@actions';
import { useMediaQuery, usePromise, useStorage } from '@hooks';
import {
  AddressRegionSelected,
  AddToCartButtonProps,
  SeoEventListLocation,
  SeoEventProductModel,
  StockStatusEnum,
} from '@models';
import { triggerAddToCartEvent } from '@utils/seo/events';
import { SpinnerModule } from '@modules';
import { mediaQuery, notification } from '@components';
import { appSelectors } from '@selectors';
import { getRegionToken } from '@helpers';

import styles from './product-add-to-cart.module.scss';

const ProductAddToCart: FC<AddToCartButtonProps> = ({
  location,
  isAllowedToAddToCart,
  product_id,
  min_qty,
  max_qty,
  relatedCartDetails,
  variation_id,
  loading,
  seoItems,
  onClick,
  onSetLoading,
  onHideVariationsSheet,
  stockStatus,
}: AddToCartButtonProps) => {
  // Media Queries
  const isMobile = useMediaQuery(mediaQuery.mobile);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const isDesktop = useMediaQuery(mediaQuery.desktop);

  const promise = usePromise();
  const dispatch = useDispatch();
  const router = useRouter();

  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visibleAreaModal
  );

  const addToCart = async () => {
    if (loading) return;

    onSetLoading(true);

    const addData = {
      product_id: product_id,
      qty: min_qty,
      variation_id: variation_id ?? undefined,
    };

    promise(cartActions.cartAdd(addData))
      .then(async () => {
        await promise(cartActions.loadCartCompact(currentRegion?.area?.id));
        seoEvent();
        dispatch(appActions.togglePopupCart(true));
        setTimeout(() => {
          dispatch(appActions.togglePopupCart(false));
        }, 5000);

        if (isTablet || isMobile) {
          notification({
            type: 'success',
            message: 'Product has been added to your cart.',
            duration: 5,
          });
        }
      })
      .catch(() => {})
      .finally(() => onSetLoading(false));
  };

  const seoEvent = () => {
    const addedProducts: SeoEventProductModel[] = [];
    addedProducts.push({
      brand: seoItems.brand,
      category: seoItems.category,
      discount: seoItems.discount,
      id: seoItems.id,
      list_location: SeoEventListLocation.ITEM_DETAILS,
      name: seoItems.name,
      position: 0,
      price: seoItems.price,
      qty: seoItems.qty,
      variant: seoItems.variant,
    } as SeoEventProductModel);

    triggerAddToCartEvent(addedProducts);
  };

  const dynamicClassNames = cn({
    [styles.Container]: true,
    // [styles.DisabledButton]:
    //   isDesktop && relatedCartDetails && relatedCartDetails?.qty >= max_qty,
    [styles.TopContainer]: location === 'top',
    [styles.BoxContainer]: location === 'box',
    [styles.StickyContainer]: location === 'sticky',
    [styles.ActiveInCart]:
      relatedCartDetails?.id && stockStatus === StockStatusEnum.IN_STOCK,
    [styles.OutOfStock]: stockStatus === StockStatusEnum.OUT_OF_STOCK,
  });

  const handleAddToCart = () => {
    if (stockStatus === StockStatusEnum.OUT_OF_STOCK) return;
    if (relatedCartDetails?.id) return router.push('/cart');
    if (isAllowedToAddToCart) {
      if (!isDesktop && onHideVariationsSheet) onHideVariationsSheet();
      return addToCart();
    }
    return onClick();
  };

  return (
    <div className={dynamicClassNames} onClick={handleAddToCart}>
      {loading && (
        <span className={styles.SpinnerContainer}>
          <SpinnerModule />
        </span>
      )}

      {stockStatus === StockStatusEnum.IN_STOCK && (
        <div>
          <ShoppingCart width={20} height={20} />
          <span>{relatedCartDetails?.id ? 'GO TO CART' : 'ADD TO CART'}</span>
          <ChevronsRight
            width={20}
            height={20}
            className={styles.ChevronIcon}
          />
        </div>
      )}

      {stockStatus === StockStatusEnum.OUT_OF_STOCK && (
        <div>
          <span>Sold Out</span>
        </div>
      )}
    </div>
  );
};

export default ProductAddToCart;
