import { headerLinksActionTypes } from '@actionTypes';
import { HeaderLinksStateModel } from '@models';

import { reducerItemInitialState } from './reducer.constant';

const initialState: HeaderLinksStateModel = {
  all: reducerItemInitialState as any,
};

function headerLinks(state = initialState, action) {
    switch (action.type) {
      /**
       * LOAD_HEADER_LINKS
       */
      case headerLinksActionTypes.LOAD_HEADER_LINKS:
        return {
          ...state,
          all: {
            fetching: true,
          },
        };
      case headerLinksActionTypes.LOAD_HEADER_LINKS_SUCCESS:
        return {
          ...state,
          all: {
            ...action.data,
            fetching: false,
          },
        };
      case headerLinksActionTypes.LOAD_HEADER_LINKS_FAILURE:
        return {
          ...state,
          all: {
            fetching: false,
          },
        };
      default:
        return state;
    }
  }
  

export default headerLinks; 