import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

import { firebaseErrorHandler, getRegionPhonePrefix } from '@helpers';
import { PhoneInput, Button } from '@components';
import { AuthPhoneFormModel } from '@models';

import classes from './auth-phone.module.scss';

type AuthPhoneProps = {
  onGetCode: (phone: string, data: ConfirmationResult) => void;
};

const AuthPhone: FC<AuthPhoneProps> = ({ onGetCode }) => {
  const auth = getAuth();
  const [form] = Form.useForm<AuthPhoneFormModel>();

  const [actionLoading, setActionLoading] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();

  const handleSendCode = async (values: AuthPhoneFormModel) => {
    if (form.getFieldError('phone_number').length > 0) return;
    setActionLoading(true);
    let appVerifier = recaptchaVerifier;

    if (!appVerifier) {
      const verifier = new RecaptchaVerifier(
        'recaptchaForm',
        {
          size: 'invisible',
        },
        auth
      );
      setRecaptchaVerifier(verifier);
      appVerifier = verifier;
    }

    try {
      const mobile_number = `+${values.prefix}${values.phone_number}`;

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        mobile_number,
        appVerifier
      );
      onGetCode(mobile_number, confirmationResult);
    } catch (error) {
      firebaseErrorHandler(error);
    } finally {
      setTimeout(() => {
        setActionLoading(false);
      }, 2000);
    }
  };

  return (
    <Form
      form={form}
      name="login_with_phone"
      autoComplete="off"
      initialValues={{
        prefix: getRegionPhonePrefix(),
      }}
      onFinish={(values) => handleSendCode(values)}
      onFinishFailed={(error) => console.log('error', error)}
    >
      <div id="recaptchaForm" />
      <PhoneInput
        required
        name="phone_number"
        enablePrefixChange={false}
        displayPrefix={getRegionPhonePrefix()}
        label="Phone Number *"
      />

      <Form.Item shouldUpdate>
        {() => (
          <Button
            size="large"
            shape="round"
            type="primary"
            fullWidth
            htmlType="submit"
            id="submitBtn"
            className={classes.ActionButton}
            loading={actionLoading}
          >
            CONTINUE
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default AuthPhone;
