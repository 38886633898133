import React, { useState } from 'react';

import { orderActions } from '@actions';
import { Button, Design, notification, TextInput, Wording } from '@components';
import { usePromise } from '@hooks';
import { Checkbox, Form } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';

import styles from './order-cancelation.module.scss';

type Props = {
  cancelReasons: any;
  onCancel: () => void;
};

const OrderCancelationForm = ({ cancelReasons, onCancel }: Props) => {
  const promise = usePromise();
  const router = useRouter();

  const orderId = router?.query?.orderId as string;

  const [selectedReasons, setSelectedReasons] = useState([]);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);

  const handleChangeReasons = (checkedValues) => {
    setSelectedReasons(checkedValues);
  };

  const handleCancelOrder = ({ comment }: any) => {
    const formData = {
      reasons: selectedReasons,
    };

    if (!!comment) {
      formData['comment'] = comment;
    }

    setCancelLoading(true);

    promise(orderActions.cancel(orderId, formData))
      .then(() => {
        onCancel();
        promise(orderActions.load(orderId));

        notification({
          type: 'success',
          message: 'Success!',
          description: 'Order canceled successfully!',
        });
      })
      .catch((err) => {
        notification({
          type: 'error',
          message: 'Error!',
          description: err.message,
        });
      })
      .finally(() => {
        setCancelLoading(false);
      });
  };

  const disabledSubmit = selectedReasons?.length === 0;

  return (
    <div>
      <Form
        name="orderCancelationForm"
        autoComplete="off"
        //   onValuesChange={handleChangeForm}
        onFinish={handleCancelOrder}
      >
        <Design display="flex" gap="16px" flexDirection="column">
          <Design display="flex" gap="10px" flexWrap="wrap">
            <Checkbox.Group
              className={styles.CheckboxWrapper}
              onChange={handleChangeReasons}
            >
              {cancelReasons?.map((item: any) => (
                <Checkbox
                  key={item?.key}
                  value={item?.key}
                  className={styles.Checkbox}
                >
                  {item?.title}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Design>

          <TextInput
            name="comment"
            label=""
            placeholder="Description (Optional)"
            type="textarea"
          />
        </Design>

        <Design marginTop="32px" marginBottom="8px">
          <Wording>
            Due to law of regulation in the state of Qatar, return and exchange
            is only applicable for certain items.{' '}
            <Link href="/faq">
              <a>see FAQ</a>
            </Link>
          </Wording>
        </Design>

        <Design>
          <Form.Item>
            <Button
              size="large"
              shape="round"
              type="primary"
              color="error"
              fullWidth
              htmlType="submit"
              disabled={disabledSubmit}
              loading={cancelLoading}
            >
              Yes, Cancel this Order
            </Button>
          </Form.Item>
          <Button
            size="large"
            shape="round"
            fullWidth
            className={styles.CancelButton}
            onClick={onCancel}
          >
            No, Keep in way
          </Button>
        </Design>
      </Form>
    </div>
  );
};

export default OrderCancelationForm;
