import { categoriesActionTypes } from '@actionTypes';
import { takeLatest } from 'redux-saga/effects';

import axiosMiddleware from '../../axios.middleware';

export default function* categoriesSaga() {
  yield takeLatest(categoriesActionTypes.LOAD_ALL_CATEGORIES, axiosMiddleware);
  yield takeLatest(categoriesActionTypes.LOAD_CATEGORIES, axiosMiddleware);
  yield takeLatest(categoriesActionTypes.CLEAR_CATEGORIES, axiosMiddleware);
  yield takeLatest(categoriesActionTypes.SET_VIEW_CATEGORIES, axiosMiddleware);
  yield takeLatest(categoriesActionTypes.LOAD_DEAL_CATEGORIES, axiosMiddleware);
  yield takeLatest(categoriesActionTypes.CATEGORIES_VIEW, axiosMiddleware);
}
