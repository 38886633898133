import {
  ListOrder,
  PaginationModel,
  StockStatusEnum,
  UploadedImageModel,
} from '@models';

export interface ProductsListModel {
  filters: ProductsListFilterItem[];
  order: ListOrder;
  pagination: PaginationModel;
  data: ProductItem[];
  facets: ProductsListFacetItems[];
  category_slug: string;
  search_term: string;
  category: ProductsListCategory;
  on_sale: boolean;
  deals: ProductListDealsType;
  deal_slug: string;
}

export type ProductListDealsType = Record<string, ProductListDealModel>;
export interface ProductListDealModel {
  id: number;
  slug: string;
  title: string;
  sub_title: string;
  background_color: string;
  is_active: boolean;
}

export interface ProductsListFilterItem {
  slug: string;
  values: string[];
}

export interface ProductItem {
  id: number;
  title: string;
  sub_title: string;
  sku: string;
  price: number;
  is_new: boolean;
  list_price: number;
  stock_status: StockStatusEnum;
  images: UploadedImageModel[];
  inventory_qty_left: number;
}

export interface ProductsListFacetItems {
  slug: string;
  label: string;
  type: string;
  options: FacetItemOption[];
  minimum: number;
  maximum: number;
  selected_count: number;
  selected_hint: string;
}
export interface FacetItemOption {
  slug: string;
  label: string;
  is_active: boolean;
  products_count: number;
  visual_swatch_type?: string;
  text_swatch_label?: string;
  visual_swatch_color_code?: string;
  visual_swatch_image?: UploadedImageModel;
  children: FacetItemOption[];
}

export interface ProductsListCategory {
  slug: string;
  title: string;
  image: any;
}

export enum DealStatus {
  NONE = 'NONE',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  SOON = 'SOON',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

export interface ProductDealModel {
  deal_is_active: boolean;
  deal_start_time?: Date;
  deal_end_time?: Date;
  deal_price?: number;
  deal_qty: number;
}

export interface CarouselProductsListModel extends ProductItem {
  available_in?: any; // whats is type ?
  slug: string;
  discount_percentage?: number;
  deal_status: DealStatus;
  product_deal: ProductDealModel;
  deal_slugs: string[];
  discount_amount: number;
  min_qty_in_shopping_cart: number;
  max_qty_in_shopping_cart: number;
  inventory_qty?: number;
  product_type?: any;
}
