export enum AuthStateEnum {
  LOGIN_OVERVIEW = 'login-overview',
  LOGIN_EMAIL = 'login-email',
  FORGET_PASSWORD = 'forget-password',
  VERIFY_PHONE = 'verify-phone',
  INFORMATION = 'information',
  WELCOME = 'welcome',
}

export interface AuthPhoneFormModel {
  phone_number: string;
  prefix: string;
}
