import { useEffect, useState } from 'react';

const useLiveTimer = (isEnable: boolean, endDate: Date | undefined) => {
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [hideTimer, setHideTimer] = useState<boolean>(true);

  useEffect(() => {
    if (!isEnable || typeof endDate === 'undefined') return;

    let date = Math.abs(+(new Date().getTime() / 1000).toFixed(0));
    let date2 = Math.abs(+(new Date(endDate).getTime() / 1000).toFixed(0));

    let diff = date2 - date;

    let days = Math.floor(diff / 86400);
    let hours = Math.floor(diff / 3600);
    let minutes = Math.floor(diff / 60) % 60;
    let seconds = diff % 60;

    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);

    const timerInterval = setInterval(() => {
      const deal_ending_at = new Date(endDate);

      let date = Math.abs(+(new Date().getTime() / 1000).toFixed(0));
      let date2 = Math.abs(
        +(new Date(deal_ending_at).getTime() / 1000).toFixed(0)
      );

      let diff = date2 - date;

      let days = Math.floor(diff / 86400);
      let hours = Math.floor(diff / 3600);
      let minutes = Math.floor(diff / 60) % 60;
      let seconds = diff % 60;

      if (days < 0 && hours < 0 && minutes < 0 && seconds < 0) {
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);

        if (typeof timerInterval !== 'undefined') {
          clearInterval(timerInterval);
        }

        return;
      }
      setHideTimer(false);
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  return {
    days,
    hours,
    minutes,
    seconds,
    hideTimer,
  };
};

export default useLiveTimer;
