import { checkoutActionTypes } from '@actionTypes';
import { takeEvery } from 'redux-saga/effects';

import axiosMiddleware from '../../axios.middleware';

export default function* checkoutSaga() {
  yield takeEvery(checkoutActionTypes.LOAD_CHECKOUT, axiosMiddleware);
  yield takeEvery(checkoutActionTypes.LOAD_PAYMENT_METHODS, axiosMiddleware);
  yield takeEvery(
    checkoutActionTypes.CHECKOUT_VALIDATE_PROMOTION,
    axiosMiddleware
  );
}
