import React, { useState, FC } from 'react';
import ds from '@ds';
import { ChevronRight, Gift, Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { checkoutActions } from '@actions';
import { Design, mediaQuery, notification, Wording } from '@components';
import { useMediaQuery, usePromise } from '@hooks';
import { PaymentMethodEnum } from '@models';
import { cartSelectors, checkoutSelectors } from '@selectors';
import { triggerAddPromoCodeEvent } from '@utils/seo/events';
import { PromotionCodeInput, SpinnerModule } from '@modules';

const PromotionCodeModule: FC = () => {
  const promise = usePromise();

  const { data: checkoutDetails } = useSelector(
    checkoutSelectors.selectDetails
  );
  const { data: cartDetails } = useSelector(cartSelectors.details);
  const checkoutParams = useSelector(checkoutSelectors.selectParams);
  const isDesktop = useMediaQuery(mediaQuery.desktop);

  const [actionLoading, setActionLoading] = useState(false);
  const [promotionCode, setPromotionCode] = useState(
    checkoutDetails?.promotion?.coupon_code ?? ''
  );
  const [validateError, setValidateError] = useState('');
  const [visibleBottomSheet, setVisibleBottomSheet] = useState(false);

  const handleApplyPromotionCode = () => {
    if (!promotionCode || !checkoutDetails) return;
    if (!cartDetails || cartDetails.items.length === 0) return;
    setActionLoading(true);

    promise(
      checkoutActions.validatePromotion({
        coupon_code: promotionCode,
        billing_address_id: checkoutDetails?.shipping_address?.id ?? 0,
        shipping_address_id: checkoutDetails?.shipping_address?.id ?? 0,
        items: cartDetails?.items.map((item) => ({
          product_id: item.product_id,
          qty: item.qty,
          variation_id: item.variation_id,
        })),
        payment_method:
          checkoutDetails?.payment_method.slug ?? PaymentMethodEnum.COD,
      })
    )
      .then(() => {
        if (!isDesktop) {
          notification({
            type: 'success',
            message: 'Promotion Code has been applied.',
          });
          setVisibleBottomSheet(false);
        }
        promise(
          checkoutActions.updateCheckoutParams({ coupon_code: promotionCode })
        );
        promise(
          checkoutActions.getCheckout({
            ...checkoutParams,
            coupon_code: promotionCode,
          })
        ).then(() => setActionLoading(false));

        // Seo Event : Apply Promotion Code
        triggerAddPromoCodeEvent('success');
      })
      .catch((error: any) => {
        triggerAddPromoCodeEvent('fail');
        setValidateError(error?.message ?? '');
        setActionLoading(false);
      });
  };

  const handleRemovePromotionCode = () => {
    setActionLoading(true);

    promise(
      checkoutActions.getCheckout({
        ...checkoutParams,
        coupon_code: '',
      })
    )
      .then(() => {
        promise(
          checkoutActions.updateCheckoutParams({
            coupon_code: '',
          })
        );
        promise(
          checkoutActions.getCheckout({
            ...checkoutParams,
            coupon_code: '',
          })
        ).then(() => setActionLoading(false));
        setPromotionCode('');
      })
      .catch(() => () => setActionLoading(false));
  };

  const renderInlineViewSection = () => {
    if (checkoutDetails?.promotion?.coupon_code)
      return (
        <Design
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="natural"
          paddingBottom="16px"
        >
          <Wording fontSize="16px" fontWeight="500" lineHeight="22px">
            Promotion Code: {checkoutDetails?.promotion?.coupon_code}
          </Wording>

          <Design
            width="24px"
            height="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={handleRemovePromotionCode}
            className="has-pointer"
          >
            {actionLoading ? (
              <SpinnerModule />
            ) : (
              <Trash2 width="18px" height="18px" color={ds.color_error} />
            )}
          </Design>
        </Design>
      );

    return (
      <Design
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="natural"
        paddingBottom="16px"
        onClick={() => setVisibleBottomSheet(true)}
        className="has-pointer"
      >
        <Design display="flex" alignItems="center" justifyContent="flex-start">
          <Design
            width="24px"
            height="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Gift width="18px" height="18px" color={ds.color_link} />
          </Design>
          <Wording
            marginLeft="8px"
            color="link"
            fontWeight="500"
            fontSize="16px"
            lineHeight="22px"
          >
            Add Your Promotion Code
          </Wording>
        </Design>
        <Design
          width="24px"
          height="24px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ChevronRight width="18px" height="18px" color={ds.color_link} />
        </Design>
      </Design>
    );
  };

  // Inline show promotion section
  if (!isDesktop)
    return (
      <>
        {renderInlineViewSection()}

        <BottomSheet
          open={visibleBottomSheet}
          onDismiss={() => setVisibleBottomSheet(false)}
        >
          <PromotionCodeInput
            actionLoading={actionLoading}
            onApply={handleApplyPromotionCode}
            onRemove={handleRemovePromotionCode}
            onSetCode={setPromotionCode}
            appliedCode={checkoutDetails?.promotion?.coupon_code}
            promotionCode={promotionCode}
            error={validateError}
          />
        </BottomSheet>
      </>
    );

  // Desktop Promotion View
  return (
    <div>
      <PromotionCodeInput
        actionLoading={actionLoading}
        onApply={handleApplyPromotionCode}
        onRemove={handleRemovePromotionCode}
        onSetCode={setPromotionCode}
        appliedCode={checkoutDetails?.promotion?.coupon_code}
        promotionCode={promotionCode}
        error={validateError}
      />
    </div>
  );
};

export default PromotionCodeModule;
