import React from 'react';

import { Design, mediaQuery, Wording } from '@components';
import { useMediaQuery } from '@hooks';
import { Progress } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import Link from 'next/link';

import styles from './order-card.module.scss';

type Props = { data: any };

const OrderCard = ({ data }: Props) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const handleProgress = () => {
    switch (data?.status) {
      case 'pending':
        return 8;

      case 'processing':
        return 40;

      case 'partially_shipped':
      case 'shipped':
        return 68;

      case 'delivered':
      case 'completed':
        return 100;

      default:
        return 'pending';
    }
  };

  return (
    <Design className={styles.OrderCard}>
      <Design
        display="flex"
        justifyContent="space-between"
        className={styles.CardHeader}
      >
        <Design
          className={styles.CardHeaderItem}
          display="flex"
          flexDirection="column"
        >
          <Wording color="caption">Order date</Wording>
          <Wording color="surface">
            {moment(data?.created_at).format('DD MMM, YYYY')}
          </Wording>
        </Design>

        {!isMobile && (
          <>
            <Design display="flex" flexDirection="column">
              <Wording color="caption">Order number</Wording>
              <Wording color="surface">{data?.id}</Wording>
            </Design>

            <Design display="flex" flexDirection="column">
              <Wording color="caption">Receiver</Wording>
              <Wording color="surface">
                {data?.shipping_address?.full_name}
              </Wording>
            </Design>
          </>
        )}

        <Design
          className={styles.CardHeaderItem}
          display="flex"
          flexDirection="column"
        >
          <Wording color="caption">Amount</Wording>
          <Wording color="surface">{data?.total}</Wording>
        </Design>

        {data?.status !== 'canceled' && isMobile && (
          <Design
            className={styles.CardHeaderItem}
            display="flex"
            flexDirection="column"
          >
            <Wording color="caption">Delivery Time Estimation</Wording>
            <Wording color="surface">{data?.estimated_shipping}</Wording>
          </Design>
        )}

        {(data?.status === 'delivered' || data?.status === 'completed') &&
          isMobile && (
            <Design
              className={styles.CardHeaderItem}
              display="flex"
              flexDirection="column"
            >
              <Wording color="caption">Delivered on</Wording>
              <Wording color="surface">{data?.estimated_shipping}</Wording>
            </Design>
          )}

        {!isMobile && (
          <Design className={styles.OrderDetailButton}>
            <Link href={`/profile/orders/${data?.id}`}>Order Detail</Link>
          </Design>
        )}
      </Design>

      {/* {device === 'mobile' && type !== 'return' && (
          <Col display_on_mobile={true}>
            <Text color="gray600">Delivery Time Estimation</Text>
            <Text>{data?.estimated_shipping}</Text>
          </Col>
        )} */}

      <Design
        display="flex"
        padding="16px"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {data?.status === 'canceled' ? (
          <div className={styles.CanceledTag}>Canceled</div>
        ) : (
          !isMobile && (
            <div className={styles.ProgressContainer}>
              <div className={styles.ProgressSteps}>
                <div className={styles.StepsWrapper}>
                  <Wording
                    fontSize={{
                      all: 'inherit',
                      tablet: '12px',
                    }}
                    color={data?.status === 'pending' ? 'link' : 'action'}
                  >
                    Order Placed
                  </Wording>
                  <Wording
                    fontSize={{
                      all: 'inherit',
                      tablet: '12px',
                    }}
                    color={data?.status === 'processing' ? 'link' : 'action'}
                  >
                    In Progress
                  </Wording>
                  <Wording
                    fontSize={{
                      all: 'inherit',
                      tablet: '12px',
                    }}
                    color={
                      data?.status === 'shipped' ||
                      data?.status === 'partially_shipped'
                        ? 'link'
                        : 'action'
                    }
                  >
                    Shipped
                  </Wording>
                  <Wording
                    fontSize={{
                      all: 'inherit',
                      tablet: '12px',
                    }}
                    color={data?.status === 'delivered' ? 'link' : 'action'}
                  >
                    Delivered
                  </Wording>
                </div>

                <Progress
                  // @ts-ignore
                  percent={handleProgress()}
                  strokeColor="#39D98A"
                  showInfo={false}
                />
              </div>

              <div className={styles.DeliveryEstimation}>
                <Wording color="action">Delivery Time Estimation</Wording>
                <Wording>{data?.estimated_shipping}</Wording>
              </div>
            </div>
          )
        )}

        <div className={styles.ProductContainer}>
          {data?.items.map((item, index) => (
            <div className={styles.ProductItem} key={index}>
              <img alt="" src={get(item, 'image.urls.original', '')} />
            </div>
          ))}
        </div>

        {isMobile && (
          <Design className={styles.OrderDetailButton}>
            <Link href={`/profile/orders/${data?.id}`}>Order Detail</Link>
          </Design>
        )}
      </Design>
    </Design>
  );
};

export default OrderCard;
