import React, { FC } from 'react';

import ds from '@ds';
import { Share2 } from 'react-feather';

import styles from './social-share.module.scss';

interface Props {
  productId: number;
  shareDescription: string;
}

const SocialShareComponent: FC<Props> = ({ productId, shareDescription }) => {
  const handleClickAction = () => {
    if (navigator.share) {
      navigator.share({
        title: shareDescription,
        url: 'https://1sell.com/p/' + productId,
      });
    }
  };

  return (
    <>
      <div className={styles.Container} onClick={handleClickAction}>
        <Share2
          width={20}
          height={20}
          fill={'transparent'}
          color={ds.color_surface}
        />
      </div>
    </>
  );
};

export default SocialShareComponent;
