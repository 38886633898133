import { StoreModel, WishlistStateModel } from '@models';
import { createSelector } from 'reselect';

const selectWishlist = (state: StoreModel) => state.wishlist;

export const selectAll = createSelector(
  [selectWishlist],
  (wishlist: WishlistStateModel) => wishlist?.all
);

export const items = createSelector(
  [selectWishlist],
  (wishlist: WishlistStateModel) => wishlist?.items
);

export const compact = createSelector(
  [selectWishlist],
  (wishlist: WishlistStateModel) => wishlist?.compact
);

export const detail = createSelector(
  [selectWishlist],
  (wishlist: WishlistStateModel) => wishlist?.detail
);
