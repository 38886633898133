export const LOAD_ALL_PRODUCTS = 'LOAD_ALL_PRODUCTS';
export const LOAD_ALL_PRODUCTS_REQUESTING = 'LOAD_ALL_PRODUCTS_REQUESTING';
export const LOAD_ALL_PRODUCTS_SUCCESS = 'LOAD_ALL_PRODUCTS_SUCCESS';
export const LOAD_ALL_PRODUCTS_FAILURE = 'LOAD_ALL_PRODUCTS_FAILURE';

export const LOAD_BEST_SELLERS = 'LOAD_BEST_SELLERS';
export const LOAD_DEAL_CATEGORIES = 'LOAD_DEAL_CATEGORIES';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';

export const PRODUCT_VIEW = 'PRODUCT_VIEW';

export const GET_SIMILAR_ITEMS = 'GET_SIMILAR_ITEMS';
export const GET_SIMILAR_ITEMS_SUCCESS = 'GET_SIMILAR_ITEMS_SUCCESS';
export const GET_SIMILAR_ITEMS_FAILURE = 'GET_SIMILAR_ITEMS_FAILURE';

export const GET_RECOMMENDED_FOR_YOU = 'GET_RECOMMENDED_FOR_YOU';
export const GET_RECOMMENDED_FOR_YOU_SUCCESS =
  'GET_RECOMMENDED_FOR_YOU_SUCCESS';
export const GET_RECOMMENDED_FOR_YOU_FAILURE =
  'GET_RECOMMENDED_FOR_YOU_FAILURE';

export const GET_FREQUENTLY_BOUGHT = 'GET_FREQUENTLY_BOUGHT';
export const GET_FREQUENTLY_BOUGHT_SUCCESS = 'GET_FREQUENTLY_BOUGHT_SUCCESS';
export const GET_FREQUENTLY_BOUGHT_FAILURE = 'GET_FREQUENTLY_BOUGHT_FAILURE';
