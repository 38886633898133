import React, { useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { CountryCallingCode } from 'libphonenumber-js';

import { getRegionPhonePrefix, isRegionPhoneValid } from '@helpers';

import styles from './form-fields.module.scss';
import InputsWrapper from './inputs-wrapper';

const { Option } = Select;

type Props = {
  name: string;
  label: string;
  value?: string;
  error?: string[];
  disabled?: boolean;
  required?: boolean;
  withFormItem?: boolean;
  enablePrefixChange: boolean;
  displayPrefix: CountryCallingCode;
};

const PhoneInput = ({
  name,
  label,
  value = '',
  error,
  displayPrefix,
  disabled = false,
  required = false,
  withFormItem = true,
  enablePrefixChange = false,
}: Props) => {
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [prefixNumber, setPrefixNumber] = useState<string>(
    getRegionPhonePrefix()
  );

  useEffect(() => {
    if (value && value.length > 0) setPrefixNumber(displayPrefix);
  }, [value]);

  const prefixSelector = () => (
    <Form.Item name="prefix" noStyle shouldUpdate>
      <Select
        value={prefixNumber}
        defaultValue={prefixNumber}
        disabled={!enablePrefixChange}
        showArrow={false}
        onChange={(value) => {
          setPrefixNumber(value);
        }}
      >
        <Option key="974" value="974">
          +974
        </Option>
        <Option key="971" value="971">
          +971
        </Option>
      </Select>
    </Form.Item>
  );

  const emptyPrefix = () => (
    <Form.Item name="prefix" shouldUpdate noStyle>
      <div className={styles.PrefixContainer}>
        <div>+</div>
        <Input value={prefixNumber} disabled />
      </div>
    </Form.Item>
  );

  const validateNumber = (value: string) => {
    if (!value) return setInvalidPhone(false);
    const phoneNumber = `+${prefixNumber}${value}`;
    if (isRegionPhoneValid(phoneNumber)) return setInvalidPhone(false);
    setInvalidPhone(true);
  };

  return (
    <div className={styles.PhoneInputStyles}>
      <InputsWrapper
        name={name}
        label={label}
        value={value}
        error={error}
        withFormItem={withFormItem}
        rules={[
          {
            required: true,
            message: 'Please enter phone number',
          },
          () => ({
            validator(_, value) {
              const phoneNumber = `+${prefixNumber}${value}`;
              if (!value || isRegionPhoneValid(phoneNumber)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Please enter a valid phone number')
              );
            },
          }),
        ]}
      >
        <Form.Item
          name={name}
          shouldUpdate
          validateStatus={invalidPhone ? 'error' : undefined}
        >
          <Input
            disabled={disabled}
            addonBefore={enablePrefixChange ? prefixSelector() : emptyPrefix()}
            onFocus={() => setInvalidPhone(false)}
            onBlur={(e) => validateNumber(e.target.value)}
            maxLength={
              process?.env?.NEXT_PUBLIC_PHONE_LENGTH
                ? +process.env.NEXT_PUBLIC_PHONE_LENGTH
                : 8
            }
            type="tel"
            required={required}
          />
        </Form.Item>
      </InputsWrapper>
    </div>
  );
};

export default PhoneInput;
