import React, { FC } from 'react';

import { Image, mediaQuery } from '@components';
import { isExternalUrl } from '@helpers';
import { useMediaQuery } from '@hooks';
import { HeroBannersProps, HeroBannerStructure } from '@models';
import Link from 'next/link';

import styles from './hero-banners.module.scss';

const HeroBannersModule: FC<HeroBannersProps> = ({
  data,
}: HeroBannersProps) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const renderImages = () => {
    return data?.map((item: HeroBannerStructure, index: number) => {
      let imgUrl = item.desktop_image.urls.original;
      if (isMobile) item.mobile_image.urls[165];

      const isExternalLink = isExternalUrl(item.url);

      return (
        <div className={styles.HeroImages} key={`${index}_${item.title}`}>
          {isExternalLink && (
            <a
              href={item.url}
              title={item.title}
              target="_self"
              rel="noreferrer"
            >
              <Image
                src={imgUrl}
                alt={item.title}
                layout="fill"
                showShimmerAnimation={false}
                // blurDataURL={item.desktop_image.urls.blurImageUrl}
              />
            </a>
          )}

          {!isExternalLink && (
            <Link href={item.url} prefetch={false}>
              <a target="_self" rel="noreferrer">
                <Image
                  src={imgUrl}
                  alt={item.title}
                  layout="fill"
                  showShimmerAnimation={false}
                  // blurDataURL={item.desktop_image.urls.blurImageUrl}
                />
              </a>
            </Link>
          )}
        </div>
      );
    });
  };

  return <>{renderImages()}</>;
};

export default HeroBannersModule;
