import axios from 'axios';
import { getAuth } from 'firebase/auth';
import * as Qs from 'qs';
import Cookies from 'universal-cookie';

import logger from './logger.helper';

const cookies = new Cookies();
const CancelToken = axios.CancelToken;
export let cancel;

export const Axios = axios.create({
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
  cancelToken: new CancelToken(function executor(c) {
    cancel = c;
  }),
});

const axiosHelper = async (params: any) => {
  let authorization = cookies.get('oneSellToken') ?? params.token;

  try {
    if (!authorization) {
      authorization = await getAuth()?.currentUser?.getIdToken(true);
    }
  } catch (_) {
    // nth
  }

  const data = {
    ...params,
    proxy: false,
    headers: {
      ...params.headers,
      accept: 'application/json',
      Authorization: `Bearer ${authorization}`,
      'App-Platform': 'web',
    },
    baseURL: process.env.NEXT_PUBLIC_API_URL,
  };

  Axios.interceptors.request.use(
    function (request) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return request;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log('REQUEST ERROR', error);
      try {
        logger.crit('Request Error: ' + error);
      } catch (_) {}

      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      try {
        logger.crit('Response Error: ' + error);
      } catch (_) {}

      return Promise.reject(error);
    }
  );

  return Axios(data);
};

export default axiosHelper;
