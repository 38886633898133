import { FC } from 'react';

import { mediaQuery } from '@components';
import { useMediaQuery } from '@hooks';
import { BrandValuesProps } from '@models';
import cn from 'clsx';
import Image from 'next/image';
import OneSellLogo from 'public/icons/onesell-logo-dark.svg';

import {
  HomeBrandValues, HomeBrandValuesMobile, ProductDetailsBrandValues,
  ProductDetailsBrandValuesMobile, StaticPagesBrandValues,
  StaticPagesValuesMobile, StaticPagesValuesTablet,
} from './brand-values.list';
import styles from './brand-values.module.scss';

const BrandValuesModule: FC<BrandValuesProps> = ({
  page,
}: BrandValuesProps) => {
  const isDesktop = useMediaQuery(mediaQuery.desktop);
  const isTablet = useMediaQuery(mediaQuery.tablet);

  const renderServices = () => {
    let services: { key: string; image: string; title: string }[] = HomeBrandValues;

    switch (page) {
      case 'home':
        if (isDesktop || isTablet) {
          services = HomeBrandValues;
        } else {
          services = HomeBrandValuesMobile;
        }
        break;

      case 'product_details':
        if (isDesktop || isTablet) {
          services = ProductDetailsBrandValues;
        } else {
          services = ProductDetailsBrandValuesMobile;
        }
        break;

      case 'static_pages':
        if (isDesktop) {
          services = StaticPagesBrandValues;
        } else if (isTablet) {
          services = StaticPagesValuesTablet;
        } else {
          services = StaticPagesValuesMobile;
        }
        break;
    }

    return services.map((service) => {
      return (
        <div className={styles.ServiceContainer} key={service.key}>
          <Image
            width={40}
            height={40}
            src={service.image}
            alt={service.title}
          />
          <p>{service.title}</p>
        </div>
      );
    });
  };

  return (
    <div
      className={`${styles.ServicesContainer} ${
        page === 'product_details' ? styles.InDetail : null
      }`}
    >
      {page !== 'static_pages' && (
        <div>
          <p>why</p>
          <div className={styles.Logo}>
            <OneSellLogo alt="Our Services" />
          </div>
        </div>
      )}

      <div
        className={cn(styles.ListContainer, {
          [styles.StaticPageContainer]: page === 'static_pages',
        })}
      >
        {renderServices()}
      </div>
    </div>
  );
};

export default BrandValuesModule;
