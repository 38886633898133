import React, { FC, useState } from 'react';

import {
  LandingCategoryModel,
  AppereanceColorsModel,
  CampaignType,
  EventProductModel,
} from '@models';
import { Image } from '@components';

import LandingProducts from '../landing-products/landing-products';

import styles from './landing-categories.module.scss';

interface Props {
  cartItems: number[];
  campaignType: CampaignType;
  categories: LandingCategoryModel[];
  colors: AppereanceColorsModel;
  toggleVariationModal: (product: EventProductModel) => void;
}

const LandingCategories: FC<Props> = ({
  cartItems,
  campaignType,
  categories,
  colors,
  toggleVariationModal,
}: Props) => {
  const [selected, setSelected] = useState<number>(0);

  const handleSelectCategory = (index: number) => {
    setSelected(index);
  };

  const renderCategory = (cat: LandingCategoryModel, index: number) => (
    <li
      key={cat.slug}
      className={`${index === selected && 'active'}`}
      style={{
        background: colors.link_color,
        border: '2px solid',
        borderColor:
          index === selected ? colors.selected_color : colors.link_color,
      }}
      onClick={() => handleSelectCategory(index)}
    >
      <div className={styles.ItemImage}>
        {!!cat?.image?.urls && (
          <Image
            src={cat?.image?.urls[256]!}
            width={70}
            height={70}
            isDark
            alt={cat.title}
          />
        )}
      </div>
      <div
        className={styles.ItemTitle}
        style={{
          color: index === selected ? colors.selected_color : colors.text_color,
        }}
      >
        {cat.title}
      </div>
      <div
        className={styles.ItemDiscount}
        style={{
          color: index === selected ? colors.selected_color : colors.text_color,
        }}
        dangerouslySetInnerHTML={{
          __html: cat.discount,
        }}
      />
    </li>
  );

  const renderCategories = () =>
    categories.map((c, index) => renderCategory(c, index));

  return (
    <div className={styles.Wrapper}>
      <div className={styles.List}>{renderCategories()}</div>

      <div className={styles.Description}>{categories[selected]?.summary}</div>

      <LandingProducts
        cartItems={cartItems}
        colors={colors}
        toggleVariationModal={toggleVariationModal}
        moreLink={categories[selected]?.meta?.action!}
        products={categories[selected]?.products}
        campaignType={campaignType}
      />
    </div>
  );
};

export default LandingCategories;
