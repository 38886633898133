import { FC, useEffect, useState } from 'react';

import { CategoryModel, MegaMenuProps } from '@models';
import { categoriesSelectors } from '@selectors';
import Image from 'next/image';
import Link from 'next/link';
import { ChevronRight } from 'react-feather';
import { useSelector } from 'react-redux';

import styles from './mega-menu.module.scss';

const MegaMenuModule: FC<MegaMenuProps> = ({
  isOnStickyNavbar,
}: MegaMenuProps) => {
  const { data: menuData } = useSelector(categoriesSelectors.selectAll);
  const [lazyChilds, setLazyChilds] = useState(true);

  useEffect(() => {
    setLazyChilds(false);
  }, []);

  const renderChildrenRecursively = (children: CategoryModel[]) => (
    <ul>
      {children.map((child) => {
        if (!child) return null;

        return (
          <li key={child.title}>
            <Link href={`/category/${child.slug}/products`} prefetch={false}>
              <a>
                <span>{child.title}</span>

                {child.children && child.children.length > 0 && (
                  <div className="sub-menu">
                    {renderChildrenRecursively(child.children)}
                  </div>
                )}
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );

  const renderMenu = () => {
    return (
      <ul>
        {menuData?.map((child) => (
          <li key={child.title}>
            <Link href={`/category/${child.slug}/products`} prefetch={false}>
              <a>
                <Image
                  src={
                    child.icon !== null
                      ? child.icon.urls.original
                      : '/assets/icons/shared/HealthHousehold.png'
                  }
                  alt={child.slug}
                  width={24}
                  height={24}
                />
                <span>{child.title}</span>
                {child.children && child.children.length > 0 && !lazyChilds && (
                  <div className="sub-menu">
                    <p>
                      <Link href={`/category/${child.slug}/products`} prefetch={false}>
                        <a>
                          All items in this category
                          <ChevronRight width={16} height={16} />
                        </a>
                      </Link>
                    </p>
                    {renderChildrenRecursively(child.children)}
                  </div>
                )}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      className={`${styles.Container} ${isOnStickyNavbar ? styles.Sticky : ''}`}
      id="MegaMenu"
    >
      {!isOnStickyNavbar && <h5>Explore Categories</h5>}
      {renderMenu()}
    </div>
  );
};

export default MegaMenuModule;
