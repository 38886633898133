import React, { FC } from 'react';

import { Design } from '@components';

import styles from '../profile-simmer.module.scss';

const PersonalInfoShimmer: FC = () => {
  return (
    <Design
      display="flex"
      backgroundColor="background"
      borderRadius="8px"
      flexDirection="column"
      justifyContent="space-between"
      padding="48px 16px"
      minHeight="386px"
    >
      <Design
        display="flex"
        flexDirection="column"
        gap={{ all: '42px', mobile: '16px' }}
      >
        <Design
          display="flex"
          gap="16px"
          flexDirection={{ all: 'row', mobile: 'column' }}
        >
          <Design
            className={styles.ShimmerItem}
            width="100%"
            height="48px"
            borderRadius="8px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="100%"
            height="48px"
            borderRadius="8px"
          ></Design>
        </Design>
        <Design
          display="flex"
          gap="16px"
          flexDirection={{ all: 'row', mobile: 'column' }}
        >
          <Design
            className={styles.ShimmerItem}
            width="100%"
            height="48px"
            borderRadius="8px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="100%"
            height="48px"
            borderRadius="8px"
          ></Design>
        </Design>
      </Design>

      <Design
        className={styles.ShimmerItem}
        width="157px"
        height="48px"
        borderRadius="40px"
        margin="16px 0 0 0"
      ></Design>
    </Design>
  );
};

export default PersonalInfoShimmer;
