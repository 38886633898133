import React, { FC, useRef } from 'react';

import { ExpandableContainer } from '@components';
import {
  ProductAttributesModel, ProductSpecificationsTabProps,
  ProductVariationAttributeTypeEnum,
} from '@models';
import dayjs from 'dayjs';

import ProductTabTitle from '../tab-title/tab-title';
import styles from './specifications-tab.module.scss';

const ProductSpecificationsTab: FC<ProductSpecificationsTabProps> = ({
  specifications,
}) => {
  const SpecificationContainer = useRef<HTMLDivElement>(null);

  const renderOptions = (attr: ProductAttributesModel) => {
    switch (attr.input_type) {
      case ProductVariationAttributeTypeEnum.BOOLEAN:
        return <>{attr.boolean_value ? 'Yes' : 'No'}</>;

      case ProductVariationAttributeTypeEnum.DATE:
        return <>{dayjs(attr.date_value).format('MMMM DD,YYYY')}</>;

      case ProductVariationAttributeTypeEnum.SELECT:
      case ProductVariationAttributeTypeEnum.TEXT_SWATCH:
      case ProductVariationAttributeTypeEnum.VISUAL_SWATCH:
        return <>{attr.options.toString()}</>;

      case ProductVariationAttributeTypeEnum.TEXT_FIELD:
      case ProductVariationAttributeTypeEnum.TEXT_AREA:
        return <>{attr.text_value}</>;
    }
  };

  const renderAttributes = (attributes: ProductAttributesModel[]) =>
    attributes.map((attr) => (
      <div className={styles.OptionContainer} key={attr.id}>
        <div className={styles.OptionTitle}>{attr.label}</div>
        <div className={styles.OptionValue}>{renderOptions(attr)}</div>
      </div>
    ));

  const renderSpecifications = () =>
    specifications.map((spec) => (
      <div className={styles.GroupContainer} key={spec.attribute_set_group_title}>
        {specifications.length > 1 && <div className={styles.GroupLabel}>{spec.attribute_set_group_title}</div>}
        <div>
          {renderAttributes(spec.attributes)}
        </div>
      </div>
    ));

  return (
    <>
      <ProductTabTitle title="Specifications" />

      <ExpandableContainer className={styles.OptionContainer}
        shouldBeExpandable={specifications?.length > 1}
        expandButtonText={'Show full specification'}
      >
        <div ref={SpecificationContainer}>{renderSpecifications()}</div>
      </ExpandableContainer>
    </>
  );
};

export default ProductSpecificationsTab;
