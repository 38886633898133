import {
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import localforage from 'localforage';

import initializeApp from './initializeApp';

const app = initializeApp();

const firebaseCloudMessaging = {
  notificationPermission: (): NotificationPermission => {
    if (!('Notification' in window)) return 'denied';
    return Notification.permission;
  },
  requestPermission: async () => {
    await Notification.requestPermission();
  },
  tokenInlocalforage: async () => {
    const token = (await localforage.getItem('fcm_token')) as string;
    return token;
  },
  resetToken: async function () {
    const messaging = getMessaging(app);
    await deleteToken(messaging).then(() =>
      localforage.removeItem('fcm_token')
    );
    await this.init();
  },
  removeToken: () => {
    localforage.removeItem('fcm_token');
  },
  onMessage: () => {
    if (!('Notification' in window)) return;
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      const dataPayload = payload as any;
      // const orderId = dataPayload['data'].web_deep_link.split('/order/')[1];
      const greeting = new Notification(payload.notification?.title ?? '', {
        body: payload.notification?.body ?? '',
        icon: '/images/fcm-icon.png',
      });
      greeting.addEventListener('click', function () {
        window.open(dataPayload['data'].web_deep_link ?? '/');
      });
      // greeting.addEventListener('show', function () {
      //   console.log('SHOWED');
      // });
      // greeting.onshow = () => {
      //   console.log('SHOW');
      // };
      // greeting.onclose = () => {
      //   console.log('CLOSE');
      // };
    });
  },

  init: async function () {
    const browserSupported = await isSupported();
    try {
      if (
        (await this.tokenInlocalforage()) !== null ||
        !browserSupported ||
        this.notificationPermission() !== 'granted'
      )
        return false;

      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            // Save the token database
            localforage.setItem('fcm_token', currentToken);
          } else {
            // Show permission request UI
            console.log(
              'NOTIFICATION, No registration token available. Request permission to generate one.'
            );
            // ...
          }
        })
        .catch((err) => {
          console.log(
            'NOTIFICATION, An error occurred while retrieving token. '
          );
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  },
};

export default firebaseCloudMessaging;
