import { checkoutActionTypes } from '@actionTypes';
import {
  LoadCheckoutParamsModel,
  ValidatePromotionRequestModel,
} from '@models';

export function getCheckout(params?: Partial<LoadCheckoutParamsModel>) {
  return {
    type: checkoutActionTypes.LOAD_CHECKOUT,
    method: 'get',
    url: '/checkout',
    params,
  };
}

export function getPaymentMethods(token?: string) {
  return {
    type: checkoutActionTypes.LOAD_PAYMENT_METHODS,
    method: 'get',
    url: '/checkout/payment-methods',
    token,
  };
}

export function validatePromotion(data: ValidatePromotionRequestModel) {
  return {
    type: checkoutActionTypes.CHECKOUT_VALIDATE_PROMOTION,
    method: 'post',
    url: '/checkout/validate-promotion',
    data,
  };
}

export function updateCheckoutParams(data?: Partial<LoadCheckoutParamsModel>) {
  return {
    type: checkoutActionTypes.UPDATE_CHECKOUT_PARAMS,
    data,
  };
}

export function updateCheckoutNote(data: string) {
  return {
    type: checkoutActionTypes.UPDATE_CHECKOUT_NOTE,
    data,
  };
}
