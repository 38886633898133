import React, { FC, useMemo } from 'react';

import {
  Design,
  Image,
  ProductAddToCart,
  ProductPricing,
  Wording,
} from '@components';
import { ProductOrderBoxProps, StockStatusEnum } from '@models';
import Paragraph from 'antd/lib/typography/Paragraph';

import { ProductDiscountBadge, ProductQuantityControl } from '../';
import styles from './product-order-box.module.scss';

const ProductOrderBox: FC<ProductOrderBoxProps> = ({
  title,
  image,
  price,
  discountedPrice,
  discountAmount,
  isAllowedToAddToCart,
  min_qty,
  max_qty,
  onAddToCart,
  product_id,
  variation_id,
  loading,
  seoItems,
  onSetLoading,
  relatedCartDetails,
  stockStatus,
  id,
  inventoryQty,
  productDeal,
  activeDeal,
}) => {
  return (
    <div>
      <Design display="flex" gap="8px" className={styles.Section}>
        <Design minWidth="56px">
          <Image
            src={image}
            width={56}
            height={56}
            layout="fixed"
            alt={title}
          />
        </Design>
        <Wording fontSize="16px">
          <Paragraph ellipsis={{ rows: 3, expandable: false, symbol: '...' }}>
            {title}
          </Paragraph>
        </Wording>
      </Design>

      <Design
        marginTop="8px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Design
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flex="1"
          marginRight="8px"
        >
          <ProductPricing
            discountedPrice={discountedPrice}
            price={price}
            discountAmount={discountAmount}
            fontSize={{ discountedPriceSize: '16px', priceSize: '28px' }}
          />
        </Design>
        <ProductDiscountBadge amount={discountAmount} />
      </Design>

      <Design
        marginTop="8px"
        display="flex"
        alignItems="center"
        gap="5px"
        justifyContent="space-between"
      >
        {relatedCartDetails?.id && stockStatus === StockStatusEnum.IN_STOCK && (
          <ProductQuantityControl
            cartId={relatedCartDetails?.id ?? ''}
            productId={id}
            page="sticky-order"
            minQty={min_qty}
            maxQty={max_qty}
            isInCartModal={false}
            inventoryQty={inventoryQty}
            qty={relatedCartDetails?.qty ?? 0}
            productDeal={productDeal}
            activeDeal={activeDeal}
          />
        )}

        <ProductAddToCart
          min_qty={min_qty}
          max_qty={max_qty}
          relatedCartDetails={relatedCartDetails}
          product_id={product_id}
          variation_id={variation_id}
          onClick={onAddToCart}
          isAllowedToAddToCart={isAllowedToAddToCart}
          location="box"
          loading={loading}
          onSetLoading={onSetLoading}
          seoItems={seoItems}
          stockStatus={stockStatus}
        />
      </Design>
    </div>
  );
};

export default ProductOrderBox;
