import React, { FC } from 'react';
import Image from 'next/image';

import { Design, Wording } from '@components';
import { LocalModel } from '@models';

type Props = {
  isActive: boolean;
  isLast: boolean;
  isBottomSheet?: boolean;
  details: LocalModel;
};
const DeliverToItemModule: FC<Props> = ({
  isActive,
  details,
  isLast,
  isBottomSheet,
}) => {
  const renderItem = () => (
    <Design
      display="flex"
      alignItems="center"
      borderBottom="1px solid"
      borderColor={isLast || isBottomSheet ? 'transparent' : 'natural'}
      padding={isBottomSheet ? '12px 14px' : '16px'}
      justifyContent="space-between"
      className="has-pointer"
      backgroundColor={
        isBottomSheet && isActive ? 'background_alt' : 'transparent'
      }
      borderRadius={isBottomSheet ? '8px' : ''}
      marginBottom={!isLast && isBottomSheet ? '6px' : '0'}
    >
      <Design display="flex" alignItems="center">
        <Design width="24px" height="18px">
          <Image
            priority
            src={details.image}
            alt="qatar"
            width="24px"
            height="18px"
          />
        </Design>
        <Wording
          marginLeft="8px"
          fontWeight="500"
          fontSize="14px"
          lineHeight="20px"
          color="surface"
        >
          {details.name}
        </Wording>
      </Design>
      <Design
        border="1.5px solid"
        borderColor={isActive ? 'primary' : 'placeholder'}
        borderRadius="50%"
        width="20px"
        height="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Design
          width="10px"
          height="10px"
          backgroundColor={isActive ? 'primary' : 'background'}
          borderRadius="10px"
        />
      </Design>
    </Design>
  );

  if (isActive) return renderItem();

  return <a href={details.link}>{renderItem()}</a>;
};

export default DeliverToItemModule;
