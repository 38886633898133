import React, { FC } from 'react';
import Link from 'next/link';

import classes from './agreement.module.scss';

type Props = {
  place: 'auth' | 'reward';
};
const Agreement: FC<Props> = ({ place }) => {
  return (
    <div className={classes.Container}>
      By {place === 'auth' ? 'registering' : 'signing up'}, you agree to our{' '}
      <Link href="/terms" prefetch={false}>
        <a target="_blank">terms of membership</a>
      </Link>{' '}
      and{' '}
      <Link href="/privacy-policy" prefetch={false}>
        <a target="_blank">privacy & policy</a>
      </Link>
      .
    </div>
  );
};

export default Agreement;
