import {
  NormalizedGtagProductModel, ProductImage, SEO_EVENT_AFFILIATION_NAME,
  SEO_EVENT_CURRENCY, SeoEventListLocation, SeoEventProductModel,
} from '@models';
import { OpenGraphMedia } from 'next-seo/lib/types';

/**
 * It takes in a list of products and returns a list of products which is compatible with GTAG events
 * @param {SeoEventProductModel[]} products - The list of products that are being tracked.
 */
const GtagProductNormalizer = (
  products: SeoEventProductModel[]
): NormalizedGtagProductModel[] => {
  return products.map((p: SeoEventProductModel) => {
    const product = {
      affiliation: SEO_EVENT_AFFILIATION_NAME,
      currency: SEO_EVENT_CURRENCY,
      discount: p?.discount ?? 0,
      index: p?.position ?? -1,
      item_brand: p?.brand ?? '',
      item_id: p?.id ?? '',
      item_category: p?.category?.[0] ?? "",
      item_category2: p?.category?.[1] ?? "",
      item_category3: p?.category?.[2] ?? "",
      item_category4: p?.category?.[3] ?? "",
      item_category5: p?.category?.[4] ?? "",
      item_list_name:
        p?.list_location ?? SeoEventListLocation.HOME_PRODUCTS_LIST,
      item_variant: p?.variant ?? '',
      price: p?.price ?? -1,
      item_name: p?.name ?? '',
      quantity: p?.qty ?? 1,
    } as NormalizedGtagProductModel;

    return product;
  }) as NormalizedGtagProductModel[];
};

const convertProductImagesToOpenGraphMedia = (
  images: ProductImage[]
): OpenGraphMedia[] => {
  return images.map((im) => {
    return {
      url: im.urls[512],
      height: 512,
      width: 512,
    } as OpenGraphMedia;
  });
};

export { convertProductImagesToOpenGraphMedia, GtagProductNormalizer };
