import React, { FC, useEffect } from 'react';
import { Form } from 'antd';
import { Trash2 } from 'react-feather';
import clsx from 'clsx';

import { ActionInput, Button, Design, mediaQuery, Wording } from '@components';
import { useMediaQuery } from '@hooks';
import { SpinnerModule } from '@modules';
import ds from '@ds';

import classes from './promotion-code-input.module.scss';

type Props = {
  actionLoading: boolean;
  appliedCode?: string;
  promotionCode?: string;
  error?: string;
  onSetCode: (code: string) => void;
  onRemove: () => void;
  onApply: () => void;
};
const PromotionCodeInput: FC<Props> = ({
  actionLoading,
  appliedCode,
  promotionCode,
  error,
  onSetCode,
  onRemove,
  onApply,
}) => {
  const [form] = Form.useForm();

  const isDesktop = useMediaQuery(mediaQuery.desktop);

  useEffect(() => {
    form.setFieldsValue({
      promotion_code: appliedCode,
    });
  }, [appliedCode]);

  const renderPromotionAction = (): JSX.Element => {
    if (!isDesktop) return <></>;
    if (actionLoading) return <SpinnerModule />;
    if (appliedCode && promotionCode)
      return (
        <Design
          onClick={onRemove}
          className={clsx(['has-pointer', classes.PromotionAction])}
        >
          <Trash2 color={ds.color_error} width="16px" height="16px" />
        </Design>
      );

    return (
      <Design
        className={clsx(['has-pointer', classes.PromotionAction])}
        onClick={onApply}
      >
        <Wording
          fontWeight="500"
          color="link"
          fontSize="16px"
          lineHeight="22px"
        >
          Apply
        </Wording>
      </Design>
    );
  };

  return (
    <Design>
      <Design
        maxWidth={{
          all: '480px',
          desktop: '100%',
        }}
        margin="0 auto"
        padding={{ all: '32px 0', desktop: '0', mobile: '24px 16px' }}
      >
        {!isDesktop && (
          <Design textAlign="center">
            <Wording
              marginBottom="24px"
              fontWeight="500"
              fontSize={{ all: '18px', mobile: '16px' }}
              lineHeight={{ all: '26px', mobile: '22px' }}
            >
              Enter Your Promotion Code
            </Wording>
          </Design>
        )}

        <Form form={form} name="setPromotionCode" autoComplete="off">
          <ActionInput
            className={clsx([
              classes.PromotionInput,
              promotionCode && !error && classes.HasValue,
            ])}
            name="promotion_code"
            label="Promotion Code"
            onChange={(event) => onSetCode(event.target.value)}
            value={promotionCode}
            status={error && 'error'}
            disabled={!!appliedCode}
            addonAfter={renderPromotionAction()}
            // type="tel"
          />

          {error && (
            <Wording
              color="error"
              fontSize="14px"
              fontWeight="500"
              lineHeight="20px"
              marginTop="8px"
            >
              {error}
            </Wording>
          )}

          {appliedCode && (
            <Wording
              color="success"
              fontSize="14px"
              fontWeight="500"
              lineHeight="20px"
              marginTop="8px"
            >
              Promotion code applied
            </Wording>
          )}

          {!isDesktop && (
            <Design marginTop="24px">
              <Button
                type="primary"
                shape="round"
                fullWidth
                onClick={onApply}
                loading={actionLoading}
                disabled={actionLoading}
              >
                APPLY
              </Button>
            </Design>
          )}
        </Form>
      </Design>
    </Design>
  );
};

export default PromotionCodeInput;
