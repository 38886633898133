import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ArrowLeft } from 'react-feather';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import {
  Design,
  mediaQuery,
  ProfileNavbar,
  Wording,
  notification,
} from '@components';
import { SpinnerModule } from '@modules';
import { useMediaQuery } from '@hooks';

import styles from './profile-page-layout.module.scss';

const ProfilePageLayout = ({ children }) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const router = useRouter();
  const auth = getAuth();

  const currentRouteTitle = router?.pathname.split('/')[2].replace(/-/g, ' ');
  const isOrderDetail = router?.pathname === '/profile/orders/[orderId]';

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onAuthStateChanged(auth, (user) => {
      if (user) setTimeout(() => setLoading(false), 2000);
      if (user?.isAnonymous) router.push('/login');
    });
  }, []);

  if (loading) {
    return (
      <Design
        display="flex"
        height="70vh"
        justifyContent="center"
        alignItems="center"
      >
        <SpinnerModule size="22px" />
      </Design>
    );
  }

  return (
    <Design className={styles.ProfileWrapper}>
      <Design
        marginBottom={{
          all: '24px',
          mobile: '8px',
        }}
      >
        {isMobile || isTablet ? (
          <>
            {!isOrderDetail && (
              <Design display="flex" justifyContent="flex-start" gap="16px">
                <div className={styles.BackArrow}>
                  <Link href="/profile">
                    <a>
                      <ArrowLeft />
                    </a>
                  </Link>
                </div>

                <Wording
                  fontSize="24px"
                  fontWeight="600"
                  className={styles.ProfilePageTitle}
                >
                  {currentRouteTitle}
                </Wording>
              </Design>
            )}
          </>
        ) : (
          <Wording fontSize="40px" fontWeight="600">
            My Account
          </Wording>
        )}
      </Design>

      <Design className={styles.ProfileInner}>
        {!isMobile && !isTablet && <ProfileNavbar />}

        <div className={styles.ProfileContents}>{children}</div>
      </Design>
    </Design>
  );
};

export default ProfilePageLayout;
