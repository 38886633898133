import React from 'react';
import cn from 'clsx';

import styles from './form-fields.module.scss';

type Props = {
  label: string;
  children: React.ReactNode;
  active: boolean;
  value?: string;
  error?: string[];
};

export default function InputLabel({
  active,
  label,
  children,
  value,
  error,
}: Props) {
  return (
    <div className={styles.FloatingLabelWrapper}>
      {children}

      <label
        className={cn(
          styles.Label,
          {
            [styles.FloatingLabel]: active || value,
          },
          { [styles.FloatingLabelError]: !!error?.length }
        )}
      >
        {label}
      </label>
    </div>
  );
}
