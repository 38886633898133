// @flow
// import { PromotionValidateBodyModel } from '@models';
import { orderActionTypes } from '@actionTypes';
import { PlaceOrderBody, PlaceOrderResponseModel } from '@models';

export function loadAll(params?: any) {
  return {
    type: orderActionTypes.LOAD_ALL_ORDER,
    method: 'get',
    url: '/orders',
    params,
  };
}

export function load(id: string) {
  return {
    type: orderActionTypes.LOAD_ORDER,
    method: 'get',
    url: `/orders/${id}`,
  };
}

export function cancel(id: any, data: any) {
  return {
    type: orderActionTypes.UPDATE_ORDER,
    method: 'put',
    url: `/orders/${id}/cancel`,
    data,
  };
}

export function create(data: PlaceOrderBody) {
  return {
    type: orderActionTypes.CREATE_ORDER,
    method: 'post',
    url: '/orders',
    data,
  };
}

export function cancelationReasons() {
  return {
    type: orderActionTypes.CANCELATION_REASONS,
    method: 'get',
    url: '/orders/cancelation-reasons',
  };
}

export function getRecommendations(params: {
  limit: number;
  order_id: number;
}) {
  return {
    type: orderActionTypes.GET_CHECKOUT_RECOMMENDATIONS,
    method: 'get',
    url: '/checkout/recommendations',
    params,
  };
}
export function setRecentOrder(data: PlaceOrderResponseModel) {
  return {
    type: orderActionTypes.SET_RECENT_ORDER,
    data,
  };
}

export function startCheckout() {
  return {
    type: orderActionTypes.START_CHECKOUT,
    method: 'post',
    url: '/integration/start-checkout',
  };
}
