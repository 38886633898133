import React, { FC, useState } from 'react';
import { Form } from 'antd';
import { useRouter } from 'next/router';
import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

import { firebaseErrorHandler, getRegionPhonePrefix } from '@helpers';
import { Button, PhoneInput } from '@components';
import { Agreement } from '@modules';
import { AuthPhoneFormModel } from '@models';

import classes from './reward-phone.module.scss';

type RewardPhoneProps = {
  onGetCode: (phone: string, data: ConfirmationResult) => void;
};

const RewardPhone: FC<RewardPhoneProps> = ({ onGetCode }) => {
  const auth = getAuth();
  const router = useRouter();
  const [form] = Form.useForm();

  const isInBlackFriday = router.pathname === '/landing/black_friday2022';

  const [actionLoading, setActionLoading] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();

  const handleSendCode = async (values: AuthPhoneFormModel) => {
    if (form.getFieldError('phone_number').length > 0) return;
    setActionLoading(true);
    let appVerifier = recaptchaVerifier;

    if (!appVerifier) {
      const verifier = new RecaptchaVerifier(
        'recaptchaForm',
        {
          size: 'invisible',
        },
        auth
      );
      setRecaptchaVerifier(verifier);
      appVerifier = verifier;
    }

    try {
      const mobile_number = `+${values.prefix}${values.phone_number}`;

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        mobile_number,
        appVerifier
      );
      onGetCode(mobile_number, confirmationResult);
    } catch (error) {
      firebaseErrorHandler(error);
    } finally {
      setTimeout(() => {
        setActionLoading(false);
      }, 2000);
    }
  };

  return (
    <div className={classes.Container}>
      <Form
        form={form}
        name="changePhoneForm"
        autoComplete="off"
        initialValues={{
          prefix: getRegionPhonePrefix(),
        }}
        onFinish={handleSendCode}
      >
        <div id="recaptchaForm" />
        {!isInBlackFriday && (
          <p className={classes.SubtitleText}>Would you like,</p>
        )}
        <p className={classes.TitleText}>
          {isInBlackFriday
            ? 'Don’t miss out on our deals…'
            : '30 QR off and free delivery on your first order?'}
        </p>
        <p className={classes.SubtitleText}>
          {isInBlackFriday
            ? 'Sign up to be informed when our deals begin.'
            : 'Sign up and get your promotion, just for you!'}
        </p>
        <PhoneInput
          required
          name="phone_number"
          enablePrefixChange={false}
          displayPrefix={getRegionPhonePrefix()}
          label="Enter your phone number"
        />

        <Form.Item shouldUpdate>
          {() => (
            <Button
              size="large"
              shape="round"
              type="primary"
              fullWidth
              htmlType="submit"
              id="submitBtn"
              className={classes.ActionButton}
              loading={actionLoading}
            >
              {isInBlackFriday ? 'ADD MY NUMBER' : 'I WANT FREE DELIVERY'}
            </Button>
          )}
        </Form.Item>
      </Form>
      <Agreement place="reward" />
    </div>
  );
};

export default RewardPhone;
