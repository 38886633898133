import { Media } from '@components';
import dynamic from 'next/dynamic';

const DesktopHeader = dynamic(() => import('./desktop'));
const MobileHeader = dynamic(() => import('./mobile'));

const HeaderModule = () => {
  return (
    <>
      <Media lessThan="laptop">
        <MobileHeader />
      </Media>
      <Media greaterThan="tablet">
        <DesktopHeader />
      </Media>
    </>
  );
};

export default HeaderModule;
