import React, { FC, useEffect, useRef, useState } from 'react';

import classes from './auth-phone-code.module.scss';

type AuthPhoneCodeProps = {
  codeLength: number;
  error?: string;
  onComplete: (code: string) => void;
};
const AuthPhoneCode: FC<AuthPhoneCodeProps> = ({
  codeLength,
  error,
  onComplete,
}) => {
  const fields = new Array(codeLength).fill(0);
  const itemsEls = useRef<(HTMLInputElement | null)[]>([]);

  const [codes, setCodes] = useState<string[]>(new Array(codeLength).fill(''));
  const [errorMessage, setErrorMessage] = useState(error);

  useEffect(() => {
    const firstInput = itemsEls.current[0];
    firstInput?.focus();
  }, []);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  const isNumber = (code: string): boolean => {
    const regex = /[0-9]/gm;
    return regex.test(code);
  };
  const handleKeyUp = (event: React.KeyboardEvent) => {
    const inputIndex: string =
      event?.target['name'].split('input-code-')?.[1] ?? '';
    if (!isNumber(inputIndex)) return;
    const nextInput = itemsEls.current[+inputIndex + 1];
    const prevInput = itemsEls.current[+inputIndex - 1];

    if (event.key === 'ArrowRight') nextInput?.focus();
    if (event.key === 'Backspace' || event.key === 'ArrowLeft')
      prevInput?.focus();
  };

  const handlePasteData = (event: React.ClipboardEvent) => {
    if (event.type === 'paste') {
      const data = event.clipboardData.getData('text');
      setCodes(
        data
          .split('')
          .filter((_, index) => index < codeLength)
          .map((i) => {
            if (isNumber(i)) return i;
            return '';
          })
      );
    }
  };

  const handleChangeInput = (value: string, index: number) => {
    const splitValue = value.split('');
    const newValue = splitValue.length > 1 ? splitValue[1] : value;
    const nextInput = itemsEls.current[index + 1];
    const currentInput = itemsEls.current[index];
    if (newValue && !isNumber(newValue)) return;
    if (index + 1 > codeLength) return;
    if (value && !isNumber(value)) return;
    setCodes((currentCodes) =>
      currentCodes.map((i, idx) => {
        if (idx === index) return newValue;
        return i;
      })
    );
    if (newValue) nextInput?.focus();
    if (!nextInput && currentInput && newValue) currentInput.blur();
    setErrorMessage('');
  };

  const handleFocusInput = (index: number) => {
    const selectedInput = itemsEls.current[index];
    selectedInput?.focus();
  };

  const handleFocusOnLatest = () => {
    const enteredCodeLength = codes.join('').length;
    if (enteredCodeLength === codeLength) return;
    const lastInput = itemsEls.current[enteredCodeLength];
    lastInput?.focus();
  };

  useEffect(() => {
    const verifyCode = codes.join('');
    if (verifyCode.length === codeLength) {
      onComplete(verifyCode);
    } else {
      onComplete('');
    }
  }, [codes]);

  return (
    <>
      <div
        className={`${[
          classes.Container,
          errorMessage && classes.HasError,
        ].join(' ')}`}
        onKeyUp={handleKeyUp}
        onPaste={(event) => handlePasteData(event)}
        onClick={handleFocusOnLatest}
      >
        {fields.map((_, index) => (
          <div
            className={classes.InputWrapper}
            onClick={() => handleFocusInput(index)}
            key={['input-code', index].join('-')}
          >
            <input
              className={!codes[index] ? classes.EmptyInput : classes.HasValue}
              ref={(element) => itemsEls.current.push(element)}
              name={['input-code', index].join('-')}
              value={codes[index] ?? ''}
              title="code"
              max={1}
              type="tel"
              onChange={(event) => handleChangeInput(event.target.value, index)}
            />
          </div>
        ))}
      </div>
      {errorMessage && <p className={classes.ErrorMessage}>{error}</p>}
    </>
  );
};

export default AuthPhoneCode;
