import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { useRouter } from 'next/router';

import { Image, Media, mediaQuery } from '@components';
import { useMediaQuery } from '@hooks';
import { ProductImageGalleryProps } from '@models';

import ProductImageGalleryModal from './image-modal.module';
import styles from './product-image-gallery.module.scss';

const ProductImageGallery: FC<ProductImageGalleryProps> = ({
  images,
  alt,
}: ProductImageGalleryProps) => {
  const router = useRouter();

  const [activeSlide, setActiveSlide] = useState(0);
  const [bigImageSrc, setBigImageSrc] = useState('');
  const [modalVisibility, setModalVisibility] = useState(false);

  const [keenLoaded, setKeenLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const isTablet = useMediaQuery(mediaQuery.tablet);
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const sliderOptions = {
    initial: 0,
    slides: {
      perView: 1,
    },
    created() {
      setKeenLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  };
  const [sliderRef, instanceRef] = useKeenSlider(sliderOptions);

  useEffect(() => {
    setActiveSlide(0);
    setBigImageSrc(images?.[0]?.urls['original'] ?? '');
  }, []);

  useEffect(() => {
    setActiveSlide(0);
    setCurrentSlide(0);
    instanceRef.current?.update({
      ...sliderOptions,
    });
    setBigImageSrc(images?.[0]?.urls['original'] ?? '');
  }, [router.query['slug']]);

  useEffect(() => {
    setBigImageSrc(images?.[activeSlide]?.urls['original'] ?? '');
  }, [activeSlide]);

  const onThumbnailClick = (index) => {
    setActiveSlide(index);
  };

  const handleOpenGalleryModal = () => setModalVisibility(true);
  const handleCloseGalleryModal = () => setModalVisibility(false);

  useEffect(() => {
    setTimeout(() => {
      // just a patch for now. we should find a better solution
      instanceRef.current?.update({
        ...sliderOptions,
      });
    }, 1000);
  }, [instanceRef]);

  const renderThumbnails = () => {
    if (!images) return null;

    let maximumThumbnails = isMobile || isTablet ? 2 : 3;
    const totalImages = images?.length ?? 0;

    if (maximumThumbnails > totalImages) maximumThumbnails = totalImages;

    const hasExtraImage = totalImages > maximumThumbnails;

    const thumbnailsList: ReactNode[] = [];

    [...Array(maximumThumbnails).keys()].map((m, idx) => {
      const image = images[idx].urls.original;

      thumbnailsList.push(
        <li
          key={idx}
          className={`${idx === activeSlide && styles.ActiveSlide}`}
          onClick={() => onThumbnailClick(idx)}
        >
          <Image
            src={image}
            alt={alt}
            width={128}
            height={95}
            objectFit="contain"
          />
        </li>
      );
    });

    if (hasExtraImage) {
      thumbnailsList.push(
        <li
          key={`thumb-${maximumThumbnails}`}
          className={styles.ThumbnailsMoreButtons}
          onClick={handleOpenGalleryModal}
        >
          <p>{images.length - maximumThumbnails} More</p>
          <Image
            src={images[maximumThumbnails].urls['original']}
            alt={alt}
            width={128}
            height={95}
            objectFit="contain"
          />
        </li>
      );
    }

    return thumbnailsList;
  };

  const renderNonMobileView = () => (
    <>
      <ul className={styles.Thumbnails}>{renderThumbnails()}</ul>

      <div className={styles.BigImage}>
        {bigImageSrc && bigImageSrc.length > 0 && (
          <Image
            src={bigImageSrc}
            alt={alt}
            width={488}
            height={488}
            onClick={handleOpenGalleryModal}
          />
        )}
        <ProductImageGalleryModal
          alt={alt}
          images={images}
          visibility={modalVisibility}
          onClose={handleCloseGalleryModal}
          initialSlide={activeSlide}
        />
      </div>
    </>
  );

  const renderDots = () => (
    <div className={styles.BulletNavigation}>
      {[
        ...Array(
          instanceRef.current && instanceRef.current.track.details.slides.length
        ).keys(),
      ].map((idx) => {
        return (
          <button
            type="button"
            title="dots"
            key={idx}
            onClick={() => {
              instanceRef.current?.moveToIdx(idx);
            }}
            className={currentSlide === idx ? styles.ActiveBullet : ''}
          />
        );
      })}
    </div>
  );

  const renderMobileView = () => (
    <div ref={sliderRef} className="keen-slider">
      {images?.map((img, idx) => (
        <div className="keen-slider__slide" key={idx}>
          <Image
            src={img.urls['original']}
            alt={alt}
            width={640}
            height={640}
            objectFit="contain"
          />
        </div>
      ))}
      {images && images?.length > 1 && renderDots()}
    </div>
  );

  return (
    <>
      <Media greaterThan="laptop">
        <div className={styles.Container}>{renderNonMobileView()}</div>
      </Media>
      <Media lessThan="desktop">
        <div className={styles.Container}>{renderMobileView()}</div>
      </Media>
    </>
  );
};

export default ProductImageGallery;
