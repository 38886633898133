import React, { FC } from 'react';

import styles from './tab-title.module.scss';

interface Props {
  title: string;
}

const ProductTabTitle: FC<Props> = ({ title }) => {
  return <div className={styles.Container}>{title}</div>;
};

export default ProductTabTitle;
