import dynamic from 'next/dynamic';
import cn from 'clsx';

import {
  CartSimilarItemsCarouselModel,
  Mode,
  ProductsListProps,
  SeoEventListLocation,
} from '@models';
import { ProductsListShimmer } from '@modules';
import { useMediaQuery } from '@hooks';
import { mediaQuery } from '@components';

const ProductsList = dynamic<ProductsListProps>(() =>
  import('@modules').then((mod) => mod.ProductsList)
);

import styles from './cart-similar-items.module.scss';

const CartRecommendCarousel = ({
  loading,
  products,
  subtitle,
  title,
}: CartSimilarItemsCarouselModel) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const renderList = () => {
    if (loading) {
      return (
        <div
          className={cn(isMobile ? 'container' : '', styles.SectionContainer)}
        >
          <ProductsListShimmer
            title={title}
            shimmerCount={5}
            mode={Mode.INLINE}
            slidePerView={{
              desktop: 5,
              mobile: 2,
              tablet: 4,
              laptop: 5,
            }}
          />
        </div>
      );
    } else if (products?.length > 3) {
      return (
        <div
          className={cn(isMobile ? 'container' : '', styles.SectionContainer)}
        >
          {subtitle && (
            <div className={styles.CarouselSubtitle}>{subtitle}</div>
          )}
          <ProductsList
            listLocation={SeoEventListLocation.RECOMMENDS_LIST}
            title={title}
            withDisplayEffect={false}
            slidePerView={{
              desktop: 5,
              mobile: 2,
              tablet: 4,
              laptop: 5,
            }}
            products={products}
            showMore={false}
            showSeeMoreCard={false}
          />
        </div>
      );
    } else {
      return null;
    }
  };
  return <>{renderList()}</>;
};

export default CartRecommendCarousel;
