import { appActionTypes } from '@actionTypes';
import { ActiveTab } from '@helpers';
import { AppState } from '@models';

const initialState: AppState = {
  visibleAuthModal: false,
  visiblePopupCart: false,
  visibleAppBanner: false,
  visibleAreaModal: false,
  regionUpdating: false,
};

function appReducer(state = initialState, action): AppState {
  switch (action.type) {
    case appActionTypes.TOGGLE_AUTH_MODAL:
      return {
        ...state,
        visibleAuthModal: action.data,
      };

    case appActionTypes.VISIBLE_AUTH_MODAL:
      return {
        ...state,
        visibleAuthModal: true,
      };

    case appActionTypes.TOGGLE_POPUP_CART:
      return {
        ...state,
        visiblePopupCart: action.data,
      };
    case appActionTypes.CHANGE_APP_BANNER_STATUS:
      return {
        ...state,
        visibleAppBanner: action.data,
      };
    case appActionTypes.CHANGE_AREA_MODAL_STATUS:
      return {
        ...state,
        visibleAreaModal: action.data,
      };
    case appActionTypes.UPDATE_REGION:
      return {
        ...state,
        regionUpdating: action.data,
      };

    default:
      return state;
  }
}

export default appReducer;
