import { Design } from '@components';

import styles from './cart-shimmer.module.scss';

const CartShimmer = () => {
  const renderDeliveryFeeShimmer = () => (
    <Design
      backgroundColor="background"
      borderRadius={{ all: '8px', mobile: '0' }}
      padding={{
        all: '10px 24px',
        tablet: '8px 138px',
        mobile: '8px 16px',
      }}
    >
      <Design
        display="flex"
        gap="16px"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="natural"
        paddingBottom="16px"
      >
        <Design
          className={styles.ShimmerItem}
          width={{ all: '42px', mobile: '40px' }}
          height={{ all: '42px', mobile: '40px' }}
          borderRadius="8px"
        />
        <Design
          className={styles.ShimmerItem}
          width={{ all: '375px', mobile: '222px' }}
          height={{ all: '12px' }}
          borderRadius="16px"
        />
      </Design>
      <Design
        className={styles.ShimmerItem}
        width="100%"
        height="12px"
        margin="16px 0"
        borderRadius="16px"
      />
    </Design>
  );

  const renderCartItemsShimmer = () => (
    <Design
      backgroundColor="background"
      borderRadius={{ all: '8px', mobile: '0' }}
      padding={{ all: '24px', tablet: '24px 138px', mobile: '16px' }}
    >
      <Design
        className={styles.ShimmerItem}
        width={{ all: '135px' }}
        height={{ all: '16px' }}
        borderRadius="40px"
      />

      <Design display="flex" flexDirection="column">
        <Design
          display="flex"
          gap="16px"
          padding="24px 0"
          borderBottom="1px solid"
          borderColor="natural"
        >
          <Design
            width={{ all: '100px', mobile: '90px' }}
            height={{ all: '100px', mobile: '90px' }}
            borderRadius="8px"
            className={styles.ShimmerItem}
          />
          <Design display="flex" gap="24px" flexDirection="column">
            <Design
              width={{ all: '358px', mobile: '165px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
              className={styles.ShimmerItem}
            />
            <Design
              width={{ all: '235px', mobile: '165px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
              className={styles.ShimmerItem}
            />
            <Design
              width={{ all: '144px', mobile: '111px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
              className={styles.ShimmerItem}
            />
          </Design>
        </Design>

        <Design
          display="flex"
          gap="16px"
          padding="24px 0"
          borderBottom="1px solid"
          borderColor="natural"
        >
          <Design
            width={{ all: '100px', mobile: '90px' }}
            height={{ all: '100px', mobile: '90px' }}
            borderRadius="8px"
            className={styles.ShimmerItem}
          />
          <Design display="flex" gap="24px" flexDirection="column">
            <Design
              width={{ all: '358px', mobile: '165px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
              className={styles.ShimmerItem}
            />
            <Design
              width={{ all: '235px', mobile: '165px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
              className={styles.ShimmerItem}
            />
            <Design
              width={{ all: '144px', mobile: '111px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
              className={styles.ShimmerItem}
            />
          </Design>
        </Design>

        <Design display="flex" gap="16px" padding="24px 0 0">
          <Design
            width={{ all: '100px', mobile: '90px' }}
            height={{ all: '100px', mobile: '90px' }}
            borderRadius="8px"
            className={styles.ShimmerItem}
          />
          <Design display="flex" gap="24px" flexDirection="column">
            <Design
              width={{ all: '358px', mobile: '165px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
              className={styles.ShimmerItem}
            />
            <Design
              width={{ all: '235px', mobile: '165px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
              className={styles.ShimmerItem}
            />
            <Design
              width={{ all: '144px', mobile: '111px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
              className={styles.ShimmerItem}
            />
          </Design>
        </Design>
      </Design>
    </Design>
  );

  return (
    <Design
      display="flex"
      gap="16px"
      flexWrap="wrap"
      flexDirection={{ all: 'row', tablet: 'column', mobile: 'column' }}
    >
      <Design display="flex" gap="16px" flexDirection="column" flex="1">
        {renderDeliveryFeeShimmer()}
        {renderCartItemsShimmer()}
      </Design>

      <Design
        backgroundColor="background"
        borderRadius={{ all: '8px', mobile: '0' }}
        padding={{
          all: '36px 42px 16px',
          tablet: '24px 138px',
          mobile: '20px 16px',
        }}
        minWidth={{ all: '486px', tablet: '100%', mobile: '100%' }}
        height="fit-content"
      >
        <Design
          display="flex"
          flexDirection="column"
          gap="36px"
          margin={{ all: '0 0 36px 0' }}
        >
          <Design display="flex" justifyContent="space-between">
            <Design
              className={styles.ShimmerItem}
              width={{ all: '100px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
            />
            <Design
              className={styles.ShimmerItem}
              width={{ all: '100px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
            />
          </Design>

          <Design display="flex" justifyContent="space-between">
            <Design
              className={styles.ShimmerItem}
              width={{ all: '100px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
            />
            <Design
              className={styles.ShimmerItem}
              width={{ all: '100px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
            />
          </Design>

          <Design display="flex" justifyContent="space-between">
            <Design
              className={styles.ShimmerItem}
              width={{ all: '100px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
            />
            <Design
              className={styles.ShimmerItem}
              width={{ all: '100px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
            />
          </Design>

          <Design
            display={{ all: 'flex', mobile: 'none' }}
            justifyContent="space-between"
          >
            <Design
              className={styles.ShimmerItem}
              width={{ all: '100px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
            />
            <Design
              className={styles.ShimmerItem}
              width={{ all: '100px' }}
              height={{ all: '12px' }}
              borderRadius="16px"
            />
          </Design>
        </Design>

        <Design
          position={{ all: 'initial', mobile: 'fixed' }}
          bottom="0"
          left="0"
          width="100%"
          backgroundColor="background"
          borderTop={{ all: '0', mobile: '1px solid' }}
          borderColor="natural"
          padding={{ all: '0', mobile: '20px 16px 14px' }}
          zIndex="10"
        >
          <Design
            display={{ all: 'none', mobile: 'flex' }}
            justifyContent="space-between"
            margin="0 0 18px 0"
          >
            <Design
              className={styles.ShimmerItem}
              width="80px"
              height="12px"
              borderRadius="16px"
            />
            <Design
              className={styles.ShimmerItem}
              width="80px"
              height="12px"
              borderRadius="16px"
            />
          </Design>

          <Design
            className={styles.ShimmerItem}
            width="100%"
            height={{ all: '56px' }}
            borderRadius="100px"
            margin="0 0 16px 0"
          />

          <Design
            className={styles.ShimmerItem}
            width={{ all: '280px', mobile: '240px' }}
            height={{ all: '12px' }}
            borderRadius="16px"
            margin="auto"
          />
        </Design>
      </Design>
    </Design>
  );
};

export default CartShimmer;
