import React, { FC, useState } from 'react';
import Image from 'next/image';
import { Button } from 'antd';
import {
  ConfirmationResult,
  getAuth,
  onAuthStateChanged,
  UserCredential,
} from 'firebase/auth';

import { AuthPhoneCode, AuthPhoneResendCode } from '@modules';
import { firebaseAuthErrors } from '@models';
import { triggerLoginEvent } from '@utils/seo/events';
import { OAuthKey } from '@helpers';
import { usePromise } from '@hooks';
import { profileActions } from '@actions';

import classes from './auth-phone-verify.module.scss';

type AuthPhoneVerifyProps = {
  OTP: ConfirmationResult | undefined;
  phoneNumber: string;
  onSetOTP: (data: ConfirmationResult) => void;
  onVerified: (user: UserCredential) => void;
  onChangePhone: () => void;
};
const AuthPhoneVerify: FC<AuthPhoneVerifyProps> = ({
  OTP,
  phoneNumber,
  onSetOTP,
  onVerified,
  onChangePhone,
}) => {
  const auth = getAuth();
  const codeLength = 6;
  const [verifyCode, setVerifyCode] = useState('');
  const [actionLoading, setActionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const promise = usePromise();

  const handleSetVerifyCode = (code: string) => {
    setErrorMessage('');
    setVerifyCode(code);
  };

  const handleVerifyNumber = async () => {
    setErrorMessage('');
    if (!OTP) return;
    if (!verifyCode || verifyCode.length < 6) {
      setErrorMessage('Please enter verification code completely.');
      return;
    }
    setActionLoading(true);
    await OTP.confirm(verifyCode)
      .then((user) => {
        triggerLoginEvent(OAuthKey.PHONE_NUMBER);
        promise(profileActions.setSignUpMethod(OAuthKey.PHONE_NUMBER));

        onAuthStateChanged(auth, (currentUser) => {
          if (currentUser?.phoneNumber) {
            onVerified(user);
            setActionLoading(false);
          }
        });
      })
      .catch((error) => {
        setActionLoading(false);
        setErrorMessage(firebaseAuthErrors[error.code] ?? error.message);
      });
  };

  return (
    <>
      <p className={classes.Message}>
        A confirmation code was sent to you. Please enter the code below.
      </p>
      <div className={classes.Container}>
        <div className={classes.PhoneWrapper} onClick={() => onChangePhone()}>
          <Image src="/icons/pen-icon.svg" height={16} width={16} alt="pen" />
          <p className={classes.Phone}>{phoneNumber}</p>
        </div>
        <AuthPhoneCode
          codeLength={codeLength}
          onComplete={handleSetVerifyCode}
          error={errorMessage}
        />
        <div className={classes.ResendCodeWrapper}>
          <AuthPhoneResendCode
            phoneNumber={phoneNumber}
            period={120}
            onResendCode={onSetOTP}
          />
        </div>

        <Button
          type="primary"
          htmlType="submit"
          shape="round"
          id="submitBtn"
          block
          loading={actionLoading}
          className={classes.ActionButton}
          onClick={handleVerifyNumber}
        >
          SUBMIT
        </Button>
      </div>
    </>
  );
};

export default AuthPhoneVerify;
