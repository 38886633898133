import { useMediaQuery } from '@hooks';
import { Footer, Header } from '@modules';
import { mediaQuery } from '@components';

const FullWidthPageLayout = ({ children }) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);

  return (
    <>
      <Header />
      <div className={isMobile ? '' : 'container'}>{children}</div>
      <Footer />
    </>
  );
};

export default FullWidthPageLayout;
