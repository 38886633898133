import { notification } from '@components';
import { IconType } from 'antd/lib/notification';
import { useEffect, useState } from 'react';

function useStorage<T>(
  storage_key: string,
  forceGet = false,
  setMessage?: { type: IconType; message: string }
) {
  const getLocalStorage = () => {
    if (typeof window !== 'undefined') {
      const savedData = localStorage.getItem(storage_key);
      if (!savedData) return undefined;
      return JSON.parse(savedData) as T;
    }
  };

  const [localData, setLocalData] = useState<T | undefined>(getLocalStorage());

  const getItems = () => {
    setLocalData(getLocalStorage());
  };

  const setItem = (value: T, showMessage = true) => {
    showMessage && setMessage?.message && notification(setMessage);
    setLocalData(value);
    updateStorage(value);
  };

  const removeLocalStorage = () => {
    setLocalData(undefined);
    localStorage.removeItem(storage_key);
  };

  const updateStorage = (value: T) => {
    if (!value) return;
    localStorage.setItem(storage_key, JSON.stringify(value));
    setLocalData(value);
  };

  useEffect(() => {
    getItems();
  }, [forceGet]);

  return {
    localData,
    setItem,
    removeLocalStorage,
  };
}

export default useStorage;
