import React, { FC, useState } from 'react';
import dynamic from 'next/dynamic';

import { CheckoutLeaveConfirm, Media } from '@components';
import { useRouter } from 'next/router';

const DesktopVersion = dynamic(() => import('./desktop-version'));
const MobileVersion = dynamic(() => import('./mobile-version'));

const CheckoutHeaderModule: FC = () => {
  const router = useRouter();
  const { step } = router.query;
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [leaveLand, setLeaveLand] = useState('/');

  /**
   * factory function that handle go back logic
   * @returns {void}
   */
  const handleGoBack = (): void => {
    if (step === 'payment') {
      router.push('/checkout/shipping', undefined, { shallow: true });
      return;
    }

    // Go back in mobile should navigate to cart page
    setLeaveLand('/cart');
    setShowLeaveModal(true);

    return;
  };

  // Factory function that handle showing leave modal message
  const handleLeaveDesktopCheckout = (): void => {
    // the modal shouldn't show in the done message and it should navigate to home page
    if (step === 'done') {
      router.push('/');
      return;
    }
    setShowLeaveModal(true);
    return;
  };

  const handleLeaveAction = () => {
    router.push(leaveLand);
    setShowLeaveModal(false);
  };

  return (
    <>
      <Media lessThan="tablet">
        <MobileVersion onBack={handleGoBack} />
      </Media>
      <Media greaterThan="mobile">
        <DesktopVersion onLeaveCheckout={handleLeaveDesktopCheckout} />
      </Media>
      <CheckoutLeaveConfirm
        show={showLeaveModal}
        onHide={() => setShowLeaveModal(false)}
        onLeave={handleLeaveAction}
      />
    </>
  );
};

export default CheckoutHeaderModule;
