import { Button, Design, Media, Wording } from '@components';
import Image from 'next/image';
import React, { FC } from 'react';

type Props = {
  onAllow: () => void;
  onClose: () => void;
};
const StayTunedModule: FC<Props> = ({ onAllow, onClose }) => {
  const renderMobileView = () => (
    <Design
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding="20px 32px"
    >
      <Image
        priority
        src="/icons/push-notification.svg"
        width="100px"
        height="100px"
        alt="Remove Item"
      />
      <Wording
        fontSize="24px"
        fontWeight="500"
        color="surface"
        margin="16px 0 12px"
        textAlign="center"
      >
        Stay tuned from 1sell!
      </Wording>
      <Wording
        color="caption"
        fontWeight="500"
        fontSize="14px"
        marginBottom="32px"
        textAlign="center"
      >
        Receive notification to be updated from latest news.
      </Wording>
      <Button type="primary" shape="round" onClick={onAllow} fullWidth>
        Allow notification
      </Button>
      <Design marginTop="20px" onClick={onClose}>
        <Wording color="action" fontWeight="500" fontSize="16px">
          Not now
        </Wording>
      </Design>
    </Design>
  );

  const renderDesktopView = () => (
    <Design
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="348px"
    >
      <Image
        priority
        src="/icons/push-notification.svg"
        width="120px"
        height="120px"
        alt="Remove Item"
      />
      <Wording
        fontSize="24px"
        fontWeight="500"
        color="surface"
        margin="16px 0 12px"
      >
        Stay tuned from 1sell!
      </Wording>
      <Wording
        color="caption"
        fontWeight="500"
        fontSize="16px"
        marginBottom="32px"
      >
        Receive notification to be updated from latest news.
      </Wording>
      <Button type="primary" shape="round" onClick={onAllow}>
        Allow notification
      </Button>
    </Design>
  );
  return (
    <>
      <Media greaterThan="mobile">{renderDesktopView()}</Media>
      <Media lessThan="tablet">{renderMobileView()}</Media>
    </>
  );
};

export default StayTunedModule;
