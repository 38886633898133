import React, { FC, ReactNode } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';

import { Media } from '@components';

import classes from './reward-login-wrapper.module.scss';

type Props = {
  children: ReactNode;
};

const RewardLoginWrapper: FC<Props> = ({ children }) => {
  const router = useRouter();

  const isInBlackFriday = router.pathname === '/landing/black_friday2022';

  const renderImage = () => (
    <>
      <Media greaterThan="laptop">
        <Image
          priority
          src={
            isInBlackFriday
              ? '/images/black-friday-reward-desktop-image.jpg'
              : '/images/reward-desktop-image.jpg'
          }
          alt="reward-image"
          width="625px"
          height="500px"
          objectFit="cover"
          layout="responsive"
          className="reward-image"
        />
      </Media>
      <Media between={['tablet', 'desktop']}>
        <Image
          priority
          src={
            isInBlackFriday
              ? '/images/black-friday-reward-tablet-image.jpg'
              : '/images/reward-tablet-image.jpg'
          }
          alt="reward-image"
          width="768px"
          height="220px"
          objectFit="cover"
          layout="responsive"
          className="reward-image"
        />
      </Media>
      <Media lessThan="tablet">
        <Image
          priority
          src={
            isInBlackFriday
              ? '/images/black-friday-reward-mobile-image.jpg'
              : '/images/reward-mobile-image.jpg'
          }
          alt="reward-image"
          width="360px"
          height="200px"
          objectFit="cover"
          layout="responsive"
          className="reward-image"
        />
      </Media>
    </>
  );
  return (
    <div className={classes.Container}>
      <div className={classes.ContentContainer}>{children}</div>
      <div className={classes.ImageContainer}>{renderImage()}</div>
    </div>
  );
};

export default RewardLoginWrapper;
