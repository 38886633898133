import { checkoutActionTypes } from '@actionTypes';
import { CheckoutModel, CheckoutStateModel } from '@models';

import { ItemInitialState } from './reducer.constant';

const initialState: CheckoutStateModel = {
  details: ItemInitialState({} as CheckoutModel),
  paymentMethods: ItemInitialState([]),
  checkoutParams: {},
  note: '',
};

function checkout(state = initialState, action) {
  switch (action.type) {
    case checkoutActionTypes.LOAD_CHECKOUT:
      return {
        ...state,
        details: {
          ...state.details,
          fetching: true,
        },
      };
    case checkoutActionTypes.LOAD_CHECKOUT_SUCCESS:
      const detailsData = {
        ...action.data,
        billing_address:
          action.data?.billing_address ??
          state?.details?.data?.billing_address ??
          null,
        shipping_address:
          action.data?.shipping_address ??
          state?.details?.data?.shipping_address ??
          null,
      };

      return {
        ...state,
        details: {
          data: detailsData,
          fetching: false,
        },
      };
    case checkoutActionTypes.LOAD_CHECKOUT_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          fetching: false,
        },
      };

    case checkoutActionTypes.LOAD_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          fetching: true,
        },
      };
    case checkoutActionTypes.LOAD_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: {
          ...action.data,
          fetching: false,
        },
      };
    case checkoutActionTypes.LOAD_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          fetching: false,
        },
      };
    case checkoutActionTypes.UPDATE_CHECKOUT_PARAMS:
      return {
        ...state,
        checkoutParams: {
          ...state.checkoutParams,
          ...action.data,
        },
      };

    case checkoutActionTypes.UPDATE_CHECKOUT_NOTE:
      return {
        ...state,
        note: action.data,
      };

    default:
      return state;
  }
}

export default checkout;
