import { Footer, Header } from '@modules';
import { AboutHero } from '@components';

const DefaultPageLayout = ({ children }) => {
  return (
    <>
      <Header />
      <AboutHero />
      <div className="container">{children}</div>
      <Footer />
    </>
  );
};

export default DefaultPageLayout;
