import { FC, useEffect, useRef, useState } from 'react';

import { cartActions, productActions } from '@actions';
import {
  Button,
  Design,
  Image,
  mediaQuery,
  Modal,
  notification,
  ProductQuantityControl,
  ProductVariations,
  Wording,
} from '@components';
import ds from '@ds';
import { useMediaQuery, usePromise, useStorage } from '@hooks';
import { AddressRegionSelected, CartItemModel, ProductModel } from '@models';
import { SpinnerModule } from '@modules';
import { appSelectors, cartSelectors } from '@selectors';
import Link from 'next/link';
import { ChevronDown } from 'react-feather';
import { useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';

import CartItemActions from './cart-item-actions';
import CartItemPrice from './cart-item-price';
import { getRegionToken } from '@helpers';

type Props = {
  details: CartItemModel;
  isFirstItem: boolean;
};

const CartItem: FC<Props> = ({ details, isFirstItem }) => {
  const promise = usePromise();
  const isMobile = useMediaQuery(mediaQuery.mobile);
  const isDesktop = useMediaQuery(mediaQuery.desktop);
  const qtyWrapperSize = isDesktop ? '32px' : '28px';

  const { id, price, qty, list_price, product_id, variation_id, product } =
    details;
  const minQty = product.min_qty_in_shopping_cart;
  const maxQty =
    product?.product_type === 'variable'
      ? product?.variation?.inventory_qty
      : product?.inventory_qty;
  const inventoryQty =
    product?.product_type === 'variable'
      ? product?.variation?.inventory_qty
      : product?.inventory_qty;

  const { data: compactData } = useSelector(cartSelectors.compact);
  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visibleAreaModal
  );

  const [visibleAttrModal, setVisibleAttrModal] = useState<boolean>(false);
  const [visibleAttrBottomSheet, setVisibleAttrBottomSheet] =
    useState<boolean>(false);
  const [validateVariations, setValidateVariations] = useState<boolean>(false);
  const [detailLoading, setDetailLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [qtyChanged, setQtyChanged] = useState<boolean>(false);
  const [productQty, setProductQty] = useState<number>(qty);
  const [productDetails, setProductDetails] =
    useState<ProductModel | null>(null);
  const [selectedVariation, setSelectedVariation] =
    useState<number | null>(null);

  const findItem = compactData?.items?.find((item) => item.id === id);

  const prevQtyRef = useRef();

  useEffect(() => {
    setProductQty(findItem?.qty ?? 0);
  }, [findItem]);

  useEffect(() => {
    prevQtyRef.current = productQty as any;
  }, [productQty]);

  const handleVisibleAttributesModal = () => {
    setDetailLoading(true);

    if (isMobile) {
      setVisibleAttrBottomSheet(true);
    } else {
      setVisibleAttrModal(true);
    }

    promise(productActions.load(product_id))
      .then((result) => {
        setProductDetails(result as ProductModel);
      })
      .finally(() => {
        setDetailLoading(false);
      });
  };

  const handleHideAttributesModal = () => {
    setVisibleAttrModal(false);
    setVisibleAttrBottomSheet(false);
    setQtyChanged(false);
    setProductQty(qty);
    setSelectedVariation(null);
  };

  const handleVariationSelection = (variation_id: number | null) => {
    setSelectedVariation(variation_id);
  };

  // Find the current selected variation for new "price" and "list price"
  const currentVariation = productDetails?.variations?.find((variation) =>
    selectedVariation
      ? variation?.id === selectedVariation
      : variation?.id === variation_id
  );

  const renderQuantityController = (isInCartModal: boolean) => {
    return (
      <Design
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={isDesktop ? '32px' : '28px'}
        width={isDesktop ? '99px' : '91px'}
      >
        <ProductQuantityControl
          isInCartModal={isInCartModal}
          cartId={id}
          productId={product_id}
          page="cart"
          minQty={minQty}
          maxQty={
            isInCartModal ? Number(currentVariation?.inventory_qty) : maxQty
          }
          inventoryQty={inventoryQty}
          // qty={isInCartModal ? findedCompactItem?.qty : qty}
          qty={isInCartModal ? productQty : findItem?.qty ?? qty}
          onSetQty={(qty) => setProductQty(qty)}
          onChangeQty={(state) => setQtyChanged(state)}
        />
      </Design>
    );
  };

  const handleFetchDataAfterUpdate = () => {
    setVisibleAttrModal(false);
    setVisibleAttrBottomSheet(false);
    promise(cartActions.loadCartDetails(currentRegion?.area?.id));
    promise(cartActions.loadCartCompact(currentRegion?.area?.id));
  };

  const handleUpdateErrors = (error) => {
    if (error.message) {
      notification({
        type: 'error',
        message: 'Error!',
        description: error.message,
      });
    } else {
      error.map((err) =>
        notification({
          type: 'error',
          message: 'Error!',
          description: err.message,
        })
      );
    }
  };

  // This handler will be called when just the "quantity" changes
  const handleUpdateQuantity = () => {
    promise(
      cartActions.cartUpdateQty(id, {
        qty: productQty,
      })
    )
      .then(() => {
        handleFetchDataAfterUpdate();
      })
      .catch((error) => {
        handleUpdateErrors(error);
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };

  // This handler will be called when "variation" changes
  const handleUpdateVariation = () => {
    promise(
      cartActions.cartUpdateVariant(id, {
        variation_id: selectedVariation,
        qty: productQty,
      })
    )
      .then(() => {
        handleFetchDataAfterUpdate();
      })
      .catch((error) => {
        handleUpdateErrors(error);
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };

  const handleUpdate = () => {
    if (selectedVariation === null) {
      setValidateVariations(true);
    } else {
      setUpdateLoading(true);

      if (selectedVariation === variation_id) {
        return handleUpdateQuantity();
      }

      return handleUpdateVariation();
    }
  };

  const renderChangedQtyMessage = () => {
    if (qtyChanged) return;

    if (productQty < Number(prevQtyRef.current) || productQty < qty) {
      return (
        <Wording fontWeight="500" color="primary">
          {/* Now: {productQty}, before: {prevQtyRef.current} */}
          This item’s quantity have reduced to {productQty}
        </Wording>
      );
    }

    return;
  };

  const renderVariationsModalContents = () => {
    if (detailLoading) {
      return (
        <Design
          display="flex"
          height="288px"
          justifyContent="center"
          alignItems="center"
        >
          <SpinnerModule />
        </Design>
      );
    }

    return (
      <Design display="flex" gap="24px" flexDirection="column">
        <Design padding="13px 16px">
          <ProductVariations
            showErrorMessage={true}
            selectedVariation={variation_id}
            isInCart
            validateVariations={validateVariations}
            onVariationSelection={handleVariationSelection}
            variations={productDetails?.variations || []}
          />

          <Design display="flex" gap="16px" flexDirection="column">
            <Wording fontSize="16px" fontWeight="500">
              Quantity
            </Wording>
            {renderQuantityController(true)}
            {renderChangedQtyMessage()}
          </Design>
        </Design>

        <Design
          display="flex"
          gap="16px"
          justifyContent="space-between"
          borderTop="1px solid"
          borderColor="natural"
          alignItems="center"
          width={{ all: '100%', tablet: '460px', mobile: '100%' }}
          margin="auto"
          padding={{
            all: '12px 58px',
            tablet: '12px 0',
            mobile: '8px 16px',
          }}
        >
          <CartItemPrice
            isInModal
            price={currentVariation?.price ?? 0}
            listPrice={currentVariation?.list_price ?? 0}
          />

          <Design minWidth={{ all: '274px', mobile: '170px' }}>
            <Button
              type="primary"
              shape="round"
              fullWidth
              loading={updateLoading}
              disabled={selectedVariation === null}
              onClick={handleUpdate}
            >
              UPDATE
            </Button>
          </Design>
        </Design>
      </Design>
    );
  };

  return (
    <Design
      display="flex"
      position="relative"
      justifyContent="space-between"
      gap={{ all: '10px', mobile: '4px' }}
      borderTop={isFirstItem ? '0' : '1px solid'}
      borderColor="natural"
      padding={isFirstItem ? '0' : '24px 0 0 0'}
      margin={isFirstItem ? '0' : '24px 0 0 0'}
    >
      <Design display="flex" gap="16px">
        <Link href={`/product/${product?.slug}`}>
          <a target="_self">
            <Design
              border="1px solid"
              borderColor="natural"
              borderRadius="8px"
              overflow="hidden"
              position="relative"
              minWidth={{ all: '100px', tablet: '90px', mobile: '90px' }}
              minHeight={{ all: '100px', tablet: '90px', mobile: '90px' }}
              maxWidth={{ all: '100px', tablet: '90px', mobile: '90px' }}
              maxHeight={{ all: '100px', tablet: '90px', mobile: '90px' }}
            >
              <Image
                src={product?.image?.urls['original']}
                width={100}
                objectFit="cover"
                height={100}
                alt={product?.title}
                priority
              />
            </Design>
          </a>
        </Link>

        <Design display="flex" flexDirection="column" gap="10px">
          <Link href={`/product/${product?.slug}`}>
            <a target="_self">
              <Wording
                fontSize={{ all: '16px', mobile: '14px' }}
                lineHeight={{ all: '22px', mobile: '20px' }}
              >
                {product?.title}
              </Wording>
            </a>
          </Link>

          <Design display="flex" gap="22px" alignItems="center">
            {product?.product_type === 'variable' && (
              <Design
                display="flex"
                borderRadius="4px"
                border="1px solid"
                borderColor="natural"
                padding="6px 8px"
                gap="8px"
                className="has-pointer"
                position="relative"
                alignItems="center"
                onClick={handleVisibleAttributesModal}
              >
                {product?.variation?.attributes?.map((attribute) => (
                  <Design key={attribute?.slug} className="has-transition">
                    <Wording color="surface">
                      {attribute?.option?.label}
                    </Wording>
                  </Design>
                ))}

                <Design
                  className="has-transition"
                  width="24px"
                  height="24px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ChevronDown
                    color={ds?.color_surface}
                    width="20px"
                    height="20px"
                  />
                </Design>
              </Design>
            )}

            <Design
              position={{
                all: 'relative',
                tablet: 'absolute',
                mobile: 'absolute',
              }}
              bottom={{ all: '0', tablet: '-83%', mobile: '-83%' }}
              right={{ all: '0' }}
              height={isDesktop ? '32px' : '28px'}
              margin={{ all: '0', tablet: 'auto', mobile: 'auto' }}
              top={{ all: '0', tablet: '3px', mobile: '3px' }}
              zIndex="10"
            >
              {renderQuantityController(false)}
            </Design>

            {inventoryQty <= 10 && (
              <Design
                display={{ all: 'block', tablet: 'none', mobile: 'none' }}
              >
                <Wording
                  fontSize={{ all: '14px', mobile: '12px' }}
                  fontWeight="500"
                  color="warning"
                >
                  Only {inventoryQty} left!
                </Wording>
              </Design>
            )}
          </Design>

          <CartItemPrice price={price} listPrice={list_price} />
        </Design>
      </Design>

      <Design
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-end"
        position="relative"
        padding={{ all: '0', tablet: '2px 0 0 0', mobile: '2px 0 0 0' }}
      >
        <CartItemActions
          id={id}
          productId={product_id}
          variationId={variation_id}
        />

        {inventoryQty <= 10 && (
          <Design
            display={{ all: 'none', tablet: 'block', mobile: 'block' }}
            position="absolute"
            bottom="25px"
            width="84px"
            textAlign={{ all: 'initial', tablet: 'right', mobile: 'right' }}
          >
            <Wording
              fontSize={{ all: '14px', mobile: '12px' }}
              fontWeight="500"
              color="warning"
            >
              Only {inventoryQty} left!
            </Wording>
          </Design>
        )}
      </Design>

      <BottomSheet
        open={visibleAttrBottomSheet}
        onDismiss={handleHideAttributesModal}
        header={false}
      >
        {renderVariationsModalContents()}
      </BottomSheet>

      <Modal
        title=""
        maskClosable
        width={576}
        visible={visibleAttrModal}
        onCancel={handleHideAttributesModal}
      >
        {renderVariationsModalContents()}
      </Modal>
    </Design>
  );
};

export default CartItem;
