import ds from '@ds';
import { IScreenSize } from '@models';
import { notification as AntdNotification } from 'antd';
import { ArgsProps, NotificationPlacement } from 'antd/lib/notification';
import { AlertCircle, CheckCircle, HelpCircle } from 'react-feather';

interface Props extends Omit<ArgsProps, 'placement'> {
  placement?: NotificationPlacement | IScreenSize<NotificationPlacement>;
}

const Notification = ({
  placement = { all: 'bottom', desktop: 'bottomRight' },
  type,
  ...rest
}: Props) => {
  let windowType: 'desktop' | 'laptop' | 'tablet' | 'mobile' = 'desktop';

  const listener = () => {
    if (window.innerWidth <= 767) windowType = 'mobile';

    if (window.innerWidth > 767 && window.innerWidth <= 1319)
      windowType = 'tablet';

    if (window.innerWidth > 992 && window.innerWidth <= 1319)
      windowType = 'laptop';

    if (window.innerWidth >= 1320) windowType = 'desktop';
  };

  listener();
  window.addEventListener('resize', listener);

  const Icon = () => {
    switch (type) {
      case 'info':
        return <HelpCircle color={ds.color_link} />;
      case 'error':
        return <AlertCircle color={ds.color_error} />;
      case 'success':
        return <CheckCircle color={ds.color_success} />;
      default:
        return <HelpCircle color={ds.color_link} />;
    }
  };

  const getPosition = (): NotificationPlacement => {
    if (typeof placement === 'object') {
      if (typeof placement[windowType] === 'undefined') {
        return placement['all'];
      }

      return placement[windowType] as NotificationPlacement;
    }

    return placement as NotificationPlacement;
  };

  AntdNotification.open({
    ...rest,
    bottom: windowType === 'desktop' ? 24 : 142,
    placement: getPosition(),
    type,
    icon: Icon(),
  });
};

export default Notification;
