import React, { FC } from 'react';

import { Button, Design, Wording } from '@components';

type Props = {
  onClose: () => void;
  onLeave: () => void;
  title: string;
};
const LeaveConfirm: FC<Props> = ({ onClose, onLeave, title }) => {
  return (
    <Design
      height={{
        all: '314px',
        mobile: '',
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding={{
        all: '48px',
        mobile: '24px 16px',
      }}
    >
      <Design
        marginBottom="18px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Wording
          color="surface"
          fontWeight="500"
          fontSize="24px"
          marginBottom={{
            all: '12px',
            mobile: '8px',
          }}
        >
          Just a moment!
        </Wording>
        <Wording
          color="caption"
          fontSize="16px"
          lineHeight="22px"
          textAlign="center"
        >
          If yoy leave this page, your {title} progress will be lost. Are you
          sure you want to leave?
        </Wording>
      </Design>
      <Design
        display="flex"
        justifyContent="center"
        gap="16px"
        marginTop={{
          all: '36px',
          mobile: '24px',
        }}
      >
        <Button size="large" shape="round" onClick={onLeave}>
          Leave {title}
        </Button>
        <Button size="large" shape="round" type="primary" onClick={onClose}>
          Stay in {title}
        </Button>
      </Design>
    </Design>
  );
};

export default LeaveConfirm;
