import { wishlistActionTypes } from '@actionTypes';

import { reducerItemInitialState } from './reducer.constant';

const initialState = {
  all: reducerItemInitialState,
  items: reducerItemInitialState,
  compact: reducerItemInitialState,
  detail: reducerItemInitialState,
};

function wishlist(state = initialState, action) {
  switch (action.type) {
    /**
     * LOAD_ALL_WISHLIST
     */
    case wishlistActionTypes.LOAD_ALL_WISHLIST:
      return {
        ...state,
        all: {
          fetching: true,
        },
      };

    case wishlistActionTypes.LOAD_ALL_WISHLIST_SUCCESS:
      return {
        ...state,
        all: {
          data: action.data,
          fetching: false,
        },
      };

    case wishlistActionTypes.LOAD_ALL_WISHLIST_FAILURE:
      return {
        ...state,
        all: {
          fetching: false,
        },
      };

    /**
     * ADD_TO_WISHLIST
     */
    case wishlistActionTypes.ADD_TO_WISHLIST:
      return {
        ...state,
        compact: {
          ...state.compact,
          fetching: true,
        },
      };

    case wishlistActionTypes.ADD_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        compact: {
          data: action.data,
          fetching: false,
        },
      };

    case wishlistActionTypes.ADD_TO_WISHLIST_FAILURE:
      return {
        ...state,
        compact: {
          ...state.compact,
          fetching: false,
        },
      };

    /**
     * DELETE_WISHLIST
     */
    case wishlistActionTypes.DELETE_WISHLIST:
      return {
        ...state,
        compact: {
          ...state.compact,
          fetching: true,
        },
      };

    case wishlistActionTypes.DELETE_WISHLIST_SUCCESS:
      return {
        ...state,
        compact: {
          data: action.data,
          fetching: false,
        },
      };

    case wishlistActionTypes.DELETE_WISHLIST_FAILURE:
      return {
        ...state,
        compact: {
          fetching: false,
        },
      };

    /**
     * COMPACT_WISHLIST
     */
    case wishlistActionTypes.COMPACT_WISHLIST:
      return {
        ...state,
        compact: {
          ...state.compact,
          fetching: true,
        },
      };

    case wishlistActionTypes.COMPACT_WISHLIST_SUCCESS:
      return {
        ...state,
        compact: {
          data: action.data,
          fetching: false,
        },
      };

    case wishlistActionTypes.COMPACT_WISHLIST_FAILURE:
      return {
        ...state,
        compact: {
          fetching: false,
        },
      };

    /**
     * DETAIL_WISHLIST
     */
    case wishlistActionTypes.DETAIL_WISHLIST:
      return {
        ...state,
        detail: {
          fetching: true,
        },
      };

    case wishlistActionTypes.DETAIL_WISHLIST_SUCCESS:
      return {
        ...state,
        detail: {
          data: action.data,
          fetching: false,
        },
      };

    case wishlistActionTypes.DETAIL_WISHLIST_FAILURE:
      return {
        ...state,
        detail: {
          fetching: false,
        },
      };

    default:
      return state;
  }
  }
  

export default wishlist; 