import React, { FC } from 'react';
import Image from 'next/image';
import { getAuth, signInWithPopup, updateProfile } from 'firebase/auth';
import { useSelector } from 'react-redux';

import {
  firebaseErrorHandler,
  getOAuthProviderByKey,
  getRegionToken,
  handleParseResultData,
  OAuthKey,
} from '@helpers';
import {
  appActions,
  cartActions,
  profileActions,
  wishlistActions,
} from '@actions';
import { usePromise, useStorage } from '@hooks';
import { triggerLoginEvent } from '@utils/seo/events';
import { appSelectors } from '@selectors';
import { AddressRegionSelected } from '@models';

import classes from './auth-socials.module.scss';

type AuthSocialsProps = {
  onLoading: (state: boolean) => void;
};
const AuthSocials: FC<AuthSocialsProps> = ({ onLoading }) => {
  const promise = usePromise();

  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visibleAreaModal
  );

  const onAuthenticate = async (provider: OAuthKey) => {
    const authProvider = getOAuthProviderByKey(provider);
    if (!authProvider) return;
    onLoading(true);
    try {
      const result = await signInWithPopup(getAuth(), authProvider);
      const currentUser = result.user;

      const resultData = handleParseResultData(result);
      const displayName = resultData?.additionalUserInfo?.profile?.name;

      updateProfile(currentUser, {
        displayName: displayName,
      });

      triggerLoginEvent(provider);
      promise(profileActions.setSignUpMethod(provider));

      Promise.all([
        promise(profileActions.loadProfile()),
        promise(cartActions.loadCartCompact(currentRegion?.area?.id)),
        promise(wishlistActions.compactWishlist()),
      ]);

      setTimeout(() => {
        promise(appActions.toggleAuthModal(false));
        onLoading(false);
      }, 2000);
    } catch (err) {
      firebaseErrorHandler(err);
      onLoading(false);
    }
  };
  return (
    <div className={classes.SocialWrapper}>
      <div
        className={classes.ProviderButton}
        onClick={() => onAuthenticate(OAuthKey.FACEBOOK)}
      >
        <Image
          priority
          width={24}
          height={24}
          src="/icons/socials/facebook-filled-icon.svg"
          alt="facebook"
        />
      </div>

      <div
        className={classes.ProviderButton}
        onClick={() => onAuthenticate(OAuthKey.GOOGLE)}
      >
        <Image
          priority
          width={24}
          height={24}
          src="/icons/socials/google-filled-icon.svg"
          alt="google"
        />
      </div>

      <div
        className={classes.ProviderButton}
        onClick={() => onAuthenticate(OAuthKey.APPLE)}
      >
        <Image
          priority
          width={24}
          height={24}
          src="/icons/socials/apple-filled-icon.svg"
          alt="apple"
        />
      </div>
    </div>
  );
};

export default AuthSocials;
