export const LOAD_ALL_ORDER = 'LOAD_ALL_ORDER';
export const LOAD_ALL_ORDER_SUCCESS = 'LOAD_ALL_ORDER_SUCCESS';
export const LOAD_ALL_ORDER_FAILURE = 'LOAD_ALL_ORDER_FAILURE';

export const LOAD_ORDER = 'LOAD_ORDER';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const LOAD_ORDER_FAILURE = 'LOAD_ORDER_FAILURE';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const CANCELATION_REASONS = 'CANCELATION_REASONS';
export const CANCELATION_REASONS_SUCCESS = 'CANCELATION_REASONS_SUCCESS';
export const CANCELATION_REASONS_FAILURE = 'CANCELATION_REASONS_FAILURE';

export const GET_CHECKOUT_RECOMMENDATIONS = 'GET_CHECKOUT_RECOMMENDATIONS';
export const GET_CHECKOUT_RECOMMENDATIONS_SUCCESS =
  'GET_CHECKOUT_RECOMMENDATIONS_SUCCESS';
export const GET_CHECKOUT_RECOMMENDATIONS_FAILURE =
  'GET_CHECKOUT_RECOMMENDATIONS_FAILURE';

export const SET_RECENT_ORDER = 'SET_RECENT_ORDER';

export const START_CHECKOUT = 'START_CHECKOUT';
