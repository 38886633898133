import moment from 'moment';

import { WalletHistoryResModel } from '@models';
import { Design, Wording } from '@components';

import styles from './wallet-history-card.module.scss';
import { getRegionCurrency } from '@helpers';
import { FC } from 'react';

type Props = {
  data: WalletHistoryResModel;
};

const WalletHistoryCard: FC<Props> = ({ data }) => {
  const amountNumber = +data?.amount;
  const isAmountPositive = Math.sign(amountNumber) > 0;
  const formattedDate = moment(data?.created_at).format('DD MMM, YYYY | HH:mm');

  return (
    <Design
      display="flex"
      backgroundColor="background"
      justifyContent="space-between"
      className={styles.CardItem}
    >
      <Design display="flex" gap="4px">
        <Wording
          fontSize="18px"
          fontWeight="500"
          color={isAmountPositive ? 'success' : 'error'}
        >
          {isAmountPositive ? '+' : '-'}
        </Wording>

        <Wording
          fontSize="18px"
          fontWeight="500"
          color={isAmountPositive ? 'success' : 'error'}
        >
          {Math.abs(data?.amount).toFixed(2)}
        </Wording>

        <Wording marginTop="2px" color={isAmountPositive ? 'success' : 'error'}>
          {getRegionCurrency()}
        </Wording>
      </Design>

      <Design display="flex" gap="20px" className={styles.CardItemRight}>
        <Wording color="placeholder">{data?.description}</Wording>
        <Wording>{formattedDate}</Wording>
      </Design>
    </Design>
  );
};

export default WalletHistoryCard;
