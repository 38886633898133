import { Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';

import InputsWrapper from './inputs-wrapper';

import styles from './form-fields.module.scss';

type OptionsModel = {
  key: string | number;
  value: string | number;
  title: string;
};

type Props = {
  name: string;
  label: string;
  value?: string;
  defaultValue?: string;
  error?: string[];
  withFormItem?: boolean;
  rules?: Rule[];
  options: OptionsModel[];
};

const { Option } = Select;

const SelectInput = ({
  name,
  label,
  value = '',
  defaultValue = '',
  error,
  withFormItem = true,
  rules,
  options = [],
}: Props) => {
  return (
    <div className={styles.SelectInputStyles}>
      <InputsWrapper
        name={name}
        label={label}
        value={value}
        error={error}
        withFormItem={withFormItem}
        rules={rules}
      >
        <Form.Item name={name}>
          <Select defaultValue={defaultValue}>
            {options?.map((option) => (
              <Option key={option?.key} value={option?.value}>
                {option?.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </InputsWrapper>
    </div>
  );
};

export default SelectInput;
