import React, { FC, useEffect, useState } from 'react';

import { Design, Wording } from '@components';
import { CategoryFacetModel } from '@models';
import { Switch } from 'antd';

type Props = {
  facet: CategoryFacetModel;
  selectValue: string[];
  onChange: (name: string, value: string) => void;
};

const FiltersBooleanModule: FC<Props> = ({ facet, selectValue, onChange }) => {
  const [checked, setChecked] = useState<boolean>(
    selectValue?.includes('true')
  );

  const handleCheckingFilter = (change: boolean) => {
    setChecked(change);
    onChange(facet.slug, change.toString());
  };

  useEffect(() => {
    setChecked(selectValue?.includes('true'));
  }, [selectValue]);

  return (
    <Design display="flex" justifyContent="space-between" alignItems="center">
      <Wording fontSize="14px" lineHeight="20px" fontWeight="500">
        {facet?.label ?? ''}
      </Wording>
      <Switch checked={checked} onChange={handleCheckingFilter} />
    </Design>
  );
};

export default FiltersBooleanModule;
