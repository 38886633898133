const SEO = {
  title: undefined,
  titleTemplate: '%s - 1Sell',
  canonical: 'https://1sell.com',
  defaultTitle:
    '1Sell | Buy Fashion and Beauty Products Online | Leading online E-commerce brand Qatar',
  description:
    'Buy Fashion and Beauty Products Online in Qatar. Get the best Fashion and Beauty Products at the best rates only at 1Sell.',
  keywords: 'Buy Beauty Products Online, Buy Fashion Products Qatar',
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.ico',
    },
  ],
  openGraph: {
    title:
      '1Sell | Buy Fashion and Beauty Products Online | Leading online E-commerce brand Qatar',
    description:
      'Buy Fashion and Beauty Products Online in Qatar. Get the best Fashion and Beauty Products at the best rates only at 1Sell.',
    images: [
      {
        url: 'https://1sell.com/images/og-image-global-qa.jpeg',
        width: 1024,
        height: 500,
        alt: '1Sell',
        type: 'image/jpeg',
      },
    ],
    site_name: 'https://1sell.com',
    locale: 'en-US',
    type: 'website',
  },
};

export default SEO;
