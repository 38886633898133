import { getCookie, setCookie } from '@helpers';
import firebaseCloudMessaging from '../firebase/firebase-cloud-messaging';

const date_key = '1sell-fcm-close-date';
const time_key = '1sell-fcm-close-time';

/**
 * Factory function that close FCM alert
 * @returns
 */
const CloseFCMAlert = () => {
  const times = +getCookie(time_key);

  const now = new Date();

  if (getCookie(date_key) && times > 0) {
    setCookie(date_key, now);
    setCookie(time_key, times + 1);
  }
  setCookie(date_key, now);
  setCookie(time_key, 1);

  return;
};

/**
 * Factory function that return status of FCM alert
 * @returns {boolean}
 */
const ShowFCMAlert = (): boolean => {
  const times = +getCookie(time_key);

  const permission = firebaseCloudMessaging.notificationPermission();
  if (permission !== 'default') return false;

  if (!getCookie(date_key) || !times) return true;

  const now = new Date();
  const lastUpdate = new Date(getCookie(date_key));

  const differenceInTimes = now.getTime() - lastUpdate.getTime();
  const differenceInDays = differenceInTimes / (1000 * 3600 * 24);

  if (times < 4 && differenceInDays > 30) return true;
  return false;
};

export { CloseFCMAlert, ShowFCMAlert };
