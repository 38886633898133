import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { appActions } from '@actions';
import { Button, Design, Wording } from '@components';
import { usePromise } from '@hooks';

import { cartSelectors, firebaseSelectors } from '@selectors';
import { formatPriceWithCurrency } from '@utils/number.utils';
import styles from './cart.module.scss';
import { triggerOpenAreaList } from '@utils/seo/events';
import notification from '../notification';

type Props = {
  setLastAction: (action: 'login' | 'checkout' | 'none') => void;
  onSendSEOEvent: () => void;
  shipTo: string;
};
const CartSummary: FC<Props> = ({ setLastAction, onSendSEOEvent, shipTo }) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(-1);

  const promise = usePromise();

  const { data: cartData, fetching: fetchingCart } = useSelector(
    cartSelectors.details
  );
  const authInfo = useSelector(firebaseSelectors?.auth);

  const itemsCount =
    cartData?.items?.reduce((prev, item) => prev + item.qty, 0) ?? 0;

  useEffect(() => {
    if (cartData) {
      if (deliveryFee > -1) {
        if (deliveryFee !== cartData?.delivery_fee) {
          notification({
            type: 'info',
            message: 'Delivery fee has been updated.',
          });
          setDeliveryFee(cartData?.delivery_fee);
        }
      } else {
        setDeliveryFee(cartData?.delivery_fee);
      }
    }
  }, [fetchingCart]);

  const handleNavigateToCheckout = () => {
    setLoading(true);
    if (authInfo?.isAnonymous) {
      handleLoginUser();
      setLoading(false);
      setLastAction('checkout');
      return;
    }
    onSendSEOEvent();
    setLoading(false);
    setLastAction('none');
    router.push('/checkout/shipping');
  };

  const handleLoginUser = async () => {
    setLastAction('login');
    promise(appActions.toggleAuthModal(true));
  };

  const handleShowChangeRegion = () => {
    triggerOpenAreaList('price_detail');
    promise(appActions.changeAreaModalStatus(true));
  };

  return (
    <Design
      backgroundColor="background"
      borderRadius={{ all: '8px', mobile: '0' }}
      padding={{
        all: '36px 42px 16px',
        tablet: '24px 138px',
        mobile: '20px 16px',
      }}
      minWidth={{ all: '486px', tablet: '100%', mobile: '100%' }}
      height="fit-content"
    >
      <Design
        display="flex"
        flexDirection="column"
        gap={{ all: '24px', tablet: '20px', mobile: '20px' }}
      >
        <Design display="flex" justifyContent="space-between">
          <Wording
            color="caption"
            fontSize={{ all: '16px', tablet: '14px', mobile: '14px' }}
            fontWeight="500"
          >
            Subtotal
          </Wording>
          <Wording
            color="surface"
            fontSize={{ all: '16px', tablet: '14px', mobile: '14px' }}
            fontWeight="500"
          >
            {formatPriceWithCurrency(cartData?.items_sub_total ?? 0)}
          </Wording>
        </Design>

        <Design display="flex" justifyContent="space-between">
          <Design display="flex" alignItems="center">
            <Design minWidth={{ all: '102px', desktop: '120px' }}>
              <Wording
                color="caption"
                fontSize={{ all: '14px', desktop: '16px' }}
                fontWeight="500"
              >
                Delivery Fee to
              </Wording>
            </Design>
            <Design onClick={handleShowChangeRegion}>
              <Wording
                fontWeight="500"
                fontSize="16px"
                lineHeight="22px"
                color="link"
                decoration="underline"
                className={styles.DeliverTo}
                marginLeft="5px"
              >
                {shipTo}
              </Wording>
            </Design>
          </Design>
          <Wording
            color="surface"
            fontSize={{ all: '14px', desktop: '16px' }}
            fontWeight="500"
          >
            {formatPriceWithCurrency(cartData?.delivery_fee ?? 0)}
          </Wording>
        </Design>

        {cartData && cartData?.discount > 0 && (
          <Design display="flex" justifyContent="space-between">
            <Wording
              color="error"
              fontSize={{ all: '16px', tablet: '14px', mobile: '14px' }}
              fontWeight="500"
            >
              Discount
            </Wording>
            <Wording
              color="error"
              fontSize={{ all: '16px', tablet: '14px', mobile: '14px' }}
              fontWeight="500"
            >
              -{formatPriceWithCurrency(cartData.discount ?? 0)}
            </Wording>
          </Design>
        )}

        <Design
          display={{ all: 'none', tablet: 'flex', mobile: 'flex' }}
          justifyContent="space-between"
        >
          <Wording
            color="surface"
            lineHeight={{ all: '28px', tablet: '22px', mobile: '22px' }}
            fontSize={{ all: '20px', tablet: '16px', mobile: '16px' }}
            fontWeight="500"
          >
            Total Due
          </Wording>
          <Wording
            color="surface"
            lineHeight={{ all: '28px', tablet: '22px', mobile: '22px' }}
            fontSize={{ all: '20px', tablet: '16px', mobile: '16px' }}
            fontWeight="500"
          >
            {formatPriceWithCurrency(cartData?.total ?? 0)}
          </Wording>
        </Design>

        <Design
          display="flex"
          flexDirection="column"
          position={{ all: 'initial', tablet: 'fixed', mobile: 'fixed' }}
          bottom={{ all: '0' }}
          width="100%"
          left="0"
          borderTop={{ all: '0', tablet: '1px solid', mobile: '1px solid' }}
          borderColor="natural"
          backgroundColor={{
            all: 'transparent',
            tablet: 'background',
            mobile: 'background',
          }}
          padding={{
            all: '0',
            tablet: '12px 156px 10px 156px',
            mobile: '12px 16px 10px 16px',
          }}
          zIndex="100"
        >
          <Design display="flex" justifyContent="space-between">
            <Wording
              color="surface"
              lineHeight={{ all: '28px', tablet: '22px', mobile: '22px' }}
              fontSize={{ all: '20px', tablet: '16px', mobile: '16px' }}
              fontWeight="500"
            >
              Total Due
            </Wording>
            <Wording
              color="surface"
              lineHeight={{ all: '28px', tablet: '22px', mobile: '22px' }}
              fontSize={{ all: '20px', tablet: '16px', mobile: '16px' }}
              fontWeight="500"
            >
              {formatPriceWithCurrency(cartData?.total ?? 0)}
            </Wording>
          </Design>

          <Design
            margin={{
              all: '32px 0 0 0',
              tablet: '12px 0 0 0',
              mobile: '13px 0 0 0',
            }}
          >
            <Button
              type="primary"
              shape="round"
              fullWidth
              loading={loading}
              onClick={handleNavigateToCheckout}
            >
              {itemsCount > 0 ? <>CHECKOUT ({itemsCount})</> : 'CHECKOUT'}
            </Button>
          </Design>

          <Design
            margin={{
              all: '16px 0 0 0',
              tablet: '10px 0 0 0',
              mobile: '10px 0 0 0',
            }}
          >
            <Wording
              color="caption"
              textAlign="center"
              fontSize={{ all: '14px', tablet: '12px', mobile: '12px' }}
            >
              Apply Promotion Code on checkout step!
            </Wording>
          </Design>
        </Design>
      </Design>
    </Design>
  );
};

export default CartSummary;
