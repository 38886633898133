import { Design, Wording } from '@components';
import { capitalize } from 'lodash';
import React, { FC, ReactNode, useState } from 'react';
import { ArrowDown, ChevronDown, ChevronUp } from 'react-feather';

type Props = {
  title: string;
  children: ReactNode;
  collapsible?: boolean;
  isExpanded?: boolean;
  footer?: ReactNode;
  showArrow?: boolean;
};
const FiltersCardModule: FC<Props> = ({
  title,
  children,
  isExpanded = false,
  footer = false,
  collapsible = true,
  showArrow = true,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const handleChangeCardStatus = () => {
    if (!collapsible) return;
    setExpanded((prev) => !prev);
  };
  return (
    <Design
      backgroundColor="background"
      borderRadius={{
        all: '0',
        desktop: '8px',
      }}
      width="100%"
      marginBottom="12px"
    >
      <Design
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={{
          all: '10px 16px',
          desktop: '10px 12px',
        }}
        className="has-pointer"
        onClick={handleChangeCardStatus}
      >
        <Wording fontSize="14px" lineHeight="20px" fontWeight="500">
          {title && capitalize(title)}
        </Wording>
        {showArrow && (
          <Design
            width="16px"
            height="16px"
            padding="0px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </Design>
        )}
      </Design>
      {expanded && (
        <Design
          borderTop={{
            all: 'none',
            desktop: '1px solid',
          }}
          borderColor="natural"
        >
          {children}
        </Design>
      )}
      {footer && expanded && (
        <Design borderTop="1px solid" borderColor="natural" padding="10px 12px">
          {footer}
        </Design>
      )}
    </Design>
  );
};

export default FiltersCardModule;
