import { Design } from '@components';
import {
  CartIRecommendCarouselModel,
  ProductsListProps,
  SeoEventListLocation,
} from '@models';
import { Spin } from 'antd';
import dynamic from 'next/dynamic';

const ProductsList = dynamic<ProductsListProps>(() =>
  import('@modules').then((mod) => mod.ProductsList)
);

const CartRecommendCarousel = ({
  loading,
  recommendsList,
}: CartIRecommendCarouselModel) => {
  return (
    <Design
      margin={{ all: '36px 0', mobile: '36px 0 36px 16px' }}
      overflow={{ all: 'visible', mobile: 'hidden' }}
    >
      {!loading ? (
        <>
          {recommendsList?.map((item) => (
            <ProductsList
              key={[item?.item_type, item?.title].join('_')}
              listLocation={SeoEventListLocation.RECOMMENDS_LIST}
              title={item?.title}
              withDisplayEffect={false}
              slidePerView={{
                desktop: 5,
                mobile: 2,
                tablet: 4,
                laptop: 5,
              }}
              products={item?.products}
              showMore={false}
              showSeeMoreCard={false}
            />
          ))}
        </>
      ) : (
        <Design display="flex" justifyContent="center">
          <Spin />
        </Design>
      )}
    </Design>
  );
};

export default CartRecommendCarousel;
