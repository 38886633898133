import React from 'react';
import { Input, Form } from 'antd';
import { Eye, EyeOff } from 'react-feather';
import { Rule } from 'antd/lib/form';

import { Design } from '@components';

import InputsWrapper from './inputs-wrapper';

import styles from './form-fields.module.scss';

type Props = {
  name: string;
  type?: 'text' | 'password' | 'phone' | 'email' | 'textarea';
  label: string;
  value?: string;
  placeholder?: string;
  error?: string[];
  disabled?: boolean;
  withFormItem?: boolean;
  rules?: Rule[];
};

export default function TextInput({
  name,
  type = 'text',
  label,
  value = '',
  placeholder = '',
  error,
  disabled = false,
  withFormItem = true,
  rules,
}: Props) {
  if (type === 'password') {
    return (
      <InputsWrapper
        name={name}
        label={label}
        value={value}
        error={error}
        withFormItem={withFormItem}
        rules={rules}
      >
        <Form.Item name={name}>
          <Input.Password
            disabled={disabled}
            placeholder={placeholder}
            type="password"
            className={styles.InputStyles}
            iconRender={(visible) =>
              visible ? (
                <Design
                  className="has-pointer"
                  width="24px"
                  height="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <EyeOff />
                </Design>
              ) : (
                <Design
                  className="has-pointer"
                  width="24px"
                  height="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Eye />
                </Design>
              )
            }
          />
        </Form.Item>
      </InputsWrapper>
    );
  }

  if (type === 'textarea') {
    return (
      <InputsWrapper
        name={name}
        label={label}
        value={value}
        error={error}
        withFormItem={withFormItem}
        rules={rules}
      >
        <Form.Item name={name}>
          <Input.TextArea
            disabled={disabled}
            placeholder={placeholder}
            className={styles.InputStyles}
          />
        </Form.Item>
      </InputsWrapper>
    );
  }

  return (
    <InputsWrapper
      name={name}
      label={label}
      value={value}
      error={error}
      withFormItem={withFormItem}
      rules={rules}
    >
      <Form.Item name={name}>
        <Input
          disabled={disabled}
          placeholder={placeholder}
          className={styles.InputStyles}
          type={type}
        />
      </Form.Item>
    </InputsWrapper>
  );
}
