import React, { FC, useEffect, useState } from 'react';

import {
  Design,
  Modal,
  notification,
  PhoneInput,
  TextInput,
  Wording,
} from '@components';
import { initializeClient } from '@firebase';
import {
  getCurrentCountryCode,
  getRegionPhonePrefix,
  handleSignOut,
} from '@helpers';
import { usePromise } from '@hooks';
import { firebaseSelectors } from '@selectors';
import { Button, Form } from 'antd';
import { getAuth, updateProfile } from 'firebase/auth';
import parsePhoneNumber from 'libphonenumber-js';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import styles from './personal-info.module.scss';

const ChangeEmailForm = dynamic(() => import('./change-email.form'));
const ChangePhoneForm = dynamic(() => import('./change-phone.form'));
const PasswordForm = dynamic(() => import('./personal-info-password.form'));

const PersonalInfoForm: FC = () => {
  const [form] = Form.useForm();
  const router = useRouter();
  const promise = usePromise();

  const authInfo = useSelector(firebaseSelectors?.auth);

  const [visibleChangeEmail, setVisibleChangeEmail] = useState<boolean>(false);
  const [visibleChangePhone, setVisibleChangePhone] = useState<boolean>(false);
  const [visiblePasswordModal, setVisiblePasswordModal] =
    useState<boolean>(false);
  const [visibleLogout, setVisibleLogout] = useState<boolean>(false);
  const hasEmail = !!authInfo?.email;
  const hasPhone = !!authInfo?.phoneNumber;
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const hasPassword = authInfo?.providerData?.some(
    (provider) => provider.providerId === 'password'
  );

  useEffect(() => {
    form.resetFields();

    form.setFieldsValue({
      full_name: authInfo?.displayName || '',
      email: authInfo?.email || '',
      phone_number:
        parsePhoneNumber(String(authInfo?.phoneNumber))?.nationalNumber || '',
      password: hasPassword ? '*******' : '',
    });
  }, [authInfo]);

  const handleVisibleChangeEmail = () => {
    setVisibleChangeEmail(true);
  };

  const handleHideChangeEmail = () => {
    setVisibleChangeEmail(false);
  };

  const handleVisiblePasswordModal = () => {
    setVisiblePasswordModal(true);
  };

  const handleHidePasswordModal = () => {
    setVisiblePasswordModal(false);
  };

  const handleVisibleChangePhone = () => {
    setVisibleChangePhone(true);
  };

  const handleHideChangePhone = () => {
    setVisibleChangePhone(false);
  };

  const handleVisibleLogoutModal = () => {
    setVisibleChangeEmail(false);
    setVisiblePasswordModal(false);
    setVisibleLogout(true);
  };

  const handleLogout = () => {
    setVisibleLogout(false);
    handleSignOut().then(() => {
      router.push('/');
    });
  };

  const handleUpdateProfile = ({ full_name }) => {
    const currentUser = getAuth().currentUser;
    if (!currentUser) return;
    setSubmitLoading(true);

    updateProfile(currentUser, {
      displayName: full_name,
    })
      .then(() => {
        // promise(profileActions.setProfileFullName(full_name));
        initializeClient(promise);

        notification({
          type: 'success',
          message: 'Success!',
          description: 'Profile updated successfully!',
        });
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleChangePhoneForm = () => {
    setVisibleChangePhone(false);
    initializeClient(promise);
  };

  return (
    <div>
      <Form
        form={form}
        initialValues={{}}
        name="personalInfoForm"
        autoComplete="off"
        onFinish={handleUpdateProfile}
      >
        <Design
          display="flex"
          gap="16px"
          flexDirection={{
            all: 'row',
            mobile: 'column',
          }}
        >
          <TextInput name="full_name" label="Full Name *" type="text" />

          <Design className={styles.HasChangeButton}>
            <TextInput
              name="email"
              disabled
              label="Email Address *"
              type="email"
            />
            <div
              className={styles.ChangeButton}
              onClick={handleVisibleChangeEmail}
            >
              <Wording color="link" fontWeight="500">
                {hasEmail ? 'Change' : 'Add'}
              </Wording>
            </div>
          </Design>
        </Design>

        <Design
          display="flex"
          gap="16px"
          flexDirection={{
            all: 'row',
            mobile: 'column',
          }}
          className={styles.SecondRow}
        >
          <Design className={styles.HasChangeButton}>
            <PhoneInput
              disabled
              name="phone_number"
              displayPrefix={
                authInfo?.phoneNumber
                  ? parsePhoneNumber(String(authInfo?.phoneNumber))
                      ?.countryCallingCode ?? ''
                  : getRegionPhonePrefix()
              }
              enablePrefixChange={false}
              value={authInfo.phoneNumber ?? ''}
              label="Mobile Number *"
            />
            <div
              className={styles.ChangeButton}
              onClick={handleVisibleChangePhone}
            >
              <Wording color="link" fontWeight="500">
                {hasPhone ? 'Change' : 'Add'}
              </Wording>
            </div>
          </Design>

          <Design className={styles.HasChangeButton}>
            <TextInput disabled name="password" label="Password" type="text" />

            <div
              className={styles.ChangeButton}
              onClick={handleVisiblePasswordModal}
            >
              <Wording color="link" fontWeight="500">
                {hasPassword ? 'Change' : 'Add'}
              </Wording>
            </div>
          </Design>
        </Design>

        <Design marginTop="24px">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              loading={submitLoading}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Design>
      </Form>

      <Modal
        maskClosable={false}
        title="CHANGE EMAIL ADDRESS"
        visible={visibleChangeEmail}
        onCancel={handleHideChangeEmail}
      >
        <Design padding="32px" display="flex" flexDirection="column" gap="24px">
          <ChangeEmailForm />
        </Design>
      </Modal>

      <Modal
        maskClosable={false}
        title={hasPhone ? 'EDIT MOBILE NUMBER' : 'ADD MOBILE NUMBER'}
        visible={visibleChangePhone}
        onCancel={handleHideChangePhone}
      >
        <Design padding="32px" display="flex" flexDirection="column" gap="24px">
          <ChangePhoneForm
            hasPhone={hasPhone}
            phoneNumber={authInfo?.phoneNumber || ''}
            onFinish={handleChangePhoneForm}
          />
        </Design>
      </Modal>

      <Modal
        maskClosable={false}
        title={hasPassword ? 'CHANGE PASSWORD' : 'ADD PASSWORD'}
        visible={visiblePasswordModal}
        onCancel={handleHidePasswordModal}
      >
        <Design padding="32px" display="flex" flexDirection="column" gap="24px">
          <PasswordForm
            hasPassword={hasPassword as boolean}
            onLogout={handleVisibleLogoutModal}
          />
        </Design>
      </Modal>

      <Modal
        maskClosable={false}
        title="LOGIN AGAIN"
        visible={visibleLogout}
        onCancel={handleLogout}
      >
        <Design padding="32px" display="flex" flexDirection="column" gap="24px">
          <Wording>
            Some security-sensitive actions—such as setting a new email address,
            and changing a password—require that the user has recently signed
            in.
          </Wording>
        </Design>
      </Modal>
    </div>
  );
};

export default PersonalInfoForm;
