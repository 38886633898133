import React, { FC } from 'react';

import ds from '@ds';
import { ProductTabsListProps, TabKeys } from '@models';
import { ChevronRight } from 'react-feather';

import styles from './product-tabs-list.module.scss';

const ProductTabsList: FC<ProductTabsListProps> = ({
  showDescriptionsTab,
  showReviewsTab,
  showSpecificationsTab,
  activeTab,
}) => {
  return (
    <ul className={styles.Container}>
      {showSpecificationsTab && (
        <li
          className={activeTab === TabKeys.SPECIFICATIONS ? styles.Active : ''}
        >
          <ChevronRight
            color={
              activeTab === TabKeys.SPECIFICATIONS
                ? ds.color_primary
                : ds.color_surface
            }
            width={20}
            height={20}
          />
          <a className="smooth-scroll" href="#specifications">
            Specifications
          </a>
        </li>
      )}
      {showDescriptionsTab && (
        <li className={activeTab === TabKeys.DESCRIPTIONS ? styles.Active : ''}>
          <ChevronRight
            color={
              activeTab === TabKeys.DESCRIPTIONS
                ? ds.color_primary
                : ds.color_surface
            }
            width={20}
            height={20}
          />
          <a className="smooth-scroll" href="#description">
            Description
          </a>
        </li>
      )}
      {showReviewsTab && (
        <li className={activeTab === TabKeys.REVIEWS ? styles.Active : ''}>
          <ChevronRight
            color={
              activeTab === TabKeys.DESCRIPTIONS
                ? ds.color_primary
                : ds.color_surface
            }
            width={20}
            height={20}
          />
          <a className="smooth-scroll" href="#reviews">
            Reviews
          </a>
        </li>
      )}
    </ul>
  );
};

export default ProductTabsList;
