import { SortTypeKey } from './category.model';
import { FetchingDataType } from './redux.model';

export interface CategoriesStateModel {
  all: FetchingDataType<CategoryModel[]>;
  item: FetchingDataType<CategoryModel>;
  dealCategories: FetchingDataType<DealCategory[]>;
}

export interface CategoryModel {
  title: string;
  slug: string;
  products_count: number;
  children: CategoryModel[];
  on_sale_products_count: number;
  image: Urls;
  icon: { urls: Urls };
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  parent?: CategoryParentModel;
}

export interface CategoryParentModel {
  slug: string;
  title: string;
  parent?: CategoryParentModel;
}

export interface Urls {
  128: string;
  256: string;
  512: string;
  '128@2x': string;
  '256@2x': string;
  original: string;
  '512@2x': string;
}

export interface DealCategory {
  slug: string;
  label: string;
}

export interface SortItemModel {
  key: SortTypeKey;
  title: string;
  sort_dir: 'DESC' | 'ASC';
}
export const CategorySortOptions: SortItemModel[] = [
  { key: 'popularity', title: 'Our Picks', sort_dir: 'DESC' },
  {
    key: 'created_at',
    title: 'Newest First',
    sort_dir: 'DESC',
  },
  { key: 'price', title: 'High to Low Price ', sort_dir: 'DESC' },
  { key: 'price', title: 'Low to High Price', sort_dir: 'ASC' },
  {
    key: 'discount_amount',
    title: 'Discount',
    sort_dir: 'DESC',
  },
];
