import React, { FC } from 'react';

import { Design, Wording } from '@components';
import Paragraph from 'antd/lib/typography/Paragraph';

interface Props {
  shortDescription: string;
}

const ProductShortDescriptions: FC<Props> = ({ shortDescription }) => {
  return (
    <Design>
      <Wording variant="p" fontSize="16px" color="surface">
        <Paragraph
          ellipsis={{ rows: 3, expandable: true, symbol: 'Read more...' }}
        >
          {shortDescription}
        </Paragraph>
      </Wording>
    </Design>
  );
};

export default ProductShortDescriptions;
