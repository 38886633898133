import React from 'react';

import { Wording, Design } from '@components';

type Props = {
  productId: number;
  deliveryEstimation: string;
};

const MainOfferDetails = ({ productId, deliveryEstimation }: Props) => {
  return (
    <>
      <Design
        display="flex"
        flexDirection="column"
        gap="8px"
        margin="8px 0 0 0"
      >
        <Design display="flex" gap="5px">
          <Wording
            fontSize={{ all: '20px', tablet: '16px' }}
            lineHeight={{ all: '23.46px', tablet: '18.77px' }}
            fontWeight={{ all: '300' }}
          >
            Product ID:
          </Wording>
          <Wording
            fontSize={{ all: '20px', tablet: '16px' }}
            lineHeight={{ all: '23.46px', tablet: '18.77px' }}
            fontWeight="600"
          >
            {productId}
          </Wording>
        </Design>

        {/* <Design display="flex" gap="5px">
          <Rate allowHalf disabled defaultValue={rating} value={rating} />
          <Wording fontSize="14px" lineHeight="19.6px" color="placeholder">
            ({reviewsCount} Review)
          </Wording>
        </Design> */}
      </Design>

      <Design display="flex" gap="8px" margin="24px 0 0 0">
        <Wording fontSize="16px" lineHeight="22.4px" color="placeholder">
          Delivery Estimation:
        </Wording>
        <Wording fontSize="16px" lineHeight="22.4px" fontWeight="600">
          {deliveryEstimation}
        </Wording>
      </Design>
    </>
  );
};

export default MainOfferDetails;
