import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChevronRight } from 'react-feather';

import { AreaSelector, Design, Wording } from '@components';
import { getCurrentRegionLabel, getRegion, getRegionToken } from '@helpers';
import { AddressAreaModel, AddressRegionSelected, Region } from '@models';
import { usePromise, useStorage } from '@hooks';
import { addressesActions, appActions } from '@actions';
import { appSelectors } from '@selectors';

type Props = {
  addressRegionArea: AddressAreaModel | null;
  onUpdateArea: (info: AddressRegionSelected) => void;
};

const AddressAreaInputModule: FC<Props> = ({
  addressRegionArea,
  onUpdateArea,
}) => {
  const region = getRegion();
  const promise = usePromise();

  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);
  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visibleAreaModal
  );

  const [regionData, setRegionData] =
    useState<AddressRegionSelected | undefined>();

  const handleShowAreaSelector = () => {
    promise(appActions.changeAreaModalStatus(true));
    promise(addressesActions.setAddressArea(addressRegionArea));
  };

  const handleUpdateRegion = (info: AddressRegionSelected) => {
    setRegionData(info);
    onUpdateArea(info);
  };

  const label = region === Region.AR_AE ? 'City/Area' : 'Area';

  const inputValue = useMemo(
    () => getCurrentRegionLabel(regionData),
    [regionData]
  );

  useEffect(() => {
    if (!regionData) {
      if (addressRegionArea) {
        setRegionData({
          country: getRegion(),
          area: addressRegionArea,
          city: addressRegionArea?.id
            ? {
                areas: [],
                id: addressRegionArea?.id,
                label: '',
                name: '',
              }
            : null,
        });
      } else if (currentRegion) setRegionData(currentRegion);
    }
  }, [currentRegion?.area.id]);

  return (
    <>
      <Design
        border="1px solid"
        borderColor="border"
        position="relative"
        height="48px"
        width="100%"
        borderRadius="8px"
        display="flex"
        alignItems="center"
        marginTop="24px"
        padding="14px 16px"
        justifyContent="space-between"
        className="has-pointer"
        onClick={handleShowAreaSelector}
      >
        <Design
          position="absolute"
          backgroundColor="background"
          top="-9px"
          left="16px"
          padding="0 4px"
        >
          <Wording
            color="placeholder"
            fontSize="12px"
            fontWeight="500"
            lineHeight="18px"
          >
            {label}
          </Wording>
        </Design>
        <Wording fontSize="14px" fontWeight="500" lineHeight="20px">
          {inputValue}
        </Wording>
        <Design height="20px">
          <ChevronRight height="20px" />
        </Design>
      </Design>
      <AreaSelector
        customerSelectedRegion={regionData}
        onUpdateRegion={handleUpdateRegion}
      />
    </>
  );
};

export default AddressAreaInputModule;
