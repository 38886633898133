import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConfirmationResult, UserCredential } from 'firebase/auth';

import { Modal } from '@components';
import {
  AuthLoginOverview,
  SpinnerModule,
  AuthPhoneVerify,
  AuthEmail,
  AuthEmailForgetPassword,
} from '@modules';
import { appSelectors } from '@selectors';
import { usePromise } from '@hooks';
import { appActions } from '@actions';
import { AuthStateEnum } from '@models';
import {
  triggerAuthFlowEvent,
  triggerEnterNumberEvent,
} from '@utils/seo/events';

import classes from './auth-modal.module.scss';

const AuthModal: FC = () => {
  const promise = usePromise();
  const visibleAuthModal = useSelector(appSelectors.visibleAuthModal);

  const [mainLoading, setMainLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [OTPConfirmation, setOTPConfirmation] = useState<ConfirmationResult>();
  const [authState, setAuthState] = useState<AuthStateEnum>(
    AuthStateEnum.LOGIN_OVERVIEW
  );

  const handleResetState = () => setAuthState(AuthStateEnum.LOGIN_OVERVIEW);

  const handleVerifyStep = (phone: string, OTP: ConfirmationResult) => {
    triggerEnterNumberEvent();
    setPhoneNumber(phone);
    setOTPConfirmation(OTP);
    setAuthState(AuthStateEnum.VERIFY_PHONE);
  };

  const handleVerifiedNumber = (user: UserCredential) => {
    setTimeout(() => {
      promise(appActions.toggleAuthModal(false));
      handleResetState();
    }, 2000);
  };

  const handleHideModal = () => {
    handleResetState();
    promise(appActions.toggleAuthModal(false));
  };
  const handleBackState = () => {
    if (authState === AuthStateEnum.FORGET_PASSWORD) {
      setAuthState(AuthStateEnum.LOGIN_EMAIL);
      return;
    }
    handleResetState();
    return;
  };

  useEffect(() => {
    if (visibleAuthModal) triggerAuthFlowEvent();
  }, [visibleAuthModal]);

  // Generate modal title base on state of authentication
  const modalTitle: string = useMemo(() => {
    switch (authState) {
      case AuthStateEnum.LOGIN_EMAIL:
        return 'LOGIN WITH EMAIL';
      case AuthStateEnum.VERIFY_PHONE:
        return 'VERIFICATION';
      case AuthStateEnum.FORGET_PASSWORD:
        return 'FORGOT PASSWORD';
      case AuthStateEnum.INFORMATION:
        return 'ADDITIONAL INFORMATION';
      default:
        return 'LOGIN/REGISTER';
    }
  }, [authState]);

  const renderContent: JSX.Element = useMemo(() => {
    switch (authState) {
      case AuthStateEnum.LOGIN_EMAIL:
        return (
          <AuthEmail
            onForgetPassword={() => setAuthState(AuthStateEnum.FORGET_PASSWORD)}
            onResetState={handleResetState}
          />
        );
      case AuthStateEnum.VERIFY_PHONE:
        return (
          <AuthPhoneVerify
            OTP={OTPConfirmation}
            phoneNumber={phoneNumber}
            onSetOTP={setOTPConfirmation}
            onVerified={handleVerifiedNumber}
            onChangePhone={handleBackState}
          />
        );
      case AuthStateEnum.FORGET_PASSWORD:
        return <AuthEmailForgetPassword />;
      case AuthStateEnum.INFORMATION:
        return <></>;
      default:
        return (
          <AuthLoginOverview
            onLoading={setMainLoading}
            onEmailLogin={() => setAuthState(AuthStateEnum.LOGIN_EMAIL)}
            onGetCode={handleVerifyStep}
          />
        );
    }
  }, [authState]);

  return (
    <Modal
      title={modalTitle}
      maskClosable={true}
      visible={visibleAuthModal}
      headerActions={
        authState === AuthStateEnum.LOGIN_OVERVIEW ? 'close' : 'both'
      }
      width={576}
      onCancel={handleHideModal}
      onBack={handleBackState}
      wrapClassName={classes.ModalContainer}
      fullTablet
    >
      <SpinnerModule loading={mainLoading} height="100%" width="100%">
        <div className={classes.Container}>{renderContent}</div>
      </SpinnerModule>
    </Modal>
  );
};

export default AuthModal;
