type Size =
  | '80'
  | '80@2x'
  | '128'
  | '128@2'
  | '360'
  | '360@2x'
  | '512'
  | '512@2x'
  | '1024'
  | '1024@2x'
  | 'original';

export interface LanguagesName {
  en: string;
  ar: string;
}

export interface ListOrder {
  order_by: string;
  order_dir: string;
}

export interface PaginationModel {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  from: number;
  to: number;
}

export interface UploadedImageModel {
  urls: Record<Size, string>;
}

export interface CityModel {
  id: number;
  name: string;
}

export interface PromiseModelArgs {
  type:
    | string // single
    | [
        string, // ACTION
        string, // ACTION_REQUESTING
        string, // ACTION_SUCCESS
        string // ACTION_FAILURE
      ]; // promise
  method?: string; // 'get' | 'post' | 'put' | 'patch' | 'delete'
  url?: string;
  payload?: any;
  data?: any;
  params?: any;
  isPublic?: boolean; // @TODO: should be removed - useless in new refresh token procedure
}

export type PromiseModel = (args: PromiseModelArgs) => Promise<any>;

export enum StockStatusEnum {
  IN_STOCK = 'in_stock',
  OUT_OF_STOCK = 'out_of_stock',
}
export enum VisualSwatchTypeEnum {
  COLOR = 'color',
  IMAGE = 'image',
}

export interface BaseParams {
  page: number;
  page_size: number;
  order_by: string;
  order_dir: string;
}

export interface ComponentBaseProps {
  className?: string;
}

export interface LocalModel {
  id: string;
  name: string;
  image: string;
  link: string;
}
