import React, { FC } from 'react';

import {
  Design,
  Wording,
  CheckoutCartSummary,
  CheckoutNote,
  CheckoutPaymentOptions,
  Button,
} from '@components';
import {
  CheckoutDeliveryTime,
  CheckoutPriceDetails,
  PromotionCode,
} from '@modules';

type Props = {
  handlePlaceOrder: () => void;
  actionLoading: boolean;
};
const CheckoutPaymentDesktopComponent: FC<Props> = ({
  handlePlaceOrder,
  actionLoading,
}) => {
  return (
    <Design
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      marginTop="42px"
    >
      <Design width="100%" flex="1" maxWidth="788px">
        <CheckoutPaymentOptions />

        <Design
          padding="24px"
          borderRadius="8px"
          backgroundColor="background"
          marginBottom="16px"
        >
          <Wording
            fontWeight="500"
            fontSize="20px"
            lineHeight="28px"
            color="surface"
          >
            Add Your Promotion Code
          </Wording>
          <PromotionCode />
        </Design>
        <CheckoutCartSummary />
        <CheckoutNote />
      </Design>
      <Design marginLeft="16px" maxWidth="486px" width="100%">
        <Design
          padding="36px 42px"
          borderRadius="8px"
          backgroundColor="background"
          marginBottom="16px"
        >
          <CheckoutPriceDetails />
          <Design
            paddingTop={{
              all: '20px',
              desktop: '32px',
              tablet: '24px',
            }}
          >
            <Button
              id="place-order-button"
              shape="round"
              type="primary"
              size="large"
              fullWidth
              onClick={handlePlaceOrder}
              loading={actionLoading}
            >
              PLACE ORDER
            </Button>
          </Design>
        </Design>
        <CheckoutDeliveryTime />
      </Design>
    </Design>
  );
};

export default CheckoutPaymentDesktopComponent;
