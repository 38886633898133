import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';

import addresses from './addresses.reducer';
import app from './app.reducer';
import cart from './cart.reducer';
import categories from './categories.reducer';
import checkout from './checkout.reducer';
import cms from './cms.reducer';
import contact from './contact.reducer';
import firebase from './firebase.reducer';
import headerLinks from './header-links.reducer';
import home from './home.reducer';
import order from './order.reducer';
import profile from './profile.reducer';
import wishlist from './wishlist.reducer';
import products from './products.reducer';

const combinedReducer = combineReducers({
  addresses,
  checkout,
  cms,
  app,
  cart,
  categories,
  contact,
  headerLinks,
  home,
  order,
  profile,
  firebase,
  wishlist,
  products,
});

const reducer = (state, action) => {
  if (action.type == HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };

    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

export default reducer;
