import { Design, Wording } from '@components';
import React, { FC } from 'react';
import { ChevronRight } from 'react-feather';

type Props = {
  city: any;
  isLast: boolean;
  onSelect: () => void;
};
const RegionCitiesItemModule: FC<Props> = ({ city, isLast, onSelect }) => {
  return (
    <Design
      borderBottom="1px solid"
      borderColor={isLast ? 'transparent' : 'natural'}
      padding={{
        all: '0 16px',
        desktop: '0 12px',
      }}
    >
      <Design
        padding="13px 0"
        borderRadius="8px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="has-pointer"
        height="48px"
        onClick={onSelect}
      >
        <Wording fontWeight="500" fontSize="16px" lineHeight="22px">
          {city.label}
        </Wording>
        <Wording fontSize="22px" lineHeight="22px">
          <ChevronRight />
        </Wording>
      </Design>
    </Design>
  );
};

export default RegionCitiesItemModule;
