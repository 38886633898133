import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import { Modal } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { showRewardModal } from '@helpers';
import { Media } from '@components';
import { triggerRewardFlowEvent } from '@utils/seo/events';

import RewardLoginOverview from '../reward-login-overview';

import classes from '../reward-manager/reward-manager.module.scss';

const RewardManager: FC = () => {
  const auth = getAuth();

  const [showReward, setShowReward] = useState(false);
  const [showed, setShowed] = useState(false);
  const [second, setSecond] = useState(12);

  onAuthStateChanged(auth, (user) => {
    if (user && !user?.isAnonymous)
      setTimeout(() => setShowReward(false), 20000);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setSecond((second) => {
        if (second - 1 > 0) return second - 1;
        clearInterval(interval);
        return 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const { currentUser } = auth;

    if (second === 0) {
      const status = showRewardModal(
        currentUser ? currentUser?.isAnonymous : true
      );

      if (!showed && status) {
        setTimeout(() => {
          setShowReward(status);
          setShowed(true);
        }, 3000);
      }
    }
  }, [second]);

  useEffect(() => {
    if (showed) triggerRewardFlowEvent();
  }, [showed]);

  return (
    <>
      <Media greaterThan="laptop">
        <Modal
          destroyOnClose
          visible={showReward}
          footer={false}
          onCancel={() => setShowReward(false)}
          centered
          className={classes.RewardModalWrapper}
          closeIcon={
            <Image
              height={36}
              width={36}
              src="/icons/reward/close-reward-icon.svg"
              alt="close-reward-icon"
            />
          }
          width={768}
        >
          <RewardLoginOverview onClose={() => setShowReward(false)} />
        </Modal>
      </Media>

      <Media lessThan="desktop">
        <BottomSheet
          open={showReward}
          onDismiss={() => setShowReward(false)}
          className={classes.RewardBottomSheetWrapper}
        >
          <RewardLoginOverview onClose={() => setShowReward(false)} />
        </BottomSheet>
      </Media>
    </>
  );
};

export default RewardManager;
