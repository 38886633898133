import { AddressModel, FetchingDataType } from '@models';

export enum CheckoutStepEnum {
  SHIPPING = 1,
  PAYMENT = 2,
  DONE = 3,
}

export interface CheckoutStateModel {
  details: FetchingDataType<CheckoutModel>;
  paymentMethods: FetchingDataType<PaymentMethodModel[]>;
  checkoutParams: Partial<LoadCheckoutParamsModel>;
  note: string;
}

export interface LoadCheckoutParamsModel {
  payment_method: PaymentMethodEnum;
  billing_address_id: number;
  shipping_address_id: number;
  coupon_code: string;
  use_wallet: boolean;
  area_id: number;
}

export interface CheckoutModel {
  billing_address: AddressModel;
  estimated_shipping: string;
  items_sub_total: number;
  items_total: number;
  payment_method: CheckoutPaymentMethodModel;
  price_items: CheckoutPriceItemModel[];
  promotion: PromotionModel;
  raw_total: number;
  shipping_address: AddressModel;
  sub_total: number;
  total: number;
  use_wallet: boolean;
}

export enum CheckoutPriceItemsType {
  SHIPPING = 'price_item_type_shipping',
  DISCOUNT = 'price_item_type_discount',
  PROMOTION = 'price_item_type_promotion',
  WALLET = 'price_item_type_wallet',
  VAT = 'price_item_type_vat',
}

export interface CheckoutPriceItemModel {
  amount: number;
  is_discount: boolean;
  title: string;
  type: CheckoutPriceItemsType;
}

export interface PromotionModel {
  coupon_code: string;
  description: string;
  discount: number;
  name: string;
}

export interface CheckoutPaymentMethodModel {
  amount: number;
  description: string;
  slug: PaymentMethodEnum;
  title: string;
}
export interface PaymentMethodModel {
  description: string;
  slug: PaymentMethodEnum;
  title: string;
  is_default: boolean;
}

export enum PaymentMethodEnum {
  COD = 'cod',
  ONLINE = 'online',
}

export interface ValidatePromotionRequestModel {
  billing_address_id: number;
  coupon_code: string;
  items: ValidatePromotionRequestItemModel[];
  payment_method: PaymentMethodEnum;
  shipping_address_id: number;
}

export interface ValidatePromotionRequestItemModel {
  id?: number;
  price?: number;
  product_id: number;
  qty: number;
  variation_id?: number;
}
