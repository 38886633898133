import React, { FC, useEffect, useMemo, useState } from 'react';
import { Input } from 'antd';

import { RegionAreaItem } from '@modules';
import { Design, Wording } from '@components';
import { AddressAreaModel } from '@models';

import styles from './region-area-list.module.scss';

type Props = {
  allAreas: AddressAreaModel[];
  selectedArea: AddressAreaModel | null;
  onSelectArea: (id: AddressAreaModel | null) => void;
};
const RegionAreaListModule: FC<Props> = ({
  allAreas,
  selectedArea,
  onSelectArea,
}) => {
  const [query, setQuery] = useState('');

  const filteredAreas = useMemo(() => {
    if (!query) return allAreas;
    return allAreas.filter((i) =>
      i.name ? i.name.toLowerCase().search(query.toLowerCase()) > -1 : i
    );
  }, [query, allAreas]);

  const areasCount = useMemo(() => filteredAreas.length, [allAreas]);

  // Factory function that only accept alphabets and numbers
  const handleSearchItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const regX = /^[a-zA-Z0-9_.-.\s]*$/g;
    if (regX.test(inputValue) || inputValue === '') setQuery(inputValue);
  };

  // Empty search when region update
  useEffect(() => {
    setQuery('');
  }, [allAreas]);

  const renderEmptySearch = () => (
    <Design padding="10px 12px" textAlign="center">
      <Wording>No results found for “{query}”</Wording>
    </Design>
  );

  return (
    <>
      {areasCount > 5 && (
        <Design
          marginBottom="6px"
          padding={{
            all: '10px 16px',
            desktop: '10px 12px',
          }}
        >
          <Input
            className={styles.FilterSearch}
            onChange={handleSearchItems}
            value={query}
            placeholder="Search area or zone"
            allowClear
          />
        </Design>
      )}

      <Design
        padding="10px 12px"
        paddingBottom="100px"
        height={{
          all: 'calc(100vh - 200px)',
          desktop: '400px',
        }}
        overflow="auto"
      >
        {filteredAreas && filteredAreas.length > 0
          ? filteredAreas.map((i) => (
              <RegionAreaItem
                key={[i.id, 'area'].join('_')}
                area={i}
                isChecked={selectedArea?.id === i.id}
                onSelect={() => onSelectArea(i)}
              />
            ))
          : renderEmptySearch()}
      </Design>
    </>
  );
};

export default RegionAreaListModule;
