import React, { FC } from 'react';
import ds from '@ds';
import { capitalize, replace } from 'lodash';
import { useRouter } from 'next/router';
import { X } from 'react-feather';

import { Design, Wording } from '@components';
import { CategoryFilterModel } from '@models';

import styles from './category-selected-filters.module.scss';

import FilterIcon from 'public/icons/filter-icon.svg';

type Props = {
  filters: CategoryFilterModel[];
  onRemove: (filters: Record<string, string | string[] | undefined>) => void;
};

const CategorySelectedFilters: FC<Props> = ({ filters, onRemove }) => {
  const router = useRouter();

  const searchTerm = router?.query?.q;

  const renderDiscountLabel = (name: string) => {
    const [left, right] = name.split('-').sort((a, b) => Number(a) - Number(b));

    if (right === '0' && left === '0') return 'No Discount';

    if (left === '0') return `Up to ${right}`;

    return `${left}% - ${right}%`;
  };

  const handleRenderItemLabel = (slug: string, name: string) => {
    // When filters are boolean type
    if (name === 'true') return slug.split('-').map(capitalize).join(' ');

    // Other Filters
    switch (slug) {
      case 'size':
        return replace(name, '-', ' ').toUpperCase();
      case 'color':
        return capitalize(replace(name, '-', ' '));
      case 'discount':
        return renderDiscountLabel(name);
      case 'price':
        return replace(name, '-', ' ~ ').toUpperCase();
      default:
        return name.split('-').map(capitalize).join(' ');
    }
  };

  const handleRemoveItem = (slug: string, value: string) => {
    const { slug: routeSlugs, ...routerQuery } = Object.assign(
      {},
      router.query
    );

    const findItem = filters.find((i) => i.slug === slug)?.values ?? [];
    const filteredItem = findItem.filter((i) => i !== value) ?? [];

    let queryFilters = {
      ...routerQuery,
      [slug]: filteredItem.join(',') || '',
      page: '1',
    };

    if (filteredItem.length === 0) {
      delete queryFilters[slug];
    }

    router.push(
      {
        pathname: searchTerm
          ? '/search'
          : `/category/${router.query.slug?.[0]}/products/`,
        query: queryFilters,
      },
      undefined,
      { shallow: true }
    );
    onRemove({ ...queryFilters, category_slug: router.query.slug?.[0] });
  };

  return (
    <Design display="flex" gap="16px" margin="0 0 32px 0" alignItems="baseline">
      <Design display="flex" gap="5px" alignItems="center">
        <FilterIcon />
        <Wording fontWeight="500" color="caption">
          Filters:
        </Wording>
      </Design>

      <Design
        display="flex"
        gap={{ all: '16px', mobile: '8px' }}
        flexWrap={{ all: 'wrap', mobile: 'nowrap' }}
        position="relative"
        top="-6px"
        overflowX={{ all: 'visible' }}
        overflowY={{ all: 'visible', mobile: 'hidden' }}
        padding={{ all: '0', mobile: '0 15px 0 0' }}
        margin={{ all: '0', mobile: '0 -15px 0 0' }}
        className={styles.Wrapper}
      >
        {filters?.map(({ slug, values }) =>
          values?.map((value) => (
            <Design
              key={value}
              backgroundColor="surface"
              borderRadius="100px"
              padding="7px 11px"
              display="flex"
              gap="6px"
              justifyContent="center"
              alignItems="center"
              className={styles.SelectedItem}
              onClick={() => handleRemoveItem(slug, value)}
            >
              <Wording
                fontSize="12px"
                fontWeight="500"
                lineHeight="18px"
                color="background"
                className={styles.ItemLabel}
              >
                {handleRenderItemLabel(slug, value)}
              </Wording>

              <X width={12} height={12} color={ds.color_background} />
            </Design>
          ))
        )}
      </Design>
    </Design>
  );
};

export default CategorySelectedFilters;
