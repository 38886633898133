import { wishlistActionTypes } from '@actionTypes';
import { takeLatest } from 'redux-saga/effects';

import axiosMiddleware from '../../axios.middleware';

export default function* wishlistSaga() {
  yield takeLatest(wishlistActionTypes.LOAD_ALL_WISHLIST, axiosMiddleware);
  yield takeLatest(wishlistActionTypes.ADD_TO_WISHLIST, axiosMiddleware);
  yield takeLatest(wishlistActionTypes.COMPACT_WISHLIST, axiosMiddleware);
  yield takeLatest(wishlistActionTypes.DETAIL_WISHLIST, axiosMiddleware);
  yield takeLatest(wishlistActionTypes.REPLACE_WISHLIST, axiosMiddleware);
  yield takeLatest(wishlistActionTypes.DELETE_WISHLIST, axiosMiddleware);
}
