import React, { ReactNode } from 'react';

import { Tabs as AntdTabs } from 'antd';

import styles from './tabs.module.scss';

type Props = {
  defaultActiveKey: string;
  children: ReactNode;
  centered?: boolean;
};

const TabsComponent = ({
  defaultActiveKey,
  children,
  centered = true,
}: Props) => {
  return (
    <AntdTabs
      centered={centered}
      className={styles.TabStyles}
      defaultActiveKey={defaultActiveKey}
    >
      {children}
    </AntdTabs>
  );
};

export default TabsComponent;
