import React, { FC, useState } from 'react';

import {
  Design, ProductAddToCart, ProductQuantityControl, ProductVariations, Wording,
} from '@components';
import { ProductStickyOrderProps, StockStatusEnum } from '@models';
import { formatPriceWithCurrency } from '@utils/number.utils';
import { animated, useSpring } from 'react-spring';
import { BottomSheet } from 'react-spring-bottom-sheet';

import styles from './sticky-order.module.scss';

const ProductStickyOrder: FC<ProductStickyOrderProps> = ({
  addToCartButton,
  variations,
  variationIsNotSelected,
  discountAmount,
  discountedPrice,
  price,
  inventoryQty,
  maxQty,
  minQty,
  relatedCartDetails,
  productDeal,
  activeDeal,
  stockStatus,
}) => {
  const [shakeVariations, setShakeVariations] = useState(false);
  const [showVariationsSheet, setShowVariationsSheet] = useState(false);
  const [shouldShake, setShouldShake] = useState(false);

  const handleAddToCartClick = () => {
    if (variationIsNotSelected) {
      setShowVariationsSheet(true);
      if (shouldShake) {
        setShakeVariations(true);
      } else {
        setShouldShake(true);
      }
    } else {
      setShowVariationsSheet(false);
      addToCartButton.onClick();
    }
  };

  const props = useSpring({
    x: shakeVariations ? 1 : 0,
    config: { duration: 200 },
    onRest: () => setShakeVariations(false),
  });

  const dismissVariations = () => {
    setShowVariationsSheet(false);
    setShouldShake(false);
  };

  if (stockStatus === StockStatusEnum.OUT_OF_STOCK) {
    return (
      <>
        <Design className={styles.Container}>
          <Design
            className={styles.InnerContainer}
            maxWidth="460px"
            padding={{
              all: '0',
              mobile: '0 16px',
            }}
          >
            <ProductAddToCart
              min_qty={addToCartButton.min_qty}
              max_qty={maxQty}
              relatedCartDetails={relatedCartDetails}
              product_id={addToCartButton.product_id}
              variation_id={addToCartButton.variation_id}
              onClick={handleAddToCartClick}
              isAllowedToAddToCart={addToCartButton.isAllowedToAddToCart}
              location="sticky"
              loading={addToCartButton.loading}
              onSetLoading={addToCartButton.onSetLoading}
              seoItems={addToCartButton.seoItems}
              onHideVariationsSheet={() => setShowVariationsSheet(false)}
              stockStatus={stockStatus}
            />
          </Design>
        </Design>
        <div
          className={`${styles.Backdrop} ${
            showVariationsSheet ? styles.ShowBackdrop : ''
          }`}
          onClick={dismissVariations}
        />
      </>
    );
  }

  return (
    <>
      <Design className={styles.Container}>
        <BottomSheet
          className={styles.BottomSheetContainer}
          open={showVariationsSheet}
          blocking={false}
          onDismiss={dismissVariations}
        >
          <div className={styles.VariationsContainer}>
            <animated.div
              style={{
                transform: props.x
                  .to({
                    range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                    output: [1, 25, 20, 12, 4, 25, 12, 1],
                  })
                  .to((x) => `translate(${x}px, 0)`),
              }}
            >
              <ProductVariations
                isInCart={true}
                variationTitlePrefix="Select"
                showErrorMessage={false}
                validateVariations={variations.validateVariations}
                onVariationSelection={variations.onVariationSelection}
                variations={variations.variations}
                selectedVariation={variations.selectedVariation}
              />
            </animated.div>
          </div>
        </BottomSheet>

        <Design
          className={styles.InnerContainer}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          maxWidth="460px"
          padding={{
            all: '0',
            mobile: '0 16px',
          }}
        >
          <Design display="flex" flexDirection="column">
            {discountAmount > 0 && (
              <Design display="flex" alignItems="center" gap="4px">
                <Wording
                  fontWeight="normal"
                  fontSize={{
                    all: '14px',
                    desktop: '18px',
                  }}
                  color="action"
                  decoration="line-through"
                >
                  {formatPriceWithCurrency(discountedPrice)}
                </Wording>
                <Wording color="error" fontWeight="600" fontSize="14px">
                  {discountAmount}% off
                </Wording>
              </Design>
            )}

            <Wording fontWeight="600" fontSize="18px">
              {formatPriceWithCurrency(price)}
            </Wording>
          </Design>
          <Design display="flex" alignItems="center" gap="16px">
            <div
              className={`${styles.QuantityControlContainer} ${styles.IsInCart}`}
            >
              {relatedCartDetails?.id && (
                <ProductQuantityControl
                  cartId={relatedCartDetails.id}
                  productId={addToCartButton.product_id}
                  page="sticky-order"
                  minQty={minQty}
                  maxQty={maxQty}
                  isInCartModal={false}
                  inventoryQty={inventoryQty}
                  qty={relatedCartDetails.qty}
                  productDeal={productDeal}
                  activeDeal={activeDeal}
                />
              )}
            </div>

            <ProductAddToCart
              min_qty={addToCartButton.min_qty}
              max_qty={maxQty}
              relatedCartDetails={relatedCartDetails}
              product_id={addToCartButton.product_id}
              variation_id={addToCartButton.variation_id}
              onClick={handleAddToCartClick}
              isAllowedToAddToCart={addToCartButton.isAllowedToAddToCart}
              location="sticky"
              loading={addToCartButton.loading}
              onSetLoading={addToCartButton.onSetLoading}
              seoItems={addToCartButton.seoItems}
              onHideVariationsSheet={() => setShowVariationsSheet(false)}
              stockStatus={stockStatus}
            />
          </Design>
        </Design>
      </Design>
      <div
        className={`${styles.Backdrop} ${
          showVariationsSheet ? styles.ShowBackdrop : ''
        }`}
        onClick={dismissVariations}
      />
    </>
  );
};

export default ProductStickyOrder;
