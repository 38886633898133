import { createMedia } from '@artsy/fresnel';

const breakpoints = {
  mobile: 0,
  tablet: 768,
  laptop: 992,
  desktop: 1320,
};

export const mediaQuery = {
  mobile: `(max-width: 767px)`,
  tablet: `(min-width: 768px) and (max-width: 1319px)`,
  laptop: `(min-width: 992px) and (max-width: 1319px)`,
  desktop: `(min-width: 1320px)`,
};

const OneSellAppMedia = createMedia({ breakpoints });

// Make styles for injection into the header of the page
export const mediaStyles = OneSellAppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = OneSellAppMedia;
