import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'react-feather';
import cn from 'clsx';
import { useRouter } from 'next/router';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import { createUrl } from '@helpers';
import { categoriesSelectors } from '@selectors';
import { ProductsListModel, ProductListDealModel, DealCategory } from '@models';
import { Design } from '@components';

import styles from './deal.module.scss';

type Props = {
  dealCategories: DealCategory[];
  products: ProductsListModel;
  deal: ProductListDealModel;
  onCategoryChanged: (slug: string) => void;
};

const DealCategories = ({
  dealCategories,
  products,
  deal,
  onCategoryChanged,
}: Props) => {
  const router = useRouter();

  const dealSlug = router?.query?.slug as string;
  const orderBy = router?.query?.order_by as string;
  const orderDir = router?.query?.order_dir as string;
  const categorySlug = (router?.query?.category_slug as string) || '';

  const [selectedCategory, setCategory] = useState('all');

  const categories = useSelector(categoriesSelectors.selectAllMap);

  // Handle filter based on deal category
  const handleCategoryFilter = (slug: string) => {
    if (slug === categorySlug) return;

    setCategory(slug);
    onCategoryChanged(slug);

    const newUrl = createUrl(
      router,
      pickBy(
        {
          category_slug: slug === 'all' ? null : slug,
          order_by: orderBy,
          order_dir: orderDir,
        },
        identity
      )
    ).search;

    router.push(`/deal/${dealSlug}?${newUrl}`, undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    if (products?.category_slug) return setCategory(products?.category_slug);
  }, [products?.category_slug]);

  return (
    <Design
      className={styles.DealSection}
      CustomBackgroundColor={deal?.background_color}
    >
      <Design
        className={styles.DealSectionHeader}
        CustomBackgroundColor={deal?.background_color}
      ></Design>
      <Design className={styles.DealTitle}>{deal?.title}</Design>
      <Design className={styles.DealSubtitle}>{deal?.sub_title}</Design>

      <Design className={cn('container', styles.DealItemContainer)}>
        <Design
          className={styles.DealItemWrapper}
          onClick={() => handleCategoryFilter('all')}
        >
          <Design
            className={styles.DealItemIcon}
            CustomBackgroundColor={
              selectedCategory === 'all' && !categorySlug
                ? '#ffffff40'
                : 'transparent'
            }
          >
            <Grid />
          </Design>

          <Design className={styles.DealItemTitle}>All Categories</Design>
        </Design>

        {dealCategories?.map((category) => {
          const categoryDetails = categories.get(category?.slug);
          return (
            <Design
              className={styles.DealItemWrapper}
              key={category?.slug}
              onClick={() => handleCategoryFilter(category?.slug)}
            >
              <Design
                className={styles.DealItemIcon}
                CustomBackgroundColor={
                  category?.slug === selectedCategory ||
                  category?.slug === categorySlug
                    ? '#ffffff40'
                    : 'transparent'
                }
              >
                <svg width="42" height="42" style={{ fill: '#fff' }}>
                  <image
                    xlinkHref={categoryDetails?.icon?.urls['original']}
                    width="42"
                    height="42"
                  />
                </svg>
              </Design>
              <Design className={styles.DealItemTitle}>
                {categoryDetails?.title}
              </Design>
            </Design>
          );
        })}
      </Design>
    </Design>
  );
};

export default memo(DealCategories);
