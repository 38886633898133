import { categoriesActionTypes } from '@actionTypes';

export function getCategories() {
  return {
    type: categoriesActionTypes.LOAD_CATEGORIES,
    method: 'get',
    url: '/Categories',
  };
}

export function categoriesView(slug: string) {
  return {
    type: categoriesActionTypes.CATEGORIES_VIEW,
    method: 'post',
    url: `/catalog/categories/${slug}/view`,
  };
}
