import { StoreModel } from '@models';
import { createSelector } from 'reselect';

const selectContact = (state: StoreModel) => state.contact;

export const selectDetails = createSelector(
  [selectContact],
  (contact) => contact.detail
);

export const selectPhone = createSelector(
  [selectContact],
  (contact) => contact.detail?.data?.phone
);

export const selectEmail = createSelector(
  [selectContact],
  (contact) => contact.detail?.data?.email
);
