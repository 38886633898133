import { FC, useEffect, useState } from 'react';

import { Image, mediaQuery } from '@components';
import { useMediaQuery } from '@hooks';
import { ProductImage } from '@models';
import { Modal } from 'antd';
import { X } from 'react-feather';

import styles from './product-image-gallery.module.scss';

interface Props {
  visibility: boolean;
  onClose: () => void;
  images: ProductImage[] | undefined;
  alt: string;
  initialSlide: number;
}

const ProductImageGalleryModal: FC<Props> = ({
  visibility,
  onClose,
  images,
  alt,
  initialSlide,
}: Props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [bigImageSrc, setBigImageSrc] = useState('');

  const isTablet = useMediaQuery(mediaQuery.tablet);

  useEffect(() => {
    setActiveSlide(initialSlide);
  }, [initialSlide, images]);

  useEffect(() => {
    setBigImageSrc(images?.at(activeSlide)?.urls['original'] ?? '');
  }, [activeSlide]);

  const onThumbnailClick = (index) => {
    setActiveSlide(index);
  };

  const renderThumbnails = () => {
    return images?.map((img, idx) => {
      return (
        <li
          key={idx}
          className={`${idx === activeSlide && styles.ActiveSlide}`}
          onClick={() => onThumbnailClick(idx)}
        >
          <Image
            src={img.urls.original}
            alt={alt}
            width={128}
            height={90}
            objectFit="contain"
          />
        </li>
      );
    });
  };

  return (
    <Modal
      centered
      title={alt}
      closeIcon={<X style={{ marginTop: 14 }} />}
      destroyOnClose
      bodyStyle={{
        minHeight: isTablet ? '100%' : '75vh',
        padding: 16,
      }}
      maskStyle={{
        backgroundColor: 'rgba(0,0,0,0.6)',
      }}
      visible={visibility}
      onCancel={onClose}
      width={isTablet ? '90vw' : '75vw'}
      footer={null}
    >
      <div className={styles.Container}>
        <ul className={styles.Thumbnails}>{renderThumbnails()}</ul>
        <div className={styles.BigImageContainer}>
          <div className={styles.BigImage}>
            {bigImageSrc && bigImageSrc.length > 0 && (
              <Image src={bigImageSrc} alt={alt} width={640} height={640} priority />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductImageGalleryModal;
