import React, { FC } from 'react';
import { Input } from 'antd';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { checkoutActions } from '@actions';
import { Design, Wording } from '@components';
import { usePromise } from '@hooks';
import { checkoutSelectors } from '@selectors';
import styles from './checkout-note.module.scss';

const { TextArea } = Input;

const CheckoutNoteComponent: FC = () => {
  const promise = usePromise();

  const checkoutNote = useSelector(checkoutSelectors.selectNote);

  /**
   * Factory function that update note after writing stop
   * @param event - input change event
   */
  const onChangeNoteInput = (event) => {
    debouncedWriting(event.target.value);
  };
  const debouncedWriting = debounce(
    (note) => promise(checkoutActions.updateCheckoutNote(note)),
    800
  );

  return (
    <Design
      padding={{
        all: '24px',
        tablet: '20px 20px 24px',
        mobile: '16px 16px 20px',
      }}
      borderRadius="8px"
      backgroundColor="background"
      marginBottom="16px"
      className={styles.NoteHolder}
    >
      <Design
        width="100%"
        margin="0 auto"
        maxWidth={{
          all: '100%',
          tablet: '460px',
        }}
      >
        <Wording
          fontWeight="500"
          fontSize={{
            desktop: '20px',
            all: '14px',
          }}
          lineHeight={{
            desktop: '28px',
            all: '20px',
          }}
          color="surface"
          marginBottom={{
            all: '24px',
            tablet: '20px',
            mobile: '16px',
          }}
        >
          Note for Order
        </Wording>
        <TextArea
          onChange={onChangeNoteInput}
          rows={6}
          placeholder="Write here..."
          defaultValue={checkoutNote}
        />
      </Design>
    </Design>
  );
};

export default CheckoutNoteComponent;
