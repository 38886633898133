import { cartActionTypes } from '@actionTypes';
import { CartAddModel } from '@models';

export function loadCartDetails(area_id?: number) {
  return {
    type: cartActionTypes.LOAD_CART_DETAILS,
    method: 'get',
    url: '/v2/cart',
    params: { area_id },
  };
}

export function cartAdd(data: CartAddModel) {
  return {
    type: cartActionTypes.CART_ADD,
    method: 'post',
    url: '/cart/items',
    data,
  };
}

export function cartUpdateQty(id: string, data: { qty: number }) {
  return {
    type: cartActionTypes.CART_UPDATE_QTY,
    method: 'patch',
    url: `/cart/items/${id}`,
    data,
  };
}

export function cartUpdateVariant(id: string, data: any) {
  return {
    type: cartActionTypes.CART_UPDATE_VARIANT,
    method: 'put',
    url: `/cart/items/${id}`,
    data,
  };
}

export function cartDelete(id: string) {
  return {
    type: cartActionTypes.CART_DELETE,
    method: 'delete',
    url: `/cart/items/${id}`,
  };
}

export function cartEmpty() {
  return {
    type: cartActionTypes.CART_EMPTY,
    method: 'delete',
    url: '/cart/empty',
  };
}

export function loadCartCompact(area_id?: number) {
  return {
    type: cartActionTypes.CART_COMPACT,
    method: 'get',
    url: '/cart/compact',
    params: { area_id },
  };
}

export function cartDetail() {
  return {
    type: cartActionTypes.CART_DETAIL,
    method: 'get',
    url: '/cart/detail',
  };
}
export function view() {
  return {
    type: cartActionTypes.CART_VIEW,
    method: 'post',
    url: '/cart/view',
  };
}
export function getRecommendations(params: { limit: number }) {
  return {
    type: cartActionTypes.GET_CART_RECOMMENDATIONS,
    method: 'get',
    url: '/cart/recommendations',
    params,
  };
}
