import { cartActionTypes } from '@actionTypes';

import { CartDetailsModel, CartStateModel } from '@models';
import { ItemInitialState, reducerItemInitialState } from './reducer.constant';

const initialState: CartStateModel = {
  all: reducerItemInitialState as any,
  compact: reducerItemInitialState as any,
  items: reducerItemInitialState as any,
  details: ItemInitialState({} as CartDetailsModel),
  recommendations: reducerItemInitialState as any,
};

const cartReducer = (state = initialState, action): CartStateModel => {
  switch (action.type) {
    /**
     * LOAD_ALL_CART_LIST
     */
    case cartActionTypes.LOAD_ALL_CART_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          fetching: true,
        },
      };
    case cartActionTypes.LOAD_ALL_CART_LIST_SUCCESS:
      return {
        ...state,
        all: {
          data: action.data,
          fetching: false,
        },
      };
    case cartActionTypes.LOAD_ALL_CART_LIST_FAILURE:
      return {
        ...state,
        all: {
          ...state.all,
          fetching: false,
        },
      };

    /**
     * CART_COMPACT
     */
    case cartActionTypes.CART_COMPACT:
      return {
        ...state,
        compact: {
          ...state.compact,
          fetching: true,
        },
      };
    case cartActionTypes.CART_COMPACT_SUCCESS:
      return {
        ...state,
        compact: {
          data: action.data,
          fetching: false,
        },
      };
    case cartActionTypes.CART_COMPACT_FAILURE:
      return {
        ...state,
        compact: {
          ...state.compact,
          fetching: false,
        },
      };

    /**
     * CART_ADD
     */
    case cartActionTypes.CART_ADD:
      return {
        ...state,
        compact: {
          ...state.compact,
          fetching: true,
        },
      };
    case cartActionTypes.CART_ADD_SUCCESS:
      return {
        ...state,
        compact: {
          data: action.data,
          fetching: false,
        },
      };
    case cartActionTypes.CART_ADD_FAILURE:
      return {
        ...state,
        compact: {
          ...state.compact,
          fetching: false,
        },
      };

    /**
     * CART_DELETE
     */
    case cartActionTypes.CART_DELETE:
      return {
        ...state,
        items: {
          ...state.items,
          fetching: true,
        },
      };
    case cartActionTypes.CART_DELETE_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          fetching: false,
        },
      };
    case cartActionTypes.CART_DELETE_FAILURE:
      return {
        ...state,
        items: {
          ...state.items,
          fetching: false,
        },
      };

    /**
     * LOAD_CART_DETAILS
     */
    case cartActionTypes.LOAD_CART_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          fetching: true,
        },
      };
    case cartActionTypes.LOAD_CART_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          data: action.data,
          fetching: false,
        },
      };
    case cartActionTypes.LOAD_CART_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          fetching: false,
        },
      };

    /**
     * GET_CART_RECOMMENDATIONS
     */
    case cartActionTypes.GET_CART_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          fetching: true,
        },
      };
    case cartActionTypes.GET_CART_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        recommendations: {
          data: action.data.data,
          fetching: false,
        },
      };
    case cartActionTypes.GET_CART_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          fetching: false,
        },
      };

    default:
      return state;
  }
};

export default cartReducer;
