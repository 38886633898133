import React, { useState, ReactNode } from 'react';
import { Form } from 'antd';
import { Rule } from 'antd/lib/form';

import InputLabel from './floating-label';
import { ValidateStatus } from 'antd/lib/form/FormItem';

type Types = {
  name: string;
  label: string;
  children: ReactNode;
  value?: string;
  error?: string[];
  withFormItem?: boolean;
  rules?: Rule[];
  validateStatus?: ValidateStatus;
  help?: React.ReactNode;
};

export default function InputsWrapper({
  name,
  label,
  children,
  value = '',
  error,
  withFormItem = true,
  rules,
  validateStatus,
  help,
}: Types) {
  const [active, setActive] = useState(false);

  const onBlur = () => {
    setActive(false);
  };

  const onFocus = () => {
    setActive(true);
  };

  if (withFormItem) {
    return (
      <Form.Item
        noStyle
        shouldUpdate={true}
        validateStatus={validateStatus}
        help={help}
      >
        {({ getFieldValue, getFieldError }) => (
          <InputLabel
            active={active}
            label={label}
            value={getFieldValue(name)}
            error={getFieldError(name)}
          >
            <Form.Item name={name} rules={rules}>
              <div onBlur={onBlur} onFocus={onFocus}>
                {children}
              </div>
            </Form.Item>
          </InputLabel>
        )}
      </Form.Item>
    );
  }

  return (
    <InputLabel active={active} label={label} value={value} error={error}>
      <div onBlur={onBlur} onFocus={onFocus}>
        {children}
      </div>
    </InputLabel>
  );
}
