import { useRouter } from 'next/router';

import { Button, Design, Wording } from '@components';

import DesktopEmptyCartIcon from 'public/icons/cart/empty-cart-desktop.svg';
import MobileEmptyCartIcon from 'public/icons/cart/empty-cart-mobile.svg';

const CartEmptyState = () => {
  const router = useRouter();

  const handleRedirect = () => {
    router.push('/');
  };

  return (
    <Design
      display="flex"
      flexDirection="column"
      backgroundColor="background"
      borderRadius={{ all: '8px', mobile: '0' }}
      justifyContent="center"
      alignItems="center"
      padding="66px 20px"
    >
      <Design
        margin={{ all: '0 0 24px 0', mobile: '0 0 12px 0' }}
        width={{ all: '140px', mobile: '120px' }}
        height={{ all: '140px', mobile: '120px' }}
      >
        <Design display={{ all: 'block', tablet: 'block', mobile: 'none' }}>
          <DesktopEmptyCartIcon />
        </Design>

        <Design display={{ all: 'none', mobile: 'block' }}>
          <MobileEmptyCartIcon />
        </Design>
      </Design>

      <Wording
        fontWeight="500"
        fontSize={{ all: '24px', mobile: '16px' }}
        lineHeight={{ all: '34px', mobile: '22px' }}
        margin="0 0 8px 0"
      >
        YOUR CART IS EMPTY
      </Wording>

      <Wording
        fontWeight="500"
        color="caption"
        textAlign="center"
        fontSize={{ all: '18px', mobile: '14px' }}
        lineHeight={{ all: '26px', mobile: '20px' }}
      >
        All your added items will be shown here.
      </Wording>

      <Design
        width={{ all: '360px', mobile: '294px' }}
        margin={{
          all: '36px 0 0 0',
          tablet: '36px 0 0 0',
          mobile: '24px 0 0 0',
        }}
      >
        <Button type="primary" shape="round" fullWidth onClick={handleRedirect}>
          GO SHOPPING
        </Button>
      </Design>
    </Design>
  );
};

export default CartEmptyState;
