import { profileActionTypes } from '@actionTypes';
import { takeLatest } from 'redux-saga/effects';

import axiosMiddleware from '../../axios.middleware';

export default function* profileSaga() {
  yield takeLatest(profileActionTypes.LOAD_PROFILE, axiosMiddleware);
  yield takeLatest(profileActionTypes.GIFT_CARD, axiosMiddleware);
  yield takeLatest(profileActionTypes.LOAD_WALLET_HISTORY, axiosMiddleware);
  yield takeLatest(
    profileActionTypes.LOAD_PROFILE_WALLET_BALANCE,
    axiosMiddleware
  );
  yield takeLatest(profileActionTypes.UPGRADE_TOKEN, axiosMiddleware);
  yield takeLatest(profileActionTypes.NEWSLETTER, axiosMiddleware);
  yield takeLatest(profileActionTypes.SET_FCM_TOKEN, axiosMiddleware);
  yield takeLatest(profileActionTypes.SET_SIGNUP_METHOD, axiosMiddleware);
}
