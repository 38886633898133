import React, { FC, useState } from 'react';
import { Form } from 'antd';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import { Button, TextInput } from '@components';
import { usePromise } from '@hooks';
import { firebaseErrorHandler, InternalSignInModel, OAuthKey } from '@helpers';
import { appActions, profileActions } from '@actions';
import { triggerLoginEvent } from '@utils/seo/events';

import classes from './auth-email.module.scss';

type AuthEmailProps = {
  onForgetPassword: () => void;
  onResetState: () => void;
};
const AuthEmail: FC<AuthEmailProps> = ({ onForgetPassword, onResetState }) => {
  const [form] = Form.useForm();

  const promise = usePromise();
  const [actionLoading, setActionLoading] = useState(false);

  // Handle login with credentials
  const handleLoginWithCredentials = async (
    loginFormValues: InternalSignInModel
  ) => {
    setActionLoading(true);
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(
        auth,
        loginFormValues.email,
        loginFormValues.password
      );

      triggerLoginEvent(OAuthKey.EMAIL);
      promise(profileActions.setSignUpMethod(OAuthKey.EMAIL));

      setTimeout(() => {
        promise(appActions.toggleAuthModal(false));
        onResetState();
      }, 2000);
    } catch (err) {
      firebaseErrorHandler(err);
    } finally {
      setTimeout(() => {
        setActionLoading(false);
      }, 2000);
    }
  };

  return (
    <div className={classes.Container}>
      <p className={classes.Message}>Please enter your email and password.</p>
      <Form
        name="login_with_email"
        autoComplete="off"
        form={form}
        onFinish={handleLoginWithCredentials}
      >
        <TextInput
          name="email"
          label="Email Address*"
          type="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ]}
        />

        <TextInput
          name="password"
          label="Password*"
          type="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        />

        <p className={classes.ForgetButton} onClick={onForgetPassword}>
          Forgot Password
        </p>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              size="large"
              shape="round"
              type="primary"
              fullWidth
              htmlType="submit"
              id="submitBtn"
              className={classes.ActionButton}
              loading={actionLoading}
            >
              LOGIN
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default AuthEmail;
