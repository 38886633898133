import Axios from 'axios';

const _instance = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    accept: 'application/json',
    'App-Platform': 'web',
  },
});

export default _instance;
