import { StoreModel } from '@models';
import { createSelector } from 'reselect';

const selectCheckout = (state: StoreModel) => state.checkout;

export const selectDetails = createSelector(
  [selectCheckout],
  (checkout) => checkout.details
);

export const selectPaymentMethods = createSelector(
  [selectCheckout],
  (checkout) => checkout.paymentMethods
);

export const selectParams = createSelector(
  [selectCheckout],
  (checkout) => checkout.checkoutParams
);

export const selectNote = createSelector(
  [selectCheckout],
  (checkout) => checkout.note
);
