import React, { FC, useEffect, useState } from 'react';
import cn from 'clsx';

import { Design, Media, mediaQuery } from '@components';
import { axiosHelper } from '@helpers';
import { useMediaQuery } from '@hooks';
import {
  CarouselProductsListModel,
  Mode,
  ProductsListProps,
  SeoEventListLocation,
} from '@models';
import { ProductCard } from '@modules';
import { Pagination } from 'antd';
import { useKeenSlider } from 'keen-slider/react';
import Image from 'next/image';
import Link from 'next/link';
import { ArrowLeft, ArrowRight, ChevronRight } from 'react-feather';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import styles from './products-list.module.scss';

const ProductsListModule: FC<ProductsListProps> = (
  props: ProductsListProps
) => {
  const {
    title,
    listLocation,
    slidePerView,
    dealsList,
    path,
    showMore,
    lazy,
    products,
    showSeeMoreCard,
    mode,
    columns,
    margin,
    firstCardData,
    showShimmerEffect = true,
    withDisplayEffect = false,
    productInNewTab = false,
    showTimer,
    pagination,
    categorySlug,
  } = props;

  const isTablet = useMediaQuery(mediaQuery.tablet);
  const isMobile = useMediaQuery(mediaQuery.mobile);
  const isLaptop = useMediaQuery(mediaQuery.laptop);

  const [keenLoaded, setKeenLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderOptions: any = {
    created() {
      setKeenLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    breakpoints: {
      '(min-width: 0px)': {
        mode: 'free',
        slides: {
          spacing: 8,
          perView: slidePerView?.mobile ?? slidePerView?.desktop,
        },
      },
      '(min-width: 360px)': {
        mode: 'free',
        slides: {
          spacing: 8,
          perView: 'auto',
        },
      },
      '(min-width: 768px)': {
        mode: 'free',
        slides: {
          spacing: 12,
          perView: 'auto',
        },
      },
      '(min-width: 992px)': {
        mode: 'free',
        slides: {
          spacing: 12,
          perView: 'auto',
        },
      },
      '(min-width: 1320px)': {
        mode: 'free-snap',
        slides: {
          perView: slidePerView?.desktop,
          spacing: 12,
        },
      },
    },
  };

  const [sliderRef, instanceRef] = useKeenSlider(sliderOptions);

  const wishlist: any = useSelector((state: any) => state.wishlist.compact);

  const [loaded, setLoaded] = useState(!lazy);
  const [lazyProducts, setLazyProducts] = useState<CarouselProductsListModel[]>(
    []
  );

  useEffect(() => {
    setTimeout(() => {
      // just a patch for now. we should find a better solution
      instanceRef.current?.update({
        ...sliderOptions,
      });
    }, 1000);
  }, [instanceRef]);

  const { ref: observe, inView } = useInView({
    triggerOnce: true,
    rootMargin: lazy ? '25%' : '0%',
  });

  useEffect(() => {
    if (inView && !loaded) {
      fetchProducts();
    }
  }, [inView]);

  const fetchProducts = async () => {
    const { data } = await axiosHelper({
      url: path,
    });

    setLazyProducts(data.data);
    setLoaded(true);
  };

  const getCardBadgeInfo = (item: CarouselProductsListModel) => {
    // check if were in deal type
    if (typeof dealsList === 'undefined')
      return {
        dealTitle: '',
        dealTitleBackground: '',
      };

    let dealTitle = 'DEAL';
    let dealTitleBackground = '#FFA000';

    if (item?.deal_slugs?.length > 0) {
      const slug = item.deal_slugs[0];
      const dealObject = dealsList[slug];
      if (dealObject) {
        dealTitle = dealObject.title;
        dealTitleBackground = dealObject.background_color;
      }
    }

    return {
      dealTitle,
      dealTitleBackground,
    };
  };

  const renderFirstCard = () => {
    if (!firstCardData) return;

    return (
      <div
        className={`keen-slider__slide ${styles['first-card__container']}`}
        key={`FIRST_CARD_${firstCardData?.imageAlt}`}
      >
        <Image
          src={firstCardData.imageUrl}
          alt={firstCardData.imageAlt}
          objectFit="contain"
          width={120}
          height={361}
        />
        <Link href={firstCardData.url ?? ''}>
          <a>
            View All
            <ChevronRight width={24} height={24} />
          </a>
        </Link>
      </div>
    );
  };

  const renderInlineProducts = () => {
    const list = lazy ? lazyProducts : products;
    const productsCards: JSX.Element[] = [];

    if ((isMobile || isTablet) && !isLaptop && firstCardData) {
      const firstCard = renderFirstCard();
      if (firstCard) productsCards.push(firstCard);
    }

    list?.map((item, index: number) => {
      const { dealTitle, dealTitleBackground } = getCardBadgeInfo(item);

      productsCards.push(
        <div className="keen-slider__slide" key={`${index}_${item.id}`}>
          <ProductCard
            showShimmerEffect={showShimmerEffect}
            cardLocation={listLocation}
            cardIndex={index}
            showTimer={showTimer}
            product={item}
            wishlistData={wishlist}
            dealTitle={dealTitle}
            dealTitleBackground={dealTitleBackground}
            // productInNewTab={true}
            categorySlug={categorySlug}
          />
        </div>
      );

      return item;
    });

    if (showSeeMoreCard) {
      productsCards.push(
        <div className="keen-slider__slide" key={`see_more_card`}>
          <Link href={path ?? '#'}>
            <div
              className={cn(styles.SeeMoreCard, {
                [styles.DealSeeMoreCard]:
                  listLocation === SeoEventListLocation.DEAL_PRODUCTS_LIST,
              })}
            >
              <a>
                <div className="arrow-icon">
                  <ArrowRight width={16} height={16} />
                </div>
                <p>See More</p>
              </a>
            </div>
          </Link>
        </div>
      );
    }

    if (isMobile || isTablet)
      productsCards.push(
        <div
          className={`keen-slider__slide ${styles.ExtraPaddingMobile}`}
          key={`extra_padding`}
        ></div>
      );

    return productsCards;
  };

  const renderGridProducts = () => {
    const list = lazy ? lazyProducts : products;

    const productsList = list?.map((item, index: number) => {
      const { dealTitle, dealTitleBackground } = getCardBadgeInfo(item);

      return (
        <ProductCard
          key={`${index}_${item.id}`}
          cardLocation={listLocation}
          showShimmerEffect={showShimmerEffect}
          cardIndex={index}
          product={item}
          showTimer={showTimer}
          wishlistData={wishlist}
          dealTitle={dealTitle}
          dealTitleBackground={dealTitleBackground}
          // productInNewTab={true}
          categorySlug={categorySlug}
        />
      );
    });

    if (showSeeMoreCard) {
      productsList.push(
        <div className={styles.SeeMoreCard} key={`see_more_card`}>
          <Link href={path ?? ''}>
            <a>
              <div className="arrow-icon">
                <ArrowRight width={16} height={16} />
              </div>
              <p>See More</p>
            </a>
          </Link>
        </div>
      );
    }

    return productsList;
  };

  const renderSliderArrows = () => {
    const prevButtonIsDisabled =
      (instanceRef.current && instanceRef.current.track.details.maxIdx === 0) ||
      currentSlide === 0;

    const nextButtonIsDisabled =
      (instanceRef.current && instanceRef.current.track.details.maxIdx === 0) ||
      (instanceRef.current &&
        currentSlide === instanceRef.current.track.details.maxIdx);

    return (
      <>
        <div
          className={`${styles.PrevButton} ${
            prevButtonIsDisabled ? styles.Disabled : ''
          }`}
        >
          <ArrowLeft
            color={'#fff'}
            onClick={(e) => {
              instanceRef.current?.prev();
            }}
          />
        </div>

        <div
          className={`${styles.NextButton} ${
            nextButtonIsDisabled ? styles.Disabled : ''
          }`}
        >
          <ArrowRight
            color={'#fff'}
            onClick={(e) => {
              instanceRef.current?.next();
            }}
          />
        </div>
      </>
    );
  };

  const renderPagination = () => {
    if (!pagination) return <></>;

    return (
      <Design margin="40px 0 30px 0" display="flex" justifyContent="center">
        <Pagination
          className={styles.PaginationContainer}
          defaultPageSize={pagination.pageSize}
          current={pagination.currentPage}
          total={pagination.totalItems}
          onChange={pagination.onPageChange}
          hideOnSinglePage={true}
          responsive={true}
          showSizeChanger={false}
          showTitle={false}
        />
      </Design>
    );
  };

  if (loaded === false) return <div ref={observe}></div>;

  if (products?.length === 0) return null;

  const renderInlineType = () => (
    <div
      className={`${styles.Container} ${
        withDisplayEffect ? styles.WithEffect : ''
      } ${inView && withDisplayEffect ? styles.InView : styles.OutOfView}`}
      ref={observe}
      style={{ margin: margin }}
    >
      <div className={styles.Header}>
        <h6>{title}</h6>
        {showMore && (
          <Link href={path ?? ''}>
            <a>
              View All
              <ArrowRight width={16} height={16} />
            </a>
          </Link>
        )}
      </div>
      <div className={styles.ProductsList}>
        <div ref={sliderRef} className="keen-slider">
          {renderInlineProducts()}
        </div>
        {keenLoaded && instanceRef.current && renderSliderArrows()}
      </div>
    </div>
  );

  const renderGridType = () => (
    <>
      <Media lessThan="tablet">
        <Design
          className={styles.Grid}
          gridTemplateColumns={`repeat(${columns?.mobile}, 1fr)`}
        >
          {renderGridProducts()}
        </Design>
      </Media>
      <Media between={['tablet', 'laptop']}>
        <Design
          className={styles.Grid}
          gridTemplateColumns={`repeat(${columns?.tablet}, 1fr)`}
        >
          {renderGridProducts()}
        </Design>
      </Media>
      <Media between={['laptop', 'desktop']}>
        <Design
          className={styles.Grid}
          gridTemplateColumns={`repeat(${columns?.laptop}, 1fr)`}
        >
          {renderGridProducts()}
        </Design>
      </Media>
      <Media greaterThan="laptop">
        <Design
          className={styles.Grid}
          gridTemplateColumns={`repeat(${columns?.desktop}, 1fr)`}
        >
          {renderGridProducts()}
        </Design>
      </Media>

      {renderPagination()}
    </>
  );

  return (
    <>
      {mode === Mode.INLINE && renderInlineType()}
      {mode === Mode.GRID && renderGridType()}
    </>
  );
};

ProductsListModule.defaultProps = {
  columns: {
    desktop: 3,
    tablet: 1,
    mobile: 1,
  },
  showMore: false,
  showSeeMoreCard: false,
  shadowColor: '#fafafa',
  mode: Mode.INLINE,
  path: '',
  lazy: false,
  slidePerView: {
    desktop: 5,
    tablet: 3,
    mobile: 3,
  },
  title: '',
  withDisplayEffect: true,
};

export default ProductsListModule;
