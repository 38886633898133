import { FC } from 'react';

import { Image, mediaQuery } from '@components';
import { isExternalUrl } from '@helpers';
import { useMediaQuery } from '@hooks';
import { BannersProps } from '@models';
import Link from 'next/link';

import styles from './banners.module.scss';

const BannersModule: FC<BannersProps> = ({ banners }: BannersProps) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const renderBanners = () => {
    return banners.map((banner, index) => {
      const isExternalLink = isExternalUrl(banner.url);
      const imgUrl = isMobile
        ? banner.mobile_image.urls.original
        : banner.desktop_image.urls.original;

      const image = <Image src={imgUrl} alt={banner.title} layout="fill" />;

      if (isExternalLink)
        return (
          <div key={`${banner.title}-${index}`}>
            <a href={banner.url} target="_self" rel="noreferrer">
              {image}
            </a>
          </div>
        );

      if (!isExternalLink)
        return (
          <div key={`${banner.title}-${index}`}>
            <Link href={banner.url}>
              <a>{image}</a>
            </Link>
          </div>
        );
    });
  };

  return (
    <div
      className={`${styles.Container} ${
        banners.length === 1 ? styles['items-1'] : styles['items-2']
      }`}
    >
      {renderBanners()}
    </div>
  );
};

export default BannersModule;
