import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Design,
  CheckoutCartSummary,
  CheckoutNote,
  CheckoutPaymentOptions,
  Wording,
} from '@components';
import {
  CheckoutDeliveryTime,
  CheckoutPriceDetails,
  PromotionCode,
} from '@modules';
import { checkoutSelectors } from '@selectors';
import { formatPriceWithCurrency } from '@utils/number.utils';

type Props = {
  handlePlaceOrder: () => void;
  actionLoading: boolean;
};
const CheckoutPaymentLessDesktopComponent: FC<Props> = ({
  handlePlaceOrder,
  actionLoading,
}) => {
  const { data: checkoutDetails } = useSelector(
    checkoutSelectors.selectDetails
  );

  return (
    <>
      <Design
        marginTop={{ all: '42px', tablet: '32px', mobile: '12px' }}
        paddingBottom="100px"
      >
        <CheckoutPaymentOptions />
        <Design
          padding={{
            all: '20px 20px 0',
            mobile: '16px 16px 0',
          }}
          borderRadius={{
            all: '8px',
            mobile: '0',
          }}
          backgroundColor="background"
          marginBottom="12px"
        >
          <Design
            margin="0 auto"
            width="100%"
            maxWidth={{ all: '100%', tablet: '460px' }}
          >
            <Design marginBottom={{ all: '20px', mobile: '16px' }}>
              <PromotionCode />
            </Design>
            <Design
              paddingBottom={{
                all: '20px',
                desktop: '32px',
                tablet: '24px',
              }}
            >
              <CheckoutPriceDetails />
            </Design>
          </Design>
        </Design>
        <CheckoutCartSummary />
        <CheckoutDeliveryTime />
        <CheckoutNote />
      </Design>
      <Design
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
        padding="8px 16px"
        backgroundColor="background"
        borderTop="1px solid"
        borderColor="natural"
        zIndex="2"
      >
        <Design maxWidth="460px" margin="0 auto">
          <Design
            display="flex"
            justifyContent="space-between"
            marginBottom="13px"
          >
            <Wording fontSize="16px" fontWeight="500" lineHeight="22px">
              Total Due
            </Wording>
            <Wording fontSize="16px" fontWeight="500" lineHeight="22px">
              {formatPriceWithCurrency(checkoutDetails?.total ?? 0)}
            </Wording>
          </Design>
          <Button
            shape="round"
            type="primary"
            size="large"
            fullWidth
            onClick={handlePlaceOrder}
            loading={actionLoading}
          >
            PLACE ORDER
          </Button>
        </Design>
      </Design>
    </>
  );
};

export default CheckoutPaymentLessDesktopComponent;
