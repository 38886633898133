import React, { FC, useEffect, useMemo, useState } from 'react';
import { Input } from 'antd';
import { useSelector } from 'react-redux';

import { RegionCitiesItem } from '@modules';
import { Design, Wording } from '@components';
import { AddressRegionCityModel } from '@models';
import { addressesSelectors } from '@selectors';

import styles from './region-cities-list.module.scss';

type Props = {
  onSelectCity: (id: AddressRegionCityModel) => void;
};
const RegionCitiesListModule: FC<Props> = ({ onSelectCity }) => {
  const [query, setQuery] = useState('');
  const allCities = useSelector(addressesSelectors.allCities);

  const filteredCities = useMemo(() => {
    if (!query) return allCities;
    return allCities.filter((i) =>
      i.label ? i.label.toLowerCase().search(query) > -1 : i
    );
  }, [query, allCities]);

  const citiesCount = useMemo(() => filteredCities?.length ?? 0, [allCities]);

  // Factory function that only accept alphabets and numbers
  const handleSearchItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const regX = /^[a-zA-Z0-9_.-.\s]*$/g;
    if (regX.test(inputValue) || inputValue === '') setQuery(inputValue);
  };

  // Empty search when region update
  useEffect(() => {
    setQuery('');
  }, [allCities]);

  const renderEmptySearch = () => (
    <Design padding="10px 12px" textAlign="center">
      <Wording>No results found for “{query}”</Wording>
    </Design>
  );

  return (
    <>
      {citiesCount > 5 && (
        <Design
          marginBottom="6px"
          padding={{
            all: '10px 16px',
            desktop: '10px 12px',
          }}
        >
          <Input
            className={styles.FilterSearch}
            onChange={handleSearchItems}
            value={query}
            placeholder="Search city"
            allowClear
          />
        </Design>
      )}
      <Design
        height={{
          all: 'calc(100vh - 130px)',
          desktop: '473px',
        }}
        overflow="auto"
      >
        {citiesCount > 0
          ? filteredCities.map((i, index) => (
              <RegionCitiesItem
                key={[i.id, 'city'].join('_')}
                city={i}
                onSelect={() => onSelectCity(i)}
                isLast={citiesCount === index + 1}
              />
            ))
          : renderEmptySearch()}
      </Design>
    </>
  );
};

export default RegionCitiesListModule;
