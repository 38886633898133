import React from 'react';

import { useMediaQuery } from '@hooks';
import { ProductModel } from '@models';
import { Design, mediaQuery } from '@components';

import MainOfferImage from './main-offer-image';
import MainOfferTitle from './main-offer-title';
import MainOfferDetails from './main-offer-details';
import MainOfferPrices from './main-offer-prices';
import MainOfferButtons from './main-offer-buttons';

type Props = {
  data: ProductModel;
};

const MainOffer = ({ data }: Props) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);

  return (
    <Design
      backgroundColor="background"
      borderRadius="8px"
      padding={{ all: '48px', tablet: '24px', mobile: '16px' }}
      position="relative"
      zIndex="1"
      display="flex"
      gap={{ all: '24px', mobile: '16px' }}
      margin={{
        all: '260px auto 0',
        tablet: '146px auto 0',
        laptop: '206px auto 0 !important',
        mobile: '177px auto 0',
      }}
    >
      <MainOfferImage
        slug={data?.slug}
        image={data?.images[0].urls['original']}
        title={data?.title}
      />

      <Design display="flex" flexDirection="column" flex="1">
        <MainOfferTitle
          slug={data?.slug}
          discountPercentage={data?.discount_percentage}
          title={data?.title}
        />

        {!isMobile && (
          <MainOfferDetails
            productId={data?.id}
            deliveryEstimation={data?.delivery_estimation}
          />
        )}

        <MainOfferPrices price={data?.price} listPrice={data?.list_price} />

        {!isMobile && (
          <MainOfferButtons
            id={data?.id}
            slug={data?.slug}
            discountAmount={data?.discount_amount}
            price={data?.price}
            inventoryQtyLeft={data?.inventory_qty_left}
            title={data?.title}
          />
        )}
      </Design>
    </Design>
  );
};

export default MainOffer;
