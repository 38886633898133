import { useMediaQuery } from '@hooks';
import { CheckoutHeader } from '@modules';
import { mediaQuery } from '@components';
import Design from '../design/design.component';

const CheckoutPageLayout = ({ children }) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);

  return (
    <>
      <CheckoutHeader />
      <Design
        marginTop={{
          all: '0',
          mobile: '80px',
        }}
        className={isMobile ? '' : 'container'}
      >
        {children}
      </Design>
    </>
  );
};

export default CheckoutPageLayout;
