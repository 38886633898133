import numeral from 'numeral';

import { Design, Wording, Button } from '@components';
import { getRegionCurrency } from '@helpers';
import { FC } from 'react';

type Props = {
  balance: number;
  onVisibleModal: () => void;
};

const WalletTitle: FC<Props> = ({ balance, onVisibleModal }) => {
  return (
    <Design
      display="flex"
      justifyContent="space-between"
      padding="16px"
      backgroundColor="background"
      borderRadius="8px"
      flexDirection={{ all: 'row', mobile: 'column' }}
    >
      <Design display="flex" flexDirection="column" gap="8">
        <Design>
          <Wording fontSize={{ all: '20px', mobile: '18px' }}>Balance</Wording>
        </Design>

        <Design display="flex" gap="8">
          <Wording
            fontSize={{ all: '32px', mobile: '24px' }}
            fontWeight="600"
            lineHeight="32px"
          >
            {balance ? numeral(balance).format('0,0.00') : 0}
          </Wording>
          <Design marginTop="8px">
            <Wording fontSize="16px" fontWeight="600" lineHeight="22px">
              {getRegionCurrency()}
            </Wording>
          </Design>
        </Design>
      </Design>

      <Design marginTop="22px">
        <Button type="primary" shape="round" onClick={onVisibleModal}>
          Redeem Code
        </Button>
      </Design>
    </Design>
  );
};

export default WalletTitle;
