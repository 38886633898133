import '../styles/antd-theme.less';
import '../styles/globals.scss';
import 'keen-slider/keen-slider.min.css';
import 'react-spring-bottom-sheet/dist/style.css';
import 'nprogress/nprogress.css';

import { useEffect, useState } from 'react';
import { DefaultSeo } from 'next-seo';
import axios from 'axios';
import { NextPage } from 'next';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Router, { useRouter } from 'next/router';
import Script from 'next/script';
import { useSelector } from 'react-redux';
import { END } from 'redux-saga';
import { BottomSheet } from 'react-spring-bottom-sheet';

import SEO_AE from 'next-seo-ae.config';
import SEO_QA from 'next-seo-qa.config';

import {
  categoriesActionTypes,
  contactsActionTypes,
  headerLinksActionTypes,
} from '@actionTypes';
import { usePromise, useStorage } from '@hooks';
import { AddressRegionSelected, AppPropsWithLayout, Region } from '@models';
import { ErrorBoundary, Media, MediaContextProvider, Modal } from '@components';
import {
  firebaseCloudMessaging,
  initializeApp,
  initializeClient,
} from '@firebase';
import {
  CloseFCMAlert,
  getRegion,
  ShowFCMAlert,
  getRegionToken,
  anonymousAxios,
} from '@helpers';
import { DevTools, RewardManager, StayTuned } from '@modules';
import { appSelectors } from '@selectors';
import { wrapper } from '@store';
import { profileActions } from '@actions';

const AppLoadingIndicator = dynamic<any>(
  () => import('@components').then((mod) => mod.AppLoadingIndicator),
  { ssr: false }
);

initializeApp();

export function reportWebVitals({ id, name, label, value }) {
  // window?.gtag('event', name, {
  //   event_category:
  //     label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
  //   value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
  //   event_label: id, // id unique to current page load
  //   non_interaction: true, // avoids affecting bounce rate.
  // })
}

const OneSellApp: NextPage<AppProps> = ({
  Component,
  pageProps,
}: AppPropsWithLayout) => {
  const promise = usePromise();
  const visibleAppBanner = useSelector(appSelectors.visibleAppBanner);
  const router = useRouter();
  const [mounted, setMounted] = useState(false);
  const [showFCMAlert, setShowFCMAlert] = useState(false);

  if (mounted) {
    firebaseCloudMessaging.onMessage();
  }

  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);
  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visibleAreaModal
  );

  useEffect(() => {
    initializeClient(promise, currentRegion?.area?.id);
    Router.events.on('routeChangeComplete', () =>
      initializeClient(promise, currentRegion?.area?.id)
    );
  }, []);

  useEffect(() => {
    firebaseCloudMessaging.init();
    const setToken = async () => {
      const token = await firebaseCloudMessaging.tokenInlocalforage();
      if (token) {
        promise(profileActions.setFCMToken({ fcm_token: token })).catch(() =>
          firebaseCloudMessaging.removeToken()
        );
        setMounted(true);
      }
    };
    setToken();
  }, []);

  // Show FCM Alert
  useEffect(() => {
    const handleShowModal = setTimeout(() => {
      setShowFCMAlert(ShowFCMAlert());
    }, 30000);
    return () => handleShowModal && clearTimeout(handleShowModal);
  }, []);

  const handleAllowNotification = () => {
    setShowFCMAlert(false);
    CloseFCMAlert();
    firebaseCloudMessaging.requestPermission();
  };

  const handleCloseFCMAlert = () => {
    CloseFCMAlert();
    setShowFCMAlert(false);
  };

  const getLayout = Component.getLayout ?? ((page) => page);

  const renderFCMModal = () => (
    <Modal
      visible={showFCMAlert}
      onCancel={handleCloseFCMAlert}
      centered={false}
    >
      <StayTuned
        onAllow={handleAllowNotification}
        onClose={handleCloseFCMAlert}
      />
    </Modal>
  );

  const renderFCMBottomSheet = () => (
    <BottomSheet open={showFCMAlert} onDismiss={handleCloseFCMAlert}>
      <StayTuned
        onAllow={handleAllowNotification}
        onClose={handleCloseFCMAlert}
      />
    </BottomSheet>
  );

  const renderFCMAlert = () => (
    <>
      <Media greaterThan="mobile">{renderFCMModal()}</Media>
      <Media lessThan="tablet">{renderFCMBottomSheet()}</Media>
    </>
  );

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, user-scalable=yes"
        />
        {process.env.NODE_ENV !== 'production' && (
          <meta name="robots" content="noindex" />
        )}
        <meta name="color-scheme" content="light" />
        <meta name="supported-color-schemes" content="light" />
      </Head>

      <Script
        id="google-gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTAG}');
                  `,
        }}
      />

      <Script
        id="universal-analytic"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
                     ga('create', '${process.env.NEXT_PUBLIC_UNIVERSAL_ANALYTIC}', 'auto');
                     ga('send', 'pageview');
                   `,
        }}
      />

      <Script
        id="facebook-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL}');
            fbq('track', 'PageView');
                  `,
        }}
      />

      {getRegion() === Region.AR_QA && (
        <DefaultSeo
          {...SEO_QA}
          canonical={process.env.NEXT_PUBLIC_SITE_URL + router.route}
          openGraph={{
            url: router.route,
          }}
        />
      )}

      {getRegion() === Region.AR_AE && (
        <DefaultSeo
          {...SEO_AE}
          canonical={process.env.NEXT_PUBLIC_SITE_URL + router.route}
          openGraph={{
            url: router.route,
          }}
        />
      )}

      <ErrorBoundary>
        <MediaContextProvider>
          <AppLoadingIndicator />
          <main
            className={[
              'main-container',
              visibleAppBanner ? 'visible-app-banner' : '',
            ].join(' ')}
          >
            <DevTools />

            {getLayout(<Component {...pageProps} />)}
            {renderFCMAlert()}

            <RewardManager />
          </main>
        </MediaContextProvider>
      </ErrorBoundary>

      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTAG}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>

      <Script
        strategy="afterInteractive"
        src="https://www.google-analytics.com/analytics.js"
      />

      {process.env.NODE_ENV !== 'development' && (
        <Script
          strategy="afterInteractive"
          id="hs-script-loader"
          src="https://js.hs-scripts.com/25661976.js"
        />
      )}

      {process.env.NODE_ENV !== 'development' && (
        <Script
          id="hotjar-analytic"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={${process.env.NEXT_PUBLIC_HOTJAR},hjsv:6}; 
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                  `,
          }}
        />
      )}
    </>
  );
};

OneSellApp.getInitialProps = wrapper.getInitialPageProps(
  (store) => async (input: any) => {
    const host = process.env.NEXT_PRIVATE_API_URL;

    if (!!host) {
      const requestList = [
        anonymousAxios.get('cms/headers_link'),
        anonymousAxios.get('catalog/categories'),
        anonymousAxios.get('contacts'),
      ];

      const values = await Promise.allSettled(requestList);

      // Header Links
      if (values[0].status === 'fulfilled') {
        const headerLinks = values[0].value.data;

        store.dispatch({
          type: headerLinksActionTypes.LOAD_HEADER_LINKS_SUCCESS,
          data: headerLinks,
        });
      }

      // Categories
      if (values[1].status === 'fulfilled') {
        const categories = values[1].value.data;

        store.dispatch({
          type: categoriesActionTypes.LOAD_ALL_CATEGORIES_SUCCESS,
          data: categories,
        });
      }

      // ContactInfo
      if (values[2].status === 'fulfilled') {
        const contactInfo = values[2].value.data;

        store.dispatch({
          type: contactsActionTypes.GET_CONTACTS_SUCCESS,
          data: contactInfo,
        });
      }

      // Stop the saga
      store.dispatch(END);
      await (store as any).sagaTask.toPromise();
    }

    return {} as any;
  }
);

export default wrapper.withRedux(OneSellApp);
