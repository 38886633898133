import { FC, useEffect, useState } from 'react';

import Image from 'next/image';
import ImagePlaceholder from 'public/icons/image-lazy-placeholder.svg';

import styles from './image.module.scss';

declare const VALID_LAYOUT_VALUES: readonly [
  'fill',
  'fixed',
  'intrinsic',
  'responsive',
  undefined
];

declare type LayoutValue = typeof VALID_LAYOUT_VALUES[number];
declare type StaticImport = StaticRequire | StaticImageData;
interface StaticRequire {
  default: StaticImageData;
}
export declare type ImageLoader = (resolverProps: ImageLoaderProps) => string;
export declare type ImageLoaderProps = {
  src: string;
  width: number;
  quality?: number;
};
declare type ImgElementStyle = NonNullable<
  JSX.IntrinsicElements['img']['style']
>;

interface Props {
  src: string | StaticImport;
  width?: number | string;
  height?: number | string;
  loaderBorderRadius?: string;
  layout?: LayoutValue;
  loader?: ImageLoader;
  quality?: number | string;
  priority?: boolean;
  isDark?: boolean;
  lazyRoot?: React.RefObject<HTMLElement> | null;
  lazyBoundary?: string;
  unoptimized?: boolean;
  objectFit?: ImgElementStyle['objectFit'];
  objectPosition?: ImgElementStyle['objectPosition'];
  className?: any;
  alt: string;
  showShimmerAnimation?: boolean;
  blurDataURL?: string;
  onClick?: () => void;
}

const ImageComponent: FC<Props> = ({
  src,
  unoptimized,
  priority,
  isDark = false,
  lazyBoundary,
  className,
  quality = 100,
  width,
  height,
  layout = 'intrinsic',
  loaderBorderRadius = '4px',
  showShimmerAnimation = true,
  objectFit,
  objectPosition,
  alt,
  onClick,
  blurDataURL,
  ...all
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState<any[]>([]);
  const [mountLoader, setMountLoader] = useState(true);

  const onLoadComplete = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (loading) return;

    let isMounted = true;

    const timeout = setTimeout(() => {
      if (isMounted) setMountLoader(false);
    }, 400);

    return () => {
      isMounted = false;
      clearTimeout(timeout);
    };
  }, [loading]);

  useEffect(() => {
    if (loadedImages.find((x) => x === src) === undefined) {
      setLoading(true);
      setMountLoader(true);
      setLoadedImages([...loadedImages, src]);
    }
  }, [src]);

  const normalizeSrc = (src) => {
    return src.startsWith('/') ? src.slice(1) : src;
  };

  const cloudflareLoader = ({ src, quality, width }: ImageLoaderProps) => {
    const params = ['format=webp'];

    if (width?.toString().indexOf('%') === -1) {
      params.push(`width=${width}`);
    }

    if (quality) {
      params.push(`quality=${quality}`);
    }
    const paramsString = params.join(',');
    return `https://1sell.com/cdn-cgi/image/${paramsString}/${normalizeSrc(
      src
    )}`;
  };

  if (blurDataURL && blurDataURL.length > 0)
    return (
      <Image
        loader={cloudflareLoader}
        onClick={onClick}
        onLoadingComplete={onLoadComplete}
        src={src}
        layout={layout}
        unoptimized={unoptimized}
        priority={priority}
        lazyBoundary={lazyBoundary}
        className={className}
        quality={quality}
        width={width}
        height={height}
        objectFit={objectFit}
        objectPosition={objectPosition}
        alt={alt}
        placeholder="blur"
        blurDataURL={blurDataURL}
      />
    );

  return (
    <>
      {mountLoader && (
        <div
          className={`${styles.Container} ${
            showShimmerAnimation ? styles.AnimatedShimmer : ''
          } ${isDark ? styles.DarkContainer : ''}`}
          style={{
            width: '100%',
            height: 'inherit',
            maxHeight: height + 'px',
            borderRadius: loaderBorderRadius,
            overflow: 'hidden',
            aspectRatio: '1/1',
            opacity: loading ? 1 : 0,
          }}
        >
          <ImagePlaceholder />
        </div>
      )}
      <Image
        loader={cloudflareLoader}
        onClick={onClick}
        onLoadingComplete={onLoadComplete}
        src={src}
        layout={layout}
        unoptimized={unoptimized}
        priority={priority}
        lazyBoundary={lazyBoundary}
        className={className}
        quality={quality}
        width={width}
        height={height}
        objectFit={objectFit}
        objectPosition={objectPosition}
        alt={alt}
      />
    </>
  );
};

export default ImageComponent;
