import React, { FC, useState } from 'react';
import { Button, Form, Input } from 'antd';

import { Design } from '@components';

import styles from './subscribe-form.module.scss';

type Props = {
  loading: boolean;
  onSubmit: (data: { email: string }) => void;
};
const SubscribeUserForm: FC<Props> = ({ loading, onSubmit }) => {
  const [email, setEmail] = useState('');
  return (
    <Design width="100%">
      <Design className={styles.InlineForm}>
        <Form
          name="subscribeForm"
          onFinish={onSubmit}
          layout="inline"
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Enter your email"
              name="email"
              type="email"
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              disabled={!email}
              type="primary"
              htmlType="submit"
            >
              Subscribe
            </Button>
          </Form.Item>
        </Form>
      </Design>
    </Design>
  );
};

export default SubscribeUserForm;
