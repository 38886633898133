import { profileActionTypes } from '@actionTypes';
import { OAuthKey } from '@helpers';

export function loadProfile() {
  return {
    type: profileActionTypes.LOAD_PROFILE,
    method: 'get',
    url: '/profile/me',
  };
}

export function loadProfileWalletBalance() {
  return {
    type: profileActionTypes.LOAD_PROFILE_WALLET_BALANCE,
    method: 'get',
    url: '/profile/wallet/balance',
  };
}

export function upgradeToken(token: String) {
  return {
    type: profileActionTypes.UPGRADE_TOKEN,
    method: 'post',
    url: '/upgrade',
    data: { token },
  };
}

export function giftCard(data: any) {
  return {
    type: profileActionTypes.GIFT_CARD,
    method: 'post',
    url: '/profile/wallet/redeem_gift_card',
    data,
  };
}

export function loadWalletHistory(params?) {
  return {
    type: profileActionTypes.LOAD_WALLET_HISTORY,
    method: 'get',
    url: '/profile/wallet/history',
    params,
  };
}

export function signUpNewsletter(data: { email: string }) {
  return {
    type: profileActionTypes.NEWSLETTER,
    method: 'post',
    url: '/newsletter/subscribe',
    data,
  };
}

export function setFCMToken(data: { fcm_token: string }) {
  return {
    method: 'post',
    url: '/profile/me/fcm_token',
    type: profileActionTypes.SET_FCM_TOKEN,
    data,
  };
}

export function setSignUpMethod(sign_up_method: OAuthKey) {
  return {
    method: 'post',
    url: '/events/sign-up',
    type: profileActionTypes.SET_SIGNUP_METHOD,
    data: {
      sign_up_method,
    },
  };
}
