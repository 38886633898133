import { Region } from '@models';
import {
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';

const getRegion = () => {
  return process.env.NEXT_PUBLIC_LOCALE as Region;
};

const getCurrentCountryCode = () => {
  const region = getRegion();

  switch (region) {
    case Region.AR_AE:
      return getCountryCallingCode('AE');
    case Region.AR_QA:
      return getCountryCallingCode('QA');
    default:
      return getCountryCallingCode('QA');
  }
};

const getRegionPhonePrefix = (): string => {
  return process.env.NEXT_PUBLIC_PHONE_PREFIX ?? '974';
};

const getPhoneWithPrefix = (phone: string): string => {
  return ['+', getRegionPhonePrefix(), phone].join('');
};

const getRegionCurrency = () => {
  return process.env.NEXT_PUBLIC_CURRENCY;
};

const getRegionByNumberPrefix = (prefix: string) => {
  switch (prefix) {
    case '971':
      return Region.AR_AE;

    case '974':
      return Region.AR_QA;
  }
};

const isRegionPhoneValid = (phoneNumber: string): boolean => {
  if (phoneNumber.length < 7 || isNaN(+phoneNumber)) return false;
  const prefix = parsePhoneNumber(`${phoneNumber}`)?.countryCallingCode;
  const region = getRegionByNumberPrefix(prefix);

  switch (region) {
    case Region.AR_QA:
      return isValidPhoneNumber(phoneNumber, 'QA');

    case Region.AR_AE:
      return isValidPhoneNumber(phoneNumber, 'AE');

    default:
      return false;
  }
};

export {
  getRegion,
  getRegionPhonePrefix,
  isRegionPhoneValid,
  getCurrentCountryCode,
  getRegionCurrency,
  getPhoneWithPrefix,
};
