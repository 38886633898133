import React, { useEffect, useMemo, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';

import { checkoutActions } from '@actions';
import { Design, Wording, notification } from '@components';
import { usePromise } from '@hooks';
import {
  CheckoutPaymentOptionItem,
  CheckoutUseWallet,
  SpinnerModule,
} from '@modules';
import { PaymentMethodEnum } from '@models';
import { checkoutSelectors, profileSelectors } from '@selectors';

import styles from './checkout-payment-options.module.scss';

const CheckoutPaymentOptionsComponent: FC = () => {
  const promise = usePromise();

  const wallet = useSelector(profileSelectors.wallet);
  const { area_id, ...checkoutParams } = useSelector(
    checkoutSelectors.selectParams
  );
  const { data: paymentOptions, fetching: paymentOptionsFetching } =
    useSelector(checkoutSelectors.selectPaymentMethods);
  const { data: checkoutDetails, fetching: checkoutFetching } = useSelector(
    checkoutSelectors.selectDetails
  );

  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [useWalletLoading, setUseWalletLoading] = useState(false);
  const [selectedMethodLoading, setSelectedMethodLoading] = useState(false);

  // Show remaining message
  const showRemainingMessage = useMemo(() => {
    if (!checkoutDetails || !wallet || !checkoutDetails?.use_wallet)
      return false;
    return wallet?.balance < checkoutDetails?.total;
  }, [checkoutDetails, wallet]);

  // Update state of using wallet for pay order
  const handleUseWallet = (status: boolean) => {
    setUseWalletLoading(true);
    promise(
      checkoutActions.getCheckout({
        ...checkoutParams,
        use_wallet: status,
      })
    )
      .finally(() => setUseWalletLoading(false))
      .then(() => {
        // Hide payment options before calling API
        if (
          status &&
          checkoutDetails &&
          wallet?.balance < checkoutDetails?.total
        )
          setShowPaymentOptions(false);

        // Show payment options before calling API
        if (!status) setShowPaymentOptions(true);
        promise(
          checkoutActions.updateCheckoutParams({
            use_wallet: status,
          })
        );
      })
      .catch((error) => {
        notification({
          type: 'error',
          message: error?.message,
        });
      });
  };

  /**
   * Factory function that update payment methods
   * @param {PaymentMethodEnum} slug - payment method slug
   */
  const handleSelectMethod = (slug: PaymentMethodEnum) => {
    setSelectedMethodLoading(true);
    promise(
      checkoutActions.getCheckout({
        ...checkoutParams,
        payment_method: slug,
      })
    )
      .finally(() => setSelectedMethodLoading(false))
      .then(() =>
        promise(checkoutActions.updateCheckoutParams({ payment_method: slug }))
      )
      .catch((error) => {
        notification({
          type: 'error',
          message: error?.message,
        });
      });
  };

  // Show or hide payment options
  useEffect(() => {
    if (!checkoutDetails || !wallet || !checkoutDetails?.use_wallet) {
      setShowPaymentOptions(true);
    } else {
      setShowPaymentOptions(wallet?.balance < checkoutDetails?.total);
    }
  }, [checkoutDetails, wallet]);

  // Show loading
  if (
    paymentOptionsFetching ||
    !paymentOptions ||
    !wallet ||
    checkoutFetching
  ) {
    return (
      <Design
        padding={{
          all: '24px',
          tablet: '20px',
          mobile: '16px',
        }}
        borderRadius={{
          all: '8px',
          mobile: '0',
        }}
        backgroundColor="background"
        marginBottom={{
          all: '12px',
          desktop: '16px',
        }}
      >
        <Design
          margin="0 auto"
          width="100%"
          maxWidth={{
            all: '100%',
            tablet: '460px',
          }}
        >
          <Skeleton.Input
            className={styles.SkeletonDesign}
            active
            size="small"
          />
          <br />
          <br />
          <Skeleton.Input
            className={styles.SkeletonDesign}
            active
            block
            size="small"
          />
          <br />
          <br />
          <Skeleton.Input
            className={styles.SkeletonDesign}
            active
            block
            size="small"
          />
        </Design>
      </Design>
    );
  }

  return (
    <Design
      padding={{
        all: '24px 24px 0',
        tablet: '20px 20px 0',
        mobile: '16px 16px 0',
      }}
      borderRadius={{
        all: '8px',
        mobile: '0',
      }}
      backgroundColor="background"
      marginBottom={{
        all: '12px',
        desktop: '16px',
      }}
    >
      <Design
        margin="0 auto"
        width="100%"
        maxWidth={{ all: '100%', tablet: '460px' }}
      >
        <Wording
          fontWeight="500"
          fontSize={{
            all: '14px',
            desktop: '20px',
          }}
          lineHeight={{
            all: '20px',
            desktop: '28px',
          }}
          color="surface"
          marginBottom={{ all: '0px', mobile: '4px' }}
        >
          Payment Options
        </Wording>
        {wallet?.balance > 0 && (
          <Design
            paddingTop={{
              all: '24px',
              tablet: '20px',
              mobile: '16px',
            }}
            paddingBottom={{
              all: !showPaymentOptions ? '16px' : '0',
              desktop: !showPaymentOptions ? '24px' : '0',
              tablet: !showPaymentOptions ? '20px' : '0',
              laptop: !showPaymentOptions ? '20px' : '0',
            }}
          >
            <CheckoutUseWallet
              useWallet={checkoutDetails?.use_wallet ?? false}
              balance={wallet.balance}
              onUseWallet={handleUseWallet}
              isChecked={checkoutDetails ? checkoutDetails?.use_wallet : false}
              actionLoading={useWalletLoading}
            />

            {showRemainingMessage && (
              <Wording
                marginTop={{
                  all: '8px',
                  desktop: '12px',
                }}
                fontSize="14px"
                fontWeight="500"
                lineHeight="20px"
              >
                You can select another way to pay the remaining amount.
              </Wording>
            )}
          </Design>
        )}

        {showPaymentOptions && (
          <Design>
            <SpinnerModule
              loading={selectedMethodLoading}
              width="100%"
              height="auto"
            >
              {paymentOptions?.map((option, index) => (
                <CheckoutPaymentOptionItem
                  hasBorder={index > 0}
                  option={option}
                  key={option.slug}
                  isSelected={
                    checkoutParams?.payment_method
                      ? checkoutParams.payment_method === option.slug
                      : option.is_default
                  }
                  onSelectMethod={handleSelectMethod}
                />
              ))}
            </SpinnerModule>
          </Design>
        )}
      </Design>
    </Design>
  );
};

export default CheckoutPaymentOptionsComponent;
