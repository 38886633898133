import React, { FC, ReactNode } from 'react';

import ds from '@ds';
import {
  Colors,
  FontWeight,
  IScreenSize,
  ScreenType,
  TextAlign,
  TextDecoration,
  TextType,
} from '@models';

interface Props {
  variant?: TextType;
  children: ReactNode;
  color?: IScreenSize<Colors> | Colors;
  customColor?: IScreenSize<string> | string;

  // margins
  margin?: IScreenSize<string> | string;
  marginTop?: IScreenSize<string> | string;
  marginRight?: IScreenSize<string> | string;
  marginBottom?: IScreenSize<string> | string;
  marginLeft?: IScreenSize<string> | string;

  // paddings
  padding?: IScreenSize<string> | string;
  paddingTop?: IScreenSize<string> | string;
  paddingRight?: IScreenSize<string> | string;
  paddingBottom?: IScreenSize<string> | string;
  paddingLeft?: IScreenSize<string> | string;

  fontSize?: IScreenSize<string> | string;
  fontWeight?: IScreenSize<FontWeight> | FontWeight;
  textAlign?: IScreenSize<TextAlign> | TextAlign;
  decoration?: IScreenSize<TextDecoration> | TextDecoration;
  lineHeight?: IScreenSize<string> | string;
  letterSpacing?: IScreenSize<string> | string;

  className?: string;
}

const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
};

const Wording: FC<Props> = ({
  children,
  variant: as = 'p',
  color = 'surface',
  customColor,
  margin,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  textAlign,
  fontSize,
  fontWeight,
  decoration: textDecoration,
  letterSpacing,
  lineHeight,
  className,
}) => {
  const Component = variantsMapping[as] as React.ElementType;

  const getStyle = <T,>(propType: T, screenSize: ScreenType) => {
    if (typeof propType === 'object') {
      if (typeof propType[screenSize] === 'undefined') {
        return propType['all'];
      }

      return propType[screenSize];
    }

    return propType;
  };

  const getStylesByScreen = (screen: ScreenType) => {
    return `
      ${color ? `color: ${ds['color_' + getStyle(color, screen)]};` : ''}
      ${
        customColor ? `color: ${getStyle(customColor, screen)} !important;` : ''
      }
      ${margin ? `margin: ${getStyle(margin, screen)};` : ''}
      ${marginTop ? `margin-top: ${getStyle(marginTop, screen)};` : ''}
      ${marginRight ? `margin-right: ${getStyle(marginRight, screen)};` : ''}
      ${marginBottom ? `margin-bottom: ${getStyle(marginBottom, screen)};` : ''}
      ${marginLeft ? `margin-left: ${getStyle(marginLeft, screen)};` : ''}
      ${padding ? `padding: ${getStyle(padding, screen)};` : ''}
      ${paddingTop ? `padding-top: ${getStyle(paddingTop, screen)};` : ''}
      ${paddingRight ? `padding-right: ${getStyle(paddingRight, screen)};` : ''}
      ${
        paddingBottom
          ? `padding-bottom: ${getStyle(paddingBottom, screen)};`
          : ''
      }
      ${paddingLeft ? `padding-left: ${getStyle(paddingLeft, screen)};` : ''}
      ${textAlign ? `text-align: ${getStyle(textAlign, screen)};` : ''}
      ${fontSize ? `font-size: ${getStyle(fontSize, screen)};` : ''}
      ${fontWeight ? `font-weight: ${getStyle(fontWeight, screen)};` : ''}
      ${
        textDecoration
          ? `text-decoration: ${getStyle(textDecoration, screen)};`
          : ''
      }
      ${
        letterSpacing
          ? `letter-spacing: ${getStyle(letterSpacing, screen)};`
          : ''
      }
      ${lineHeight ? `line-height: ${getStyle(lineHeight, screen)};` : ''}
    `;
  };

  return (
    <Component className={className}>
      {children}
      <style jsx>
        {`
          @media (min-width: 1320px) {
            ${variantsMapping[as]} {
              ${getStylesByScreen('desktop')}
            }
          }
          @media (min-width: 992px) and (max-width: 1319px) {
            ${variantsMapping[as]} {
              ${getStylesByScreen('laptop')}
            }
          }
          @media (min-width: 768px) and (max-width: 1319px) {
            ${variantsMapping[as]} {
              ${getStylesByScreen('tablet')}
            }
          }
          @media (max-width: 767px) {
            ${variantsMapping[as]} {
              ${getStylesByScreen('mobile')}
            }
          }
        `}
      </style>
    </Component>
  );
};

export default Wording;
