import React, { CSSProperties } from 'react';

import {
  Colors, FontSize, FontWeight, Spacing, TextType,
} from './design-system';

export type TextDecoration =
  | 'dashed'
  | 'dotted'
  | 'double'
  | 'line-through'
  | 'overline'
  | 'none'
  | 'solid'
  | 'underline'
  | 'wavy';

export type TextAlign =
  | 'left'
  | 'center'
  | 'right'
  | 'start'
  | 'end'
  | 'justify';
export interface TextComponentProps {
  type?: TextType;
  textAlign?: TextAlign;
  color?: Colors;
  colorD?: Colors;
  colorL?: Colors;
  colorT?: Colors;
  colorM?: Colors;
  fontSize?: FontSize;
  fontSizeD?: FontSize;
  fontSizeT?: FontSize;
  fontSizeM?: FontSize;
  lineHeight?: Spacing;
  lineHeightD?: Spacing;
  lineHeightT?: Spacing;
  lineHeightM?: Spacing;
  fontWeight?: FontWeight;
  decoration?: TextDecoration;
  className?: string;
  children?: React.ReactNode;
  styles?: CSSProperties | undefined
}
