import { homeActionTypes } from '@actionTypes';
import { HomeStateModel } from '@models';

import { reducerItemInitialState } from './reducer.constant';

const initialState: HomeStateModel = {
  all: reducerItemInitialState as any,
};

function home(state = initialState, action) {
    switch (action.type) {
      case homeActionTypes.LOAD_HOME:
        return {
          ...state,
          all: {
            fetching: true,
          },
        };
      case homeActionTypes.LOAD_HOME_SUCCESS:
        return {
          ...state,
          all: {
            ...action.data,
            fetching: false,
          },
        };
      case homeActionTypes.LOAD_HOME_FAILURE:
        return {
          ...state,
          all: {
            fetching: false,
          },
        };
      default:
        return state;
    }
  }
  

export default home; 