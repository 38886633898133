import { Footer, Header } from '@modules';

const FullWidthPageLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default FullWidthPageLayout;
