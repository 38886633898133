import React, { FC, useMemo } from 'react';

import { Design, Wording } from '@components';
import { OrderModel } from '@models';

type OrderAddressInformationProps = {
  order: OrderModel | null;
};
const OrderAddressInformation: FC<OrderAddressInformationProps> = ({
  order,
}) => {
  const shippingAddress = useMemo(() => {
    if (!order?.shipping_address.address_line_1) return '';
    if (order?.shipping_address.address_line_2)
      return [
        order?.shipping_address?.address_line_1,
        order?.shipping_address?.address_line_2,
      ].join(', ');

    return order?.shipping_address?.address_line_1;
  }, [order]);
  return (
    <Design
      borderRadius="8px"
      padding="16px"
      backgroundColor="background"
      flex="1"
    >
      <Wording fontWeight="600" fontSize="16px">
        Address Information
      </Wording>

      <Design marginTop="16px">
        <Wording
          fontWeight="500"
          fontSize="14px"
          lineHeight="20px"
          color="gray__600"
        >
          Name
        </Wording>
        <Wording
          fontWeight="400"
          fontSize="16px"
          lineHeight="22px"
          marginBottom="16px"
          color="surface"
        >
          {order?.shipping_address?.full_name}
        </Wording>
        <Wording
          fontWeight="500"
          fontSize="14px"
          lineHeight="20px"
          color="gray__600"
        >
          Shipping Address
        </Wording>
        <Wording
          fontWeight="400"
          fontSize="16px"
          lineHeight="22px"
          marginBottom="16px"
          color="surface"
        >
          {shippingAddress}
        </Wording>
        {order?.shipping_address?.area?.name && (
          <>
            <Wording
              fontWeight="500"
              fontSize="14px"
              lineHeight="20px"
              color="gray__600"
            >
              Area
            </Wording>
            <Wording
              fontWeight="400"
              fontSize="16px"
              lineHeight="22px"
              marginBottom="16px"
              color="surface"
            >
              {order?.shipping_address?.area?.name}
            </Wording>
          </>
        )}
        <Wording
          fontWeight="500"
          fontSize="14px"
          lineHeight="20px"
          color="gray__600"
        >
          Phone Number
        </Wording>
        <Wording
          fontWeight="400"
          fontSize="16px"
          lineHeight="22px"
          marginBottom="16px"
          color="surface"
        >
          {order?.shipping_address?.phone_number}
        </Wording>
      </Design>
    </Design>
  );
};

export default OrderAddressInformation;
