import { OAuthKey } from '@helpers';
import {
  PaymentMethodEnum,
  SEO_EVENT_AFFILIATION_NAME,
  SEO_EVENT_CURRENCY,
  SeoEvent,
  SeoEventProductModel,
} from '@models';

import { GtagProductNormalizer } from './seo-normalizer';

const gtagProductNormalizer = GtagProductNormalizer;

// view_item
export const triggerViewItemEvent = (products: SeoEventProductModel[]) => {
  if (typeof window === 'undefined') return;

  if (products.length <= 0) return;

  const product = products?.[0];

  if (!product) return;

  const gtagModel = {
    currency: SEO_EVENT_CURRENCY,
    value: product.price,
    items: gtagProductNormalizer(products),
  };

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.VIEW_ITEM, gtagModel);

  if (!window.fbq) return;

  // Pixel Event
  window.fbq('track', 'ViewContent', {
    currency: SEO_EVENT_CURRENCY,
    content_ids: [product.id],
    content_name: product.name,
    content_type: 'product',
    contents: [
      {
        id: product.id,
        quantity: product.qty ?? 1,
      },
    ],
    value: product.price,
  });
};

// add_to_cart
export const triggerAddToCartEvent = (products: SeoEventProductModel[]) => {
  if (typeof window === 'undefined') return;

  if (products.length <= 0) return;

  const product = products?.[0];

  if (!product) return;

  const gtagModel = {
    currency: SEO_EVENT_CURRENCY,
    value: product.price,
    items: gtagProductNormalizer(products),
  };

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.ADD_TO_CART, gtagModel);

  if (!window.fbq) return;

  // Pixel Event
  window.fbq('track', 'AddToCart', {
    currency: SEO_EVENT_CURRENCY,
    content_ids: [product.id],
    content_name: product.name,
    content_type: 'product',
    contents: [
      {
        id: product.id,
        quantity: product.qty ?? 1,
      },
    ],
    value: product.price,
  });
};

// add_to_wishlist
export const triggerAddToWishlistEvent = (products: SeoEventProductModel[]) => {
  if (typeof window === 'undefined') return;

  if (products.length <= 0) return;

  const product = products?.[0];

  if (!product) return;

  const gtagModel = {
    currency: SEO_EVENT_CURRENCY,
    value: product.price,
    items: gtagProductNormalizer(products),
  };

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.ADD_TO_WISHLIST, gtagModel);

  if (!window.fbq) return;

  // Pixel Event
  window.fbq('track', 'AddToWishlist', {
    currency: SEO_EVENT_CURRENCY,
    content_ids: [product.id],
    content_name: product.name,
    content_type: 'product',
    contents: [
      {
        id: product.id,
        quantity: product.qty ?? 1,
      },
    ],
    value: product.price,
  });
};

// begin_checkout (click on checkout button)
export const triggerBeginCheckout = (
  totalValue: number,
  products: SeoEventProductModel[]
) => {
  if (typeof window === 'undefined') return;

  const gtagModel = {
    currency: SEO_EVENT_CURRENCY,
    value: totalValue,
    coupon: null,
    items: gtagProductNormalizer(products),
  };

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.BEGIN_CHECKOUT, gtagModel);

  if (!window.fbq) return;

  // Pixel Event
  window.fbq('track', 'InitiateCheckout', {
    currency: SEO_EVENT_CURRENCY,
    content_ids: products.map((x) => x.id),
    content_category: products.map((x) => x.category?.[0]) ?? '',
    num_items: products.reduce((c, p) => c + p.qty, 0),
    content_type: 'product',
    contents: products.map((p) => {
      id: p.id;
      quantity: p.qty;
    }),
    value: totalValue,
  });
};

// add_shipping_info (when a user adds their shipping information during checkout)
export const triggerAddShippingInfoEvent = (
  totalValue: number,
  products: SeoEventProductModel[]
) => {
  if (typeof window === 'undefined') return;

  const gtagModel = {
    currency: SEO_EVENT_CURRENCY,
    value: totalValue,
    coupon: null,
    shipping_tier: 1,
    items: gtagProductNormalizer(products),
  };

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.ADD_SHIPPING_INFO, gtagModel);
};

// purchase complete
export const triggerPurchaseCompleteEvent = (
  transaction_id: number,
  totalValue: number,
  coupon: string,
  tax: number,
  shipping: number,
  paymentMethod: string,
  products: SeoEventProductModel[]
) => {
  if (typeof window === 'undefined') return;

  const gtagModel = {
    transaction_id: transaction_id,
    affiliation: SEO_EVENT_AFFILIATION_NAME,
    currency: SEO_EVENT_CURRENCY,
    value: totalValue,
    coupon: coupon,
    tax: tax,
    shipping: shipping,
    payment_method: paymentMethod,
    items: gtagProductNormalizer(products),
  };

  // GTAG Event
  if (window.gtag) {
    window.gtag('event', SeoEvent.PURCHASE_COMPLETE, gtagModel);

    if (process.env.NEXT_PUBLIC_SEND_AW_EVENTS === 'true') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-370183097/gJvfCM_U460DELmXwrAB',
        transaction_id: transaction_id,
      });
    }
  }

  if (!window.fbq) return;

  // Pixel Event
  window.fbq('track', 'Purchase', {
    currency: SEO_EVENT_CURRENCY,
    value: totalValue,
  });
};

// page view
export const triggerViewPageEvent = () => {
  if (typeof window === 'undefined') return;

  if (!window.fbq) return;

  // Pixel Event
  window.fbq('track', 'PageView');
};

// Login
export const triggerLoginEvent = (method: OAuthKey) => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag)
    window.gtag('event', SeoEvent.LOGIN, {
      method,
    });

  if (!window.fbq) return;

  // Pixel Event
  window.fbq('track', 'CompleteRegistration', {
    status: 'login',
    value: method,
  });
};

// Register
export const triggerRegisterEvent = (method: OAuthKey) => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag)
    window.gtag('event', SeoEvent.SIGNUP, {
      method,
    });

  if (!window.fbq) return;

  // Pixel Event
  window.fbq('track', 'CompleteRegistration', {
    status: 'signup',
    value: method,
  });
};

// App banner click
export const triggerAppBannerClickEvent = () => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.APP_BANNER_CLICK);
};

// select address type
export const triggerSelectAddressType = (type: 'existing' | 'new') => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.SELECT_ADDRESS_TYPE, { type });
};

// Apply promotion code
export const triggerAddPromoCodeEvent = (status: 'success' | 'fail') => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.ADD_PROMO_CODE, { status });
};

// open cart page
export const triggerOpenCartPageEvent = () => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.CART_OPEN);
};

// select payment option // UNUSED
export const triggerSelectPaymentOptionEvent = (option: PaymentMethodEnum) => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag)
    window.gtag('event', SeoEvent.PAYMENT_OPTION_SELECT, { option });
};

// remove from cart
export const triggerRemoveFromCartEvent = (
  products: SeoEventProductModel[]
) => {
  if (typeof window === 'undefined') return;

  if (products.length <= 0) return;

  const product = products?.[0];

  if (!product) return;

  const gtagModel = {
    currency: SEO_EVENT_CURRENCY,
    value: product.price,
    items: gtagProductNormalizer(products),
  };

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.REMOVE_FROM_CART, gtagModel);
};

// Changing Product Qty
export const triggerCartQtyChangeEvent = (state: 'plus' | 'minus') => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.CART_QTY_CHANGE, { state });
};

// Delete Item
export const triggerDeleteItemEvent = () => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.CART_DELETEITEM_CLICK);
};

// OPEN AREA LIST MODAL
export const triggerOpenAreaList = (open_type: 'top' | 'price_detail') => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.AREA_LIST_OPEN, { open_type });
};

// CHANGE DEFAULT SELECTED AREA
export const triggerChangeArea = () => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.AREA_CHANGED);
};
// LOGIN_REGISTER_STARTED
export const triggerAuthFlowEvent = () => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.LOGIN_REGISTER_STARTED);
};

// REGISTRATION_REWARD_OPEN
export const triggerRewardFlowEvent = () => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.REGISTRATION_REWARD_OPEN);
};
// LOGIN_NUMBER_ENTERED
export const triggerEnterNumberEvent = () => {
  if (typeof window === 'undefined') return;

  // GTAG Event
  if (window.gtag) window.gtag('event', SeoEvent.LOGIN_NUMBER_ENTERED);
};
