import React, { FC, useCallback, useState } from 'react';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import { Heart } from 'react-feather';
import { useSelector, useStore } from 'react-redux';

import { wishlistActions } from '@actions';

import { usePromise } from '@hooks';
import {
  ProductModel,
  SeoEventListLocation,
  SeoEventProductModel,
  WishlistCompactDataModel,
} from '@models';
import ds from '@ds';
import { triggerAddToWishlistEvent } from '@utils/seo/events';
import { SpinnerModule } from '@modules';
import { notification } from '@components';

import styles from './add-to-wishlist.module.scss';

interface Props {
  product: ProductModel;
}

const AddToWishlistComponent: FC<Props> = ({ product }) => {
  const promise = usePromise();
  const store = useStore();
  const [loading, setLoading] = useState(false);

  const selectCompactWishlist = createSelector(
    (state) => state.wishlist.compact.data,
    (products) =>
      products?.data &&
      products.data.findIndex((item) => item.product_id === product.id) > -1
  );

  const selectWishlist = createSelector(
    (state) => state.wishlist?.items?.data,
    (products) =>
      products &&
      products.data.findIndex((item) => item.product_id === product.id) > -1
  );

  const usedCompactWishlist = useSelector(selectCompactWishlist);

  const usedWishlist = useSelector(selectWishlist);

  const isInWishlist = usedCompactWishlist || usedWishlist;

  const handleAddToWishlist = useCallback(() => {
    promise(
      wishlistActions.addToWishlist({
        product_id: product.id,
      })
    )
      .catch((err) => {
        notification({
          type: 'error',
          message: 'Error',
          description: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [product.id]);

  const handleRemoveFromWishlist = useCallback((id) => {
    return promise(wishlistActions.deleteWishlist(id))
      .then(() => {
        promise(wishlistActions.compactWishlist());
        promise(wishlistActions.loadAll());
      })
      .catch((err) => {
        notification({ type: 'error', message: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleClickAction = () => {
    if (loading) return;

    setLoading(true);

    const wishlistStore = store.getState().wishlist;
    const wishlistData: WishlistCompactDataModel[] = get(
      wishlistStore,
      'compact.data.data',
      []
    );

    const id = wishlistData?.find((item) => item.product_id === product.id)?.id;

    if (id) {
      handleRemoveFromWishlist(id);
    } else {
      const addedProducts: SeoEventProductModel[] = [];

      addedProducts.push({
        brand: '',
        category: [],
        discount: product?.discount_amount,
        id: product.id.toString(),
        list_location: SeoEventListLocation.ITEM_DETAILS,
        name: product.title,
        position: 0,
        price: product?.price,
        qty: product?.inventory_qty_left,
        variant: product.id.toString(),
      } as SeoEventProductModel);
      triggerAddToWishlistEvent(addedProducts);
      handleAddToWishlist();
    }
  };

  return (
    <>
      <div className={styles.Container} onClick={handleClickAction}>
        {!loading && (
          <Heart
            width={20}
            height={20}
            fill={isInWishlist ? ds.color_primary : 'transparent'}
            color={isInWishlist ? ds.color_primary : ds.color_surface}
          />
        )}

        {loading && <SpinnerModule />}
      </div>
    </>
  );
};

export default AddToWishlistComponent;
