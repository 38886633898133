import { takeLatest } from 'redux-saga/effects';

import { orderActionTypes } from '@actionTypes';
import axiosMiddleware from '../../axios.middleware';

export default function* orderSaga() {
  yield takeLatest(orderActionTypes.LOAD_ALL_ORDER, axiosMiddleware);
  yield takeLatest(orderActionTypes.LOAD_ORDER, axiosMiddleware);
  yield takeLatest(orderActionTypes.UPDATE_ORDER, axiosMiddleware);
  yield takeLatest(orderActionTypes.CREATE_ORDER, axiosMiddleware);
  yield takeLatest(orderActionTypes.CANCELATION_REASONS, axiosMiddleware);
  yield takeLatest(
    orderActionTypes.GET_CHECKOUT_RECOMMENDATIONS,
    axiosMiddleware
  );
  yield takeLatest(orderActionTypes.START_CHECKOUT, axiosMiddleware);
}
