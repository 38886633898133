import { ProfileStateModel, StoreModel } from '@models';
import { createSelector } from 'reselect';

const selectHome = (state: StoreModel) => state.profile;

export const wallet = createSelector(
  [selectHome],
  (profile: ProfileStateModel) => profile?.wallet
);

export const walletHistory = createSelector(
  [selectHome],
  (profile: ProfileStateModel) => profile?.walletHistory
);
