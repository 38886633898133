import React, { FC, useEffect, useMemo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { ChevronRight } from 'react-feather';
import { useSelector } from 'react-redux';

import { formatPriceWithCurrency } from '@utils/number.utils';
import { Design, Wording, Media } from '@components';
import { addressesSelectors, appSelectors, cartSelectors } from '@selectors';
import { usePromise, useStorage } from '@hooks';

import styles from './cart.module.scss';
import { appActions, cartActions } from '@actions';
import {
  getCurrentRegionLabel,
  getDefaultRegion,
  getRegionToken,
  getUserAddressRegion,
} from '@helpers';
import { AddressRegionSelected } from '@models';
import { triggerOpenAreaList } from '@utils/seo/events';

const CartDeliveryFee: FC = () => {
  const promise = usePromise();

  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { data: userAddresses, fetching: fetchingUserAddresses } = useSelector(
    addressesSelectors.selectAll
  );
  const { data: cartDetails } = useSelector(cartSelectors.details);
  const { data: areasData, fetching: fetchingAreas } = useSelector(
    addressesSelectors.areas
  );
  const { localData: currentRegion, setItem: setCurrentRegion } =
    useStorage<AddressRegionSelected>(
      getRegionToken(),
      regionUpdating || visibleAreaModal
    );

  const shipToLabel = getCurrentRegionLabel(currentRegion);

  const remainFeeToFree = useMemo(() => {
    if (!cartDetails) return 0;
    return (
      cartDetails.free_shipping_threshold -
      cartDetails.items_sub_total +
      cartDetails.discount
    );
  }, [cartDetails]);

  const handleShowAreaSelector = () => {
    triggerOpenAreaList('top');
    promise(appActions.changeAreaModalStatus(true));
  };

  const handleSelectRegion = (region: AddressRegionSelected) => {
    promise(cartActions.loadCartDetails(region.area.id));
    setCurrentRegion(region);
  };

  // Set default region base on user address or default area
  useEffect(() => {
    if (!currentRegion?.area?.id && areasData?.areas) {
      promise(appActions.setRegionUpdating(true));
      // When user has default shipping address
      const addressRegion = getUserAddressRegion(userAddresses?.data ?? []);
      if (addressRegion) {
        handleSelectRegion(addressRegion);
      } else {
        // For anonymous user or empty address list
        const defaultRegion = getDefaultRegion(areasData);
        if (defaultRegion) handleSelectRegion(defaultRegion);
      }
      setTimeout(() => promise(appActions.setRegionUpdating(false)), 100);
    }
  }, [fetchingAreas, fetchingUserAddresses]);

  const renderShippingIcon = () => {
    let link = '/icons/cart/cart-shipping.svg';

    if (cartDetails?.delivery_fee === 0)
      link = '/icons/cart/cart-free-shipping.svg';

    return (
      <>
        <Media greaterThan="laptop">
          <Image
            width="36px"
            height="36px"
            src={link}
            alt="delivery-icon"
            priority
          />
        </Media>
        <Media between={['tablet', 'desktop']}>
          <Image
            width="40px"
            height="40px"
            src={link}
            alt="delivery-icon"
            priority
          />
        </Media>
        <Media lessThan="tablet">
          <Image
            width="32px"
            height="32px"
            src={link}
            alt="delivery-icon"
            priority
          />
        </Media>
      </>
    );
  };

  const renderFreeDelivery = () => (
    <Design display="flex" gap="3px">
      <Wording fontSize={{ all: '16px', tablet: '14px', mobile: '14px' }}>
        Yaaay! Your Shipping is
      </Wording>
      <Wording
        fontSize={{ all: '16px', tablet: '14px', mobile: '14px' }}
        fontWeight={{ all: '500' }}
      >
        FREE!
      </Wording>
    </Design>
  );
  const renderNotFreeDelivery = () => (
    <>
      <Wording fontSize={{ all: '16px', tablet: '14px', mobile: '14px' }}>
        Buy <b>{formatPriceWithCurrency(remainFeeToFree) ?? 0}</b> more to enjoy{' '}
        <b>FREE SHIPPING</b>!
      </Wording>
      <Link href="/">
        <a>
          <Design display="flex" gap="3px" alignItems="center">
            <Wording
              color="link"
              fontSize={{ all: '16px', tablet: '14px', mobile: '14px' }}
              fontWeight="500"
            >
              ADD
            </Wording>
            <ChevronRight width="20px" height="20px" />
          </Design>
        </a>
      </Link>
    </>
  );
  return (
    <Design
      backgroundColor="background"
      borderRadius={{ all: '8px', mobile: '0' }}
      padding={{ all: '8px 24px', tablet: '8px 138px', mobile: '8px 16px' }}
    >
      <Design display="flex" alignItems="center">
        {renderShippingIcon()}
        <Wording
          fontWeight="400"
          fontSize="16px"
          lineHeight="22px"
          marginLeft="8px"
        >
          Ship to
        </Wording>
        <Design
          onClick={handleShowAreaSelector}
          marginLeft="3px"
          className="has-pointer"
        >
          <Wording
            fontWeight="500"
            fontSize="16px"
            lineHeight="22px"
            color="link"
            decoration="underline"
            className={styles.CurrentLocation}
          >
            {shipToLabel}
          </Wording>
        </Design>
        <Design
          onClick={handleShowAreaSelector}
          className="has-pointer"
          color="link"
          marginLeft="8px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            width="14px"
            height="8px"
            src="/icons/cart/chevron.svg"
            alt="chevron"
            priority
          />
        </Design>
      </Design>
      <Design
        display="flex"
        justifyContent="space-between"
        gap="3px"
        alignItems="center"
        padding="16px 0"
        borderTop="1px solid"
        borderColor="natural"
      >
        {!cartDetails || cartDetails.delivery_fee > 0
          ? renderNotFreeDelivery()
          : renderFreeDelivery()}
      </Design>
    </Design>
  );
};

export default CartDeliveryFee;
