import React, { FC } from 'react';

import { Design } from '@components';

import styles from './product-card-shimmer.module.scss';

const ProductCardShimmerModule: FC = () => {
  const renderImage = () => {
    return <div className={styles.ImageContainer}></div>;
  };

  const renderTitle = () => {
    return <div className={styles.Title}></div>;
  };

  const renderPricing = () => {
    return <div className={styles.Price}></div>;
  };

  return (
    <div className={styles.Container}>
      {renderImage()}
      {renderTitle()}
      <Design display="flex" gap="8px">
        {renderPricing()}
        {renderPricing()}
      </Design>
      <div className={`${styles.Price} ${styles.Full}`}></div>
    </div>
  );
};

export default ProductCardShimmerModule;
