import { ContactBodyModel } from '@models';
import { contactsActionTypes } from '@actionTypes';

export function getContacts() {
  return {
    type: contactsActionTypes.GET_CONTACTS,
    method: 'get',
    url: '/contacts',
  };
}

export function create(data: ContactBodyModel) {
  return {
    type: contactsActionTypes.CREATE_CONTACTS,
    method: 'post',
    url: '/cms/contact_us',
    data,
  };
}
