import { ProductListDealModel } from './products-list.model';
import { ProductCarouselItemModel } from './products.model';
import { VisualSwatchTypeEnum } from './shared.model';

// ## Enums
enum SortOptions {
  CREATED_AT = 'created_at',
  POPULARITY = 'popularity',
  LOW = 'low',
  PRICE = 'price',
  DISCOUNT_AMOUNT = 'discount_amount',
}

export enum FacetTypeEnum {
  RANGE = 'range',
  TEXT_SWATCH = 'text_swatch',
  VISUAL_SWATCH = 'visual_swatch',
  SELECT = 'select',
  CATEGORY = 'category',
  BOOLEAN = 'boolean',
}

// ## Types
type SortTypeKey = 'popularity' | 'price' | 'created_at' | 'discount_amount';

// ## Server API Models
interface CategoryItemApiResponseModel {
  filters: CategoryFilterModel[];
  order: CategoryOrderModel;
  pagination: CategoryPaginationModel;
  data: ProductCarouselItemModel[];
  deals: ProductListDealModel[];
  facets: CategoryFacetApiResponseModel[];
  search_term: string;
}

interface CategoryFacetApiResponseModel {
  slug: string;
  label: string;
  type: FacetTypeEnum;
  minimum: number;
  maximum: number;
  selected_count: number;
  selected_hint: string;
  option_slugs: string[];
  options: CategoryFacetOptionsModel[];
}

// ## Local Models
interface CategoryFacetOptionsModel {
  slug: string;
  is_active: boolean;
  products_count: number;
  children?: CategoryFacetOptionChildrenModel[];
  label?: string;
  text_swatch_label?: string;
  visual_swatch_color_code?: string;
  visual_swatch_image?: any;
  visual_swatch_type?: VisualSwatchTypeEnum;
}

interface CategoryFacetOptionChildrenModel {
  children?: CategoryFacetOptionChildrenModel[];
  is_active: boolean;
  label: string;
  products_count: number;
  slug: string;
}

interface CategoryFacetMapModel {
  slug: string;
  label: string;
  type: FacetTypeEnum;
  minimum: number;
  maximum: number;
  selected_count: number;
  selected_hint: string;
  option_slugs: string[];
  options: Map<string, CategoryFacetOptionsModel>;
}
interface FacetOptionsModel {
  slug: string;
  label: string;
  type: FacetTypeEnum;
  minimum: number;
  maximum: number;
  selected_count: number;
  selected_hint: string;
  option_slugs: string[];
  options: CategoryFacetOptionsModel[];
}

interface CategoryFacetModel {
  slug: string;
  label: string;
  type: FacetTypeEnum;
  minimum: number;
  maximum: number;
  selected_count: number;
  selected_hint: string;
  option_slugs: string[];
  options: Record<string, CategoryFacetOptionsModel>;
}

interface CategoryPaginationModel {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  from: number;
  to: number;
}

interface CategoryOrderModel {
  order_by: SortTypeKey;
  order_dir: 'ASC' | 'DESC';
}

interface CategoryFilterModel {
  slug: string;
  values: string[];
}

interface GeneratedCategoryModel {
  filters: CategoryFilterModel[];
  order: CategoryOrderModel;
  pagination: CategoryPaginationModel;
  data: ProductCarouselItemModel[];
  deals: ProductListDealModel[];
  facets: Record<string, CategoryFacetModel>;
  search_term: string;
}

interface ProductsFacetModel {
  facets: {
    [key: string]: {
      option: CategoryFacetOptionsModel[];
    };
  };
}

interface ProductFacetRangeModel {
  min: number;
  max: number;
}

interface FetchedCategoryDetails {
  data: GeneratedCategoryModel;
  category: CategoryItemApiResponseModel;
}

export type {
  CategoryFacetApiResponseModel,
  CategoryFacetMapModel,
  CategoryFacetModel,
  CategoryFacetOptionChildrenModel,
  CategoryFacetOptionsModel,
  CategoryFilterModel,
  CategoryItemApiResponseModel,
  CategoryOrderModel,
  CategoryPaginationModel,
  FacetOptionsModel,
  FetchedCategoryDetails,
  GeneratedCategoryModel,
  ProductFacetRangeModel,
  ProductsFacetModel,
  SortTypeKey,
};
