import { useEffect, useState } from 'react';

import { Media } from '@components';
import { getCookie, getRegion, setCookie } from '@helpers';
import { Region } from '@models';
import { SubscribeForm } from '@modules';
import { firebaseSelectors } from '@selectors';
import cn from 'clsx';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import AppleDownloadIcon from 'public/icons/download-app-apple.svg';
import AndroidDownloadIcon from 'public/icons/download-app-google.svg';
import OnesellLogoDark from 'public/icons/onesell-logo-dark.svg';
import FacebookChatIcon from 'public/icons/socials/facebook-chat.svg';
import FacebookIcon from 'public/icons/socials/facebook.svg';
import InstagramIcon from 'public/icons/socials/instagram.svg';
import TikTokIcon from 'public/icons/socials/tiktok.svg';
import WhatsAppIcon from 'public/icons/socials/whatsapp.svg';
import { useSelector } from 'react-redux';

import styles from './footer.module.scss';

const CookieApproval = dynamic<any>(
  () => import('@modules').then((mod) => mod.CookieApproval),
  {
    ssr: false,
  }
);

const FooterModule = () => {
  const router = useRouter();
  const authInfo = useSelector(firebaseSelectors?.auth);

  const [hideApproval, setHideApproval] = useState(
    !!getCookie('approval') || !authInfo?.isAnonymous
  );

  useEffect(() => {
    setHideApproval(!!getCookie('approval') || !authInfo?.isAnonymous);
  }, [authInfo]);

  const handleAccept = () => {
    setCookie('approval', true, new Date(dayjs().add(12, 'M').toString()));
    setHideApproval(true);
  };

  const year = dayjs().format('YYYY');

  return (
    <footer
      id="FooterContainer"
      className={cn(styles.Container, {
        [styles.CartFooter]: router.pathname === '/cart',
      })}
    >
      <div className="container">
        <div className={styles.InnerContainer}>
          <div className={styles.Logo}>
            <Link href="/">
              <a>
                <OnesellLogoDark alt="One Sell Logo" />
              </a>
            </Link>
          </div>
          <div className={styles.Links}>
            <nav>
              <ul>
                <li>
                  <Link href="/about">
                    <a>Who we are?</a>
                  </Link>
                </li>
                <li>
                  <Link href="/contact">
                    <a>Contact</a>
                  </Link>
                </li>
                <li>
                  <Link href="/terms">
                    <a>Terms and condition</a>
                  </Link>
                </li>
                <li>
                  <Link href="/privacy-policy">
                    <a>Privacy policy</a>
                  </Link>
                </li>
              </ul>

              <ul>
                <li>
                  <Link href="/faq">
                    <a>FAQ</a>
                  </Link>
                </li>
                <li>
                  <Link href="/return-policy">
                    <a>How to return</a>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className={styles.Subscribe}>
            <SubscribeForm />
          </div>
          <div className={styles.Application}>
            {getRegion() === Region.AR_QA && (
              <>
                <p>Download 1Sell Application</p>

                <div className={styles.DownloadApp}>
                  <a
                    title="download-android-app"
                    href="https://play.google.com/store/apps/details?id=org.metatude.onesell"
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <AndroidDownloadIcon />
                  </a>
                  <a
                    title="download-ios-app"
                    href="https://apps.apple.com/us/app/1sell-easy-online-shopping/id1596568282"
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <AppleDownloadIcon />
                  </a>
                </div>
              </>
            )}

            <ul className={getRegion() === Region.AR_AE  ? styles.NoApplication : ''}>
              <li>
                <p>Follow us</p>
                <ul>
                  <li>
                    <a
                      title="instagram"
                      href={process.env.NEXT_PUBLIC_SOCIAL_INSTAGRAM}
                      target="blank"
                    >
                      <InstagramIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      title="facebook"
                      href={process.env.NEXT_PUBLIC_SOCIAL_FACEBOOK}
                      target="blank"
                    >
                      <FacebookIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      title="tiktok"
                      href="https://www.tiktok.com/@1sell_"
                      target="blank"
                    >
                      <TikTokIcon />
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <p>Chat with us</p>
                <ul>
                  <li>
                    <a
                      title="facebook"
                      href={process.env.NEXT_PUBLIC_SOCIAL_FACEBOOK_MESSENGER}
                      target="blank"
                    >
                      <FacebookChatIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      title="whatsapp"
                      href={process.env.NEXT_PUBLIC_SOCIAL_WHATSAPP}
                      target="blank"
                    >
                      <WhatsAppIcon />
                    </a>
                  </li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
            </ul>
          </div>
          <Media lessThan="tablet">
            <p className={styles.Copyright}>
              © {year} 1Sell All rights reserved.
            </p>
          </Media>
        </div>
      </div>
      {hideApproval ? (
        <></>
      ) : (
        authInfo.isAnonymous && <CookieApproval onAccept={handleAccept} />
      )}
    </footer>
  );
};

export default FooterModule;
