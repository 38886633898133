import { FC, useEffect, useMemo, useState } from 'react';

import { Design, notification, Wording } from '@components';
import { useSelector } from 'react-redux';
import {
  addressesSelectors,
  appSelectors,
  checkoutSelectors,
} from '@selectors';
import { formatPriceWithCurrency } from '@utils/number.utils';
import { SpinnerModule } from '@modules';
import {
  AddressRegionSelected,
  CheckoutPriceItemModel,
  CheckoutPriceItemsType,
} from '@models';
import { useStorage } from '@hooks';
import {
  getCurrentRegionLabel,
  getCurrentRegionLabelBaseAreaId,
  getRegionToken,
} from '@helpers';

import styles from './checkout-price-details.module.scss';

const CheckoutPriceDetailsModule: FC = () => {
  const { data, fetching } = useSelector(checkoutSelectors.selectDetails);
  const checkoutParams = useSelector(checkoutSelectors.selectParams);
  const allAreas = useSelector(addressesSelectors.allAreas);
  const [deliveryFee, setDeliveryFee] = useState(-1);

  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating
  );

  const shipTo = useMemo(() => {
    const labelBaseParams = getCurrentRegionLabelBaseAreaId(
      checkoutParams.area_id,
      allAreas
    );
    if (labelBaseParams) return labelBaseParams;
    return getCurrentRegionLabel(currentRegion);
  }, [currentRegion, checkoutParams?.area_id]);

  useEffect(() => {
    if (data?.price_items) {
      const fee =
        data.price_items.find((i) => i.type === CheckoutPriceItemsType.SHIPPING)
          ?.amount ?? 0;
      if (deliveryFee > -1) {
        if (deliveryFee !== fee) {
          notification({
            type: 'info',
            message: 'Delivery fee has been updated.',
          });
          setDeliveryFee(fee);
        }
      } else {
        setDeliveryFee(fee);
      }
    }
  }, [fetching]);

  const renderPriceDetails = (item: CheckoutPriceItemModel, index: number) => {
    if (item.type === CheckoutPriceItemsType.SHIPPING) {
      return (
        <Design
          marginBottom={{
            all: '20px',
            desktop: '24px',
          }}
          key={[item.title, index].join('_')}
          display="flex"
          justifyContent="space-between"
        >
          <Design display="flex" alignItems="center">
            <Design minWidth={{ all: '102px', desktop: '120px' }}>
              <Wording
                color="caption"
                fontSize={{ all: '14px', desktop: '16px' }}
                fontWeight="500"
              >
                Delivery Fee to
              </Wording>
            </Design>
            <Design>
              <Wording
                color="caption"
                fontSize={{ all: '14px', desktop: '16px' }}
                fontWeight="500"
                className={styles.DeliverTo}
                marginLeft="3px"
              >
                {shipTo}
              </Wording>
            </Design>
          </Design>
          <Wording
            fontWeight="500"
            fontSize={{ all: '14px', desktop: '16px' }}
            color={item.amount < 0 ? 'error' : 'surface'}
          >
            {formatPriceWithCurrency(item.amount)}
          </Wording>
        </Design>
      );
    }
    return (
      <Design
        marginBottom={{
          all: '20px',
          desktop: '24px',
        }}
        key={[item.title, index].join('_')}
        display="flex"
        justifyContent="space-between"
      >
        <Wording
          fontWeight="500"
          fontSize={{
            all: '14px',
            desktop: '16px',
          }}
          color={item.amount < 0 ? 'error' : 'caption'}
        >
          {item.title}
        </Wording>
        <Wording
          fontWeight="500"
          fontSize={{
            all: '14px',
            desktop: '16px',
          }}
          color={item.amount < 0 ? 'error' : 'surface'}
        >
          {formatPriceWithCurrency(item.amount)}
        </Wording>
      </Design>
    );
  };

  if (!data?.items_sub_total && fetching)
    return (
      <Design
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="210px"
      >
        <SpinnerModule loading size="48px" />
      </Design>
    );

  return (
    <>
      <SpinnerModule size="48px" loading={fetching} height="110px">
        <Design
          marginBottom={{
            all: '20px',
            desktop: '24px',
          }}
          display="flex"
          justifyContent="space-between"
        >
          <Wording
            fontWeight="500"
            fontSize={{
              all: '14px',
              desktop: '16px',
            }}
            color="caption"
          >
            Subtotal
          </Wording>
          <Wording
            fontWeight="500"
            fontSize={{
              all: '14px',
              desktop: '16px',
            }}
            color="surface"
          >
            {formatPriceWithCurrency(data?.items_sub_total ?? 0)}
          </Wording>
        </Design>
        {data?.price_items?.map((item, index) =>
          renderPriceDetails(item, index)
        )}
        <Design display="flex" justifyContent="space-between">
          <Wording
            fontWeight="500"
            fontSize={{
              all: '16px',
              desktop: '20px',
            }}
            color="surface"
          >
            Total Due
          </Wording>
          <Wording
            fontWeight="500"
            fontSize={{
              all: '16px',
              desktop: '20px',
            }}
            color="surface"
          >
            {formatPriceWithCurrency(data?.total ?? 0)}
          </Wording>
        </Design>
      </SpinnerModule>
    </>
  );
};

export default CheckoutPriceDetailsModule;
