import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { checkoutActions } from '@actions';
import { checkoutSelectors } from '@selectors';
import { Design, Wording } from '@components';

const CheckoutDeliveryTimeModule: FC = () => {
  const { data } = useSelector(checkoutSelectors.selectDetails);
  return (
    <Design
      width="100%"
      padding="16px"
      backgroundColor="background"
      borderRadius={{
        all: '8px',
        mobile: '0',
      }}
      marginBottom={{
        all: '0',
        tablet: '12px',
        mobile: '12px',
      }}
    >
      <Design
        width="100%"
        margin="0 auto"
        maxWidth={{
          all: '100%',
          tablet: '460px',
        }}
        display="flex"
        justifyContent={{
          all: 'center',
          tablet: 'space-between',
          mobile: 'space-between',
        }}
        alignItems="center"
      >
        <Wording
          color="caption"
          fontSize="16px"
          lineHeight="22px"
          fontWeight="500"
        >
          Delivery Time:
        </Wording>
        <Wording
          color="surface"
          fontSize="16px"
          lineHeight="22px"
          fontWeight="500"
          marginLeft={{ all: '0', desktop: '10px' }}
        >
          {data?.estimated_shipping}
        </Wording>
      </Design>
    </Design>
  );
};

export default CheckoutDeliveryTimeModule;
