import { StoreModel } from '@models';
import { createSelector } from 'reselect';

const selectApp = (state: StoreModel) => state.app;

export const visibleAuthModal = createSelector(
  [selectApp],
  (app) => app.visibleAuthModal
);

export const visiblePopupCart = createSelector(
  [selectApp],
  (app) => app.visiblePopupCart
);

export const visibleAppBanner = createSelector(
  [selectApp],
  (app) => app.visibleAppBanner
);

export const visibleAreaModal = createSelector(
  [selectApp],
  (app) => app.visibleAreaModal
);

export const regionUpdating = createSelector(
  [selectApp],
  (app) => app.regionUpdating
);
