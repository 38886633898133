import React, { FC, useMemo } from 'react';

import { Design, Wording } from '@components';
import ds from '@ds';
import { useLiveTimer } from '@hooks';
import { DealStatus, ProductDealItem, ProductDealModel } from '@models';
import { Clock } from 'react-feather';

interface Props {
  dealStatus: DealStatus;
  productDeal: ProductDealModel;
  assignedDeals: ProductDealItem[];
}

const ProductFlashSales: FC<Props> = ({
  dealStatus,
  productDeal,
  assignedDeals,
}: Props) => {
  const dealInfo = useMemo(() => {
    const [firstDeal] = assignedDeals;
    return firstDeal;
  }, [assignedDeals]);

  const { hours, days, minutes, seconds, hideTimer } = useLiveTimer(
    productDeal && productDeal.deal_is_active === true,
    productDeal?.deal_end_time
  );

  if (dealStatus !== DealStatus.ACTIVE || hideTimer) return <></>;

  return (
    <Design
      rowGap={{ all: '32px', mobile: '8px' }}
      columnGap={{ all: '32px', mobile: '8px' }}
      display="flex"
      alignItems="center"
      marginBottom="16px"
      flexWrap="wrap"
    >
      {dealInfo && (
        <Wording
          customColor={dealInfo?.background_color || '#FFA000'}
          fontSize="18px"
          fontWeight="500"
        >
          {dealInfo.title}
        </Wording>
      )}

      <Design gap="8px" display="flex" alignItems="center">
        <Clock
          color={dealInfo?.background_color || ds.color_primary}
          width={ds.spacing_20}
          height={ds.spacing_20}
        />

        <Wording
          customColor={dealInfo?.background_color || '#FFA000'}
          fontSize="18px"
          fontWeight="500"
        >
          <Design display="flex" gap="5px">
            <span>
              {hours >= 48 ? (
                <>{days} Days Left</>
              ) : hours < 10 ? (
                ('0' + hours).slice(-2)
              ) : (
                hours
              )}
            </span>
            {hours < 48 && (
              <>
                <span>:</span>
                <span>{minutes}</span>
                <span>:</span>
                <span>{seconds}</span>
              </>
            )}
          </Design>
        </Wording>
      </Design>
    </Design>
  );
};

export default ProductFlashSales;
