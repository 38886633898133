import React, { useEffect, useState, FC } from 'react';

import { checkoutActions, orderActions } from '@actions';
import { Media, notification } from '@components';
import { usePromise } from '@hooks';
import { PlaceOrderBody } from '@models';
import { addressesSelectors, checkoutSelectors } from '@selectors';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import CheckoutPaymentDesktop from './checkout-payment-desktop/checkout-payment-desktop.component';
import CheckoutPaymentLessDesktop from './checkout-payment-less-desktop/checkout-payment-less-desktop';

const CheckoutPaymentComponent: FC = () => {
  const router = useRouter();
  const promise = usePromise();
  const [actionLoading, setActionLoading] = useState(false);

  const { data: addressList } = useSelector(addressesSelectors.selectAll);
  const { data: checkoutDetails } = useSelector(
    checkoutSelectors.selectDetails
  );
  const checkoutParams = useSelector(checkoutSelectors.selectParams);
  const orderNote = useSelector(checkoutSelectors.selectNote);

  useEffect(() => {
    promise(checkoutActions.getPaymentMethods());
  }, []);

  /**
   * Factory function that select default address as selected address when user refresh payment page
   */
  useEffect(() => {
    if (typeof checkoutParams.shipping_address_id !== 'undefined') return;
    const defaultAddressId = addressList?.data?.find(
      (address) => address.is_default_shipping
    )?.id;
    if (!checkoutDetails?.shipping_address?.id && defaultAddressId)
      promise(
        checkoutActions.getCheckout({
          ...checkoutParams,
          billing_address_id: defaultAddressId,
          shipping_address_id: defaultAddressId,
        })
      ).then(() =>
        promise(
          checkoutActions.updateCheckoutParams({
            billing_address_id: defaultAddressId,
            shipping_address_id: defaultAddressId,
          })
        )
      );
  }, [checkoutDetails, addressList, checkoutParams]);

  /**
   * Factory function that place new order
   * @returns {void}
   */
  const handlePlaceOrder = (): void => {
    if (!checkoutDetails) return;
    setActionLoading(true);
    // Shipping address and billing address should be same
    const addressId = checkoutDetails.shipping_address?.id;

    const request: PlaceOrderBody = {
      billing_address_id: addressId,
      coupon_code: checkoutDetails.promotion?.coupon_code,
      customer_note: orderNote,
      payment_method: checkoutDetails.payment_method?.slug,
      shipping_address_id: addressId,
      use_wallet: checkoutDetails.use_wallet,
    };
    promise(orderActions.create(request))
      .then((data: any) => {
        promise(orderActions.setRecentOrder(data));
        router.push('/checkout/done', undefined, { shallow: true });
      })
      .catch((error) => {
        notification({
          type: 'error',
          message: error?.message,
        });
      })
      .finally(() => setActionLoading(false));

    return;
  };

  return (
    <>
      <Media greaterThan="laptop">
        <CheckoutPaymentDesktop
          actionLoading={actionLoading}
          handlePlaceOrder={handlePlaceOrder}
        />
      </Media>
      <Media lessThan="desktop">
        <CheckoutPaymentLessDesktop
          actionLoading={actionLoading}
          handlePlaceOrder={handlePlaceOrder}
        />
      </Media>
    </>
  );
};

export default CheckoutPaymentComponent;
