import React, { FC } from 'react';

import { Design } from '@components';

import styles from '../profile-simmer.module.scss';

const WalletShimmer: FC = () => {
  return (
    <Design display="flex" flexDirection="column" gap="16px">
      <Design
        borderRadius="8px"
        backgroundColor="background"
        padding="16px"
        display="flex"
        justifyContent="space-between"
      >
        <Design display="flex" flexDirection="column" gap="16px">
          <Design
            className={styles.ShimmerItem}
            width="80px"
            height="16px"
            borderRadius="16px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="118px"
            height="16px"
            borderRadius="16px"
          ></Design>
        </Design>
        <Design
          className={styles.ShimmerItem}
          width="160px"
          height="48px"
          borderRadius="100px"
        ></Design>
      </Design>

      <Design
        borderRadius="8px"
        backgroundColor="background"
        padding="16px"
        display="flex"
        flexDirection="column"
        minHeight="290px"
      >
        <Design
          display="flex"
          justifyContent="space-between"
          padding="16px 0 24px 0"
          borderBottom="1px solid"
          borderColor="natural"
        >
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
        </Design>

        <Design
          display="flex"
          justifyContent="space-between"
          padding="24px 0"
          borderBottom="1px solid"
          borderColor="natural"
        >
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
        </Design>

        <Design
          display="flex"
          justifyContent="space-between"
          padding="24px 0"
          borderBottom="1px solid"
          borderColor="natural"
        >
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
        </Design>

        <Design
          display="flex"
          justifyContent="space-between"
          padding="24px 0 16px 0"
        >
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
          <Design
            className={styles.ShimmerItem}
            width="111px"
            height="12px"
            borderRadius="40px"
          ></Design>
        </Design>
      </Design>
    </Design>
  );
};

export default WalletShimmer;
