import React, { FC } from 'react';

import { Design, Wording } from '@components';

interface Props {
  itemsLeft: number;
}

const ProductSellOutBadge: FC<Props> = ({ itemsLeft }: Props) => {
  if (!itemsLeft || itemsLeft > 10) return <></>;

  return (
    <Design
      display="flex"
      backgroundColor="primary__dimmer"
      padding="6px"
      borderRadius="8px"
    >
      <Wording color="primary" fontWeight="700" fontSize="14px">
        {itemsLeft} Left
      </Wording>
      <Wording color="primary" fontSize="14px">
        {' '}
        - Buy Before Sell Out
      </Wording>
    </Design>
  );
};

export default ProductSellOutBadge;
