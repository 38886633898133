import { StoreModel } from '@models';
import { createSelector } from 'reselect';

const selectAddresses = (state: StoreModel) => state.addresses;

export const selectAll = createSelector(
  [selectAddresses],
  (addresses) => addresses.all
);

export const selectDetail = createSelector(
  [selectAddresses],
  (addresses) => addresses.detail
);

export const selectCities = createSelector(
  [selectAddresses],
  (addresses) => addresses?.cities ?? []
);

export const addressArea = createSelector(
  [selectAddresses],
  (addresses) => addresses?.addressArea
);

export const areas = createSelector(
  [selectAddresses],
  (addresses) => addresses?.areas
);

export const allAreas = createSelector(
  [selectAddresses],
  (addresses) => addresses?.areas.data?.areas ?? []
);

export const allCities = createSelector(
  [selectAddresses],
  (addresses) => addresses?.areas.data?.cities ?? []
);
