export const LOAD_ALL_ADDRESSES = 'LOAD_ALL_ADDRESSES';
export const LOAD_ALL_ADDRESSES_REQUESTING = 'LOAD_ALL_ADDRESSES_REQUESTING';
export const LOAD_ALL_ADDRESSES_SUCCESS = 'LOAD_ALL_ADDRESSES_SUCCESS';
export const LOAD_ALL_ADDRESSES_FAILURE = 'LOAD_ALL_ADDRESSES_FAILURE';

export const LOAD_ADDRESSES = 'LOAD_ADDRESSES';
export const LOAD_ADDRESSES_REQUESTING = 'LOAD_ADDRESSES_REQUESTING';
export const LOAD_ADDRESSES_SUCCESS = 'LOAD_ADDRESSES_SUCCESS';
export const LOAD_ADDRESSES_FAILURE = 'LOAD_ADDRESSES_FAILURE';

export const LOAD_ALL_CITIES = 'LOAD_ALL_CITIES';
export const LOAD_ALL_CITIES_REQUESTING = 'LOAD_ALL_CITIES_REQUESTING';
export const LOAD_ALL_CITIES_SUCCESS = 'LOAD_ALL_CITIES_SUCCESS';
export const LOAD_ALL_CITIES_FAILURE = 'LOAD_ALL_CITIES_FAILURE';

export const LOAD_ALL_REGION = 'LOAD_ALL_REGION';
export const LOAD_ALL_REGION_REQUESTING = 'LOAD_ALL_REGION_REQUESTING';
export const LOAD_ALL_REGION_SUCCESS = 'LOAD_ALL_REGION_SUCCESS';
export const LOAD_ALL_REGION_FAILURE = 'LOAD_ALL_REGION_FAILURE';

export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAILURE = 'CREATE_ADDRESS_FAILURE';

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE';

export const CLEAR_ADDRESS_DETAILS = 'CLEAR_ADDRESS_DETAILS';
export const SET_ADDRESS_AREA = 'SET_ADDRESS_AREA';

export const LOAD_ALL_AREAS = 'LOAD_ALL_AREAS';
export const LOAD_ALL_AREAS_REQUESTING = 'LOAD_ALL_AREAS_REQUESTING';
export const LOAD_ALL_AREAS_SUCCESS = 'LOAD_ALL_AREAS_SUCCESS';
export const LOAD_ALL_AREAS_FAILURE = 'LOAD_ALL_AREAS_FAILURE';
