import { CheckoutStepEnum } from '../models/checkout.model';

/**
 * Factory function that show each step status
 * @param {CheckoutStepEnum} step - new step
 * @param {number} current - current active step
 * @returns {boolean}
 */
const stepStatus = (step: CheckoutStepEnum, current: number): boolean => {
  if (step <= current) return true;
  return false;
};

/**
 * Factory function that handle change step base on param
 * @param {string} newStep
 * @returns {CheckoutStepEnum}
 */
const stepChange = (newStep: string): CheckoutStepEnum => {
  if (newStep === 'payment') return CheckoutStepEnum.PAYMENT;
  if (newStep === 'done') return CheckoutStepEnum.DONE;
  return CheckoutStepEnum.SHIPPING;
};

const checkoutHelper = { stepStatus, stepChange };
export default checkoutHelper;
