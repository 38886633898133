import React, { useCallback, useState } from 'react';

import { wishlistActions } from '@actions';
import { Button, Design, notification } from '@components';
import ds from '@ds';
import { usePromise } from '@hooks';
import {
  SeoEventListLocation, SeoEventProductModel, StoreModel,
} from '@models';
import { triggerAddToWishlistEvent } from '@utils/seo/events';
import { Spin } from 'antd';
import get from 'lodash/get';
import Link from 'next/link';
import { Heart } from 'react-feather';
import { useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

type Props = {
  id: number;
  slug: string;
  discountAmount: number;
  price: number;
  inventoryQtyLeft: number;
  title: string;
};

const MainOfferButtons = ({
  id,
  slug,
  discountAmount,
  price,
  inventoryQtyLeft,
  title,
}: Props) => {
  /**
   * @description
   * This component contains the main product's
   * "View Product" and "Wishlist" buttons and their
   * functionalities
   */

  const promise = usePromise();
  const store = useStore();

  const [wishlistLoading, setWishlistLoading] = useState<boolean>(false);

  const selectCompactWishlist = createSelector(
    (state: StoreModel) => state.wishlist.compact.data,
    (products) =>
      products?.data &&
      products.data.findIndex((item) => item.product_id === id) > -1
  );

  const selectWishlist = createSelector(
    (state) => state.wishlist?.items?.data,
    (products) =>
      products && products.data.findIndex((item) => item.product_id === id) > -1
  );

  const usedCompactWishlist = useSelector(selectCompactWishlist);
  const usedWishlist = useSelector(selectWishlist);

  const isInWishlist = usedCompactWishlist || usedWishlist;

  const handleAddToWishlist = useCallback((productId: number) => {
    return promise(
      wishlistActions.addToWishlist({
        product_id: productId,
      })
    )
      .catch((err) => {
        notification({
          type: 'error',
          message: 'Error',
          description: err.message,
        });
      })
      .finally(() => {
        setWishlistLoading(false);
      });
  }, []);

  const handleRemoveFromWishlist = useCallback((product_id) => {
    const wishlistStore = store.getState().wishlist;
    const wishlistData: any[] = get(wishlistStore, 'compact.data.data', []);

    const id = wishlistData.find((item) => item.product_id === product_id).id;

    return promise(wishlistActions.deleteWishlist(id))
      .catch((err) => {
        notification({ type: 'error', message: err.message });
      })
      .finally(() => {
        setWishlistLoading(false);
      });
  }, []);

  const handleWishlistClick = (e) => {
    setWishlistLoading(true);

    e.stopPropagation();
    e.preventDefault();
    isInWishlist ? handleRemoveFromWishlist(id) : handleAddToWishlist(id);

    const addedProducts: SeoEventProductModel[] = [];

    addedProducts.push({
      brand: "",
      category: [],
      discount: discountAmount,
      id: id.toString(),
      list_location: SeoEventListLocation.ITEM_DETAILS,
      name: title,
      position: 0,
      price: price,
      qty: inventoryQtyLeft,
      variant: id.toString(),
    } as SeoEventProductModel);

    triggerAddToWishlistEvent(addedProducts);
  };

  return (
    <Design display="flex" gap="16px" margin="16px 0 0 0">
      <Link href={`/product/${slug}`}>
        <a>
          <Button type="primary" shape="round" size="large">
            View Product
          </Button>
        </a>
      </Link>

      <Button
        shape="round"
        size="large"
        disabled={wishlistLoading}
        onClick={handleWishlistClick}
      >
        <Design
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="6px"
        >
          {wishlistLoading ? (
            <Design
              width="16px"
              height="16px"
              position="relative"
              display="flex"
              top="-2px"
            >
              <Spin size="small" />
            </Design>
          ) : (
            <>
              {isInWishlist ? (
                <Heart
                  width={16}
                  height={16}
                  color={ds.color_primary}
                  fill={ds.color_primary}
                />
              ) : (
                <Heart width={16} height={16} color={ds.color_surface} />
              )}
            </>
          )}
          Wishlist
        </Design>
      </Button>
    </Design>
  );
};

export default MainOfferButtons;
