import { FC, useEffect } from 'react';

import { Button } from '@components';
import Link from 'next/link';

import styles from './cookie-approval.module.scss';

interface Props {
  onAccept: () => void;
}

const CookieApprovalModule: FC<Props> = ({ onAccept }: Props) => {
  useEffect(() => {
    setTimeout(onAccept, 20000);
  }, []);

  return (
    <div className={styles.MainWrapper}>
      <div className={`${styles.ApprovalWrapper} container`}>
        <div>
          <div className={styles.TitleWrapper}>
            We’ll tailor your experience
          </div>
          <div className={styles.TextWrapper}>
            We are using cookies to give you the best experience on 1sell
            website. You can find out about cookies we are using in our{' '}
            <Link href="/privacy-policy">
              <a href="" className={styles.LinkText}>
                privacy policy
              </a>
            </Link>
          </div>
        </div>
        <Button
          onClick={onAccept}
          type="primary"
          size="large"
          className={styles.AcceptButton}
        >
          {`That's OK`}
        </Button>
      </div>
    </div>
  );
};

export default CookieApprovalModule;
