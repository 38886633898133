import { useState } from 'react';

import { usePromise } from '@hooks';
import NewsletterCheckIcon from 'public/icons/newsletter-check-icon.svg';

import styles from './subscribe-form.module.scss';
import SubscribeUserForm from './subscribe-user-form';
import { profileActions } from '@actions';

const Subscribe = () => {
  const promise = usePromise();
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubscribe = async (data: { email: string }) => {
    setLoading(true);
    promise(profileActions.signUpNewsletter(data))
      .then(() => {
        setSubscribed(true);
        setTimeout(() => setSubscribed(false), 5000);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className={styles.Title}>Get the latest news on discounts!</div>

      {subscribed ? (
        <div className={styles.SuccessSection}>
          Email has been successfully registered
          <NewsletterCheckIcon />
        </div>
      ) : (
        <SubscribeUserForm onSubmit={handleSubscribe} loading={loading} />
      )}
    </>
  );
};

export default Subscribe;
