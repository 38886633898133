import { anonymousAxios } from '@helpers';
import {
  CategoryFacetApiResponseModel, CategoryFacetMapModel,
  CategoryFacetOptionsModel, CategoryItemApiResponseModel, CategoryModel,
  CategoryParentModel, GeneratedCategoryModel, ProductsFacetModel,
} from '@models';
import capitalize from 'lodash/capitalize';
import { ParsedUrlQuery } from 'querystring';

const mergeFacetsWithCategories = async (
  productsData: CategoryItemApiResponseModel,
  params?: string,
  category_slug?: string
) => {
  // fetch facets base on the option_slugs that we gather from the categories
  const facetBaseUrl = `v2/catalog/products/facets`;

  // base facet structure
  const facetsMap: Map<string, CategoryFacetMapModel> = new Map();

  // initiate facetsMap with the facets from the categories
  const extractedFacetOptions: Record<string, { values: string[] }> = {};
  productsData.facets.forEach((facet: CategoryFacetApiResponseModel) => {
    //fill default values
    const optionsMap = new Map<string, CategoryFacetOptionsModel>();
    facet.options.forEach((o) => {
      optionsMap.set(o.slug, o);
    });

    facetsMap.set(facet.slug, { ...facet, options: optionsMap });

    // some facets have no options (e.g. brand, price)
    if (facet.option_slugs?.length > 0) {
      extractedFacetOptions[facet.slug] = { values: facet.option_slugs };
    }
  });

  // fetch facets by facet options
  const { data: additionalFacetOptions } =
    await anonymousAxios.put<ProductsFacetModel>(
      `${process.env.NEXT_PUBLIC_API_URL}${facetBaseUrl}?${params}`,
      {
        option_slugs: extractedFacetOptions,
      }
    );

  // loop through additionalFacetOptions.facets
  Object.keys(additionalFacetOptions.facets).forEach((facetSlug) => {
    const newOptions = additionalFacetOptions.facets[facetSlug];

    // we should replace all options if the slug equal to Categories
    if (facetSlug === 'category') {
      const optionsMap = new Map<string, CategoryFacetOptionsModel>();
      newOptions?.option.forEach((o) => {
        optionsMap.set(o.slug, o);
      });

      facetsMap.set('category', {
        ...(facetsMap.get('category') as CategoryFacetMapModel),
        options: optionsMap,
      });
    }

    const currentOptions = facetsMap.get(facetSlug)?.options;
    if (currentOptions) {
      newOptions?.option.forEach((o) => {
        currentOptions.set(o.slug, { ...currentOptions.get(o.slug), ...o });
      });
    }
  });

  return {
    facets: convertFacetMapToObject(facetsMap),
    filters: productsData.filters,
    pagination: productsData.pagination,
    data: productsData.data,
    order: productsData.order,
    deals: productsData.deals,
    search_term: productsData.search_term,
  } as GeneratedCategoryModel;
};

const convertFacetMapToObject = (map: Map<string, CategoryFacetMapModel>) => {
  let finalObject = {};

  map.forEach((value, key) => {
    finalObject[key] = { ...value };
    finalObject[key].options = Object.fromEntries(finalObject[key]?.options);
  });

  return finalObject;
};

const categoryUrlParser = (queries: ParsedUrlQuery) => {
  const queryObject = JSON.parse(JSON.stringify(queries)) as {
    page: string;
    order_dir: string;
    order_by: string;
    filters: string[];
  };
  ``;

  const { page, order_dir, order_by, filters } = queryObject;

  return {
    page: page ? parseInt(page, 10) : 1,
    order_dir: order_dir ? order_dir : 'asc',
    order_by: order_by ? order_by : '',
    filters: filters ? filters : [],
  };
};

export function snakeToPascalCase(string, splitBy = '_', joinBy = ' ') {
  if (!string) return;
  return string
    .split(splitBy)
    .map((word) => capitalize(word))
    .join(joinBy);
}

type Route = Record<'key' | 'path' | 'title', string>;
let routesArray: Route[];

const getRouteFromCategory = (category: CategoryParentModel | undefined) => {
  if (!category) return;

  routesArray.push({
    key: category?.slug,
    path: `category/${category?.slug}/products`,
    title: category?.title,
  });

  if (category?.parent) {
    getRouteFromCategory(category?.parent);
  }
};

const generateCategoryRouteForBreadcrumb = (category: CategoryModel) => {
  routesArray = [
    {
      key: category.slug,
      path: `category/${category?.slug}/products`,
      title: category?.title,
    },
  ];
  getRouteFromCategory(category?.parent);

  return routesArray.reverse();
};

let _nestedCategory: string[] = [];
const _findNestedCategory = (
  categories: CategoryModel[],
  key: string,
  categorySlug: string
) => {
  // Base case
  if (categories[key] === categorySlug) {
    //if (categories[key]) _nestedCategory.push(categories[key]);

    return categories;
  } else {
    var keys = Object.keys(categories); // add this line to iterate over the keys

    for (var i = 0, len = keys.length; i < len; i++) {
      var k = keys[i]; // use this key for iteration, instead of index "i"

      // add "obj[k] &&" to ignore null values
      if (categories[k] && typeof categories[k] == 'object') {
        var found = _findNestedCategory(categories[k], key, categorySlug);
        if (found) {
          if (categories[k].title) _nestedCategory.push(categories[k].title);
          // If the object was found in the recursive call, bubble it up.
          return found;
        }
      }
    }
  }
};

const generateNestedRouteBySlug = (
  categories: CategoryModel[],
  categorySlug: string
) => {
  _findNestedCategory(categories, 'slug', categorySlug);
  const cats = _nestedCategory.reverse();
  _nestedCategory = [];
  return cats;
};

export {
  categoryUrlParser, generateCategoryRouteForBreadcrumb,
  generateNestedRouteBySlug, mergeFacetsWithCategories,
};
