export const INVALID_PARAMS = [
  'q',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'fbclid',
  'hsa_ver',
  'hsa_net',
  'hsa_src',
  'hsa_ad',
  'hsa_grp',
  'hsa_cam',
  'hsa_acc',
  'utm_content',
  'hsa_la',
  'hsa_ol'
];
