export const LOAD_CHECKOUT = 'LOAD_CHECKOUT';
export const LOAD_CHECKOUT_REQUESTING = 'LOAD_CHECKOUT_REQUESTING';
export const LOAD_CHECKOUT_SUCCESS = 'LOAD_CHECKOUT_SUCCESS';
export const LOAD_CHECKOUT_FAILURE = 'LOAD_CHECKOUT_FAILURE';

export const LOAD_PAYMENT_METHODS = 'LOAD_PAYMENT_METHODS';
export const LOAD_PAYMENT_METHODS_REQUESTING =
  'LOAD_PAYMENT_METHODS_REQUESTING';
export const LOAD_PAYMENT_METHODS_SUCCESS = 'LOAD_PAYMENT_METHODS_SUCCESS';
export const LOAD_PAYMENT_METHODS_FAILURE = 'LOAD_PAYMENT_METHODS_FAILURE';

export const CHECKOUT_VALIDATE_PROMOTION = 'CHECKOUT_VALIDATE_PROMOTION';
export const CHECKOUT_VALIDATE_PROMOTION_SUCCESS =
  'CHECKOUT_VALIDATE_PROMOTION_SUCCESS';
export const CHECKOUT_VALIDATE_PROMOTION_FAILURE =
  'CHECKOUT_VALIDATE_PROMOTION_FAILURE';

export const UPDATE_CHECKOUT_PARAMS = 'UPDATE_CHECKOUT_PARAMS';
export const UPDATE_CHECKOUT_NOTE = 'UPDATE_CHECKOUT_NOTE';
