import { useMemo, useState } from 'react';

import { cartActions, wishlistActions } from '@actions';
import {
  Button,
  Design,
  mediaQuery,
  Modal,
  notification,
  Wording,
} from '@components';
import ds from '@ds';
import { useMediaQuery, usePromise, useStorage } from '@hooks';
import { SpinnerModule } from '@modules';
import { appSelectors, wishlistSelectors } from '@selectors';
import { Heart, X } from 'react-feather';
import { useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { AddressRegionSelected } from '@models';
import { getRegionToken } from '@helpers';

type Props = {
  id: string;
  productId: number;
  variationId: number;
};

const CartItemActions = ({ id, productId, variationId }: Props) => {
  const promise = usePromise();
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const { data: wishlistCompact } = useSelector(wishlistSelectors.compact);
  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { localData: currentRegion } = useStorage<AddressRegionSelected>(
    getRegionToken(),
    regionUpdating || visibleAreaModal
  );

  const [wishlistLoading, setWishlistLoading] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [visibleDeleteBottomSheet, setVisibleDeleteBottomSheet] =
    useState(false);

  const findInWishlist = useMemo(() => {
    return wishlistCompact?.data?.find((i) => i.product_id === productId);
  }, [wishlistCompact, productId]);

  const handleItemWishlist = (): Promise<unknown> => {
    setWishlistLoading(true);

    if (findInWishlist?.id) {
      return promise(wishlistActions.deleteWishlist(findInWishlist?.id))
        .catch((error) => {
          notification({
            type: 'error',
            message: 'Error',
            description: error?.message,
          });
        })
        .finally(() => {
          setWishlistLoading(false);
        });
    }

    return promise(
      wishlistActions.addToWishlist({
        product_id: productId,
        variation_id: variationId,
      })
    )
      .catch((error) => {
        notification({
          type: 'error',
          message: 'Error',
          description: error?.message,
        });
      })
      .finally(() => {
        setWishlistLoading(false);
      });
  };

  const handleVisibleDeleteModal = () => {
    if (isMobile) {
      setVisibleDeleteBottomSheet(true);
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const handleHideDeleteModal = () => {
    if (isMobile) {
      setVisibleDeleteBottomSheet(false);
    } else {
      setVisibleDeleteModal(false);
    }
  };

  const handleRemoveCartItem = () => {
    setRemoveLoading(true);

    promise(cartActions.cartDelete(id))
      .then(() => {
        Promise.all([
          promise(cartActions.loadCartCompact(currentRegion?.area?.id)),
          promise(cartActions.loadCartDetails(currentRegion?.area?.id)),
        ]);
        handleHideDeleteModal();
      })
      .finally(() => {
        setRemoveLoading(false);
      });
  };

  const renderDeleteContents = () => {
    return (
      <Design
        padding={isMobile ? '11px 16px 24px 16px' : '68px 57px'}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Wording
          fontSize="24px"
          lineHeight="34px"
          fontWeight="500"
          margin="0 0 12px 0"
        >
          Just a moment!
        </Wording>
        <Wording
          fontSize="16px"
          lineHeight="22px"
          fontWeight="500"
          margin="0 0 36px 0"
          color="caption"
          textAlign="center"
        >
          Are you sure you want to remove this item from your cart?
        </Wording>
        <Design display="flex" gap="16px">
          <Design width={{ all: '193px', mobile: '140px' }}>
            <Button
              size="large"
              shape="round"
              fullWidth
              onClick={handleHideDeleteModal}
            >
              Cancel
            </Button>
          </Design>
          <Design width={{ all: '193px', mobile: '140px' }}>
            <Button
              size="large"
              type="primary"
              shape="round"
              color="error"
              fullWidth
              loading={removeLoading}
              onClick={handleRemoveCartItem}
            >
              Remove
            </Button>
          </Design>
        </Design>
      </Design>
    );
  };

  return (
    <>
      <Design display="flex" gap="12px">
        {wishlistLoading ? (
          <Design display="flex" position="relative" top="-3px" right="-6px">
            <SpinnerModule />
          </Design>
        ) : (
          <Heart
            width={isMobile ? '16px' : '18px'}
            height={isMobile ? '16px' : '18px'}
            className="has-pointer"
            fill={!!findInWishlist ? ds?.color_primary : ds?.color_background}
            stroke={!!findInWishlist ? ds?.color_primary : ds?.color_surface}
            onClick={handleItemWishlist}
          />
        )}

        <X
          width={isMobile ? '17px' : '19px'}
          height={isMobile ? '17px' : '19px'}
          className="has-pointer"
          onClick={handleVisibleDeleteModal}
        />
      </Design>

      <BottomSheet
        open={visibleDeleteBottomSheet}
        onDismiss={handleHideDeleteModal}
      >
        {renderDeleteContents()}
      </BottomSheet>

      <Modal
        maskClosable={false}
        title=""
        width={576}
        visible={visibleDeleteModal}
        onCancel={handleHideDeleteModal}
      >
        {renderDeleteContents()}
      </Modal>
    </>
  );
};

export default CartItemActions;
