import React, { FC } from 'react';

import { Design, Wording } from '@components';

interface Props {
  title: string;
}

const ProductTitle: FC<Props> = ({ title }: Props) => {
  return (
    <Design>
      <Wording
        fontWeight="600"
        variant="h1"
        color="surface"
        fontSize={{
          all: "42px",
          tablet: "32px",
          mobile: "24px"
        }}
        lineHeight={{
          all: '45px',
          mobile: '34px',
          tablet: '42px',
        }}
      >
        {title}
      </Wording>
    </Design>
  );
};

export default ProductTitle;
