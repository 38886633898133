import React, { FC } from 'react';

import { ProductModel } from '@models';
import {
  convertProductImagesToOpenGraphMedia,
} from '@utils/seo/seo-normalizer';
import { NextSeo, ProductJsonLd } from 'next-seo';

interface Props {
  product: ProductModel;
}

const ProductSeo: FC<Props> = ({ product }) => {
  return (
    <>
      <NextSeo
        title={product?.title}
        description={product?.meta_description}
        canonical={`${process.env.NEXT_PUBLIC_SITE_URL}/product/${product?.slug}`}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: product?.meta_keyword,
          },
        ]}
        openGraph={{
          title: product?.meta_title ?? product.title,
          description: product?.meta_description ?? product?.short_description,
          images:
            product?.images &&
            convertProductImagesToOpenGraphMedia(product.images),
          site_name: process.env.NEXT_PUBLIC_SITE_URL,
          locale: 'en-US',
          type: 'product',
          url: `${process.env.NEXT_PUBLIC_SITE_URL}/product/${product?.slug}`,
        }}
      />

      <ProductJsonLd
        productName={product.title}
        itemCondition={'New'}
        sku={product?.sku.toString()}
        description={product?.description}
        name={product?.title}
        image={product?.images?.[0]?.urls?.original}
        brand={product?.brand?.titles.en}
        offers={{
          '@type': 'AggregateOffer',
          url: `${process.env.NEXT_PUBLIC_SITE_URL}/product/${product?.slug}`,
          priceCurrency: process.env.NEXT_PUBLIC_CURRENCY,
          offerCount: product.inventory_qty_left,
          lowPrice: product.price,
          highPrice: product.list_price,
        }}
        price={product?.list_price.toString()}
        priceCurrency={process.env.NEXT_PUBLIC_CURRENCY}
        aggregateRating={{
          '@type': 'AggregateRating',
          ratingValue: '5',
          bestRating: '5',
          worstRating: '0',
          ratingCount: '5',
        }}
      />
    </>
  );
};

export default ProductSeo;
