import { firebaseActionTypes } from '@actionTypes';
import { FirebaseStateModel } from '@models';

import { reducerItemInitialState } from './reducer.constant';

const initialState: FirebaseStateModel = {
  data: reducerItemInitialState as any,
};

function firebase(state = initialState, action) {
  switch (action.type) {
    /**
     * SET_PROFILE_FULL_NAME
     */
    case firebaseActionTypes.SET_PROFILE:
      return {
        ...state,
        data: action.data,
      };

    default:
      return state;
  }
}

export default firebase;
