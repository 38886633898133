import cn from 'clsx';

import { Footer, Header } from '@modules';

import styles from './cart-page-layout.module.scss';

const CartPageLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className={cn('container', styles.CartContainer)}>{children}</div>
      <Footer />
    </>
  );
};

export default CartPageLayout;
