import { appActionTypes } from '@actionTypes';

export function toggleAuthModal(data: boolean) {
  return {
    type: appActionTypes.TOGGLE_AUTH_MODAL,
    data,
  };
}

export function togglePopupCart(state: boolean) {
  return {
    type: appActionTypes.TOGGLE_POPUP_CART,
    data: state,
  };
}

export function changeAppBannerStatus(status: boolean) {
  return {
    type: appActionTypes.CHANGE_APP_BANNER_STATUS,
    data: status,
  };
}

export function changeAreaModalStatus(status: boolean) {
  return {
    type: appActionTypes.CHANGE_AREA_MODAL_STATUS,
    data: status,
  };
}

export function setRegionUpdating(status: boolean) {
  return {
    type: appActionTypes.UPDATE_REGION,
    data: status,
  };
}
