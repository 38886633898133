import { FC, ReactNode } from 'react';
import cn from 'clsx';
import { Modal as AntdModal } from 'antd';
import { X, ArrowLeft } from 'react-feather';

import ds from '@ds';

import styles from './modal.module.scss';
import Design from '../design/design.component';

type Props = {
  children: ReactNode;
  visible: boolean;
  maskClosable?: boolean;
  visibleBack?: boolean;
  isInLanding?: boolean;
  centered?: boolean;
  headerActions?: 'back' | 'close' | 'both';
  fullTablet?: boolean;
  width?: number;
  title?: string;
  getContainer?: string;
  wrapClassName?: string;
  onBack?: () => void;
  onCancel: () => void;
};

const Modal: FC<Props> = ({
  children,
  visible,
  maskClosable = false,
  visibleBack = false,
  isInLanding = false,
  centered = true,
  headerActions = 'close',
  fullTablet = false,
  width,
  title = '',
  getContainer,
  wrapClassName = '',
  onCancel,
  onBack,
}) => {
  const renderHeader: ReactNode = (
    <div className={styles.HeaderContainer}>
      <div className={styles.HeaderIcon}>
        {headerActions !== 'close' && (
          <ArrowLeft
            onClick={onBack}
            className="has-pointer"
            color={ds.color_surface}
          />
        )}
      </div>
      <div>{title}</div>
      <div className={styles.HeaderIcon}>
        {headerActions !== 'back' && (
          <X
            onClick={onCancel}
            className="has-pointer"
            color={isInLanding ? ds.color_background : ds.color_surface}
          />
        )}
      </div>
    </div>
  );

  return (
    <AntdModal
      destroyOnClose
      centered={centered}
      closable={false}
      footer={null}
      width={width}
      title={renderHeader}
      visible={visible}
      maskClosable={maskClosable}
      getContainer={getContainer}
      wrapClassName={wrapClassName}
      closeIcon={
        visibleBack ? (
          <ArrowLeft color={ds.color_surface} />
        ) : (
          <X color={isInLanding ? ds.color_background : ds.color_surface} />
        )
      }
      className={cn(styles.ModalWrapper, {
        [styles.FullTablet]: fullTablet,
        [styles.LandingModal]: isInLanding,
      })}
      onCancel={onCancel}
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
