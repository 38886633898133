import {
  DealStatus,
  LanguagesName,
  ProductDealModel,
  StockStatusEnum,
  ProductsListModel,
  FetchingDataType,
} from '@models';

export type ImageSize =
  | '80'
  | '80@2x'
  | '128'
  | '128@2'
  | '360'
  | '360@2x'
  | '512'
  | '512@2x'
  | '1024'
  | '1024@2x'
  | 'original';

export interface ProductImage {
  urls: Record<ImageSize, string>;
}

export interface ProductsStateModel {
  similarItems: FetchingDataType<ProductCarouselItemModel[]>;
  recommendedForYou: FetchingDataType<ProductCarouselItemModel[]>;
  frequentlyBought: FetchingDataType<ProductCarouselItemModel[]>;
}

export interface ProductModel {
  id: number;
  sku: string;
  title: string;
  sub_title: string;
  inventory_qty_left: number;
  short_description: string;
  description: string;
  meta_title: string;
  meta_keyword: string;
  meta_description: string;
  product_type: ProductTypeEnum;
  share_url: string;
  share_description: string;
  delivery_estimation: string;
  rating: number;
  reviews_count: number;
  price: number;
  list_price: number;
  stock_status: StockStatusEnum;
  is_buyer: boolean;
  wrote_review: boolean;
  images: ProductImage[];
  min_qty_in_shopping_cart: number;
  max_qty_in_shopping_cart: number;
  variations: ProductVariation[];
  category: ProductCategory;
  inventory_qty: number;
  slug: string;
  discount_percentage: number;
  brand: ProductBrandModel;
  displayable_attributes: ProductSpecificationModel[];
  deal_status: DealStatus;
  product_deal: ProductDealModel;
  deal_slugs: string[];
  deal_slug: string[];
  discount_amount: number;
  deals: ProductDealItem[];
}

export interface ProductDealItem {
  background_color: string;
  id: number;
  is_active: boolean;
  slug: string;
  sub_title: string;
  title: string;
}

export interface Product {
  sku: string;
  titles: LanguagesName;
  descriptions: LanguagesName;
  category_ids: number[];
  meta_titles: LanguagesName;
  meta_keywords: LanguagesName;
  meta_descriptions: LanguagesName;
  attribute_set_id: number;
  attributes: ProductAttributes;
  images?: ProductImage[];
  parent_id?: number;
  list_price: number;
  is_parent?: boolean;
  price: number;
  stock_status: string;
  min_qty_in_shopping_card: number;
  max_qty_in_shopping_card: number;
  supplier_id: number;
  brand_id: number;
  inventory_qty: number;
  cost: number;
  is_active: boolean;
  force_sync?: boolean;
  title?: string;
  product_type: ProductTypeEnum;
  variation?: ProductVariation;
}

export interface ProductBrandModel {
  id: number;
  titles: LanguagesName;
}

export interface ProductAttributes {
  boolean_attributes: ProductBoleanAttribute[];
  date_attributes: ProductDateAttribute[];
  select_attributes: ProductSelectAttribute[];
  text_attributes: ProductTextAttribute[];
}

export interface ProductBoleanAttribute {
  id: number;
  value: boolean;
}

export interface ProductDateAttribute {
  id: number;
  value: string;
}

export interface ProductSelectAttribute {
  id: number;
  option_ids: number[];
}

export interface ProductTextAttribute {
  id: number;
  values: LanguagesName;
}

export interface ProductColor {
  name: string;
  hex: string;
  is_active?: boolean;
  slug?: string;
  image?: ProductImage;
  color_type?: string;
}

export interface ProductVisualSwatchModel {
  name: string;
  hex: string;
  is_active?: boolean;
  slug?: string;
  image?: ProductImage;
  color_type?: string;
  disabled?: boolean;
}

export interface ProductSize {
  label: string;
  slug: string;
  is_active: boolean;
}

export interface ProductBrand {
  label: string;
  value: string;
  disabled: boolean;
}

export interface ProductReview {
  by_me: boolean;
  content: string;
  created_at: string;
  dislikes_count: number;
  id: number;
  images: ProductImage[];
  is_buyer: boolean;
  likes_count: number;
  my_interaction: 'like' | 'dislike' | null;
  rate: number;
  title: string;
  writer: { full_name: string };
  recommending_purchase: boolean;
}

export interface ProductReviewSummary {
  rating: number;
  reviews_count: number;
  recommending_purchase_percent: number;
  ratings_count_5_stars: number;
  ratings_count_4_stars: number;
  ratings_count_3_stars: number;
  ratings_count_2_stars: number;
  ratings_count_1_star: number;
}

export type ProductDescriptionModel = Record<'short' | 'long', string>;

type ProductVariationAttributeOption = { slug: string } & Record<
  string,
  string
>;

export type VisualSwatchType = 'color' | 'image';

export interface ProductVariationAttributeOptionModel {
  label: string;
  slug: string;
  order: number;
  text_swatch_label: string;
  visual_swatch_color_code: string;
  visual_swatch_image: ProductImage;
  visual_swatch_type: VisualSwatchType;
}

export interface ProductVariationAttribute {
  type: ProductVariationAttributeTypeEnum;
  slug: string;
  label: string;
  options: ProductVariationAttributeOptionModel[];
}

export interface ProductVariationObjectDataModel
  extends ProductVariationAttributeOptionModel {
  variationId: number;
  sku: string;
  price: number;
  list_price: number;
  stock_status: StockStatusEnum;
  disabled: boolean;
  typeLabel: string;
}

export enum ProductVariationAttributeTypeEnum {
  SELECT = 'select',
  TEXT_FIELD = 'text_field',
  TEXT_AREA = 'text_area',
  BOOLEAN = 'boolean',
  DATE = 'date',
  TEXT_SWATCH = 'text_swatch',
  VISUAL_SWATCH = 'visual_swatch',
}

type PartialAttributes =
  | ProductVariationAttributeTypeEnum.TEXT_AREA
  | ProductVariationAttributeTypeEnum.TEXT_FIELD
  | ProductVariationAttributeTypeEnum.DATE
  | ProductVariationAttributeTypeEnum.BOOLEAN;

type VariationRecord = Record<
  ProductVariationAttributeTypeEnum,
  ProductVariationObjectDataModel[]
>;
type VariationRecordPartial = Partial<Pick<VariationRecord, PartialAttributes>>;
type VariationRecordRequired = Omit<VariationRecord, PartialAttributes>;

export type ProductVariationObjectModel = VariationRecordPartial &
  VariationRecordRequired;

type VariationsOptionRecord = Record<ProductVariationAttributeTypeEnum, string>;
type VariationsOptionRecordRecordPartial = Partial<
  Pick<VariationsOptionRecord, PartialAttributes>
>;
type VariationsOptionRecordRequired = Omit<
  VariationsOptionRecord,
  PartialAttributes
>;
export type SelectedProductVariationsOption =
  VariationsOptionRecordRecordPartial & VariationsOptionRecordRequired;

export type VariationType = 'sizes' | 'models' | 'colors';

export type ProductVariation = {
  id: number;
  list_price: number;
  price: number;
  sku: string;
  stock_status: StockStatusEnum;
  attributes: ProductVariationAttribute[];
  description: string;
  discount_percentage?: number;
  discount_amount: number;
  images: any[];
  inventory_qty: number;
  meta_description: string;
  meta_keyword: string;
  meta_title: string;
  short_description: string;
  title: string;
  delivery_estimation: string;
  product_deal: ProductDealModel;
};

export interface ProductCategory {
  title: string;
  slug: string;
  child: ProductCategory | null;
}

export interface ProductCarouselListItemModel {
  item_type: string;
  products: ProductCarouselItemModel[];
  sub_title: string;
  title: string;
}
export interface ProductCarouselItemModel {
  id: number;
  title: string;
  sub_title: string;
  sku: string;
  price: number;
  is_new: boolean;
  list_price: number;
  stock_status: StockStatusEnum;
  images: ProductImage[];
  inventory_qty_left: number;
  available_in: string;
  slug: string;
  deal_status: DealStatus;
  discount_amount: number;
  deal_slugs: string[];
  product_deal: ProductDealModel;
  min_qty_in_shopping_cart: number;
  max_qty_in_shopping_cart: number;
}

export interface ProductSpecificationModel {
  attribute_set_group_title: string;
  attributes: ProductAttributesModel[];
}

export interface ProductAttributesModel {
  id: string;
  boolean_value?: boolean;
  date_value?: string;
  input_type: ProductVariationAttributeTypeEnum;
  label: string;
  options: [];
  slug: string;
  text_value?: string;
}

export interface RenderOptionModel {
  name: string;
  title: string;
  label: string;
  key: string;
  slug?: string;
  hex?: string;
  image?: ProductImage;
  disabled: boolean;
  out_of_stock: boolean;
}

type RenderRecord = Record<
  ProductVariationAttributeTypeEnum,
  RenderOptionModel[]
>;
type RenderRecordPartial = Partial<Pick<RenderRecord, PartialAttributes>>;
type RenderRecordRequired = Omit<RenderRecord, PartialAttributes>;

export type RenderRecordObjectModel = RenderRecordPartial &
  RenderRecordRequired;

export enum ProductTypeEnum {
  SIMPLE = 'simple',
  VARIABLE = 'variable',
}

export type SortModel = Record<'key' | 'title' | 'sort_dir', string>;

export interface VariationConvertModelAttributeModel {
  variationId: number;
  stock_status: StockStatusEnum;
  inventory_qty: number;
  label: string;
  slug: string;
  visual_swatch_type?: VisualSwatchType;
  visual_swatch_color_code?: string;
  text_swatch_label?: string;
  visual_swatch_image?: ProductImage;
}

export interface ProductVariationConvertModel {
  label: string;
  type: ProductVariationAttributeTypeEnum;
  attributes: VariationConvertModelAttributeModel[];
}

export const DealSortOptions: SortModel[] = [
  { key: 'popularity', title: 'Our Picks', sort_dir: 'DESC' },
  { key: 'low', title: 'Price: Low to High', sort_dir: 'ASC' },
  { key: 'price', title: 'Price: High to Low ', sort_dir: 'DESC' },
  { key: 'discount_amount', title: 'Discount', sort_dir: 'DESC' },
  { key: 'sold_count', title: 'Sold Count', sort_dir: 'DESC' },
];

export interface ProductViewModel {
  product_id: number;
  category_slug?: string;
  list_price?: number;
  price?: number;
  deal_slug?: string;
}
