import React, { FC } from 'react';

import { Design } from '@components';

import styles from '../profile-simmer.module.scss';

const AddressBookShimmer: FC = () => {
  return (
    <Design
      display="flex"
      backgroundColor="background"
      borderRadius="8px"
      padding="24px"
      minHeight="386px"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Design>
        <Design
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="natural"
          padding="0 0 24px 0"
        >
          <Design display="flex" flexDirection="column" gap="12px">
            <Design
              className={styles.ShimmerItem}
              width={{ all: '111px' }}
              height="12px"
              borderRadius="16px"
              margin="0 0 4px 0"
            ></Design>
            <Design
              className={styles.ShimmerItem}
              width={{ all: '284px' }}
              height="12px"
              borderRadius="16px"
            ></Design>
            <Design
              className={styles.ShimmerItem}
              width={{ all: '192px' }}
              height="12px"
              borderRadius="16px"
            ></Design>
          </Design>
          <Design display="flex" gap="24px">
            <Design
              className={styles.ShimmerItem}
              width="58px"
              height="12px"
              borderRadius="16px"
            ></Design>
            <Design
              className={styles.ShimmerItem}
              width="58px"
              height="12px"
              borderRadius="16px"
            ></Design>
          </Design>
        </Design>

        <Design
          display="flex"
          justifyContent="space-between"
          padding="24px 0 0 0"
        >
          <Design display="flex" flexDirection="column" gap="12px">
            <Design
              className={styles.ShimmerItem}
              width={{ all: '111px' }}
              height="12px"
              borderRadius="16px"
              margin="0 0 4px 0"
            ></Design>
            <Design
              className={styles.ShimmerItem}
              width={{ all: '284px' }}
              height="12px"
              borderRadius="16px"
            ></Design>
            <Design
              className={styles.ShimmerItem}
              width={{ all: '192px' }}
              height="12px"
              borderRadius="16px"
            ></Design>
          </Design>
          <Design display="flex" gap="24px">
            <Design
              className={styles.ShimmerItem}
              width="58px"
              height="12px"
              borderRadius="16px"
            ></Design>
            <Design
              className={styles.ShimmerItem}
              width="58px"
              height="12px"
              borderRadius="16px"
            ></Design>
          </Design>
        </Design>
      </Design>

      <Design
        className={styles.ShimmerItem}
        width="214px"
        height="56px"
        borderRadius="100px"
      ></Design>
    </Design>
  );
};

export default AddressBookShimmer;
