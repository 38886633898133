import React, { FC, useEffect, useState } from 'react';

import { appActions, cartActions } from '@actions';
import { Button, Design, Image, ProductQuantityControl } from '@components';
import { usePromise } from '@hooks';
import { CartProduct, CartProductVariation, PopupCartProps } from '@models';
import { SpinnerModule } from '@modules';
import { cartSelectors } from '@selectors';
import { formatPriceWithCurrency } from '@utils/number.utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import EmptyCartIcon from 'public/icons/cart/popup-empty-cart.svg';
import { ShoppingCart } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import Wording from '../wording';
import styles from './popup-cart.module.scss';

const PopupCart: FC<PopupCartProps> = ({ show }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  const { data: cart } = useSelector(cartSelectors.details);

  useEffect(() => {
    if (!!cart) setFirstLoading(false);
  }, [cart]);

  const renderVariations = (variations: CartProductVariation) =>
    variations?.attributes.map((v, index) => (
      <Design
        display="flex"
        gap="5px"
        key={[v.slug, variations.sku, index].join('_')}
      >
        <Wording color="caption" fontSize="12px" fontWeight="500">
          {v.label}:
        </Wording>
        <Wording color="surface" fontSize="12px" fontWeight="500">
          {v.option.label}
        </Wording>
      </Design>
    ));

  const renderProduct = (
    product: CartProduct,
    price: number,
    listPrice: number,
    itemId: string,
    qty: number,
    index: number
  ) => {
    return (
      <Design
        variant="li"
        display="flex"
        gap="12px"
        alignItems="flex-start"
        key={[product?.id, product?.sku, index].join('_')}
        borderTop={index > 0 ? '1px solid #EEEEEE' : ''}
        paddingTop={index > 0 ? '16px' : '0'}
        marginTop={index > 0 ? '16px' : '0'}
      >
        <Design
          minWidth="86px"
          maxWidth="86px"
          position="relative"
          overflow="hidden"
        >
          <Link href={`/product/${product?.slug}`}>
            <a target="_blank">
              <Image
                src={product.image.urls.original}
                width={86}
                height={86}
                layout="responsive"
                alt={product.title}
              />
            </a>
          </Link>
        </Design>

        <Design>
          <Link href={`/product/${product?.slug}`}>
            <a target="_blank">
              <Wording color="surface" fontSize="12px" fontWeight="500">
                {product.title}
              </Wording>
            </a>
          </Link>

          {renderVariations(product.variation)}

          <Design display="flex" gap="8px">
            <Wording color="surface" fontSize="12px" fontWeight="500">
              {formatPriceWithCurrency(price) ?? 0}
            </Wording>

            {price < listPrice && (
              <Wording
                color="caption"
                fontSize="12px"
                fontWeight="500"
                decoration="line-through"
              >
                {formatPriceWithCurrency(listPrice) ?? 0}
              </Wording>
            )}
          </Design>

          <Design marginTop="14px">
            <ProductQuantityControl
              cartId={itemId}
              productId={product.id}
              page="popup-cart"
              minQty={product?.min_qty_in_shopping_cart}
              maxQty={
                product?.product_type === 'variable'
                  ? product?.variation?.inventory_qty
                  : product?.inventory_qty
              }
              inventoryQty={product.inventory_qty}
              qty={qty}
            />
          </Design>
        </Design>
      </Design>
    );
  };

  const renderProducts = () => (
    <Design
      display="flex"
      variant="ul"
      padding="16px"
      flexDirection="column"
      maxHeight="311px"
      overflow="auto"
    >
      {cart?.items.map((item, index) =>
        renderProduct(
          item?.product,
          item?.price,
          item?.list_price,
          item?.id,
          item?.qty,
          index
        )
      )}
    </Design>
  );

  const handleGoToCart = () => {
    dispatch(appActions.togglePopupCart(false));
    router.push('/cart');
  };

  const renderCart = () => {
    const total = Number(cart?.items_sub_total) - Number(cart?.discount);

    return (
      <>
        {renderProducts()}
        <Design
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="12px 16px"
          borderTop="1px solid #EEEEEE"
        >
          <Design>
            <Wording
              variant="p"
              fontSize="12px"
              color="caption"
              fontWeight="500"
            >
              Total
            </Wording>
            <Wording
              variant="p"
              fontSize="16px"
              color="surface"
              fontWeight="500"
            >
              {formatPriceWithCurrency(total)}
            </Wording>
          </Design>
          <Design>
            <Button
              type="primary"
              icon={<ShoppingCart width={16} />}
              shape="round"
              onClick={handleGoToCart}
            >
              GO TO CART
            </Button>
          </Design>
        </Design>
      </>
    );
  };

  const renderEmptyCart = () => {
    return (
      <Design
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="203px"
        flexDirection="column"
      >
        <EmptyCartIcon />

        <Wording
          fontSize="16px"
          fontWeight="500"
          lineHeight="22px"
          color="surface"
          marginTop="12px"
        >
          YOUR CART IS EMPTY
        </Wording>
        <Wording
          fontSize="14px"
          fontWeight="500"
          lineHeight="20px"
          color="caption"
          marginTop="8px"
        >
          All your added items will be shown here.
        </Wording>
      </Design>
    );
  };

  return (
    <Design display={show ? 'block' : 'none'} className={styles.Container}>
      {firstLoading && (
        <Design
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="203px"
        >
          <SpinnerModule />
        </Design>
      )}
      {!firstLoading && cart?.items_count === 0 && renderEmptyCart()}
      {!firstLoading && cart && cart?.items_count > 0 && renderCart()}
    </Design>
  );
};

export default PopupCart;
