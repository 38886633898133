import { createSelector } from 'reselect';
import { OrderStateModel, StoreModel } from '@models';

const selectOrder = (state: StoreModel) => state.order;

export const all = createSelector([selectOrder], (order) => order?.all);

export const checkout = createSelector(
  [selectOrder],
  (order) => order?.checkout
);

export const detail = createSelector([selectOrder], (order) => order?.detail);

export const payment_methods = createSelector(
  [selectOrder],
  (order) => order?.payment_methods
);

export const recommendations = createSelector(
  [selectOrder],
  (order) => order?.recommendations
);

export const recentOrder = createSelector(
  [selectOrder],
  (order) => order?.recentOrder
);
