import React, { useState, FC, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';

import { addressesActions, appActions, checkoutActions } from '@actions';
import { AddressForm, Design, mediaQuery, Modal, Wording } from '@components';
import { useMediaQuery, usePromise, useStorage } from '@hooks';
import {
  AddressModel,
  AddressRegionSelected,
  CreateAddressModel,
} from '@models';
import { CheckoutAddressItem } from '@modules';
import {
  addressesSelectors,
  appSelectors,
  checkoutSelectors,
  firebaseSelectors,
} from '@selectors';
import { triggerSelectAddressType } from '@utils/seo/events';
import { getPhoneWithPrefix, getRegion, getRegionToken } from '@helpers';

const CheckoutAddressesComponent: FC = () => {
  const promise = usePromise();
  const router = useRouter();

  const isDesktop = useMediaQuery(mediaQuery.desktop);
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const [visibleModal, setShowModal] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [activatedAddress, setActivatedAddress] =
    useState<AddressModel | undefined>();

  const [selectedAddress, setSelectedAddress] =
    useState<AddressModel | undefined>();

  const checkoutParams = useSelector(checkoutSelectors.selectParams);
  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { localData: currentRegion, setItem: setRegion } =
    useStorage<AddressRegionSelected>(
      getRegionToken(),
      regionUpdating || visibleAreaModal
    );

  const { data: addressList } = useSelector(addressesSelectors.selectAll);

  const authInfo = useSelector(firebaseSelectors?.auth);

  const handleShowAddressModal = (address: AddressModel) => {
    setShowModal(true);
    setSelectedAddress(address);
  };
  const handleCloseAddressModal = () => {
    setShowModal(false);
    setSelectedAddress(undefined);
  };

  /**
   * Factory function that update or create new address
   * @param {CreateAddressModel} details - Address form details
   * @returns {void}
   */
  const handleAddressAction = (details: CreateAddressModel): void => {
    setActionLoading(true);

    // Update address
    if (selectedAddress?.id) {
      promise(
        addressesActions.update(selectedAddress.id, {
          ...details,
          phone_number: getPhoneWithPrefix(details.phone_number),
          is_default_billing: selectedAddress.is_default_billing,
          is_default_shipping: selectedAddress.is_default_shipping,
        })
      )
        .then((updatedAddress: any) => {
          const address = updatedAddress as AddressModel;

          handleSetRegion(address);
          promise(
            checkoutActions.getCheckout({
              billing_address_id: address.id,
              shipping_address_id: address.id,
              // area_id: address?.area?.id,
            })
          ).then(() => promise(appActions.setRegionUpdating(false)));

          setShowModal(false);
          setSelectedAddress(undefined);
          promise(addressesActions.loadAll());
          handelChooseAddress(address, false);
        })
        .finally(() => setActionLoading(false));

      return;
    }

    // Create new address
    promise(
      addressesActions.create({
        ...details,
        phone_number: getPhoneWithPrefix(details.phone_number),
        is_default_billing: true,
        is_default_shipping: true,
      })
    )
      .then((createResult: any) => {
        const address = createResult as AddressModel;
        promise(
          checkoutActions.getCheckout({
            billing_address_id: address.id,
            shipping_address_id: address.id,
            // area_id: address?.area?.id,
          })
        ).then(() => {
          handleSetRegion(address);

          promise(
            checkoutActions.updateCheckoutParams({
              billing_address_id: address.id,
              shipping_address_id: address.id,
              area_id: address?.area?.id,
            })
          );
        });
        setActivatedAddress(address);
        setSelectedAddress(undefined);
        setShowModal(false);

        // Seo Event : Use existing Address
        triggerSelectAddressType('new');

        router.push('/checkout/payment', undefined, { shallow: true });
        promise(addressesActions.loadAll());
      })
      .finally(() => setActionLoading(false));

    return;
  };

  const handelChooseAddress = (address: AddressModel, updateRegion = true) => {
    setActivatedAddress(address);
    promise(appActions.setRegionUpdating(true));

    promise(
      checkoutActions.updateCheckoutParams({
        billing_address_id: address.id,
        shipping_address_id: address.id,
        area_id: address.area?.id,
      })
    );
    promise(
      checkoutActions.getCheckout({
        billing_address_id: address.id,
        shipping_address_id: address.id,
        // area_id: address.area?.id,
      })
    ).then(() => {
      updateRegion && handleSetRegion(address);
    });

    // Seo Event : Use existing Address
    triggerSelectAddressType('existing');
  };

  const handleSetRegion = (address: AddressModel) => {
    setTimeout(() => promise(appActions.setRegionUpdating(true)), 5);

    if (
      (address.area && currentRegion?.area?.id !== address.area.id) ||
      (address.city.id && currentRegion?.area?.city_id !== address.city.id)
    ) {
      setRegion(
        {
          area: address.area,
          city: address.city.id
            ? { areas: [], id: address.city.id, label: '', name: '' }
            : null,
          country: getRegion(),
        }
        // currentRegion?.area?.delivery_fee !== address?.area?.delivery_fee
      );
    }
    setTimeout(() => promise(appActions.setRegionUpdating(false)), 5);
  };

  const getRegionPriceSummary = (region: AddressRegionSelected) => {
    promise(
      checkoutActions.updateCheckoutParams({
        area_id: region.area?.id,
      })
    );
    promise(
      checkoutActions.getCheckout({
        area_id: region.area.id,
      })
    );
  };

  // Default customer address
  const defaultAddress = useMemo(
    () => addressList?.data?.find((i) => i.is_default_shipping),
    [addressList]
  );

  useEffect(() => {
    if (addressList && !activatedAddress) {
      let activeAddress: AddressModel | undefined;
      // Set active address base on checkout params or customer choices
      if (checkoutParams?.shipping_address_id) {
        activeAddress = addressList.data.find(
          (i) => checkoutParams?.shipping_address_id === i.id
        );
      } else if (!checkoutParams?.shipping_address_id) {
        if (currentRegion?.area.id) {
          const areaBaseAddresses = addressList.data.filter(
            (i) => i?.area?.id === currentRegion?.area.id
          );
          if (areaBaseAddresses.length > 1 || areaBaseAddresses.length === 0) {
            // The default address will be select if there are more than one area base address or there is no address
            activeAddress = defaultAddress;
          } else {
            // The address with the same area as default area
            activeAddress = areaBaseAddresses[0];
          }
        } else if (!currentRegion?.area.id) {
          // If user dose'nt select default area
          activeAddress = defaultAddress;
        }
      }

      if (activeAddress) {
        handelChooseAddress(activeAddress);
        promise(
          checkoutActions.getCheckout({
            // area_id: activeAddress?.area?.id,
            billing_address_id: activeAddress.id,
            shipping_address_id: activeAddress.id,
          })
        );
      }
    } else if (!addressList?.data || addressList.data.length < 1) {
      promise(
        checkoutActions.getCheckout({
          area_id: currentRegion?.area.id,
        })
      );
    }
  }, [addressList]);

  // Show Address form when user doesn't have any address
  if (addressList?.data?.length === 0) {
    return (
      <Design
        width="100%"
        margin={{
          all: '0 auto',
          desktop: '0 auto 24px',
        }}
        maxWidth={{
          all: '100%',
          tablet: '460px',
        }}
        padding={{ all: '0', mobile: '16px' }}
      >
        <Wording
          textAlign="center"
          fontSize={{
            all: '16px',
            mobile: '14px',
          }}
          fontWeight="500"
          lineHeight={{
            all: '22px',
            mobile: '20px',
          }}
          marginTop={{
            all: '12px',
            tablet: '18px',
          }}
        >
          To what address should we send your order?
        </Wording>

        <AddressForm
          enablePrefixChange={false}
          loading={actionLoading}
          onSubmit={(values) =>
            handleAddressAction({ ...values, is_default_shipping: true })
          }
          onSelectRegion={getRegionPriceSummary}
          initial={{
            full_name: authInfo?.displayName ?? '',
            phone_number: authInfo?.phoneNumber ?? '',
          }}
          hideButton={isDesktop}
          buttonId="checkout-add-address"
        />
      </Design>
    );
  }

  return (
    <>
      <Design
        width="100%"
        margin={{
          all: '0 auto',
        }}
        maxWidth={{
          all: '100%',
          tablet: '460px',
        }}
      >
        <Design
          className={isMobile ? 'container' : ''}
          borderBottom="1px solid"
          borderColor="natural"
        >
          <Design
            className="has-pointer"
            display="flex"
            justifyContent="flex-start"
            padding={{ all: '0 0 24px', tablet: '0 0 16px', mobile: '17px 0' }}
            onClick={() => setShowModal(true)}
          >
            <Design width="24px" height="24px" marginRight="8px" padding="4px">
              <Plus height={16} width={16} />
            </Design>
            <Wording
              color="surface"
              fontSize={{
                all: '16px',
                desktop: '18px',
              }}
              lineHeight={{
                all: '22px',
                desktop: '26px',
              }}
              fontWeight="500"
            >
              Add New Address
            </Wording>
          </Design>
        </Design>
        <Design className={isMobile ? 'container' : ''}>
          {addressList?.data?.map((address, index) => (
            <Design
              key={address.id}
              borderBottom="1px solid"
              borderColor={
                index + 1 === addressList.data.length
                  ? 'transparent'
                  : 'natural'
              }
            >
              <CheckoutAddressItem
                address={address}
                isSelected={activatedAddress?.id === address.id}
                onSelectAddress={() => handelChooseAddress(address)}
                onEdit={() => handleShowAddressModal(address)}
              />
            </Design>
          ))}
        </Design>
      </Design>
      <Modal
        visible={visibleModal}
        title={selectedAddress?.id ? 'EDIT ADDRESS' : 'ADD NEW ADDRESS'}
        onCancel={handleCloseAddressModal}
      >
        <Design
          maxWidth="402px"
          margin="auto"
          padding={{ all: '16px 0 32px 0', mobile: '16px' }}
        >
          <Wording
            textAlign="center"
            fontSize={{
              all: '16px',
              mobile: '14px',
            }}
            fontWeight="500"
            lineHeight={{
              all: '22px',
              mobile: '20px',
            }}
            marginTop={{
              all: '12px',
              tablet: '16px',
              mobile: '0',
            }}
          >
            To what address should we send your order?
          </Wording>
          <AddressForm
            enablePrefixChange={false}
            loading={actionLoading}
            onSubmit={handleAddressAction}
            initial={
              selectedAddress ?? {
                full_name: authInfo?.displayName ?? '',
                phone_number: authInfo?.phoneNumber ?? '',
              }
            }
            buttonTitle={
              selectedAddress?.id ? 'SAVE CHANGES' : 'SAVE & CONTINUE'
            }
          />
        </Design>
      </Modal>
    </>
  );
};

export default CheckoutAddressesComponent;
