import React, { FC, useEffect, useMemo, useState } from 'react';

import { Design, Wording } from '@components';
import {
  ProductVariationConvertModel,
  StockStatusEnum,
  VariationConvertModelAttributeModel,
} from '@models';
import cn from 'clsx';

import styles from '../variations.module.scss';

interface Props {
  onOptionSelect: (index: number, optionSlug: string | null) => void;
  variation: ProductVariationConvertModel;
  allowedVariations: string[];
  variationTitlePrefix: string;
  index: number;
  isInCart?: boolean;
  isInLanding?: boolean;
  defaultSelected: number | null;
}

const TextSwatch: FC<Props> = ({
  onOptionSelect,
  variation,
  allowedVariations,
  variationTitlePrefix,
  index,
  defaultSelected,
  isInCart = false,
  isInLanding = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [defaultVariationId, setDefaultVariationId] = useState<number | null>(
    defaultSelected || null
  );

  useEffect(() => {
    setDefaultVariationId(defaultSelected);
  }, [defaultSelected]);

  const localAttributes = useMemo(() => {
    return variation.attributes.map((v) => {
      return v.slug;
    });
  }, [variation]);

  useEffect(() => {
    if (
      selectedOption &&
      allowedVariations.indexOf(selectedOption) === -1 &&
      (!isInCart || !isInLanding)
    )
      setSelectedOption(null);
  }, [allowedVariations]);

  useEffect(() => {
    onOptionSelect(index, selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    const findVariation = variation.attributes.find(
      (i) => i.variationId === defaultSelected
    );

    if (isInCart || isInLanding) {
      setSelectedOption(String(findVariation?.slug));
    }
  }, [defaultSelected]);

  const handleOptionSelect = (slug: string) => {
    if (isInCart || isInLanding) {
      setDefaultVariationId(null);
    }

    if (slug === selectedOption) setSelectedOption(null);
    else setSelectedOption(slug);
  };

  const renderOption = (option: VariationConvertModelAttributeModel) => {
    const isSelected =
      option.slug === selectedOption ||
      option.variationId === defaultVariationId;
    const isOutOfStock = option.stock_status === StockStatusEnum.OUT_OF_STOCK;
    const isNotAllowed =
      localAttributes.indexOf(option.slug) === -1 &&
      allowedVariations.length > 0 &&
      allowedVariations.indexOf(option.slug) === -1;

    const rootClassName = cn({
      [styles.DisableVariation]: isNotAllowed,
      [styles.SelectedVariation]: isSelected,
      [styles.FullDisable]: isOutOfStock,
    });

    return (
      <li
        key={option.slug}
        className={rootClassName}
        onClick={() => {
          if (!isOutOfStock) handleOptionSelect(option.slug);
        }}
      >
        {option.label}
      </li>
    );
  };

  const renderOptions = () =>
    variation.attributes.map((vr) => renderOption(vr));

  if (isInCart || isInLanding)
    return (
      <Design
        key={variation.label}
        display="flex"
        gap="16px"
        marginTop="16px"
        marginBottom="16px"
        alignItems={'flex-start'}
        flexDirection={'column'}
      >
        <Wording
          fontWeight={'500'}
          fontSize={'16px'}
          color={isInLanding ? 'background' : 'surface'}
        >
          {variationTitlePrefix + ' '}
          {variation.label}:
        </Wording>
        <ul
          className={cn(styles.TextSwatchWrapper, {
            [styles.IsInLanding]: isInLanding,
          })}
        >
          {renderOptions()}
        </ul>
      </Design>
    );

  return (
    <Design
      key={variation.label}
      display="flex"
      gap="16px"
      marginTop="16px"
      marginBottom="16px"
      alignItems={{
        all: 'center',
        mobile: 'flex-start',
        tablet: 'flex-start',
      }}
      flexDirection={{
        all: 'row',
        mobile: 'column',
        tablet: 'column',
      }}
    >
      <Wording fontWeight={'normal'} fontSize={'14px'}>
        {variationTitlePrefix + ' '}
        {variation.label}
      </Wording>
      <ul className={styles.TextSwatchWrapper}>{renderOptions()}</ul>
    </Design>
  );
};

export default TextSwatch;
