export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_FAILURE = 'LOAD_CATEGORIES_FAILURE';

export const LOAD_ALL_CATEGORIES = 'LOAD_ALL_CATEGORIES';
export const LOAD_ALL_CATEGORIES_SUCCESS = 'LOAD_ALL_CATEGORIES_SUCCESS';
export const LOAD_ALL_CATEGORIES_FAILURE = 'LOAD_ALL_CATEGORIES_FAILURE';

export const LOAD_DEAL_CATEGORIES = 'LOAD_DEAL_CATEGORIES';
export const LOAD_DEAL_CATEGORIES_SUCCESS = 'LOAD_DEAL_CATEGORIES_SUCCESS';
export const LOAD_DEAL_CATEGORIES_FAILURE = 'LOAD_DEAL_CATEGORIES_FAILURE';

export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES';
export const SET_VIEW_CATEGORIES = 'SET_VIEW_CATEGORIES';

export const CATEGORIES_VIEW = 'CATEGORIES_VIEW';
