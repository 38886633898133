import React, { FC } from 'react';

import { Button, ButtonProps, Spin } from 'antd';
import { Colors } from '@models';
import cn from 'clsx';
import { Loading3QuartersOutlined, LoadingOutlined } from '@ant-design/icons';
import { reset } from 'numeral';

import styles from './button.module.scss';

const antIcon = <LoadingOutlined spin />;

interface Props extends ButtonProps {
  children: React.ReactNode;
  className?: string;
  color?: Colors;
  fullWidth?: boolean;
}

const ButtonComponent: FC<Props> = (props) => {
  const {
    children,
    className,
    color,
    fullWidth,
    loading = false,
    disabled = false,
    ...rest
  } = props;
  const rootClassName = cn(
    {
      [styles['color-primary']]: color === 'primary',
      [styles['color-error']]: color === 'error',
      [styles.round_shape]: rest.shape === 'round',
      [styles.type_default]: !rest.type,
      [styles.full_width]: fullWidth,
    },
    className
  );

  return (
    <Button className={rootClassName} disabled={disabled ?? loading} {...rest}>
      {loading ? <Spin indicator={antIcon} /> : children}
    </Button>
  );
};

export default ButtonComponent;
