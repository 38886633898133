import * as AuthTypes from '@firebase/auth-types';

export const firebaseAuthErrors = {
  'auth/claims-too-large':
    'Something is wrong with authenticating your account please try again or contact support.',
  'auth/email-already-exists':
    'Your email is already in use by an existing user, If you are the email owner please login.',
  'auth/email-already-in-use':
    'Your email is already in use by an existing user, If you are the email owner please login.',
  'auth/id-token-expired': 'Your session is expired please login again.',
  'auth/id-token-revoked': 'Your session is expired please login again.',
  'auth/internal-error':
    'something is wrong with authenticating your account please try again or contact support.',
  'auth/invalid-credential':
    'something is wrong with authenticating your account please try again or contact support.',
  'auth/invalid-email': 'Please enter a valid email for your account',
  'auth/invalid-id-token': 'Your session is expired please login again.',
  'auth/invalid-password':
    'password must be at least 6 characters, please enter again.',
  'auth/invalid-verification-code':
    'Incorrect code! please check and enter again',
  'auth/operation-not-allowed':
    'Something is wrong with authenticating your account please try again or contact support.',
  'auth/user-not-found':
    'There is no user with this Email address please register a new account.',
  'auth/wrong-password':
    'The email address or the password is incorrect. Please try again.',
  'auth/network-request-failed':
    'A network error has occurred. Please check your connectivity and try again',
};

export interface FirebaseStateModel {
  data: AuthProfileState;
}

export interface AuthProfileState extends AuthTypes.UserInfo {
  isLoaded: boolean;
  isEmpty: boolean;
  apiKey: string;
  appName: string;
  authDomain: string;
  createdAt: string;
  emailVerified: boolean;
  isAnonymous: boolean;
  lastLoginAt: string;
  providerData: AuthTypes.UserInfo[] | null;
  redirectEventId: null;
  stsTokenManager: {
    accessToken: string;
    apiKey: string;
    expirationTime: number;
    refreshToken: string;
  };
}
