import React, { FC, useEffect, useState } from 'react';

import { Button, Design } from '@components';
import { Drawer } from 'antd';
import getConfig from 'next/config';
import { Settings } from 'react-feather';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

interface Props {}

const DevTools: FC<Props> = () => {
  const [visible, setVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);

  useEffect(() => {
    const state = localStorage.getItem('devtools-visible-state');

    if (state) {
      setSettingsVisible(true);
    }
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const renderDebugEnvironment = () => {
    const envValues = {
      NEXT_PUBLIC_CURRENCY: process.env.NEXT_PUBLIC_CURRENCY,
      NEXT_PUBLIC_HOST: process.env.NEXT_PUBLIC_HOST,
      NEXT_PUBLIC_PORT: process.env.NEXT_PUBLIC_PORT,
      NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
      NEXT_PUBLIC_APP_NAME: process.env.NEXT_PUBLIC_APP_NAME,
      NEXT_PUBLIC_CAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY,
      NEXT_PUBLIC_CAPTCHA_SECRET_KEY:
        process.env.NEXT_PUBLIC_CAPTCHA_SECRET_KEY,
      NEXT_PUBLIC_REDIS_CONNECTION: process.env.NEXT_PUBLIC_REDIS_CONNECTION,
      NEXT_PUBLIC_REDIS_TTL: process.env.NEXT_PUBLIC_REDIS_TTL,
      NEXT_PUBLIC_UNIVERSAL_ANALYTIC:
        process.env.NEXT_PUBLIC_UNIVERSAL_ANALYTIC,
      NEXT_PUBLIC_HOTJAR: process.env.NEXT_PUBLIC_HOTJAR,
      NEXT_PUBLIC_FIREBASE_CLIENT_API_KEY:
        process.env.NEXT_PUBLIC_FIREBASE_CLIENT_API_KEY,
      NEXT_PUBLIC_FIREBASE_CLIENT_AUTH_DOMAIN:
        process.env.NEXT_PUBLIC_FIREBASE_CLIENT_AUTH_DOMAIN,
      NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID:
        process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID,
      NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET:
        process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      NEXT_PUBLIC_FIREBASE_MESSAGING_ID:
        process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_ID,
      NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID:
        process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
      CACHE_REMOVE_KEY: process.env.CACHE_REMOVE_KEY,
      NEXT_PUBLIC_CACHE_REVALIDATION_IN_SECONDS: process.env.NEXT_PUBLIC_CACHE_REVALIDATION_IN_SECONDS,
      NEXT_PUBLIC_STRIPE_API_KEY: process.env.NEXT_PUBLIC_STRIPE_API_KEY,
    };
    return (
      <Design marginBottom="16px">
        <Button
          fullWidth
          onClick={() => {
            console.log(envValues);
          }}
        >
          Log Environment Variables
        </Button>
      </Design>
    );
  };

  const renderClearFirebaseDB = () => {
    return (
      <Design marginBottom="16px">
        <Button
          fullWidth
          onClick={() => {
            indexedDB
              .databases()
              .then((dbs) =>
                dbs.forEach((db: any) => indexedDB.deleteDatabase(db.name))
              )
              .finally(() => {
                alert('Firebase DB cleared');
              });
          }}
        >
          Clear Firebase DB
        </Button>
      </Design>
    );
  };

  const renderClearCookies = () => {
    return (
      <Design marginBottom="16px">
        <Button
          fullWidth
          onClick={() => {
            var cookies = document.cookie.split('; ');
            for (var c = 0; c < cookies.length; c++) {
              var d = window.location.hostname.split('.');
              while (d.length > 0) {
                var cookieBase =
                  encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
                  '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
                  d.join('.') +
                  ' ;path=';
                var p = location.pathname.split('/');
                document.cookie = cookieBase + '/';
                while (p.length > 0) {
                  document.cookie = cookieBase + p.join('/');
                  p.pop();
                }
                d.shift();
              }
            }
            alert('Cookies cleared');
          }}
        >
          Clear Cookies
        </Button>
      </Design>
    );
  };

  const renderRevalidateButton = () => {
    return (
      <Design marginBottom="16px">
        <Button
          fullWidth
          onClick={() => {
            location.href = location.origin + '/api/revalidate?secret=8610210ee4da4d749bb199735cb4f5fd'
          }}
        >
          Revalidate Home Page
        </Button>
      </Design>
    );
  }

  const renderRedisCacheButton = () => {
    return (
      <Design marginBottom="16px">
        <Button
          fullWidth
          onClick={() => {
            location.href = location.origin + '/api/cache?secret=8610210ee4da4d749bb199735cb4f5fd'
          }}
        >
          Clear Redis Cache
        </Button>
      </Design>
    );
  }

  if (!settingsVisible) return <></>;

  return (
    <Design>
      <Drawer
        title="Dev Tools"
        placement={'left'}
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        {renderDebugEnvironment()}
        {renderClearFirebaseDB()}
        {renderClearCookies()}
        {renderRevalidateButton()}
        {renderRedisCacheButton()}
      </Drawer>

      <Design position="fixed" zIndex="1000" bottom="30px" left="30px">
        <Button
          shape="circle"
          color="primary"
          onClick={showDrawer}
          style={{ display: 'flex', justifyContent: 'center', paddingLeft: 5 }}
        >
          <Settings color="white" />
        </Button>
      </Design>
    </Design>
  );
};

export default DevTools;
