import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Design, Wording } from '@components';
import { AddressModel } from '@models';
import { checkoutSelectors } from '@selectors';

type Props = {
  address: AddressModel;
  isSelected: boolean;
  onEdit: () => void;
  onSelectAddress: () => void;
};
const CheckoutAddressItemModule: FC<Props> = ({
  address,
  isSelected,
  onEdit,
  onSelectAddress,
}) => {
  return (
    <Design
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      padding={{ all: '24px 0', tablet: '20px 0', mobile: '16px 0' }}
    >
      <Design
        display="flex"
        justifyContent="flex-start"
        className="has-pointer"
        onClick={onSelectAddress}
      >
        <Design
          marginTop={{
            all: '4px',
            mobile: '0px',
          }}
          width="20px"
          height="20px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="1px solid"
          borderColor={isSelected ? 'primary' : 'placeholder'}
          borderRadius="50%"
          marginRight="10px"
        >
          <Design
            width="10px"
            height="10px"
            borderRadius="50%"
            backgroundColor={isSelected ? 'primary' : undefined}
          />
        </Design>
        <Design>
          <Wording
            color={isSelected ? 'surface' : 'action'}
            fontSize={{
              all: '16px',
              desktop: '18px',
            }}
            lineHeight={{
              all: '22px',
              desktop: '26px',
            }}
            fontWeight="500"
          >
            {address?.full_name}
          </Wording>
          <Wording
            color="caption"
            fontSize={{
              all: '14px',
              desktop: '16px',
            }}
            lineHeight={{
              all: '20px',
              desktop: '22px',
            }}
            fontWeight="500"
            marginTop="8px"
          >
            {address?.address_line_1}
          </Wording>
          <Wording
            color="caption"
            fontSize={{
              all: '14px',
              desktop: '16px',
            }}
            lineHeight={{
              all: '20px',
              desktop: '22px',
            }}
            fontWeight="500"
            marginTop="6px"
          >
            {address?.area?.name}
          </Wording>
          <Wording
            color="caption"
            fontSize={{
              all: '14px',
              desktop: '16px',
            }}
            lineHeight={{
              all: '20px',
              desktop: '22px',
            }}
            fontWeight="500"
            marginTop="6px"
          >
            {address?.phone_number}
          </Wording>
        </Design>
      </Design>
      <Design className="has-pointer" onClick={onEdit}>
        <Wording
          color="link"
          fontWeight="500"
          lineHeight={{
            all: '18px',
            desktop: '22px',
          }}
          fontSize={{
            all: '12px',
            desktop: '16px',
          }}
        >
          Edit
        </Wording>
      </Design>
    </Design>
  );
};

export default CheckoutAddressItemModule;
