import { getCookie, setCookie } from '@helpers';

const times_key = '1sell-app-banner-close-times';
const date_key = '1sell-app-banner-close-date';

/**
 * Factory function that close App Banner
 * @returns
 */
const CloseBanner = (appGot?: boolean) => {
  const times = +getCookie(times_key);
  const now = new Date();
  if (appGot) {
    setCookie(date_key, now);
    setCookie(times_key, 10);
    return;
  }
  if (times > 0 && getCookie(date_key)) {
    setCookie(date_key, now);
    setCookie(times_key, times + 1);
    return;
  }
  setCookie(date_key, now);
  setCookie(times_key, 1);
  return;
};

/**
 * Factory function that return status of banner
 * @returns {boolean}
 */
const ShowBanner = (): boolean => {
  if (!getCookie(times_key) || !getCookie(date_key)) return true;

  const now = new Date();
  const lastUpdate = new Date(getCookie(date_key));
  const times = +getCookie(times_key);
  const differenceInTimes = now.getTime() - lastUpdate.getTime();
  const differenceInDays = differenceInTimes / (1000 * 3600 * 24);

  if (times < 3 && differenceInDays > 7) return true;
  return false;
};

export { CloseBanner, ShowBanner };
