import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Design, Wording } from '@components';
import { CategoryFacetModel, ProductFacetRangeModel } from '@models';
import { Input, Slider } from 'antd';

import styles from './filters-range.module.scss';
import debounce from 'lodash/debounce';
import { formatPriceWithCurrency } from '@utils/number.utils';

type Props = {
  facet: CategoryFacetModel;
  selectedRangeInfo: string[];
  onRangeChange: (name: string, rangeInfo: ProductFacetRangeModel) => void;
};
const FiltersRangeModule: FC<Props> = ({
  facet,
  selectedRangeInfo,
  onRangeChange,
}) => {
  const initialValues = useMemo(
    () => ({ min: facet?.minimum ?? 1, max: facet?.maximum ?? 12000 }),
    [facet.minimum, facet.maximum]
  );

  const selectedValues = useCallback((): ProductFacetRangeModel => {
    const [ranges] = selectedRangeInfo;
    if (ranges) {
      const [min, max] = ranges.split('-');
      return { min: +min, max: +max };
    }

    return initialValues;
  }, [selectedRangeInfo]);

  const [values, setValues] = useState<ProductFacetRangeModel>(
    selectedValues()
  );

  useEffect(() => {
    setValues({
      min: selectedValues().min,
      max: selectedValues().max,
    });
  }, [selectedRangeInfo, facet.slug]);

  const handleChangeSliderValue = useCallback((v: [number, number]) => {
    setValues({ min: v[0], max: v[1] });
  }, []);

  const valuesArray: [number, number] = useMemo(
    () => [values.min, values.max],
    [values]
  );

  const handleFilterPrice = (range: number[]) => {
    const [min, max] = range;

    onRangeChange(facet.slug, { min, max });
  };

  const handleChange = (value: number, key: 'min' | 'max') => {
    setValues((currentValue) => {
      const newRange = handleChangeValue(value, key, currentValue);
      onRangeChange(facet.slug, newRange);
      return newRange;
    });
  };

  const handleChangeValue = (
    value: number,
    key: 'min' | 'max',
    current: ProductFacetRangeModel
  ) => {
    const newValues = { ...current, [key]: value };
    const newValueArray = [newValues.min, newValues.max].sort((a, b) => a - b);
    const [min, max] = newValueArray;
    return { min, max };
  };

  const inputChange = useCallback(debounce(handleChange, 800), []);

  return (
    <Design
      padding={{
        all: '12px 16px',
        desktop: '12px 10px',
      }}
    >
      <Design
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        marginBottom="20px"
      >
        <Design flex="1" marginRight="12px">
          <Wording
            fontSize="12px"
            fontWeight="500"
            color="caption"
            lineHeight="18px"
            textAlign="center"
          >
            From
          </Wording>
          <Wording
            fontSize="14px"
            fontWeight="500"
            color="background__dark"
            lineHeight="32px"
            textAlign="center"
          >
            {formatPriceWithCurrency(values.min)}
          </Wording>
        </Design>
        <Design flex="1">
          <Wording
            fontSize="12px"
            fontWeight="500"
            color="caption"
            lineHeight="18px"
            textAlign="center"
          >
            To
          </Wording>
          <Wording
            fontSize="14px"
            fontWeight="500"
            color="background__dark"
            lineHeight="32px"
            textAlign="center"
          >
            {formatPriceWithCurrency(values.max)}
          </Wording>
        </Design>
      </Design>

      <Design className={styles.SliderWrapper}>
        <Slider
          range
          value={valuesArray}
          onChange={handleChangeSliderValue}
          onAfterChange={handleFilterPrice}
          tipFormatter={null}
          min={initialValues.min}
          max={initialValues.max}
        />
      </Design>
    </Design>
  );
};

export default FiltersRangeModule;
