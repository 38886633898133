import React, { FC } from 'react';
import { Mail, Phone, Tag } from 'react-feather';

import ds from '@ds';
import { Design, Wording } from '@components';
import { useSelector } from 'react-redux';
import {
  ContactDetailEmailModel,
  ContactDetailPhoneModel,
  ProductModel,
} from '@models';
import { contactSelectors } from '@selectors';

type Props = {
  email: ContactDetailEmailModel;
  phone: ContactDetailPhoneModel;
  productId: number;
};
const BusinessContactInfoModule: FC<Props> = ({ productId, email, phone }) => {
  return (
    <Design
      padding={{
        all: '32px',
        mobile: '16px',
      }}
      backgroundColor="background"
      borderRadius={{ all: '8px', mobile: '0' }}
      display="grid"
      gridTemplateColumns="repeat(3, minmax(0, 1fr))"
      gap="5px"
    >
      <Design textAlign="center">
        <Design
          marginBottom={{ all: '16px', mobile: '6px' }}
          width={{
            all: '38px',
            mobile: '24px',
          }}
          height={{
            all: '38px',
            mobile: '24px',
          }}
          textAlign="center"
          padding="2px"
          margin="0 auto"
        >
          <Tag color={ds.color_caption} width="100%" height="100%" />
        </Design>
        <Wording
          marginBottom={{ all: '8px', mobile: '2px' }}
          fontWeight="500"
          fontSize={{ all: '16px', mobile: '12px' }}
          lineHeight={{ all: '22px', mobile: '18px' }}
          color="caption"
        >
          Product ID
        </Wording>
        <Wording
          fontWeight="500"
          fontSize={{ all: '20px', mobile: '12px' }}
          lineHeight={{ all: '28px', mobile: '18px' }}
          color="link"
        >
          {productId}
        </Wording>
      </Design>
      <Design textAlign="center">
        <Design
          marginBottom={{ all: '16px', mobile: '6px' }}
          width={{
            all: '38px',
            mobile: '24px',
          }}
          height={{
            all: '38px',
            mobile: '24px',
          }}
          textAlign="center"
          padding="2px"
          margin="0 auto"
        >
          <Phone color={ds.color_caption} width="100%" height="100%" />
        </Design>
        <Wording
          marginBottom={{ all: '8px', mobile: '2px' }}
          fontWeight="500"
          fontSize={{ all: '16px', mobile: '12px' }}
          lineHeight={{ all: '22px', mobile: '18px' }}
          color="caption"
        >
          {phone?.title}
        </Wording>
        <Wording
          fontWeight="500"
          fontSize={{ all: '20px', mobile: '12px' }}
          lineHeight={{ all: '28px', mobile: '18px' }}
          color="link"
        >
          <a className="support" href={`tel:${phone?.phone_number}`}>
            {phone?.phone_number}
          </a>
        </Wording>
      </Design>
      <Design textAlign="center">
        <Design
          marginBottom={{ all: '16px', mobile: '6px' }}
          width={{
            all: '38px',
            mobile: '24px',
          }}
          height={{
            all: '38px',
            mobile: '24px',
          }}
          textAlign="center"
          padding="2px"
          margin="0 auto"
        >
          <Mail color={ds.color_caption} width="100%" height="100%" />
        </Design>
        <Wording
          marginBottom={{ all: '8px', mobile: '2px' }}
          fontWeight="500"
          fontSize={{ all: '16px', mobile: '12px' }}
          lineHeight={{ all: '22px', mobile: '18px' }}
          color="caption"
        >
          {email?.title}
        </Wording>
        <Wording
          fontWeight="500"
          fontSize={{ all: '20px', mobile: '12px' }}
          lineHeight={{ all: '28px', mobile: '18px' }}
          color="link"
        >
          <a className="support" href={`mailto:${email?.email}`}>
            {email?.email}
          </a>
        </Wording>
      </Design>
    </Design>
  );
};

export default BusinessContactInfoModule;
