import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { useMediaQuery } from '@hooks';

import { mediaQuery } from '../media/media.component';
import styles from './expandable-container.module.scss';

interface Props {
  shouldBeExpandable: boolean;
  expandButtonText: string;
  children?: ReactNode;
  className: any;
}

/**
 * will collapse the content in mobile view and show the expand button
 */
const ExpandableContainer: FC<Props> = (props: Props) => {
  const { expandButtonText, children, shouldBeExpandable } = props;

  const containerRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [expandHeight, setExpandHeight] = useState(260);
  const [containerHeight, setContainerHeight] = useState(0);
  const isMobile = useMediaQuery(mediaQuery.mobile);

  useEffect(() => {
    if (isMobile) {
      const clientHeight = (containerRef?.current as any).scrollHeight;

      setExpandHeight(clientHeight);

      if (shouldBeExpandable) {
        setContainerHeight(clientHeight * 0.15);
      } else {
        setContainerHeight(clientHeight);
        setExpanded(true);
      }
    }
  }, [containerRef.current]);

  useEffect(() => {
    if (expanded) {
      setContainerHeight(expandHeight);
    }
  }, [expanded]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const renderExpandButton = () => (
    <div className={styles.ExpandButton} onClick={toggleExpand}>
      <span>{expandButtonText}</span>
    </div>
  );

  return (
    <div
      className={`${styles.Container} ${
        expanded ? '' : styles.CollapsedContainer
      }`}
      ref={containerRef}
      {...(isMobile ? { style: { height: `${containerHeight}px` } } : {})}
    >
      {children}

      {isMobile && !expanded && renderExpandButton()}
    </div>
  );
};
export default ExpandableContainer;
