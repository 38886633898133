import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from 'antd';
import { ChevronDown } from 'react-feather';
import Link from 'next/link';

import { usePromise, useMediaQuery } from '@hooks';
import { cmsActions } from '@actions';
import { cmsSelectors } from '@selectors';
import { Design, Image, mediaQuery, Wording } from '@components';

import styles from './value-proposition.module.scss';

const { Panel } = Collapse;

interface Props {
  isInCart?: boolean;
}

const ValueProposition: FC<Props> = ({ isInCart }) => {
  const promise = usePromise();
  const isMobile = useMediaQuery(mediaQuery.mobile);

  const valuePropositions = useSelector(cmsSelectors.valuePropositions);

  useEffect(() => {
    promise(cmsActions.loadValueProposition());
  }, []);

  const renderShimmerItem = (
    <Design
      borderTop="1px solid"
      borderColor="natural"
      display="flex"
      gap="12px"
      alignItems="center"
      padding="18px 0"
    >
      <Design
        className={styles.ShimmerItem}
        width={{ all: '28px', mobile: '24px' }}
        height={{ all: '28px', mobile: '24px' }}
        borderRadius="4px"
      />
      <Design
        className={styles.ShimmerItem}
        width="198px"
        height="16px"
        borderRadius="100"
      />
    </Design>
  );

  if (valuePropositions.fetching) {
    return (
      <>
        <Design
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
        >
          {renderShimmerItem}
          {renderShimmerItem}
          {renderShimmerItem}
        </Design>
      </>
    );
  }

  return (
    <div className={styles.Wrapper}>
      <Collapse
        accordion
        expandIconPosition="right"
        className={isInCart ? styles.isInCart : ''}
        expandIcon={() => <ChevronDown />}
      >
        {valuePropositions?.data?.data?.map((item, index) => (
          <Panel
            key={index}
            header={
              <Design display="flex" alignItems="center" gap="12px">
                <Design
                  position="relative"
                  width={{ all: '28px', mobile: '24px' }}
                  height={{ all: '28px', mobile: '24px' }}
                >
                  <Image
                    src={item.icon.urls.original}
                    alt={item.title}
                    width={isMobile ? 24 : 28}
                    height={isMobile ? 24 : 28}
                  />
                </Design>

                <Wording
                  fontSize={{ all: '20px', tablet: '18px', mobile: '18px' }}
                  lineHeight={{ all: '28px', tablet: '24px', mobile: '24px' }}
                  fontWeight="500"
                >
                  {item.title}
                </Wording>
              </Design>
            }
          >
            {item.paragraphs.map((par, index) => (
              <p key={index}>
                {par}

                {!!item.more_info_url && index === item.paragraphs.length - 1 && (
                  <Link href={item.more_info_url}>
                    <a className={styles.ReadMoreLink} target="_blank">
                      More info
                    </a>
                  </Link>
                )}
              </p>
            ))}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ValueProposition;
