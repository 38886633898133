import { CarouselProductsListModel } from './products-list.model';
import { FetchingDataType } from './redux.model';

export enum SectionType {
  BANNERS = 'banners',
  PRODUCTS = 'products',
  PRODUCT_DEAL = 'product_deal',
  BRAND_VALUES = 'brand_values',
  PRODUCT_RECOMMENDATION = 'product_recommendation',
  PRODUCT_RECENTLY_VIEWED = 'product_recently_viewed',
  PRODUCT_BEST_SELLER = 'product_best_seller',
}

export interface SliderImageDesktopEntity {
  urls: {
    original: string;
    blurImageUrl: string;
  };
}

export interface SliderImageMobileEntity {
  urls: {
    original: string;
  };
}

export interface HeroBannerDesktopEntity {
  urls: {
    original: string;
    blurImageUrl: string;
  };
}

export interface HeroBannerMobileEntity {
  urls: {
    165: string;
    original: string;
  };
}

export interface BannerDesktopEntity {
  urls: {
    588: string;
    original: string;
  };
}

export interface BannerMobileEntity {
  urls: {
    165: string;
    original: string;
  };
}

export interface ProductListEntity {
  path: string;
  requires_auth: boolean;
  show_more_button: boolean;
  title: string;
}

export interface BannerEntity {
  title: string;
  url: string;
  desktop_image: BannerDesktopEntity;
  mobile_image: BannerMobileEntity;
}

export interface SliderEntity {
  title: string;
  url: string;
  desktop_image: SliderImageDesktopEntity;
  mobile_image: SliderImageMobileEntity;
}

export interface DealEntity {
  slug: string;
  sub_title: string;
  title: string;
  background_color: string;
  desktop_image: SliderImageDesktopEntity;
  mobile_image: SliderImageMobileEntity;
}

export interface DataStructure {
  product_list: ProductListEntity;
  product: CarouselProductsListModel[];
  banner: BannerEntity[];
  slider: SliderEntity[];
  deal: DealEntity;
  deals: Map<string, DealEntity>;
  type: SectionType;
}

export interface SliderStructure {
  desktop_image: SliderImageDesktopEntity;
  mobile_image: SliderImageMobileEntity;
  title: string;
  url: string;
}

export interface HeroBannerStructure {
  desktop_image: HeroBannerDesktopEntity;
  mobile_image: HeroBannerMobileEntity;
  title: string;
  url: string;
}

export interface HomeStructureModel {
  data: DataStructure[];
  sliders: SliderStructure[];
  hero_banners: HeroBannerStructure[];
}
export interface HomeStateModel {
  all: FetchingDataType<HomeStructureModel>;
}
