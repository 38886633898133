import { useState } from 'react';
import { mediaQuery } from '@components';
import useIsomorphicLayoutEffect from './useIsoMorphicLayoutEffect';
import useMediaQuery from './useMediaQuery';

const useRemoveChatIcon = () => {
  const [isRunning, setIsRunning] = useState(false);
  const isDesktop = useMediaQuery(mediaQuery.desktop);

  useIsomorphicLayoutEffect(() => {
    let interval: NodeJS.Timer;
    let hubspot = document.getElementById('hubspot-messages-iframe-container');

    if (!isDesktop && !isRunning) {
      setIsRunning(true);
      interval = setInterval(() => {
        if (!hubspot) {
          hubspot = document.getElementById(
            'hubspot-messages-iframe-container'
          );
        } else {
          hubspot.style.cssText = 'display: none !important';
          clearInterval(interval);
        }
      }, 300);
    }

    return () => {
      clearInterval(interval);
      setIsRunning(false);
      if (hubspot) {
        hubspot.style.cssText =
          'display: initial!important; width: 100px; height: 96px;';
      }
    };
  }, [isDesktop]);
};

export default useRemoveChatIcon;
