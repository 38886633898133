import React, { FC } from 'react';

import { Design, Wording } from '@components';

interface Props {
  amount: number;
}

const ProductDiscountBadge: FC<Props> = ({ amount }: Props) => {
  return (
    <>
      {amount > 0 && (
        <Design backgroundColor="error" padding="0 6px" borderRadius="100px">
          <Wording
            color="background"
            fontWeight="600"
            fontSize="16px"
            lineHeight="22px"
          >
            {amount}%
          </Wording>
        </Design>
      )}
    </>
  );
};

export default ProductDiscountBadge;
