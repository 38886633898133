import { profileActionTypes } from '@actionTypes';
import { ProfileStateModel } from '@models';

import { reducerItemInitialState } from './reducer.constant';

const initialState: ProfileStateModel = {
  wallet: reducerItemInitialState as any,
  walletHistory: reducerItemInitialState as any,
};

function profileReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * LOAD_PROFILE
     */
    case profileActionTypes.LOAD_PROFILE:
      return {
        ...state,
        fetching: true,
      };
    case profileActionTypes.LOAD_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.data,
        fetching: false,
      };
    case profileActionTypes.LOAD_PROFILE_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    /**
     * LOAD_PROFILE_WALLET_BALANCE
     */
    case profileActionTypes.LOAD_PROFILE_WALLET_BALANCE:
      return {
        ...state,
        wallet: {
          fetching: true,
        },
      };
    case profileActionTypes.LOAD_PROFILE_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        wallet: {
          ...action.data,
          fetching: false,
        },
      };
    case profileActionTypes.LOAD_PROFILE_WALLET_BALANCE_FAILURE:
      return {
        ...state,
        wallet: {
          fetching: false,
        },
      };

    /**
     * LOAD_WALLET_HISTORY
     */
    case profileActionTypes.LOAD_WALLET_HISTORY:
      return {
        ...state,
        walletHistory: {
          ...state.walletHistory,
          fetching: true,
        },
      };
    case profileActionTypes.LOAD_WALLET_HISTORY_SUCCESS:
      return {
        ...state,
        walletHistory: {
          data: action.data,
          fetching: false,
        },
      };
    case profileActionTypes.LOAD_WALLET_HISTORY_FAILURE:
      return {
        ...state,
        walletHistory: {
          ...state.walletHistory,
          fetching: false,
        },
      };

    default:
      return state;
  }
}

export default profileReducer;
