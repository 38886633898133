import React, { FC } from 'react';

import { Design, Wording } from '@components';
import { ProductBrandModel } from '@models';

interface Props {
  brand: ProductBrandModel;
}

const ProductBrand: FC<Props> = ({ brand }: Props) => {
  if (!brand) return <></>;

  return (
    <Design marginTop="6px" marginBottom="16px">
      <Wording fontSize="16px" color="action">
        {brand.titles.en}
      </Wording>
    </Design>
  );
};

export default ProductBrand;
