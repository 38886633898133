import {
  FetchingDataType,
  ProductCarouselListItemModel,
  AddressModel,
  AddressRequestModel,
  CartItemModel,
  PaymentMethodEnum,
} from '@models';
import {
  CheckoutPaymentMethodModel,
  CheckoutPriceItemModel,
  PromotionModel,
} from './checkout.model';

export interface OrderStateModel {
  all: FetchingDataType<any>;
  detail: FetchingDataType<OrderModel>;
  payment_methods: FetchingDataType<any>;
  checkout: FetchingDataType<any>;
  recommendations: FetchingDataType<ProductCarouselListItemModel[]>;
  recentOrder: PlaceOrderResponseModel;
}

export interface OrderModel {
  id: number;
  billing_address: AddressModel;
  shipping_address: AddressModel;
  status: OrderStatusEnum;
  items: any[];
  promotion: OrderPromotionModel;
  payment_method: OrderPaymentMethodModel;
  shipping_method: string;
  shipping_method_title: string;
  is_cancelable: true;
  estimated_shipping: string;
  items_sub_total: number;
  price_items: any[];
  sub_total: number;
  total: number;
  created_at: string;
  updated_at: string;
  paid_amount: number;
  due_amount: number;
  raw_total: number;
  is_payable: true;
}

export interface OrderPromotionModel {
  name: string;
  description: string;
  discount: number;
  coupon_code: string;
}

export interface OrderPaymentMethodModel {
  title: string;
  description: string;
  amount: number;
  slug: string;
}

export interface PlaceOrderBody {
  billing_address?: AddressRequestModel;
  billing_address_id: number;
  coupon_code: string;
  customer_note: string;
  payment_method: PaymentMethodEnum;
  shipping_address?: AddressRequestModel;
  shipping_address_id: number;
  use_wallet: boolean;
}

export interface PlaceOrderResponseAdminModel {
  created_at: string;
  deleted_at: string;
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  is_active: true;
  is_system: true;
  last_name: string;
  mobile_number: string;
  updated_at: string;
}

export interface PlaceOrderResponseCustomerModel {
  created_at: string;
  customer_id: string;
  date_of_birth: string;
  email: string;
  full_name: string;
  is_active: true;
  mobile_number: string;
}

export enum AgentTypeEnum {
  ANDROID = 'android',
  IOS = 'ios',
  WEB = 'web',
  ADMIN = 'admin',
}

export enum CouponTypeEnum {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
  CART_FIXED = 'cart_fixed',
}
export interface PlaceOrderResponsePromotionCouponModel {
  agent_type: AgentTypeEnum[];
  amount: number;
  coupon_code: string;
  created_at: string;
  deleted_at: string;
  descriptions: {
    ar: string;
    en: string;
  };
  first_time_buyer: true;
  free_shipping: true;
  from_date: string;
  id: number;
  includes_shipping: true;
  is_active: true;
  minimum_order_amount: number;
  names: {
    ar: string;
    en: string;
  };
  to_date: string;
  type: CouponTypeEnum;
  updated_at: string;
  usage_count: number;
  usage_per_coupon: number;
  usage_per_customer: number;
}
export interface PlaceOrderResponsePromotionModel {
  coupon: PlaceOrderResponsePromotionCouponModel;
  discount: number;
}

export enum SaleChannelEnum {
  ONESELL_FACEBOOK = 'Onesell_facebook',
  ONESELL_INSTAGRAM = 'Onesell_instagram',
  ONESELL_WHATSAPP = 'Onesell_whatsapp',
  QSOUQ_FACEBOOK = 'Qsouq_facebook',
  QSOUQ_INSTAGRAM = 'Qsouq_instagram',
  QSOUQ_WHATSAPP = 'Qsouq_whatsapp',
  OTHERS = 'others',
}

export enum OrderStatusEnum {
  PENDING = 'pending',
  PROCESSING = 'processing',
  PARTIALLY_SHIPPED = 'partially_shipped',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}
export interface PlaceOrderResponseModel {
  application_payment_url: string;
  billing_address: AddressModel;
  created_at: string;
  customer_note: string;
  due_amount: number;
  estimated_shipping: string;
  id: number;
  is_cancelable: true;
  is_payable: true;
  items: CartItemModel[];
  items_sub_total: number;
  paid_amount: number;
  payment_info: {
    cod: any;
    stripe: {
      client_secret: string;
    };
    type: 'payment_type_cod' | 'payment_type_stripe';
  };
  payment_method: CheckoutPaymentMethodModel;
  price_items: CheckoutPriceItemModel[];
  promotion: PromotionModel;
  raw_total: number;
  shipping_address: AddressModel;
  shipping_method: string;
  shipping_method_title: string;
  status: OrderStatusEnum;
  sub_total: number;
  total: number;
  updated_at: string;
}
