import React from 'react';

import { Design } from '@components';

import styles from '../profile-simmer.module.scss';

const OrderItemShimmer = () => {
  const items = [1, 2, 3];

  return (
    <Design display="flex" gap="16px" flexDirection="column">
      <Design
        width="100%"
        display="flex"
        justifyContent="space-between"
        backgroundColor="background"
        borderRadius="8px"
        alignItems="center"
        padding={{ all: '24px 16px' }}
      >
        <Design
          width="80px"
          height="16px"
          className={styles.ShimmerItem}
        ></Design>

        <Design display="flex" gap="8px" flexDirection="column">
          <Design
            width="53px"
            height="12px"
            className={styles.ShimmerItem}
          ></Design>
          <Design
            width="74px"
            height="12px"
            className={styles.ShimmerItem}
          ></Design>
        </Design>

        <Design display="flex" gap="8px" flexDirection="column">
          <Design
            width="53px"
            height="12px"
            className={styles.ShimmerItem}
          ></Design>
          <Design
            width="74px"
            height="12px"
            className={styles.ShimmerItem}
          ></Design>
        </Design>

        <Design display="flex" gap="8px" flexDirection="column">
          <Design
            width="53px"
            height="12px"
            className={styles.ShimmerItem}
          ></Design>
          <Design
            width="74px"
            height="12px"
            className={styles.ShimmerItem}
          ></Design>
        </Design>
      </Design>

      <Design
        width="100%"
        display="flex"
        backgroundColor="background"
        borderRadius="8px"
        flexDirection="column"
        padding={{ all: '24px 16px' }}
      >
        <Design
          width="80px"
          height="16px"
          margin="0 0 42px 0"
          className={styles.ShimmerItem}
        ></Design>

        <Design
          maxWidth="440px"
          width="100%"
          height="12px"
          margin="0 0 12px 0"
          className={styles.ShimmerItem}
        ></Design>
        <Design
          width="126px"
          height="32px"
          borderRadius="100px"
          className={styles.ShimmerItem}
        ></Design>
      </Design>

      <Design
        display="flex"
        gap="16px"
        flexDirection={{ all: 'row', mobile: 'column' }}
      >
        <Design
          width="100%"
          display="flex"
          backgroundColor="background"
          borderRadius="8px"
          flexDirection="column"
          height="276px"
          padding={{ all: '24px 16px' }}
        >
          <Design
            width="80px"
            height="16px"
            margin="0 0 32px 0"
            className={styles.ShimmerItem}
          ></Design>
          <Design
            maxWidth="284px"
            width="100%"
            height="12px"
            margin="0 0 12px 0"
            className={styles.ShimmerItem}
          ></Design>
          <Design
            maxWidth="192px"
            width="100%"
            height="12px"
            margin="0 0 12px 0"
            className={styles.ShimmerItem}
          ></Design>
        </Design>
        <Design
          width="100%"
          display="flex"
          backgroundColor="background"
          borderRadius="8px"
          flexDirection="column"
          height="276px"
          padding={{ all: '24px 16px' }}
        >
          <Design
            width="80px"
            height="16px"
            margin="0 0 32px 0"
            className={styles.ShimmerItem}
          ></Design>
          <Design
            maxWidth="284px"
            width="100%"
            height="12px"
            margin="0 0 12px 0"
            className={styles.ShimmerItem}
          ></Design>
          <Design
            maxWidth="192px"
            width="100%"
            height="12px"
            margin="0 0 12px 0"
            className={styles.ShimmerItem}
          ></Design>
        </Design>
      </Design>

      <Design
        width="100%"
        display="flex"
        backgroundColor="background"
        borderRadius="8px"
        flexDirection="column"
      >
        <Design padding={{ all: '24px 16px' }}>
          <Design
            width="80px"
            height="16px"
            className={styles.ShimmerItem}
          ></Design>
        </Design>

        {items.map((item) => (
          <Design
            key={item}
            borderTop={item === 1 ? '0' : '1px solid'}
            borderColor="natural"
            display="flex"
            gap="16px"
            padding={{ all: item === 1 ? '0 16px 16px' : '16px' }}
            margin={{ all: item === 1 ? '8px 0 0 0' : '0' }}
          >
            <Design
              width="104px"
              height="104px"
              className={styles.ShimmerItem}
            ></Design>

            <Design
              display="flex"
              justifyContent="space-between"
              flex="1"
              gap="8px"
              flexDirection={{ all: 'row', mobile: 'column' }}
            >
              <Design
                margin="10px 0 0 0"
                display="flex"
                flexDirection="column"
                gap="12px"
              >
                <Design
                  width="192px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
                <Design
                  width="161px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
              </Design>

              <Design
                margin="10px 0 0 0"
                display="flex"
                flexDirection="column"
                gap="12px"
              >
                <Design
                  width="74px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
                <Design
                  width="74px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
              </Design>

              <Design
                margin="10px 0 0 0"
                display="flex"
                flexDirection="column"
                gap="12px"
              >
                <Design
                  width="74px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
                <Design
                  width="74px"
                  height="12px"
                  className={styles.ShimmerItem}
                ></Design>
              </Design>

              <Design
                width="107px"
                height="24px"
                borderRadius="100px"
                className={styles.ShimmerItem}
              ></Design>
            </Design>
          </Design>
        ))}
      </Design>
    </Design>
  );
};

export default OrderItemShimmer;
