import React, { FC, useMemo } from 'react';

import {
  generateCategoryRouteForBreadcrumb,
  generateProductRouteForBreadcrumb,
} from '@helpers';
import { CategoryModel, ProductCategory } from '@models';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import Link from 'next/link';

interface Props {
  category: ProductCategory | CategoryModel | null;
  productTitle?: string;
  location: 'PLP' | 'PDP';
}

const Breadcrumb: FC<Props> = ({ category, location, productTitle }) => {
  const routes = useMemo(() => {
    if (!category) return [];

    // PLP Breadcrumb
    if (location === 'PLP')
      return generateCategoryRouteForBreadcrumb(category as CategoryModel);

    // PDP Breadcrumb
    return generateProductRouteForBreadcrumb(category as ProductCategory);
  }, [category]);

  return (
    <AntdBreadcrumb>
      {routes.length > 0 && (
        <AntdBreadcrumb.Item key="home-link">
          <Link href="/">
            <a>Home</a>
          </Link>
        </AntdBreadcrumb.Item>
      )}

      {routes.map((route, i) => (
        <AntdBreadcrumb.Item key={`${route.key}_${route.title}`}>
          <Link href={`/${route.path}`}>
            <a>{route.title}</a>
          </Link>
        </AntdBreadcrumb.Item>
      ))}
    </AntdBreadcrumb>
  );
};

export default Breadcrumb;
